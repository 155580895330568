import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Unlock = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M15 14.25a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75z" />
      <path
        clipRule="evenodd"
        d="M3.818 3.818A4.5 4.5 0 0111.5 7v2H6.75a.75.75 0 00-.75.75v13.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75H13V7A6 6 0 101 7v2.25a.75.75 0 001.5 0V7a4.5 4.5 0 011.318-3.182zM22.5 10.5h-15v12h15v-12z"
        fillRule="evenodd"
      />
    </g>
  );
});
Unlock.displayName = 'Unlock';
