import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesResponsiveWebSiteBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M5.15 19.6c-1.33 0-1.7-.37-1.83-.5a1.13 1.13 0 01-.32-.8v-1.46a1.13 1.13 0 011.1-1.12H22V6.48a.25.25 0 00-.25-.25h-10.4a1 1 0 110-2h10.42A2.25 2.25 0 0124 6.48v10.87a2.249 2.249 0 01-2.25 2.25H5.15z" />
        <path d="M13.5 24a1 1 0 01-1-1v-4.4a1 1 0 012 0V23a1 1 0 01-1 1z" />
        <path d="M18.86 24H8.08a1 1 0 010-2h10.78a1 1 0 010 2zM7 12.63H2.07a1.5 1.5 0 110-3H7a1.5 1.5 0 010 3z" />
        <path d="M7.8 13.64H1.44A1.45 1.45 0 010 12.19V1.45A1.45 1.45 0 011.44 0H7.8a1.45 1.45 0 011.45 1.45v10.74a1.46 1.46 0 01-.45 1.05 1.44 1.44 0 01-1 .4zm-5.8-2h5.25V2H2v9.64z" />
      </g>
    );
  }
);
DevicesResponsiveWebSiteBold.displayName = 'DevicesResponsiveWebSiteBold';
