import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BrickSideviewBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.29 6.277h-2.23V4.021a1.037 1.037 0 00-.143-.611A1.006 1.006 0 0020.45 3H14.8a1.005 1.005 0 00-.473.408 1.038 1.038 0 00-.147.613v2.256H9.82V4.021a1.038 1.038 0 00-.143-.611A1.006 1.006 0 009.21 3H3.56a1.006 1.006 0 00-.473.408 1.038 1.038 0 00-.147.613v2.256H.71a.697.697 0 00-.502.213.726.726 0 00-.208.512v13.273c0 .192.075.377.208.513A.703.703 0 00.71 21h22.58a.703.703 0 00.502-.212.733.733 0 00.208-.513V7.002a.74.74 0 00-.208-.512.71.71 0 00-.502-.213z" />
      </g>
    );
  }
);
BrickSideviewBold.displayName = 'BrickSideviewBold';
