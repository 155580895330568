/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  ChakraTheme,
  extendTheme as chakraExtendTheme,
  isChakraTheme,
  Theme,
  ThemeExtension,
  ThemeOverride,
} from '@chakra-ui/react';
import { theme as klikTheme } from '@lego/klik-ui-theme';

export const extendTheme = <
  BaseTheme extends ChakraTheme = Theme,
  Extensions extends (
    | BaseTheme
    // @ts-expect-error https://github.com/chakra-ui/chakra-ui/issues/4226
    | ThemeExtension<ThemeOverride<BaseTheme>>
    | ThemeOverride<BaseTheme>
  )[] = (ThemeExtension<ThemeOverride<BaseTheme>> | ThemeOverride<BaseTheme>)[]
>(
  ...extensions: [...Extensions]
) => {
  let overrides = [...extensions];
  let baseTheme = extensions[extensions.length - 1];

  if (
    isChakraTheme(baseTheme) &&
    // this ensures backward compatibility
    // previously only `extendTheme(override, baseTheme?)` was allowed
    overrides.length > 1
  ) {
    overrides = overrides.slice(0, overrides.length - 1);
  } else {
    baseTheme = klikTheme as unknown as BaseTheme;
  }

  return chakraExtendTheme(...overrides, baseTheme);
};
