import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClientProvider } from './providers/ApolloClientProvider';
import { UserProvider } from './providers/UserProvider';
import { KlikContextProvider } from '@lego/klik-react';
import { KlikProvider } from '@lego/klik-ui';
import { RouteHandler } from './components/RouteHandler';
import { logger } from './utils/logger';
import { ToastContainer } from 'react-toastify';
import { snackbarConfig } from './utils/snackbar';

import './App.css';
import './index.scss';
import { UsersnapProvider } from './providers/UserSnapProvider';

const App = () => {
  // Init error logging to Elastic APM
  logger.init();

  return (
    <ApolloClientProvider>
      <UserProvider>
        <KlikContextProvider>
          <KlikProvider>
            <ToastContainer {...snackbarConfig} />
            <UsersnapProvider>
              <Router>
                <RouteHandler />
              </Router>
            </UsersnapProvider>
          </KlikProvider>
        </KlikContextProvider>
      </UserProvider>
    </ApolloClientProvider>
  );
};

export { App };
