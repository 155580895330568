import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DuplicateBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M1.25 4.251a3.001 3.001 0 013-3.001H16.5A2.75 2.75 0 0119.25 4a1.5 1.5 0 01-2.98.25H4.25v12.021A1.5 1.5 0 014 19.25a2.75 2.75 0 01-2.75-2.75V4.251z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.5 6.5a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2v-12a2 2 0 00-2-2h-12zm7 5.2a1 1 0 00-2 0v1.8h-1.8a1 1 0 000 2h1.8v1.8a1 1 0 102 0v-1.8h1.8a1 1 0 100-2h-1.8v-1.8z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
DuplicateBold.displayName = 'DuplicateBold';
