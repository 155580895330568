import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LaptopSmileyBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#laptop-smiley-bold_svg__a)">
          <path d="M23.793 20.519l-2.043-5.107H2.08L.22 20.366a2.339 2.339 0 002.176 3.33H21.8a2.237 2.237 0 001.992-3.177zm-9.652 0h-4.003a1.021 1.021 0 110-2.043h4.003a1.021 1.021 0 110 2.043z" />
          <path d="M21.873 15.79H2.079V3.626a2.717 2.717 0 012.789-2.625h14.217a2.717 2.717 0 012.798 2.635l-.01 12.154zM3.938 14.044h16.086V3.626a.92.92 0 00-.95-.888H4.868a.92.92 0 00-.95.888l.02 10.418z" />
          <path d="M9.668 7.773A1.021 1.021 0 018.647 6.75V5.444a1.021 1.021 0 012.042 0v1.307a1.021 1.021 0 01-1.021 1.022zm4.473 0A1.021 1.021 0 0113.12 6.75V5.444a1.021 1.021 0 012.043 0v1.307a1.021 1.021 0 01-1.022 1.022zm-2.635 4.575c-1.694-.102-2.907-.633-4.34-1.542a1.022 1.022 0 011.113-1.695 8.406 8.406 0 003.738 1.195 8.334 8.334 0 003.667-1.226 1.023 1.023 0 011.113 1.716c-1.441.904-2.572 1.437-4.27 1.552h-1.02z" />
        </g>
        <defs>
          <clipPath id="laptop-smiley-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
LaptopSmileyBold.displayName = 'LaptopSmileyBold';
