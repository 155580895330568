import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const TagBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 25', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M22.23.064A2.308 2.308 0 0021.69 0h-6.423a3.851 3.851 0 00-2.722 1.127L.676 12.995a2.31 2.31 0 000 3.267l7.062 7.063a2.31 2.31 0 003.266 0l11.87-11.87A3.85 3.85 0 0024 8.733V2.31A2.31 2.31 0 0022.23.064z" />
    </g>
  );
});
TagBold.displayName = 'TagBold';
