import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const PieChart = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M20.894 3.856A9.75 9.75 0 0014 1a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h9a.75.75 0 00.75-.75 9.75 9.75 0 00-2.856-6.894zM14.75 2.534A8.249 8.249 0 0122.216 10H14.75V2.534z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.333 5.393A9.75 9.75 0 0110.75 3.75a.75.75 0 01.75.75v8.25h8.25a.75.75 0 01.75.75A9.75 9.75 0 115.333 5.393zM10 5.284a8.25 8.25 0 108.966 8.966H10.75a.75.75 0 01-.75-.75V5.284z"
        fillRule="evenodd"
      />
    </g>
  );
});
PieChart.displayName = 'PieChart';
