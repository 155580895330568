import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ProfitAndLossBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M20 1.25a.75.75 0 01.743.648L20.75 2v7a.75.75 0 01-1.493.102L19.25 9V2a.75.75 0 01.75-.75z" />
        <path d="M19.47 1.47a.75.75 0 01.976-.073l.084.073 3 3a.75.75 0 01-.976 1.133l-.084-.073L20 3.061l-2.47 2.47a.75.75 0 01-.976.072l-.084-.073a.75.75 0 01-.073-.976l.073-.084 3-3zM4 22.75a.75.75 0 01-.743-.648L3.25 22v-7a.75.75 0 011.493-.102L4.75 15v7a.75.75 0 01-.75.75z" />
        <path d="M4.53 22.53a.75.75 0 01-.976.073l-.084-.073-3-3a.75.75 0 01.976-1.133l.084.073L4 20.939l2.47-2.47a.75.75 0 01.976-.072l.084.073a.75.75 0 01.073.976l-.073.084-3 3zM5 11.5A6.5 6.5 0 1011.5 5 6.508 6.508 0 005 11.5zm5.592-3.697l-.006-.008a.19.19 0 00.165-.2v-.35a.75.75 0 011.5 0v.335a.17.17 0 00.169.165h.581a.75.75 0 010 1.5h-2.035a.592.592 0 00-.219 1.141l2.062.826a2.078 2.078 0 01-.417 3.989.184.184 0 00-.137.2v.353a.75.75 0 01-1.5 0v-.334a.17.17 0 00-.164-.166H10a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.061-.83a2.079 2.079 0 01.4-3.98z" />
      </g>
    );
  }
);
ProfitAndLossBold.displayName = 'ProfitAndLossBold';
