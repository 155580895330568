import { __assign } from "tslib";
import { SENSITIVE_STRING } from "@aws-sdk/smithy-client";
export var AccountLimit;
(function (AccountLimit) {
    AccountLimit.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AccountLimit || (AccountLimit = {}));
export var AccountUsage;
(function (AccountUsage) {
    AccountUsage.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AccountUsage || (AccountUsage = {}));
export var AddLayerVersionPermissionRequest;
(function (AddLayerVersionPermissionRequest) {
    AddLayerVersionPermissionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AddLayerVersionPermissionRequest || (AddLayerVersionPermissionRequest = {}));
export var AddLayerVersionPermissionResponse;
(function (AddLayerVersionPermissionResponse) {
    AddLayerVersionPermissionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AddLayerVersionPermissionResponse || (AddLayerVersionPermissionResponse = {}));
export var InvalidParameterValueException;
(function (InvalidParameterValueException) {
    InvalidParameterValueException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidParameterValueException || (InvalidParameterValueException = {}));
export var PolicyLengthExceededException;
(function (PolicyLengthExceededException) {
    PolicyLengthExceededException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PolicyLengthExceededException || (PolicyLengthExceededException = {}));
export var PreconditionFailedException;
(function (PreconditionFailedException) {
    PreconditionFailedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PreconditionFailedException || (PreconditionFailedException = {}));
export var ResourceConflictException;
(function (ResourceConflictException) {
    ResourceConflictException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceConflictException || (ResourceConflictException = {}));
export var ResourceNotFoundException;
(function (ResourceNotFoundException) {
    ResourceNotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceNotFoundException || (ResourceNotFoundException = {}));
export var ServiceException;
(function (ServiceException) {
    ServiceException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ServiceException || (ServiceException = {}));
export var ThrottleReason;
(function (ThrottleReason) {
    ThrottleReason["CallerRateLimitExceeded"] = "CallerRateLimitExceeded";
    ThrottleReason["ConcurrentInvocationLimitExceeded"] = "ConcurrentInvocationLimitExceeded";
    ThrottleReason["FunctionInvocationRateLimitExceeded"] = "FunctionInvocationRateLimitExceeded";
    ThrottleReason["ReservedFunctionConcurrentInvocationLimitExceeded"] = "ReservedFunctionConcurrentInvocationLimitExceeded";
    ThrottleReason["ReservedFunctionInvocationRateLimitExceeded"] = "ReservedFunctionInvocationRateLimitExceeded";
})(ThrottleReason || (ThrottleReason = {}));
export var TooManyRequestsException;
(function (TooManyRequestsException) {
    TooManyRequestsException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TooManyRequestsException || (TooManyRequestsException = {}));
export var AddPermissionRequest;
(function (AddPermissionRequest) {
    AddPermissionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AddPermissionRequest || (AddPermissionRequest = {}));
export var AddPermissionResponse;
(function (AddPermissionResponse) {
    AddPermissionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AddPermissionResponse || (AddPermissionResponse = {}));
export var AliasRoutingConfiguration;
(function (AliasRoutingConfiguration) {
    AliasRoutingConfiguration.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AliasRoutingConfiguration || (AliasRoutingConfiguration = {}));
export var AliasConfiguration;
(function (AliasConfiguration) {
    AliasConfiguration.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AliasConfiguration || (AliasConfiguration = {}));
export var AllowedPublishers;
(function (AllowedPublishers) {
    AllowedPublishers.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AllowedPublishers || (AllowedPublishers = {}));
export var Architecture;
(function (Architecture) {
    Architecture["arm64"] = "arm64";
    Architecture["x86_64"] = "x86_64";
})(Architecture || (Architecture = {}));
export var CreateAliasRequest;
(function (CreateAliasRequest) {
    CreateAliasRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateAliasRequest || (CreateAliasRequest = {}));
export var CodeSigningPolicy;
(function (CodeSigningPolicy) {
    CodeSigningPolicy["Enforce"] = "Enforce";
    CodeSigningPolicy["Warn"] = "Warn";
})(CodeSigningPolicy || (CodeSigningPolicy = {}));
export var CodeSigningPolicies;
(function (CodeSigningPolicies) {
    CodeSigningPolicies.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CodeSigningPolicies || (CodeSigningPolicies = {}));
export var CreateCodeSigningConfigRequest;
(function (CreateCodeSigningConfigRequest) {
    CreateCodeSigningConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateCodeSigningConfigRequest || (CreateCodeSigningConfigRequest = {}));
export var CodeSigningConfig;
(function (CodeSigningConfig) {
    CodeSigningConfig.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CodeSigningConfig || (CodeSigningConfig = {}));
export var CreateCodeSigningConfigResponse;
(function (CreateCodeSigningConfigResponse) {
    CreateCodeSigningConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateCodeSigningConfigResponse || (CreateCodeSigningConfigResponse = {}));
export var OnFailure;
(function (OnFailure) {
    OnFailure.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(OnFailure || (OnFailure = {}));
export var OnSuccess;
(function (OnSuccess) {
    OnSuccess.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(OnSuccess || (OnSuccess = {}));
export var DestinationConfig;
(function (DestinationConfig) {
    DestinationConfig.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DestinationConfig || (DestinationConfig = {}));
export var FunctionResponseType;
(function (FunctionResponseType) {
    FunctionResponseType["ReportBatchItemFailures"] = "ReportBatchItemFailures";
})(FunctionResponseType || (FunctionResponseType = {}));
export var EndPointType;
(function (EndPointType) {
    EndPointType["KAFKA_BOOTSTRAP_SERVERS"] = "KAFKA_BOOTSTRAP_SERVERS";
})(EndPointType || (EndPointType = {}));
export var SelfManagedEventSource;
(function (SelfManagedEventSource) {
    SelfManagedEventSource.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SelfManagedEventSource || (SelfManagedEventSource = {}));
export var SourceAccessType;
(function (SourceAccessType) {
    SourceAccessType["BASIC_AUTH"] = "BASIC_AUTH";
    SourceAccessType["SASL_SCRAM_256_AUTH"] = "SASL_SCRAM_256_AUTH";
    SourceAccessType["SASL_SCRAM_512_AUTH"] = "SASL_SCRAM_512_AUTH";
    SourceAccessType["VIRTUAL_HOST"] = "VIRTUAL_HOST";
    SourceAccessType["VPC_SECURITY_GROUP"] = "VPC_SECURITY_GROUP";
    SourceAccessType["VPC_SUBNET"] = "VPC_SUBNET";
})(SourceAccessType || (SourceAccessType = {}));
export var SourceAccessConfiguration;
(function (SourceAccessConfiguration) {
    SourceAccessConfiguration.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SourceAccessConfiguration || (SourceAccessConfiguration = {}));
export var EventSourcePosition;
(function (EventSourcePosition) {
    EventSourcePosition["AT_TIMESTAMP"] = "AT_TIMESTAMP";
    EventSourcePosition["LATEST"] = "LATEST";
    EventSourcePosition["TRIM_HORIZON"] = "TRIM_HORIZON";
})(EventSourcePosition || (EventSourcePosition = {}));
export var CreateEventSourceMappingRequest;
(function (CreateEventSourceMappingRequest) {
    CreateEventSourceMappingRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateEventSourceMappingRequest || (CreateEventSourceMappingRequest = {}));
export var EventSourceMappingConfiguration;
(function (EventSourceMappingConfiguration) {
    EventSourceMappingConfiguration.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EventSourceMappingConfiguration || (EventSourceMappingConfiguration = {}));
export var CodeSigningConfigNotFoundException;
(function (CodeSigningConfigNotFoundException) {
    CodeSigningConfigNotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CodeSigningConfigNotFoundException || (CodeSigningConfigNotFoundException = {}));
export var CodeStorageExceededException;
(function (CodeStorageExceededException) {
    CodeStorageExceededException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CodeStorageExceededException || (CodeStorageExceededException = {}));
export var CodeVerificationFailedException;
(function (CodeVerificationFailedException) {
    CodeVerificationFailedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CodeVerificationFailedException || (CodeVerificationFailedException = {}));
export var FunctionCode;
(function (FunctionCode) {
    FunctionCode.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ZipFile && { ZipFile: SENSITIVE_STRING }))); };
})(FunctionCode || (FunctionCode = {}));
export var DeadLetterConfig;
(function (DeadLetterConfig) {
    DeadLetterConfig.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeadLetterConfig || (DeadLetterConfig = {}));
export var Environment;
(function (Environment) {
    Environment.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Variables && { Variables: SENSITIVE_STRING }))); };
})(Environment || (Environment = {}));
export var FileSystemConfig;
(function (FileSystemConfig) {
    FileSystemConfig.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(FileSystemConfig || (FileSystemConfig = {}));
export var ImageConfig;
(function (ImageConfig) {
    ImageConfig.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ImageConfig || (ImageConfig = {}));
export var PackageType;
(function (PackageType) {
    PackageType["Image"] = "Image";
    PackageType["Zip"] = "Zip";
})(PackageType || (PackageType = {}));
export var Runtime;
(function (Runtime) {
    Runtime["dotnetcore10"] = "dotnetcore1.0";
    Runtime["dotnetcore20"] = "dotnetcore2.0";
    Runtime["dotnetcore21"] = "dotnetcore2.1";
    Runtime["dotnetcore31"] = "dotnetcore3.1";
    Runtime["go1x"] = "go1.x";
    Runtime["java11"] = "java11";
    Runtime["java8"] = "java8";
    Runtime["java8al2"] = "java8.al2";
    Runtime["nodejs"] = "nodejs";
    Runtime["nodejs10x"] = "nodejs10.x";
    Runtime["nodejs12x"] = "nodejs12.x";
    Runtime["nodejs14x"] = "nodejs14.x";
    Runtime["nodejs43"] = "nodejs4.3";
    Runtime["nodejs43edge"] = "nodejs4.3-edge";
    Runtime["nodejs610"] = "nodejs6.10";
    Runtime["nodejs810"] = "nodejs8.10";
    Runtime["provided"] = "provided";
    Runtime["providedal2"] = "provided.al2";
    Runtime["python27"] = "python2.7";
    Runtime["python36"] = "python3.6";
    Runtime["python37"] = "python3.7";
    Runtime["python38"] = "python3.8";
    Runtime["python39"] = "python3.9";
    Runtime["ruby25"] = "ruby2.5";
    Runtime["ruby27"] = "ruby2.7";
})(Runtime || (Runtime = {}));
export var TracingMode;
(function (TracingMode) {
    TracingMode["Active"] = "Active";
    TracingMode["PassThrough"] = "PassThrough";
})(TracingMode || (TracingMode = {}));
export var TracingConfig;
(function (TracingConfig) {
    TracingConfig.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TracingConfig || (TracingConfig = {}));
export var VpcConfig;
(function (VpcConfig) {
    VpcConfig.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(VpcConfig || (VpcConfig = {}));
export var CreateFunctionRequest;
(function (CreateFunctionRequest) {
    CreateFunctionRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Code && { Code: FunctionCode.filterSensitiveLog(obj.Code) })), (obj.Environment && { Environment: Environment.filterSensitiveLog(obj.Environment) }))); };
})(CreateFunctionRequest || (CreateFunctionRequest = {}));
export var EnvironmentError;
(function (EnvironmentError) {
    EnvironmentError.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Message && { Message: SENSITIVE_STRING }))); };
})(EnvironmentError || (EnvironmentError = {}));
export var EnvironmentResponse;
(function (EnvironmentResponse) {
    EnvironmentResponse.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Variables && { Variables: SENSITIVE_STRING })), (obj.Error && { Error: EnvironmentError.filterSensitiveLog(obj.Error) }))); };
})(EnvironmentResponse || (EnvironmentResponse = {}));
export var ImageConfigError;
(function (ImageConfigError) {
    ImageConfigError.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Message && { Message: SENSITIVE_STRING }))); };
})(ImageConfigError || (ImageConfigError = {}));
export var ImageConfigResponse;
(function (ImageConfigResponse) {
    ImageConfigResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Error && { Error: ImageConfigError.filterSensitiveLog(obj.Error) }))); };
})(ImageConfigResponse || (ImageConfigResponse = {}));
export var LastUpdateStatus;
(function (LastUpdateStatus) {
    LastUpdateStatus["Failed"] = "Failed";
    LastUpdateStatus["InProgress"] = "InProgress";
    LastUpdateStatus["Successful"] = "Successful";
})(LastUpdateStatus || (LastUpdateStatus = {}));
export var LastUpdateStatusReasonCode;
(function (LastUpdateStatusReasonCode) {
    LastUpdateStatusReasonCode["EniLimitExceeded"] = "EniLimitExceeded";
    LastUpdateStatusReasonCode["ImageAccessDenied"] = "ImageAccessDenied";
    LastUpdateStatusReasonCode["ImageDeleted"] = "ImageDeleted";
    LastUpdateStatusReasonCode["InsufficientRolePermissions"] = "InsufficientRolePermissions";
    LastUpdateStatusReasonCode["InternalError"] = "InternalError";
    LastUpdateStatusReasonCode["InvalidConfiguration"] = "InvalidConfiguration";
    LastUpdateStatusReasonCode["InvalidImage"] = "InvalidImage";
    LastUpdateStatusReasonCode["InvalidSecurityGroup"] = "InvalidSecurityGroup";
    LastUpdateStatusReasonCode["InvalidSubnet"] = "InvalidSubnet";
    LastUpdateStatusReasonCode["SubnetOutOfIPAddresses"] = "SubnetOutOfIPAddresses";
})(LastUpdateStatusReasonCode || (LastUpdateStatusReasonCode = {}));
export var Layer;
(function (Layer) {
    Layer.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Layer || (Layer = {}));
export var State;
(function (State) {
    State["Active"] = "Active";
    State["Failed"] = "Failed";
    State["Inactive"] = "Inactive";
    State["Pending"] = "Pending";
})(State || (State = {}));
export var StateReasonCode;
(function (StateReasonCode) {
    StateReasonCode["Creating"] = "Creating";
    StateReasonCode["EniLimitExceeded"] = "EniLimitExceeded";
    StateReasonCode["Idle"] = "Idle";
    StateReasonCode["ImageAccessDenied"] = "ImageAccessDenied";
    StateReasonCode["ImageDeleted"] = "ImageDeleted";
    StateReasonCode["InsufficientRolePermissions"] = "InsufficientRolePermissions";
    StateReasonCode["InternalError"] = "InternalError";
    StateReasonCode["InvalidConfiguration"] = "InvalidConfiguration";
    StateReasonCode["InvalidImage"] = "InvalidImage";
    StateReasonCode["InvalidSecurityGroup"] = "InvalidSecurityGroup";
    StateReasonCode["InvalidSubnet"] = "InvalidSubnet";
    StateReasonCode["Restoring"] = "Restoring";
    StateReasonCode["SubnetOutOfIPAddresses"] = "SubnetOutOfIPAddresses";
})(StateReasonCode || (StateReasonCode = {}));
export var TracingConfigResponse;
(function (TracingConfigResponse) {
    TracingConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TracingConfigResponse || (TracingConfigResponse = {}));
export var VpcConfigResponse;
(function (VpcConfigResponse) {
    VpcConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(VpcConfigResponse || (VpcConfigResponse = {}));
export var FunctionConfiguration;
(function (FunctionConfiguration) {
    FunctionConfiguration.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Environment && { Environment: EnvironmentResponse.filterSensitiveLog(obj.Environment) })), (obj.ImageConfigResponse && {
        ImageConfigResponse: ImageConfigResponse.filterSensitiveLog(obj.ImageConfigResponse),
    }))); };
})(FunctionConfiguration || (FunctionConfiguration = {}));
export var InvalidCodeSignatureException;
(function (InvalidCodeSignatureException) {
    InvalidCodeSignatureException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidCodeSignatureException || (InvalidCodeSignatureException = {}));
export var DeleteAliasRequest;
(function (DeleteAliasRequest) {
    DeleteAliasRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteAliasRequest || (DeleteAliasRequest = {}));
export var DeleteCodeSigningConfigRequest;
(function (DeleteCodeSigningConfigRequest) {
    DeleteCodeSigningConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteCodeSigningConfigRequest || (DeleteCodeSigningConfigRequest = {}));
export var DeleteCodeSigningConfigResponse;
(function (DeleteCodeSigningConfigResponse) {
    DeleteCodeSigningConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteCodeSigningConfigResponse || (DeleteCodeSigningConfigResponse = {}));
export var DeleteEventSourceMappingRequest;
(function (DeleteEventSourceMappingRequest) {
    DeleteEventSourceMappingRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteEventSourceMappingRequest || (DeleteEventSourceMappingRequest = {}));
export var ResourceInUseException;
(function (ResourceInUseException) {
    ResourceInUseException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceInUseException || (ResourceInUseException = {}));
export var DeleteFunctionRequest;
(function (DeleteFunctionRequest) {
    DeleteFunctionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteFunctionRequest || (DeleteFunctionRequest = {}));
export var DeleteFunctionCodeSigningConfigRequest;
(function (DeleteFunctionCodeSigningConfigRequest) {
    DeleteFunctionCodeSigningConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteFunctionCodeSigningConfigRequest || (DeleteFunctionCodeSigningConfigRequest = {}));
export var DeleteFunctionConcurrencyRequest;
(function (DeleteFunctionConcurrencyRequest) {
    DeleteFunctionConcurrencyRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteFunctionConcurrencyRequest || (DeleteFunctionConcurrencyRequest = {}));
export var DeleteFunctionEventInvokeConfigRequest;
(function (DeleteFunctionEventInvokeConfigRequest) {
    DeleteFunctionEventInvokeConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteFunctionEventInvokeConfigRequest || (DeleteFunctionEventInvokeConfigRequest = {}));
export var DeleteLayerVersionRequest;
(function (DeleteLayerVersionRequest) {
    DeleteLayerVersionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteLayerVersionRequest || (DeleteLayerVersionRequest = {}));
export var DeleteProvisionedConcurrencyConfigRequest;
(function (DeleteProvisionedConcurrencyConfigRequest) {
    DeleteProvisionedConcurrencyConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteProvisionedConcurrencyConfigRequest || (DeleteProvisionedConcurrencyConfigRequest = {}));
export var GetAccountSettingsRequest;
(function (GetAccountSettingsRequest) {
    GetAccountSettingsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetAccountSettingsRequest || (GetAccountSettingsRequest = {}));
export var GetAccountSettingsResponse;
(function (GetAccountSettingsResponse) {
    GetAccountSettingsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetAccountSettingsResponse || (GetAccountSettingsResponse = {}));
export var GetAliasRequest;
(function (GetAliasRequest) {
    GetAliasRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetAliasRequest || (GetAliasRequest = {}));
export var GetCodeSigningConfigRequest;
(function (GetCodeSigningConfigRequest) {
    GetCodeSigningConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetCodeSigningConfigRequest || (GetCodeSigningConfigRequest = {}));
export var GetCodeSigningConfigResponse;
(function (GetCodeSigningConfigResponse) {
    GetCodeSigningConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetCodeSigningConfigResponse || (GetCodeSigningConfigResponse = {}));
export var GetEventSourceMappingRequest;
(function (GetEventSourceMappingRequest) {
    GetEventSourceMappingRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetEventSourceMappingRequest || (GetEventSourceMappingRequest = {}));
export var GetFunctionRequest;
(function (GetFunctionRequest) {
    GetFunctionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetFunctionRequest || (GetFunctionRequest = {}));
export var FunctionCodeLocation;
(function (FunctionCodeLocation) {
    FunctionCodeLocation.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(FunctionCodeLocation || (FunctionCodeLocation = {}));
export var Concurrency;
(function (Concurrency) {
    Concurrency.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Concurrency || (Concurrency = {}));
export var GetFunctionResponse;
(function (GetFunctionResponse) {
    GetFunctionResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Configuration && { Configuration: FunctionConfiguration.filterSensitiveLog(obj.Configuration) }))); };
})(GetFunctionResponse || (GetFunctionResponse = {}));
export var GetFunctionCodeSigningConfigRequest;
(function (GetFunctionCodeSigningConfigRequest) {
    GetFunctionCodeSigningConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetFunctionCodeSigningConfigRequest || (GetFunctionCodeSigningConfigRequest = {}));
export var GetFunctionCodeSigningConfigResponse;
(function (GetFunctionCodeSigningConfigResponse) {
    GetFunctionCodeSigningConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetFunctionCodeSigningConfigResponse || (GetFunctionCodeSigningConfigResponse = {}));
export var GetFunctionConcurrencyRequest;
(function (GetFunctionConcurrencyRequest) {
    GetFunctionConcurrencyRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetFunctionConcurrencyRequest || (GetFunctionConcurrencyRequest = {}));
export var GetFunctionConcurrencyResponse;
(function (GetFunctionConcurrencyResponse) {
    GetFunctionConcurrencyResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetFunctionConcurrencyResponse || (GetFunctionConcurrencyResponse = {}));
export var GetFunctionConfigurationRequest;
(function (GetFunctionConfigurationRequest) {
    GetFunctionConfigurationRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetFunctionConfigurationRequest || (GetFunctionConfigurationRequest = {}));
export var FunctionEventInvokeConfig;
(function (FunctionEventInvokeConfig) {
    FunctionEventInvokeConfig.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(FunctionEventInvokeConfig || (FunctionEventInvokeConfig = {}));
export var GetFunctionEventInvokeConfigRequest;
(function (GetFunctionEventInvokeConfigRequest) {
    GetFunctionEventInvokeConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetFunctionEventInvokeConfigRequest || (GetFunctionEventInvokeConfigRequest = {}));
export var GetLayerVersionRequest;
(function (GetLayerVersionRequest) {
    GetLayerVersionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetLayerVersionRequest || (GetLayerVersionRequest = {}));
export var LayerVersionContentOutput;
(function (LayerVersionContentOutput) {
    LayerVersionContentOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LayerVersionContentOutput || (LayerVersionContentOutput = {}));
export var GetLayerVersionResponse;
(function (GetLayerVersionResponse) {
    GetLayerVersionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetLayerVersionResponse || (GetLayerVersionResponse = {}));
export var GetLayerVersionByArnRequest;
(function (GetLayerVersionByArnRequest) {
    GetLayerVersionByArnRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetLayerVersionByArnRequest || (GetLayerVersionByArnRequest = {}));
export var GetLayerVersionPolicyRequest;
(function (GetLayerVersionPolicyRequest) {
    GetLayerVersionPolicyRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetLayerVersionPolicyRequest || (GetLayerVersionPolicyRequest = {}));
export var GetLayerVersionPolicyResponse;
(function (GetLayerVersionPolicyResponse) {
    GetLayerVersionPolicyResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetLayerVersionPolicyResponse || (GetLayerVersionPolicyResponse = {}));
export var GetPolicyRequest;
(function (GetPolicyRequest) {
    GetPolicyRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetPolicyRequest || (GetPolicyRequest = {}));
export var GetPolicyResponse;
(function (GetPolicyResponse) {
    GetPolicyResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetPolicyResponse || (GetPolicyResponse = {}));
export var GetProvisionedConcurrencyConfigRequest;
(function (GetProvisionedConcurrencyConfigRequest) {
    GetProvisionedConcurrencyConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetProvisionedConcurrencyConfigRequest || (GetProvisionedConcurrencyConfigRequest = {}));
export var ProvisionedConcurrencyStatusEnum;
(function (ProvisionedConcurrencyStatusEnum) {
    ProvisionedConcurrencyStatusEnum["FAILED"] = "FAILED";
    ProvisionedConcurrencyStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
    ProvisionedConcurrencyStatusEnum["READY"] = "READY";
})(ProvisionedConcurrencyStatusEnum || (ProvisionedConcurrencyStatusEnum = {}));
export var GetProvisionedConcurrencyConfigResponse;
(function (GetProvisionedConcurrencyConfigResponse) {
    GetProvisionedConcurrencyConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetProvisionedConcurrencyConfigResponse || (GetProvisionedConcurrencyConfigResponse = {}));
export var ProvisionedConcurrencyConfigNotFoundException;
(function (ProvisionedConcurrencyConfigNotFoundException) {
    ProvisionedConcurrencyConfigNotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ProvisionedConcurrencyConfigNotFoundException || (ProvisionedConcurrencyConfigNotFoundException = {}));
export var EC2AccessDeniedException;
(function (EC2AccessDeniedException) {
    EC2AccessDeniedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EC2AccessDeniedException || (EC2AccessDeniedException = {}));
export var EC2ThrottledException;
(function (EC2ThrottledException) {
    EC2ThrottledException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EC2ThrottledException || (EC2ThrottledException = {}));
export var EC2UnexpectedException;
(function (EC2UnexpectedException) {
    EC2UnexpectedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EC2UnexpectedException || (EC2UnexpectedException = {}));
export var EFSIOException;
(function (EFSIOException) {
    EFSIOException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EFSIOException || (EFSIOException = {}));
export var EFSMountConnectivityException;
(function (EFSMountConnectivityException) {
    EFSMountConnectivityException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EFSMountConnectivityException || (EFSMountConnectivityException = {}));
export var EFSMountFailureException;
(function (EFSMountFailureException) {
    EFSMountFailureException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EFSMountFailureException || (EFSMountFailureException = {}));
export var EFSMountTimeoutException;
(function (EFSMountTimeoutException) {
    EFSMountTimeoutException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EFSMountTimeoutException || (EFSMountTimeoutException = {}));
export var ENILimitReachedException;
(function (ENILimitReachedException) {
    ENILimitReachedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ENILimitReachedException || (ENILimitReachedException = {}));
export var InvalidRequestContentException;
(function (InvalidRequestContentException) {
    InvalidRequestContentException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidRequestContentException || (InvalidRequestContentException = {}));
export var InvalidRuntimeException;
(function (InvalidRuntimeException) {
    InvalidRuntimeException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidRuntimeException || (InvalidRuntimeException = {}));
export var InvalidSecurityGroupIDException;
(function (InvalidSecurityGroupIDException) {
    InvalidSecurityGroupIDException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidSecurityGroupIDException || (InvalidSecurityGroupIDException = {}));
export var InvalidSubnetIDException;
(function (InvalidSubnetIDException) {
    InvalidSubnetIDException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidSubnetIDException || (InvalidSubnetIDException = {}));
export var InvalidZipFileException;
(function (InvalidZipFileException) {
    InvalidZipFileException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidZipFileException || (InvalidZipFileException = {}));
export var InvocationType;
(function (InvocationType) {
    InvocationType["DryRun"] = "DryRun";
    InvocationType["Event"] = "Event";
    InvocationType["RequestResponse"] = "RequestResponse";
})(InvocationType || (InvocationType = {}));
export var LogType;
(function (LogType) {
    LogType["None"] = "None";
    LogType["Tail"] = "Tail";
})(LogType || (LogType = {}));
export var InvocationRequest;
(function (InvocationRequest) {
    InvocationRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Payload && { Payload: SENSITIVE_STRING }))); };
})(InvocationRequest || (InvocationRequest = {}));
export var InvocationResponse;
(function (InvocationResponse) {
    InvocationResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Payload && { Payload: SENSITIVE_STRING }))); };
})(InvocationResponse || (InvocationResponse = {}));
export var KMSAccessDeniedException;
(function (KMSAccessDeniedException) {
    KMSAccessDeniedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(KMSAccessDeniedException || (KMSAccessDeniedException = {}));
export var KMSDisabledException;
(function (KMSDisabledException) {
    KMSDisabledException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(KMSDisabledException || (KMSDisabledException = {}));
export var KMSInvalidStateException;
(function (KMSInvalidStateException) {
    KMSInvalidStateException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(KMSInvalidStateException || (KMSInvalidStateException = {}));
export var KMSNotFoundException;
(function (KMSNotFoundException) {
    KMSNotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(KMSNotFoundException || (KMSNotFoundException = {}));
export var RequestTooLargeException;
(function (RequestTooLargeException) {
    RequestTooLargeException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RequestTooLargeException || (RequestTooLargeException = {}));
export var ResourceNotReadyException;
(function (ResourceNotReadyException) {
    ResourceNotReadyException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceNotReadyException || (ResourceNotReadyException = {}));
export var SubnetIPAddressLimitReachedException;
(function (SubnetIPAddressLimitReachedException) {
    SubnetIPAddressLimitReachedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SubnetIPAddressLimitReachedException || (SubnetIPAddressLimitReachedException = {}));
export var UnsupportedMediaTypeException;
(function (UnsupportedMediaTypeException) {
    UnsupportedMediaTypeException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnsupportedMediaTypeException || (UnsupportedMediaTypeException = {}));
export var InvokeAsyncRequest;
(function (InvokeAsyncRequest) {
    InvokeAsyncRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvokeAsyncRequest || (InvokeAsyncRequest = {}));
export var InvokeAsyncResponse;
(function (InvokeAsyncResponse) {
    InvokeAsyncResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvokeAsyncResponse || (InvokeAsyncResponse = {}));
export var ListAliasesRequest;
(function (ListAliasesRequest) {
    ListAliasesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListAliasesRequest || (ListAliasesRequest = {}));
export var ListAliasesResponse;
(function (ListAliasesResponse) {
    ListAliasesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListAliasesResponse || (ListAliasesResponse = {}));
export var ListCodeSigningConfigsRequest;
(function (ListCodeSigningConfigsRequest) {
    ListCodeSigningConfigsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListCodeSigningConfigsRequest || (ListCodeSigningConfigsRequest = {}));
export var ListCodeSigningConfigsResponse;
(function (ListCodeSigningConfigsResponse) {
    ListCodeSigningConfigsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListCodeSigningConfigsResponse || (ListCodeSigningConfigsResponse = {}));
export var ListEventSourceMappingsRequest;
(function (ListEventSourceMappingsRequest) {
    ListEventSourceMappingsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListEventSourceMappingsRequest || (ListEventSourceMappingsRequest = {}));
export var ListEventSourceMappingsResponse;
(function (ListEventSourceMappingsResponse) {
    ListEventSourceMappingsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListEventSourceMappingsResponse || (ListEventSourceMappingsResponse = {}));
export var ListFunctionEventInvokeConfigsRequest;
(function (ListFunctionEventInvokeConfigsRequest) {
    ListFunctionEventInvokeConfigsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListFunctionEventInvokeConfigsRequest || (ListFunctionEventInvokeConfigsRequest = {}));
export var ListFunctionEventInvokeConfigsResponse;
(function (ListFunctionEventInvokeConfigsResponse) {
    ListFunctionEventInvokeConfigsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListFunctionEventInvokeConfigsResponse || (ListFunctionEventInvokeConfigsResponse = {}));
export var FunctionVersion;
(function (FunctionVersion) {
    FunctionVersion["ALL"] = "ALL";
})(FunctionVersion || (FunctionVersion = {}));
export var ListFunctionsRequest;
(function (ListFunctionsRequest) {
    ListFunctionsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListFunctionsRequest || (ListFunctionsRequest = {}));
export var ListFunctionsResponse;
(function (ListFunctionsResponse) {
    ListFunctionsResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Functions && { Functions: obj.Functions.map(function (item) { return FunctionConfiguration.filterSensitiveLog(item); }) }))); };
})(ListFunctionsResponse || (ListFunctionsResponse = {}));
export var ListFunctionsByCodeSigningConfigRequest;
(function (ListFunctionsByCodeSigningConfigRequest) {
    ListFunctionsByCodeSigningConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListFunctionsByCodeSigningConfigRequest || (ListFunctionsByCodeSigningConfigRequest = {}));
export var ListFunctionsByCodeSigningConfigResponse;
(function (ListFunctionsByCodeSigningConfigResponse) {
    ListFunctionsByCodeSigningConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListFunctionsByCodeSigningConfigResponse || (ListFunctionsByCodeSigningConfigResponse = {}));
export var ListLayersRequest;
(function (ListLayersRequest) {
    ListLayersRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListLayersRequest || (ListLayersRequest = {}));
export var LayerVersionsListItem;
(function (LayerVersionsListItem) {
    LayerVersionsListItem.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LayerVersionsListItem || (LayerVersionsListItem = {}));
export var LayersListItem;
(function (LayersListItem) {
    LayersListItem.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LayersListItem || (LayersListItem = {}));
export var ListLayersResponse;
(function (ListLayersResponse) {
    ListLayersResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListLayersResponse || (ListLayersResponse = {}));
export var ListLayerVersionsRequest;
(function (ListLayerVersionsRequest) {
    ListLayerVersionsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListLayerVersionsRequest || (ListLayerVersionsRequest = {}));
export var ListLayerVersionsResponse;
(function (ListLayerVersionsResponse) {
    ListLayerVersionsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListLayerVersionsResponse || (ListLayerVersionsResponse = {}));
export var ListProvisionedConcurrencyConfigsRequest;
(function (ListProvisionedConcurrencyConfigsRequest) {
    ListProvisionedConcurrencyConfigsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListProvisionedConcurrencyConfigsRequest || (ListProvisionedConcurrencyConfigsRequest = {}));
export var ProvisionedConcurrencyConfigListItem;
(function (ProvisionedConcurrencyConfigListItem) {
    ProvisionedConcurrencyConfigListItem.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ProvisionedConcurrencyConfigListItem || (ProvisionedConcurrencyConfigListItem = {}));
export var ListProvisionedConcurrencyConfigsResponse;
(function (ListProvisionedConcurrencyConfigsResponse) {
    ListProvisionedConcurrencyConfigsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListProvisionedConcurrencyConfigsResponse || (ListProvisionedConcurrencyConfigsResponse = {}));
export var ListTagsRequest;
(function (ListTagsRequest) {
    ListTagsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsRequest || (ListTagsRequest = {}));
export var ListTagsResponse;
(function (ListTagsResponse) {
    ListTagsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsResponse || (ListTagsResponse = {}));
export var ListVersionsByFunctionRequest;
(function (ListVersionsByFunctionRequest) {
    ListVersionsByFunctionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListVersionsByFunctionRequest || (ListVersionsByFunctionRequest = {}));
export var ListVersionsByFunctionResponse;
(function (ListVersionsByFunctionResponse) {
    ListVersionsByFunctionResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Versions && { Versions: obj.Versions.map(function (item) { return FunctionConfiguration.filterSensitiveLog(item); }) }))); };
})(ListVersionsByFunctionResponse || (ListVersionsByFunctionResponse = {}));
export var LayerVersionContentInput;
(function (LayerVersionContentInput) {
    LayerVersionContentInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ZipFile && { ZipFile: SENSITIVE_STRING }))); };
})(LayerVersionContentInput || (LayerVersionContentInput = {}));
export var PublishLayerVersionRequest;
(function (PublishLayerVersionRequest) {
    PublishLayerVersionRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Content && { Content: LayerVersionContentInput.filterSensitiveLog(obj.Content) }))); };
})(PublishLayerVersionRequest || (PublishLayerVersionRequest = {}));
export var PublishLayerVersionResponse;
(function (PublishLayerVersionResponse) {
    PublishLayerVersionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PublishLayerVersionResponse || (PublishLayerVersionResponse = {}));
export var PublishVersionRequest;
(function (PublishVersionRequest) {
    PublishVersionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PublishVersionRequest || (PublishVersionRequest = {}));
export var PutFunctionCodeSigningConfigRequest;
(function (PutFunctionCodeSigningConfigRequest) {
    PutFunctionCodeSigningConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutFunctionCodeSigningConfigRequest || (PutFunctionCodeSigningConfigRequest = {}));
export var PutFunctionCodeSigningConfigResponse;
(function (PutFunctionCodeSigningConfigResponse) {
    PutFunctionCodeSigningConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutFunctionCodeSigningConfigResponse || (PutFunctionCodeSigningConfigResponse = {}));
export var PutFunctionConcurrencyRequest;
(function (PutFunctionConcurrencyRequest) {
    PutFunctionConcurrencyRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutFunctionConcurrencyRequest || (PutFunctionConcurrencyRequest = {}));
export var PutFunctionEventInvokeConfigRequest;
(function (PutFunctionEventInvokeConfigRequest) {
    PutFunctionEventInvokeConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutFunctionEventInvokeConfigRequest || (PutFunctionEventInvokeConfigRequest = {}));
export var PutProvisionedConcurrencyConfigRequest;
(function (PutProvisionedConcurrencyConfigRequest) {
    PutProvisionedConcurrencyConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutProvisionedConcurrencyConfigRequest || (PutProvisionedConcurrencyConfigRequest = {}));
export var PutProvisionedConcurrencyConfigResponse;
(function (PutProvisionedConcurrencyConfigResponse) {
    PutProvisionedConcurrencyConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutProvisionedConcurrencyConfigResponse || (PutProvisionedConcurrencyConfigResponse = {}));
export var RemoveLayerVersionPermissionRequest;
(function (RemoveLayerVersionPermissionRequest) {
    RemoveLayerVersionPermissionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RemoveLayerVersionPermissionRequest || (RemoveLayerVersionPermissionRequest = {}));
export var RemovePermissionRequest;
(function (RemovePermissionRequest) {
    RemovePermissionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RemovePermissionRequest || (RemovePermissionRequest = {}));
export var TagResourceRequest;
(function (TagResourceRequest) {
    TagResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceRequest || (TagResourceRequest = {}));
export var UntagResourceRequest;
(function (UntagResourceRequest) {
    UntagResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceRequest || (UntagResourceRequest = {}));
export var UpdateAliasRequest;
(function (UpdateAliasRequest) {
    UpdateAliasRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateAliasRequest || (UpdateAliasRequest = {}));
export var UpdateCodeSigningConfigRequest;
(function (UpdateCodeSigningConfigRequest) {
    UpdateCodeSigningConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateCodeSigningConfigRequest || (UpdateCodeSigningConfigRequest = {}));
export var UpdateCodeSigningConfigResponse;
(function (UpdateCodeSigningConfigResponse) {
    UpdateCodeSigningConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateCodeSigningConfigResponse || (UpdateCodeSigningConfigResponse = {}));
export var UpdateEventSourceMappingRequest;
(function (UpdateEventSourceMappingRequest) {
    UpdateEventSourceMappingRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEventSourceMappingRequest || (UpdateEventSourceMappingRequest = {}));
export var UpdateFunctionCodeRequest;
(function (UpdateFunctionCodeRequest) {
    UpdateFunctionCodeRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ZipFile && { ZipFile: SENSITIVE_STRING }))); };
})(UpdateFunctionCodeRequest || (UpdateFunctionCodeRequest = {}));
export var UpdateFunctionConfigurationRequest;
(function (UpdateFunctionConfigurationRequest) {
    UpdateFunctionConfigurationRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Environment && { Environment: Environment.filterSensitiveLog(obj.Environment) }))); };
})(UpdateFunctionConfigurationRequest || (UpdateFunctionConfigurationRequest = {}));
export var UpdateFunctionEventInvokeConfigRequest;
(function (UpdateFunctionEventInvokeConfigRequest) {
    UpdateFunctionEventInvokeConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateFunctionEventInvokeConfigRequest || (UpdateFunctionEventInvokeConfigRequest = {}));
