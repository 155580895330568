import { useMemo, Children, cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors } from '../../config';

const dropDownStyle = (width) =>
  css({
    width: width || '100%',
    backgroundColor: colors.neutralColors.white,
    border: `1px solid ${colors.neutralColors.slate30}`,
    borderRadius: 4,
    boxShadow: `0px 2px ${colors.shadows.boxShadow}`,
    zIndex: 999,
    position: 'relative',
    overflow: 'hidden',
  });

const DropDown = forwardRef(
  ({ width, onSelectHandler, children, selectedValue = null }, ref) => {
    const providedChildren = useMemo(
      () =>
        Children.map(children, (child) => {
          const { value } = child.props;
          return cloneElement(child, {
            isSelected: value === selectedValue,
            onClick: onSelectHandler,
            ...child.props,
          });
        }),
      [selectedValue, onSelectHandler, children]
    );

    return (
      <div aria-label="Dropdown" ref={ref} css={dropDownStyle(width)}>
        {providedChildren}
      </div>
    );
  }
);

DropDown.displayName = 'DropDown';
DropDown.propTypes = {
  children: PropTypes.node.isRequired,
  onSelectHandler: PropTypes.func.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
};

export { DropDown };
