import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesExternalScreenBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.19 2H.81a.81.81 0 00-.81.81V17.6a.81.81 0 00.81.81h5.66a15.003 15.003 0 00-2.75 2.14 1 1 0 001.44 1.39s3.19-3.25 6.84-3.25 6.56 3.18 6.58 3.21a1 1 0 101.52-1.3 12.68 12.68 0 00-2.6-2.19h5.69a.81.81 0 00.81-.81V2.81a.81.81 0 00-.81-.81zM2.92 6.17a.9.9 0 01.9-.9h16.36a.9.9 0 01.9.9v8.09a.9.9 0 01-.264.636c-.168.17-.267.264-.506.264H3.95c-.239 0-.598-.095-.766-.264a.9.9 0 01-.264-.636V6.17z" />
      </g>
    );
  }
);
DevicesExternalScreenBold.displayName = 'DevicesExternalScreenBold';
