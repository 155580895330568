import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudUnavailable = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.904 24a.796.796 0 01-.573-.244L.524 1.216A.798.798 0 011.67.153L23.478 22.65a.797.797 0 01-.574 1.35zM5.715 19.45a6.364 6.364 0 01-4.034-1.35 4.04 4.04 0 01-1.36-2.978 4.375 4.375 0 011.19-3.455 5.167 5.167 0 013.185-1.318v-.383a.766.766 0 01.934-.744.796.796 0 01.765.83L6.33 11.22a1.064 1.064 0 01-.881.744 4.062 4.062 0 00-2.729.882 2.85 2.85 0 00-.753 2.276 2.48 2.48 0 00.785 1.828 4.996 4.996 0 003.185.936h8.144a.796.796 0 01.563 1.361.796.796 0 01-.563.233H5.715v-.031zm15.841-1.67a.785.785 0 01-.435-.127.797.797 0 01-.234-1.063s1.476-2.286.977-4.04a4.051 4.051 0 00-3.833-2.935h-.276a.902.902 0 01-.658-.531A5.454 5.454 0 0011.98 5.45a5.44 5.44 0 00-2.167.443.797.797 0 01-1.039-1.047.798.798 0 01.433-.43 6.936 6.936 0 019.163 3.582 5.474 5.474 0 015 4.062c.712 2.466-1.061 5.241-1.178 5.315a.797.797 0 01-.637.404z" />
      </g>
    );
  }
);
CloudUnavailable.displayName = 'CloudUnavailable';
