import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const QrCode = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M4.5 5.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zM6 6v1.5h1.5V6H6zm-1.5 9.75a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm1.5.75V18h1.5v-1.5H6zm9-11.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm1.5.75v1.5H18V6h-1.5zm-12 6.75a.75.75 0 01.75-.75h6a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-.75H5.25a.75.75 0 01-.75-.75zm9.75.75a.75.75 0 01.75.75V18h3v-3h-.75a.75.75 0 010-1.5h1.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 01.75-.75zm-3 3a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zm0-12a.75.75 0 01.75.75V9h.75a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 01.75-.75zM15 11.25a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM2.25 1.5a.75.75 0 00-.75.75V6A.75.75 0 010 6V2.25A2.25 2.25 0 012.25 0H6a.75.75 0 010 1.5H2.25zm15-.75A.75.75 0 0118 0h3.75A2.25 2.25 0 0124 2.25V6a.75.75 0 01-1.5 0V2.25a.75.75 0 00-.75-.75H18a.75.75 0 01-.75-.75zm6 16.5A.75.75 0 0124 18v3.75A2.25 2.25 0 0121.75 24H18a.75.75 0 010-1.5h3.75a.75.75 0 00.75-.75V18a.75.75 0 01.75-.75zm-22.5 0a.75.75 0 01.75.75v3.75a.75.75 0 00.75.75H6A.75.75 0 016 24H2.25A2.25 2.25 0 010 21.75V18a.75.75 0 01.75-.75z"
        fillRule="evenodd"
      />
    </g>
  );
});
QrCode.displayName = 'QrCode';
