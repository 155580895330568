import PropTypes from 'prop-types';
import { AssessmentStatusIndicator } from '../../../AssessmentStatusIndicator';

const StatusCell = ({ cell: { value } }) => (
  <AssessmentStatusIndicator status={value} />
);

StatusCell.propTypes = {
  cell: PropTypes.object.isRequired,
};

export { StatusCell };
