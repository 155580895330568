import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesWav = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-wav_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h.5a.75.75 0 000-1.5h-.5a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path d="M8.201 23.887a.668.668 0 01-.41-.13c-2.387-1.751-2.157-7.093-2.117-7.693a.7.7 0 011.183-.386.7.7 0 01.205.456 13.27 13.27 0 00.81 5.542l.838-3.251a.7.7 0 01.67-.52.708.708 0 01.679.51l.86 3.12c.609-1.74.83-3.594.648-5.43a.713.713 0 01.66-.76.71.71 0 01.759.66c.3 6.001-1.678 7.571-1.908 7.731a.668.668 0 01-.63.08.69.69 0 01-.439-.47l-.6-2.04-.529 2.06a.73.73 0 01-.44.48.67.67 0 01-.239.04z" />
        <path
          clipRule="evenodd"
          d="M15.773 15.374a2.086 2.086 0 00-2.078 2.09v5.752a.701.701 0 00.981.722.7.7 0 00.408-.722v-2.87h1.378v2.87a.7.7 0 101.399 0v-5.751a2.103 2.103 0 00-2.088-2.09zm-.69 3.571v-1.48a.69.69 0 011.177-.488.69.69 0 01.202.488v1.48h-1.378z"
          fillRule="evenodd"
        />
        <path d="M20.991 23.836a.61.61 0 00.256.05.64.64 0 00.51-.19c.1-.09 2.517-2.48 2.217-7.692a.65.65 0 00-.74-.59.679.679 0 00-.638.71 9.733 9.733 0 01-1.329 6.002c-1.348-2.13-1.348-6.052-1.348-6.052a.67.67 0 00-1.147-.474.68.68 0 00-.202.474c0 .22.02 5.342 2.207 7.612a.63.63 0 00.214.15z" />
      </g>
      <defs>
        <clipPath id="files-wav_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesWav.displayName = 'FilesWav';
