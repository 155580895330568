import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudUpload = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M12.185 23.5a.813.813 0 00.812-.81v-7.765l2.674 2.668a.813.813 0 001.366-.578.81.81 0 00-.218-.567l-4.06-4.028a.813.813 0 00-.564-.238.813.813 0 00-.53.238l-4.114 4.028a.824.824 0 00.61 1.397.828.828 0 00.58-.252l2.675-2.668v7.754a.808.808 0 00.769.821z" />
        <path d="M19.222 16.578a.813.813 0 01-.814-.69.808.808 0 01.576-.898c.14 0 3.4-1.08 3.4-4.32a4.313 4.313 0 00-1.02-2.953 4.334 4.334 0 00-2.748-1.496 1.085 1.085 0 01-.953-.702 5.642 5.642 0 00-2.393-2.708 5.664 5.664 0 00-3.55-.704c-4.397.248-5.414 4.32-5.718 5.68a1.144 1.144 0 01-1.082.929 3.028 3.028 0 00-2.983 1.768c-.169.375-.26.78-.265 1.19a2.736 2.736 0 00.628 2.16 4.141 4.141 0 002.62 1.08.814.814 0 01.79.832.863.863 0 01-.823.789 5.757 5.757 0 01-3.778-1.588 4.317 4.317 0 01-1.083-3.358 4.54 4.54 0 011.349-3.102 4.564 4.564 0 013.123-1.315C5.894 1.945 9.13.692 11.622.54a7.368 7.368 0 014.37.895 7.34 7.34 0 013.046 3.252 5.937 5.937 0 013.615 2.083 5.909 5.909 0 011.344 3.943 6.318 6.318 0 01-1.34 3.642 6.345 6.345 0 01-3.218 2.179.79.79 0 01-.217.043z" />
      </g>
    );
  }
);
CloudUpload.displayName = 'CloudUpload';
