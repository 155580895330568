import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LevelIndicator = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M12 4a11.961 11.961 0 00-8.52 3.6A12.054 12.054 0 000 16.17v2.074a2.253 2.253 0 002.246 2.246h19.508A2.253 2.253 0 0024 18.245v-2.074C24.05 9.52 18.668 4.051 12 4zm-.017 12.754h.034A2.25 2.25 0 0114.246 19H9.754a2.25 2.25 0 012.229-2.246zm10.525 1.491a.761.761 0 01-.754.755h-6c0-1.715-1.166-3.172-2.76-3.617l1.972-5.897a.736.736 0 00-.48-.943.736.736 0 00-.943.48l-2.092 6.274A3.76 3.76 0 008.263 19h-6a.761.761 0 01-.754-.755v-2.074A10.535 10.535 0 014.56 8.663 10.389 10.389 0 0112 5.509c5.828.034 10.543 4.817 10.508 10.662v2.074z" />
        <path d="M4.869 16.754a1.131 1.131 0 100-2.263 1.131 1.131 0 000 2.263zm1.508-6a1.13 1.13 0 100 2.263 1.12 1.12 0 001.132-1.131 1.13 1.13 0 00-1.132-1.132zm12.754 6a1.131 1.131 0 100-2.263 1.131 1.131 0 000 2.263zm-.377-4.868c0-.6-.48-1.098-1.08-1.115h-.12a1.138 1.138 0 00-1.063 1.114 1.13 1.13 0 102.263 0zM9.429 7.754c-.018 0-.018 0 0 0h-.12a1.138 1.138 0 00-1.063 1.114A1.13 1.13 0 009.377 10a1.12 1.12 0 001.131-1.132c0-.6-.497-1.097-1.08-1.114z" />
      </g>
    );
  }
);
LevelIndicator.displayName = 'LevelIndicator';
