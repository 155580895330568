import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFemale2 = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.22 20.19A5.62 5.62 0 0020 18a3.72 3.72 0 00-1.69-.4H6c-3.46.15-4.37 3.68-4.37 5.52a.75.75 0 00.75.75.728.728 0 00.75-.75c0-.16.05-3.9 2.91-4h12.27a2.29 2.29 0 011 .22 4.1 4.1 0 011.62 1.59c.37.653.54 1.4.49 2.15a.749.749 0 00.7.8h.05a.75.75 0 00.75-.7 5.33 5.33 0 00-.7-2.99zM18.62 8A3.58 3.58 0 0015 4.37a.75.75 0 00-.28.05l-2.1.84a8.75 8.75 0 01-3.2.66C8.61 5.93 6 5.94 6 8v4a3.58 3.58 0 003.58 3.58H15A3.58 3.58 0 0018.62 12V8zm-1.5 4A2.078 2.078 0 0115 14.09H9.62A2.08 2.08 0 017.54 12V8c0-.43 1-.52 1.92-.53a10.24 10.24 0 003.75-.77l1.74-.7a.84.84 0 01.46-.06A2.09 2.09 0 0117.12 8v4z" />
        <path d="M3.3 15.24a9.72 9.72 0 001.9.51l.62.12a.75.75 0 00.3-1.47l-.65-.12a5.49 5.49 0 01-1.37-.34s-.89-.35 0-1.08a3.57 3.57 0 001.05-2.8l-.23-2.47a5.3 5.3 0 01.53-2.29A5.67 5.67 0 017 3.39a7.8 7.8 0 015-1.78c1.29 0 3.58.26 4.38 2a.75.75 0 001 .38 2.1 2.1 0 011.11-.16 1.46 1.46 0 011.06 1 6.44 6.44 0 01-.07 2.56s-.66 3.28 1.08 5.4c.104.127.187.206.221.342a.69.69 0 01-.024.413c-.1.264-.3.31-.657.455a11.88 11.88 0 01-1.66.44.75.75 0 00.16 1.48h.16c.81-.136 1.602-.364 2.36-.68 2.41-.93.26-3.68.26-3.68-.94-1.39-.45-4.25-.45-4.25a5.55 5.55 0 00-.08-3 2.89 2.89 0 00-2.17-1.9 3.25 3.25 0 00-1.31 0C16.38.93 14.48.11 11.95.11A9.31 9.31 0 006 2.24a7.16 7.16 0 00-1.9 2.41 6.84 6.84 0 00-.68 3l.24 2.46A2.83 2.83 0 012.88 12s-2.42 2.19.42 3.24z" />
        <path d="M10.46 9.5a.6.6 0 100-1.2.6.6 0 000 1.2zm3.65 0a.6.6 0 100-1.2.6.6 0 000 1.2zm.22 1.24a.31.31 0 00-.4.18s-.31.71-1.69.71c-1.32 0-1.57-.67-1.58-.71a.314.314 0 00-.6.18s.36 1.15 2.18 1.15c1.85 0 2.26-1.07 2.28-1.12a.31.31 0 00-.19-.39z" />
      </g>
    );
  }
);
LegoUserFemale2.displayName = 'LegoUserFemale2';
