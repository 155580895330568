import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Duplicate = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M2 4.251A2.251 2.251 0 014.25 2H16.5a2 2 0 012 2A.75.75 0 0117 4a.5.5 0 00-.5-.5H4.25a.751.751 0 00-.75.751V16.5a.5.5 0 00.5.5.75.75 0 010 1.5 2 2 0 01-2-2V4.251z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M20 7.5H8a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h12a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5zM8 6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2H8z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M14 10.45a.75.75 0 01.75.75v2.05h2.05a.75.75 0 010 1.5h-2.05v2.05a.75.75 0 11-1.5 0v-2.05H11.2a.75.75 0 110-1.5h2.05V11.2a.75.75 0 01.75-.75z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
Duplicate.displayName = 'Duplicate';
