import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFaceless = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M8 15.59h8a3.15 3.15 0 003.15-3.15V6A3.15 3.15 0 0016 2.83h-.33v-1.1A1.49 1.49 0 0014.2.23H9.94a1.49 1.49 0 00-1.49 1.5v1.1H8A3.15 3.15 0 004.82 6v6.46A3.15 3.15 0 008 15.59zm2-13.88h4.27v1.12H9.93L10 1.71zM6.29 6A1.68 1.68 0 018 4.3h8A1.68 1.68 0 0117.69 6v6.46A1.68 1.68 0 0116 14.12H8a1.68 1.68 0 01-1.68-1.68L6.29 6zm14.27 11.64a4.1 4.1 0 00-1.84-.42H5C1.22 17.38.22 21.09.22 23a.735.735 0 101.47 0c0-.17 0-4.2 3.35-4.34h13.68a2.64 2.64 0 011.18.26A4.25 4.25 0 0122.29 23a.73.73 0 00.73.74.74.74 0 00.74-.73 5.72 5.72 0 00-3.2-5.37z" />
      </g>
    );
  }
);
LegoUserFaceless.displayName = 'LegoUserFaceless';
