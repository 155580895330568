import { IPopoverArrowProps, PopoverArrow as PopoverArrowInternal } from './PopoverArrow';
import { IPopoverBodyProps, PopoverBody as PopoverBodyInternal } from './PopoverBody';
import {
  IPopoverCloseButtonProps,
  PopoverCloseButton as PopoverCloseButtonInternal,
} from './PopoverCloseButton';
import { IPopoverContentProps, PopoverContent as PopoverContentInternal } from './PopoverContent';
import { IPopoverFooterProps, PopoverFooter as PopoverFooterInternal } from './PopoverFooter';
import { IPopoverHeaderProps, PopoverHeader as PopoverHeaderInternal } from './PopoverHeader';
import { PopoverTrigger as PopoverTriggerInternal } from './PopoverTrigger';

export * from './Popover';
export {
  IPopoverArrowProps,
  IPopoverBodyProps,
  IPopoverCloseButtonProps,
  IPopoverContentProps,
  IPopoverFooterProps,
  IPopoverHeaderProps,
};

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Popover.Arrow` instead
 */
export const PopoverArrow = PopoverArrowInternal;
/**
 * @deprecated use `Popover.Body` instead
 */
export const PopoverBody = PopoverBodyInternal;
/**
 * @deprecated use `Popover.CloseButton` instead
 */
export const PopoverCloseButton = PopoverCloseButtonInternal;
/**
 * @deprecated use `Popover.Content` instead
 */
export const PopoverContent = PopoverContentInternal;
/**
 * @deprecated use `Popover.Footer` instead
 */
export const PopoverFooter = PopoverFooterInternal;
/**
 * @deprecated use `Popover.Header` instead
 */
export const PopoverHeader = PopoverHeaderInternal;
/**
 * @deprecated use `Popover.Trigger` instead
 */
export const PopoverTrigger = PopoverTriggerInternal;
