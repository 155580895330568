import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const WorkshopBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#workshop-bold_svg__a)">
          <path d="M9.734 12.119v1.358a.76.76 0 00.746.748h2.982a.754.754 0 00.745-.748v-1.358A4.486 4.486 0 0016.1 9.947a4.51 4.51 0 00.185-2.881 4.494 4.494 0 00-1.598-2.4 4.46 4.46 0 00-5.443 0 4.51 4.51 0 00-1.412 5.28 4.486 4.486 0 001.892 2.173h.01zm3.718 2.855H10.47a.751.751 0 00-.776.443.762.762 0 00.461 1.034.751.751 0 00.315.03h2.982a.753.753 0 00.745-.748.751.751 0 00-.458-.701.742.742 0 00-.287-.058zm10.457 7.986a5.178 5.178 0 00-2.644-2.995 4.643 4.643 0 00-.567-.24 3.369 3.369 0 001.42-1.631 3.387 3.387 0 00-1.06-3.965 3.35 3.35 0 00-4.089 0 3.387 3.387 0 00-1.062 3.965c.282.686.782 1.26 1.421 1.632a5.146 5.146 0 00-2.008 1.233 5.176 5.176 0 00-1.252 2.001.751.751 0 00.457.998.571.571 0 00.229 0h8.26c.158.056.33.056.487 0a.765.765 0 00.499-.7.77.77 0 00-.052-.298h-.04zM7.25 19.965a5.733 5.733 0 00-.568-.24 3.38 3.38 0 001.422-1.637 3.398 3.398 0 00-1.067-3.974 3.36 3.36 0 00-4.098 0 3.398 3.398 0 00-1.067 3.974 3.38 3.38 0 001.42 1.638 5.145 5.145 0 00-1.996 1.236A5.174 5.174 0 00.052 22.96a.751.751 0 00.497.998c.08.016.16.016.239 0h8.31c.117.03.24.03.358 0a.745.745 0 00.514-.697.751.751 0 00-.057-.3 5.273 5.273 0 00-2.664-2.996z" />
          <path d="M11.98 3.464a.992.992 0 00.995-.998V.998a.992.992 0 10-1.988 0v1.468a.992.992 0 00.994.998zm4.97 2.066c.255.002.5-.094.686-.27l1.064-.997a1.02 1.02 0 00.24-1.108 1.02 1.02 0 00-.928-.646 1.012 1.012 0 00-.724.286l-1.063.998a1 1 0 00.726 1.737zM5.3 4.253l1.064.998A.994.994 0 007.96 4.122a.999.999 0 00-.224-.319l-1.063-.998a.992.992 0 00-1.193-.12.726.726 0 00-.19.13.997.997 0 00-.168.18 1.002 1.002 0 00.149 1.218l.03.04zm11.65 3.873a1 1 0 00.292.706.991.991 0 00.703.292h1.49a.992.992 0 00.703-.292 1 1 0 00-.703-1.704h-1.49a.992.992 0 00-.703.292 1 1 0 00-.291.706zM6.016 9.244a1 1 0 000-1.997h-1.5a.992.992 0 00-.703.293 1 1 0 00.703 1.704h1.5z" />
        </g>
        <defs>
          <clipPath id="workshop-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
WorkshopBold.displayName = 'WorkshopBold';
