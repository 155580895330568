import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MessageBubble = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M4.5 7.5a.75.75 0 01.75-.75h13.5a.75.75 0 010 1.5H5.25a.75.75 0 01-.75-.75zm0 4.5a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H5.25A.75.75 0 014.5 12z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M2.25 1.5a.75.75 0 00-.75.75v15a.75.75 0 00.75.75h3a.75.75 0 01.75.75v3l4.8-3.6a.75.75 0 01.45-.15h10.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75H2.25zM.659.659A2.25 2.25 0 012.25 0h19.5A2.25 2.25 0 0124 2.25v15a2.25 2.25 0 01-2.25 2.25H11.5l-5.8 4.35a.75.75 0 01-1.2-.6V19.5H2.25A2.25 2.25 0 010 17.25v-15C0 1.653.237 1.081.659.659z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
MessageBubble.displayName = 'MessageBubble';
