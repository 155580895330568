import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesImageBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M9.74 10.18a1 1 0 000-1.44 1 1 0 00-.74-.3 1 1 0 00-.72 1.74 1.06 1.06 0 001.46 0z" />
        <path d="M21.92 6.05a.998.998 0 00-.21-.32L16.29.32A.94.94 0 0015.6 0H4a2 2 0 00-1.41.61A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.44a.87.87 0 00-.08-.39zM7 7.45a3 3 0 014 0 2.85 2.85 0 010 4 2.84 2.84 0 01-4.84-2 2.86 2.86 0 01.84-2zm11.56 11.94a.9.9 0 01-1.26-.25l-3.37-5.05-2.24 3.3a1 1 0 01-.62.38.869.869 0 01-.69-.19L9 16.43l-1.81 2.71a.91.91 0 01-.75.41.85.85 0 01-.5-.16.91.91 0 01-.26-1.26l1.81-2.72A2 2 0 018 14.9a1.81 1.81 0 01.68-.26 2 2 0 01.73 0 1.9 1.9 0 01.65.33l.68.54 1.75-2.5a1.779 1.779 0 011.51-.72c.287.008.568.083.82.22.257.136.474.335.63.58l3.37 5a.899.899 0 01-.26 1.3z" />
      </g>
    );
  }
);
FilesImageBold.displayName = 'FilesImageBold';
