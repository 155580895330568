import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const TransferBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6 10.153c.553 0 1-.468 1-1.044V7.804c0-.145.112-.261.25-.261h10.108c.828 0 1.5-.702 1.5-1.567s-.672-1.566-1.5-1.566H7.25A.256.256 0 017 4.15V2.843c0-.376-.194-.723-.507-.909a.964.964 0 00-1.008.014l-5 3.133A1.054 1.054 0 000 5.977c0 .367.184.707.485.895l5 3.133a.968.968 0 00.515.148zm17.515 6.975l-5-3.133a.963.963 0 00-1.008-.013 1.053 1.053 0 00-.507.91v1.304a.256.256 0 01-.25.261H6.642c-.828 0-1.5.702-1.5 1.567s.672 1.566 1.5 1.566H16.75c.066 0 .13.027.177.076a.27.27 0 01.073.185v1.305c0 .376.194.724.507.909a.963.963 0 001.008-.014l5-3.132c.3-.19.485-.53.485-.896 0-.367-.184-.706-.485-.895z" />
      </g>
    );
  }
);
TransferBold.displayName = 'TransferBold';
