import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const QrCodeBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M4.84 22l-2.584.037a.25.25 0 01-.236-.153.251.251 0 01-.02-.097V19.16a1 1 0 10-2 0v2.883A1.957 1.957 0 001.957 24H4.84a1 1 0 000-2zM1 5.84a1 1 0 001-1l-.038-2.584A.251.251 0 012.212 2H4.84a1 1 0 000-2H1.956A1.956 1.956 0 000 1.956V4.84a1 1 0 001 1zM22.043 0H19.16a1 1 0 100 2l2.584-.037a.251.251 0 01.256.25V4.84a1 1 0 002 0V1.957A1.957 1.957 0 0022.043 0zM23 18.16a1 1 0 00-1 1l.038 2.584a.25.25 0 01-.25.256H19.16a1 1 0 000 2h2.884A1.956 1.956 0 0024 22.044V19.16a1 1 0 00-1-1zM5 4.5h3.5V8H5V4.5zm10.5 0H19V8h-3.5V4.5zm0 11.5H19v3.5h-3.5V16zM5 16h3.5v3.5H5V16zm5.5-7.739a.75.75 0 00.75-.75V6.25A.25.25 0 0111.5 6h1.932a.75.75 0 100-1.5H10.5a.75.75 0 00-.75.75v2.261a.75.75 0 00.75.75zm3-1.13a.75.75 0 00-.75.75v3.348a.75.75 0 101.5 0V7.881a.75.75 0 00-.75-.75zm-3 7.369a.75.75 0 00.75-.75v-2.413a.75.75 0 10-1.5 0v1.413a.25.25 0 01-.25.25h-4a.75.75 0 100 1.5h5zm2.932 3.5H11.5a.25.25 0 01-.25-.25v-1.413a.75.75 0 10-1.5 0v2.413a.75.75 0 00.75.75h2.932a.75.75 0 100-1.5zM5.5 11h2a.75.75 0 100-1.5h-2a.75.75 0 000 1.5zM16 11h2.5a.75.75 0 100-1.5H16a.75.75 0 100 1.5zm2.5 2H13a.75.75 0 100 1.5h5.5a.75.75 0 100-1.5z" />
      </g>
    );
  }
);
QrCodeBold.displayName = 'QrCodeBold';
