import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import {
  ArrowUpAndDownBold,
  ArrowLongDownBold,
  ArrowLongUpBold,
} from '@lego/klik-icons-react';
import { colors, spacing } from '../../../../config';

const columnIndicatorIconStyle = (isSorted) =>
  css({
    color: isSorted ? 'inherit' : colors.neutralColors.slate30,
    verticalAlign: 'middle',
    width: 14,
    height: 14,
  });

const sortIndicatorStyle = css({
  marginRight: spacing.size1,
  marginLeft: -2,
  display: 'inline-block',
});

const ColumnSortIndicator = ({ isSorted, isSortedDesc, isSortDisabled }) => {
  if (isSortDisabled) return null;

  if (!isSorted) {
    return (
      <span css={sortIndicatorStyle}>
        <ArrowUpAndDownBold css={columnIndicatorIconStyle(isSorted)} />
      </span>
    );
  }

  if (isSortedDesc) {
    return (
      <span css={sortIndicatorStyle}>
        <ArrowLongDownBold css={columnIndicatorIconStyle(isSorted)} />
      </span>
    );
  }

  return (
    <span css={sortIndicatorStyle}>
      <ArrowLongUpBold css={columnIndicatorIconStyle(isSorted)} />
    </span>
  );
};

ColumnSortIndicator.propTypes = {
  isSorted: PropTypes.bool.isRequired,
  isSortedDesc: PropTypes.bool.isRequired,
  isSortDisabled: PropTypes.bool,
};

export { ColumnSortIndicator };
