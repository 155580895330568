import { getValidChildren } from '@chakra-ui/react-utils';
import { chakra, StylesProvider, ThemingProps, useMultiStyleConfig } from '@chakra-ui/system';
import * as React from 'react';
import { IStepProps, Step, StepperState } from './Step';
import { StepLabel } from './StepLabel';
import { StepPanel } from './StepPanel';
import { StepperProvider } from './StepperProvider';

export type IStepperProps = ThemingProps<'Stepper'> & {
  orientation?: 'horizontal' | 'vertical';
  alignment?: 'center' | 'left' | 'right';
  activeStep?: number;
  completedSteps?: number[];
  readonlySteps?: number[];
  onChange?: (index: number) => void;
  children: React.ReactElement<IStepProps> | React.ReactElement<IStepProps>[];
};

type IStepperComponent = React.FunctionComponent<IStepperProps> & {
  Label: typeof StepLabel;
  Panel: typeof StepPanel;
  Step: typeof Step;
};

/**
 * Stepper
 * Stepper base element
 * @param param0
 * @returns Stepper Component
 * @memberof stepper
 * @author Simon Groenborg
 */
export const Stepper: IStepperComponent = ({
  children,
  orientation = 'horizontal',
  alignment = 'center',
  activeStep = 1,
  completedSteps = [],
  readonlySteps = [],
  colorScheme,
  onChange,
  ...props
}) => {
  const derivedVariant = orientation === 'horizontal' ? alignment : orientation;
  const styles = useMultiStyleConfig('Stepper', { colorScheme, variant: derivedVariant });

  const state = React.useCallback(
    (index: number): StepperState => {
      if (index === activeStep) {
        return 'active';
      }

      return completedSteps.includes(index) ? 'complete' : 'incomplete';
    },
    [activeStep, completedSteps]
  );

  const validChildren = getValidChildren(children);

  return (
    <StepperProvider value={{ activeStep, completedSteps, alignment, orientation, readonlySteps }}>
      <StylesProvider value={styles}>
        <chakra.ul __css={styles.container} {...props}>
          {validChildren.map((child: React.ReactElement<IStepProps>, index) => {
            return React.cloneElement(child, {
              index: index + 1,
              onClick: () => {
                if (onChange && state(index + 1) === 'complete') {
                  onChange(index + 1);
                }
              },
              state: state(index + 1),
              ...child.props,
            });
          })}
        </chakra.ul>
      </StylesProvider>
    </StepperProvider>
  );
};

Stepper.Label = StepLabel;
Stepper.Panel = StepPanel;
Stepper.Step = Step;
