import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFacelessFemale2Bold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.21 18.615c-.84-.84-1.93-1.27-3.25-1.27H5.85c-3.65.01-4.62 3.53-4.62 5.39 0 .55.45 1 1 1s1-.45 1-1c0-.35.1-3.39 2.62-3.39h12.11c2.73 0 2.81 3.04 2.81 3.38 0 .55.44 1 1 1 .55 0 1-.44 1-1 .01-.09 0-2.54-1.56-4.11zm-6.48-14.01h-.19l-2.25.9c-1.05.41-2.08.63-3.06.63-.9 0-3.63.02-3.63 2.26v4c0 2.09 1.7 3.79 3.79 3.79h5.34c2.09 0 3.79-1.7 3.79-3.79v-4c.01-2.09-1.7-3.79-3.79-3.79zm1.8 7.79c0 .99-.81 1.79-1.79 1.79H9.39c-.99 0-1.79-.81-1.79-1.79l-.01-4c.02-.03.27-.25 1.65-.26 1.24-.01 2.51-.27 3.79-.78l1.88-.75c.91.09 1.62.86 1.62 1.79v4z" />
        <path d="M2.9 17.225c.52.19 1.09-.07 1.29-.59.19-.52-.07-1.09-.59-1.29-.09-.03-.16-.09-.19-.23l1.25-2.6c.3-.59.46-1.25.46-1.91v-.1l-.26-2.5c.01-.75.19-1.47.52-2.16.34-.71.83-1.32 1.46-1.84 1.36-1.12 3.11-1.74 4.94-1.74 1.62 0 3.44.77 4.65 1.95.31.3.77.37 1.15.18.48-.25.92-.09 1.14.03.39.21.66.59.7.96.04.39-.05.9-.16 1.5-.11.61-.24 1.3-.25 2.07-.01.6-.02 2.64.46 3.58.4.8 1.09 2.2 1.2 2.45 0 .11-.12.34-.22.41a1 1 0 00-.49 1.31c.16.38.53.6.92.6.13 0 .27-.03.4-.08.92-.41 1.69-1.81 1.27-2.88-.21-.54-1.19-2.48-1.3-2.7-.09-.18-.28-1.1-.24-2.63.01-.63.12-1.21.22-1.78.13-.71.25-1.38.18-2.06-.1-1.02-.77-1.98-1.73-2.51-.74-.4-1.55-.51-2.33-.32-1.55-1.29-3.63-2.08-5.57-2.08-2.29 0-4.5.78-6.22 2.2-.84.7-1.51 1.54-1.98 2.51-.47.97-.72 2-.72 3.07v.11l.26 2.51c-.01.33-.09.66-.25.98l-1.27 2.63c-.13.28-.19.58-.19.88 0 .3.07.6.2.89.25.54.71.96 1.29 1.18z" />
      </g>
    );
  }
);
LegoUserFacelessFemale2Bold.displayName = 'LegoUserFacelessFemale2Bold';
