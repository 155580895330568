import { Breadcrumb as BreadcrumbComponent, BreadcrumbProps } from '@chakra-ui/breadcrumb';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import { ArrowRightBold } from '@lego/klik-ui-icons';
import * as React from 'react';
import { BreadcrumbItem } from './BreadcrumbItem';
import { BreadcrumbLink } from './BreadcrumbLink';
import { BreadcrumbSeparator } from './BreadcrumbSeparator';

export type IBreadcrumbProps = BreadcrumbProps;

type IBreadcrumbComponent = ComponentWithAs<'nav', IBreadcrumbProps> & {
  Item: typeof BreadcrumbItem;
  Link: typeof BreadcrumbLink;
  Separator: typeof BreadcrumbSeparator;
};

export const Breadcrumb = forwardRef<IBreadcrumbProps, 'nav'>(
  ({ separator = <ArrowRightBold verticalAlign="text-top" />, ...rest }, ref) => {
    return <BreadcrumbComponent {...rest} ref={ref} separator={separator} />;
  }
) as IBreadcrumbComponent;

Breadcrumb.Item = BreadcrumbItem;
Breadcrumb.Link = BreadcrumbLink;
Breadcrumb.Separator = BreadcrumbSeparator;
