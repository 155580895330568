import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const VirtualReality = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M7.5 13.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75z" />
        <path
          clipRule="evenodd"
          d="M11.998.75A8.272 8.272 0 003.75 8.998v.019a4.125 4.125 0 00.375 8.233c.164 0 .326-.022.482-.063A2.25 2.25 0 006.75 18.75h3a.75.75 0 00.416-.126L12 17.401l1.833 1.223a.751.751 0 00.416.126h3a2.25 2.25 0 002.143-1.563c.156.041.318.063.483.063a4.125 4.125 0 00.375-8.233v-.019A8.272 8.272 0 0012.002.75h-.004zm6.232 9.225a1.875 1.875 0 01.52-.6v-.374A6.772 6.772 0 0012 2.25a6.772 6.772 0 00-6.75 6.751v.374a1.875 1.875 0 01.52.6 2.25 2.25 0 01.98-.225h10.5c.343 0 .677.079.98.225zM6 12v4.5a.75.75 0 00.75.75h2.773l2.061-1.374a.75.75 0 01.832 0l2.06 1.374h2.773a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H6.75A.75.75 0 006 12zm-1.5-1.125a.375.375 0 00-.375-.375 2.625 2.625 0 000 5.25.375.375 0 00.375-.375v-4.5zm15.375-.375a.375.375 0 00-.375.375v4.5a.375.375 0 00.375.375 2.625 2.625 0 000-5.25z"
          fillRule="evenodd"
        />
        <path d="M17.774 20.89a.75.75 0 00-1.05-1.072 6.75 6.75 0 01-8.774.582.75.75 0 10-.9 1.2 8.25 8.25 0 0010.724-.71z" />
      </g>
    );
  }
);
VirtualReality.displayName = 'VirtualReality';
