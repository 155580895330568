import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowRight = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M8.495.445a.75.75 0 011.06.05l10 11a.75.75 0 010 1.01l-10 11a.75.75 0 11-1.11-1.01L17.986 12 8.445 1.505a.75.75 0 01.05-1.06z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowRight.displayName = 'ArrowRight';
