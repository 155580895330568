import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Bluetooth = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M11.293.076a.755.755 0 01.793.082l6.774 5.25a.75.75 0 01.053 1.138l-6.002 5.63 5.984 5.262a.749.749 0 01-.035 1.154l-6.774 5.25a.755.755 0 01-1.215-.592v-9.161L6.12 18.546a.755.755 0 01-1.064-.032.748.748 0 01.032-1.06l5.59-5.243-5.572-4.899a.748.748 0 01-.067-1.058.754.754 0 011.063-.066l4.77 4.194V.75a.75.75 0 01.422-.674zm1.083 13.63l4.842 4.258-4.842 3.753v-8.01zm0-3.089V2.283l4.865 3.771-4.865 4.563z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
Bluetooth.displayName = 'Bluetooth';
