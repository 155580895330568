import { ButtonGroup as ButtonGroupComponent, ButtonGroupProps } from '@chakra-ui/button';
import { getValidChildren } from '@chakra-ui/react-utils';
import { forwardRef, SystemStyleObject } from '@chakra-ui/system';
import { Button } from '@lego/klik-ui-button';
import * as React from 'react';

// TODO: Add `isRound` to the official chakra and ask them to propagate it, imho that's an
// oversight on their behalf. Once they have
export interface IButtonGroupProps extends ButtonGroupProps {
  isRound?: boolean;
}

export const ButtonGroup = forwardRef<IButtonGroupProps, 'div'>(
  ({ children, spacing = '1em', isAttached, variant, isRound = false, ...rest }, ref) => {
    let groupStyles: SystemStyleObject = {
      display: 'inline-flex',
      flexWrap: 'wrap',
      '& > *:not(style) ~ *:not(style)': { marginStart: 0 },
    };

    if (isAttached) {
      groupStyles = {
        ...groupStyles,
        '> *:first-of-type:not(:last-of-type)': { borderEndRadius: 0, borderEndWidth: 0 },
        '> *:not(:first-of-type):not(:last-of-type)': { borderRadius: 0, borderEndWidth: 0 },
        '> *:not(:first-of-type):last-of-type': { borderStartRadius: 0 },
        gap: variant === 'outline' ? 'none' : '2px',
      };
    } else {
      groupStyles = {
        ...groupStyles,
        gap: spacing,
      };
    }

    const validChildren = getValidChildren(children);

    const modifiedVariant = variant ? variant : isAttached ? 'attached' : variant;

    return (
      <ButtonGroupComponent variant={modifiedVariant} {...rest} ref={ref} sx={groupStyles}>
        {React.Children.map(validChildren, (child) => {
          return React.cloneElement(child, {
            ...(child.type !== Button ? { isRound } : {}),
          });
        })}
      </ButtonGroupComponent>
    );
  }
);
