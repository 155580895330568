import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFemale1 = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#lego-user-female-1_svg__a)">
          <path d="M22.06 14.57a.72.72 0 000-1A5.341 5.341 0 0121 11.7a4.161 4.161 0 010-2c.59-2 1.11-5.89-.58-8.13A4.23 4.23 0 0016.84 0 5.16 5.16 0 0013 1.86c-1.34-.25-5-.7-7.09 1.42C4.33 4.83 4 7.36 4.86 10.8V12A3.77 3.77 0 006 14.7a3.722 3.722 0 002.65 1.1h5.68a3.71 3.71 0 002.2-.73 4.32 4.32 0 005.53-.5zm-7.77-.27H8.61A2.251 2.251 0 016.36 12V8.14c1.87-.12 6.59-.63 8.49-2.6a1.82 1.82 0 011 .55 3 3 0 01.7 1.91v4a2.25 2.25 0 01-.659 1.591c-.422.422-1.004.709-1.6.709zm3.27-.3a2.27 2.27 0 00.467-.846c.094-.313.073-.71.073-.964V8.71c0-3.06-1.35-3.93-1.35-3.93a3.18 3.18 0 00-2.3-.78.75.75 0 00-.53.3C12.7 6 8 6.57 5.92 6.68a3.82 3.82 0 011-2.34c2-2 6.13-.93 6.17-.92a.75.75 0 00.84-.35l.11-.17a3.67 3.67 0 012.81-1.42 2.76 2.76 0 012.36 1c1.26 1.67.88 5 .32 6.87a5.879 5.879 0 000 2.82 6.718 6.718 0 001 1.77c-1.22 1.06-2.97.06-2.97.06zm4.86 6a6.09 6.09 0 00-2.37-2.42 3.63 3.63 0 00-1.8-.44H5.09c-3.67.17-4.64 4.06-4.64 6.1a.75.75 0 001.49 0s0-4.46 3.18-4.61h13.14a2.29 2.29 0 011.07.26 4.6 4.6 0 012.31 4.3.75.75 0 00.69.79h.05a.75.75 0 00.74-.7 6.08 6.08 0 00-.7-3.28z" />
          <path d="M9.62 9.94a.6.6 0 100-1.2.6.6 0 000 1.2zm3.65 0a.6.6 0 100-1.2.6.6 0 000 1.2zm.22 1.24a.31.31 0 00-.4.18s-.31.71-1.69.71c-1.32 0-1.57-.67-1.58-.71a.314.314 0 00-.6.18s.36 1.15 2.18 1.15c1.85 0 2.26-1.07 2.28-1.12a.31.31 0 00-.19-.39z" />
        </g>
        <defs>
          <clipPath id="lego-user-female-1_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
LegoUserFemale1.displayName = 'LegoUserFemale1';
