const assessmentStatus = {
  ASSESSMENT: 'ASSESSMENT',
  SELF_ASSESSMENT: 'SELF_ASSESSMENT',
  RE_ASSESSMENT: 'RE_ASSESSMENT',
  REVIEW: 'REVIEW',
  APPROVAL: 'APPROVAL',
  COMPLETED: 'COMPLETED',
};

const assessmentStatusTranslations = {
  [assessmentStatus.ASSESSMENT]: '1 - Assessment',
  [assessmentStatus.SELF_ASSESSMENT]: '1 - Self-assessment',
  [assessmentStatus.RE_ASSESSMENT]: '1 - Re-assessment',
  [assessmentStatus.REVIEW]: '2 - Review',
  [assessmentStatus.APPROVAL]: '3 - Approval',
  [assessmentStatus.COMPLETED]: '4 - Complete',
};

export { assessmentStatus, assessmentStatusTranslations };
