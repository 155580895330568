const optionsIncludingTime = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const parseFormatDateOptions = (options) =>
  options.includeTime ? optionsIncludingTime : options;

const formatDate = (date, options = {}) =>
  new Intl.DateTimeFormat('da', parseFormatDateOptions(options)).format(date);

export { formatDate };
