import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { colors } from '../config';

const highLightedTextCss = css({
  color: colors.utilityColors.error.error600,
});

const HighlightedText = ({ text }) => {
  return <span css={highLightedTextCss}>{text}</span>;
};

HighlightedText.propTypes = {
  text: PropTypes.string.isRequired,
};

export { HighlightedText };
