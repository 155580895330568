import { usePopoverContext } from '@chakra-ui/popover';
import { CloseButton, ICloseButtonProps } from '@lego/klik-ui-close-button';
import * as React from 'react';

export type IPopoverCloseButtonProps = ICloseButtonProps;

export const PopoverCloseButton: React.FunctionComponent<IPopoverCloseButtonProps> = (props) => {
  const { onClose } = usePopoverContext();

  return (
    <CloseButton onClick={onClose} position="absolute" right="0" size="sm" top="0" {...props} />
  );
};
