import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SavingMoneyFlowerBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.589 13.325a.475.475 0 00-.319-.255.493.493 0 00-.406.08c-.672.462-1.44.78-2.25.93-.714.14-1.397.4-2.017.767a2.37 2.37 0 00-1.234 1.902 1.68 1.68 0 00.096.57.225.225 0 01-.077.254 14.71 14.71 0 00-1.62 1.452.246.246 0 01-.264.058.231.231 0 01-.152-.215v-4.107c0-.113.085-.21.2-.228 3.631-.581 6.193-3.747 5.89-7.28C19.134 3.718 16.07 1 12.388 1c-3.68 0-6.746 2.72-7.049 6.252-.302 3.534 2.26 6.7 5.89 7.28.116.02.2.116.2.229v3.192a.23.23 0 01-.136.208.247.247 0 01-.254-.03 10.423 10.423 0 00-1.829-1.144.132.132 0 01-.076-.125 2.56 2.56 0 00-1.314-2.078 6.242 6.242 0 00-5.606.069.452.452 0 00-.068.716 5.012 5.012 0 011.18 1.817c.234.607.577 1.17 1.014 1.661.43.501 1.06.805 1.734.838.087 0 .173-.005.26-.018a2.08 2.08 0 001.513-1.178.24.24 0 01.08-.083l.034-.022a.246.246 0 01.24-.016 7.342 7.342 0 013.197 2.701.222.222 0 01.033.117v.846a1 1 0 00.794.903c.443.098.9-.096 1.123-.478A13.173 13.173 0 0116.606 19a.093.093 0 01.134.022c.304.56.848.96 1.487 1.1.118.021.238.032.358.032a3.14 3.14 0 002.153-1.033 6.111 6.111 0 00.85-5.796zm-10.925-1.384c-.463 0-.839-.362-.839-.808 0-.446.376-.808.839-.808h2.338c.338 0 .63-.23.693-.55a.674.674 0 00-.431-.759l-2.372-.907c-.987-.38-1.592-1.343-1.485-2.361.107-1.019.901-1.845 1.947-2.027a.233.233 0 00.197-.227v-.111c0-.446.376-.808.839-.808.463 0 .838.362.838.808v.069c0 .127.107.23.24.23h.647c.463 0 .838.362.838.808 0 .446-.375.808-.838.808h-2.339a.698.698 0 00-.69.55.674.674 0 00.429.758l2.374.914c.986.38 1.592 1.344 1.484 2.362-.107 1.018-.902 1.844-1.947 2.025a.234.234 0 00-.198.227v.11c0 .446-.375.808-.838.808-.463 0-.839-.362-.839-.808v-.069a.235.235 0 00-.24-.23l-.647-.004z" />
      </g>
    );
  }
);
SavingMoneyFlowerBold.displayName = 'SavingMoneyFlowerBold';
