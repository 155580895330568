import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const PlusBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M13.5 1.565a1.5 1.5 0 00-3 0V10.5H1.565a1.5 1.5 0 000 3H10.5v8.935a1.5 1.5 0 003 0V13.5h8.935a1.5 1.5 0 000-3H13.5V1.565z" />
    </g>
  );
});
PlusBold.displayName = 'PlusBold';
