import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFacelessBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.782 18.82c-.88-.89-2.05-1.34-3.47-1.34H5.472c-3.83 0-4.84 3.52-4.84 5.39 0 .55.45 1 1 1s1-.45 1-1c0-.14.03-3.38 2.84-3.38h12.84c2.95 0 3.04 3.04 3.04 3.38 0 .55.44 1 1 1 .55 0 1-.44 1-1 0-.1 0-2.48-1.57-4.05zm-13.6-2.5h7.63c1.91 0 3.46-1.55 3.46-3.46V6.52c0-1.86-1.49-3.38-3.33-3.45V1.9c-.01-.98-.8-1.77-1.78-1.77h-4.34c-.98 0-1.77.79-1.77 1.77v1.17a3.453 3.453 0 00-3.33 3.45v6.34c0 1.91 1.55 3.46 3.46 3.46zm1.87-14.19h3.88v.92h-3.88v-.92zm-3.33 4.39c0-.81.66-1.46 1.46-1.46h7.63c.81 0 1.46.66 1.46 1.46v6.34c0 .81-.66 1.46-1.46 1.46h-7.63c-.81 0-1.46-.66-1.46-1.46V6.52z" />
      </g>
    );
  }
);
LegoUserFacelessBold.displayName = 'LegoUserFacelessBold';
