import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesVttBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM8.31 17.35a.57.57 0 01-.41.17.6.6 0 01-.41-.18c-1.92-2-1.94-6.49-1.94-6.68a.6.6 0 011.19 0s0 3.44 1.18 5.31a8.62 8.62 0 001.17-5.3.59.59 0 01.56-.62.57.57 0 01.62.55c.26 4.57-1.87 6.67-1.96 6.75zm5.62-6h-.61v5.55a.62.62 0 11-1.23 0v-5.51h-.61a.61.61 0 010-1.22h2.45a.61.61 0 010 1.22v-.04zm3.91 0h-.61v5.55a.61.61 0 01-1.22 0v-5.51h-.61a.61.61 0 010-1.22h2.44a.61.61 0 010 1.22v-.04z" />
      </g>
    );
  }
);
FilesVttBold.displayName = 'FilesVttBold';
