import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesJpg = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-jpg_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h1.948a.75.75 0 000-1.5H3.75a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M13.972 15.101a.743.743 0 00-.742.731v7.34a.738.738 0 00.433.763.744.744 0 001.042-.762V20.97c.78 0 1.528-.31 2.08-.86a2.93 2.93 0 000-4.15 2.947 2.947 0 00-2.08-.859h-.733zm.733 1.472v2.935a1.477 1.477 0 001.476-1.473 1.47 1.47 0 00-1.476-1.462z"
          fillRule="evenodd"
        />
        <path d="M21.822 23.824a3.016 3.016 0 01-1.927-.621 4.195 4.195 0 01-1.265-3.505 4.757 4.757 0 011.556-4.006 3.828 3.828 0 013.061-.63.753.753 0 01.557.905.751.751 0 01-.908.556 2.324 2.324 0 00-1.817.38 3.434 3.434 0 00-1.004 2.795 2.996 2.996 0 00.703 2.313 2.092 2.092 0 001.716.2V20.47l-.813.05a.754.754 0 01-.753-.751.75.75 0 01.753-.751h.863A1.457 1.457 0 0124 20.469v1.793a1.43 1.43 0 01-1.104 1.402c-.35.099-.71.152-1.074.16zm-12.357.09h-.803a.703.703 0 01-.503-1.204.714.714 0 01.503-.208h.803a1.166 1.166 0 001.165-1.152v-5.628a.699.699 0 01.702-.711.715.715 0 01.713.711v5.598a2.57 2.57 0 01-1.588 2.396c-.314.13-.651.198-.992.198z" />
      </g>
      <defs>
        <clipPath id="files-jpg_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesJpg.displayName = 'FilesJpg';
