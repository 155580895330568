import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StrategyBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#strategy-bold_svg__a)">
          <path d="M12 24a1.25 1.25 0 001.25-1.25v-.42a10.314 10.314 0 015.713-9.254.252.252 0 01.343.128l.251.606a1 1 0 001.726.218l2.516-3.342a1 1 0 00-.66-1.591l-4.146-.583a1 1 0 00-1.061 1.372l.225.543a.25.25 0 01-.123.321 12.816 12.816 0 00-4.34 3.389.25.25 0 01-.444-.16V6.214a.249.249 0 01.25-.25h.807a1.042 1.042 0 00.9-1.572L12.9.477a1.087 1.087 0 00-1.8 0L8.796 4.392a1.043 1.043 0 00.9 1.572h.806a.249.249 0 01.25.25v7.763a.251.251 0 01-.443.16 12.816 12.816 0 00-4.34-3.389.25.25 0 01-.123-.32l.224-.544a1 1 0 00-1.062-1.372l-4.145.583a1 1 0 00-.66 1.592l2.52 3.343a1 1 0 001.725-.217l.251-.607a.251.251 0 01.344-.127 10.313 10.313 0 015.713 9.252v.421A1.25 1.25 0 0012 24z" />
        </g>
        <defs>
          <clipPath id="strategy-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
StrategyBold.displayName = 'StrategyBold';
