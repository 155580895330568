import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ShareBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M5.8 10h1.3a1.5 1.5 0 100-3H5.7a2.9 2.9 0 00-2.9 2.9v11.2A2.9 2.9 0 005.7 24h12.6a2.9 2.9 0 002.9-2.9V9.9A2.9 2.9 0 0018.3 7h-1.4a1.5 1.5 0 000 3h1.3v11H5.8V10z" />
        <path d="M10.99.43a1.401 1.401 0 012-.02l3.29 3.29a1.414 1.414 0 11-2 2l-.88-.88v5.8a1.41 1.41 0 11-2.82 0V4.81l-.88.88a1.414 1.414 0 01-2-2L10.99.43z" />
      </g>
    );
  }
);
ShareBold.displayName = 'ShareBold';
