import { Accordion as AccordionComponent, AccordionProps } from '@chakra-ui/accordion';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import * as React from 'react';
import { AccordionButton } from './AccordionButton';
import { AccordionIcon } from './AccordionIcon';
import { AccordionItem } from './AccordionItem';
import { AccordionPanel } from './AccordionPanel';

export type IAccordionProps = AccordionProps;

type IAccordionComponent = ComponentWithAs<'div', IAccordionProps> & {
  Button: typeof AccordionButton;
  Icon: typeof AccordionIcon;
  Item: typeof AccordionItem;
  Panel: typeof AccordionPanel;
};

export const Accordion = forwardRef<IAccordionProps, 'div'>(
  ({ allowMultiple = true, ...rest }, ref) => {
    return <AccordionComponent allowMultiple={allowMultiple} {...rest} ref={ref} />;
  }
) as IAccordionComponent;

Accordion.Button = AccordionButton;
Accordion.Icon = AccordionIcon;
Accordion.Item = AccordionItem;
Accordion.Panel = AccordionPanel;
