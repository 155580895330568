import { useState } from 'react';
import PropTypes from 'prop-types';
import { S3Image } from 'aws-amplify-react';
import { css } from '@emotion/core';
import { Overlay } from '@lego/klik-react';
import { Cross } from '@lego/klik-icons-react';
import { Spinner } from '../../spinner/Spinner';
import { spacing, colors } from '../../../config';

const imageViewerImageStyle = css({
  height: 'auto',
  width: '100%',
});

const imageViewerContainerStyle = css({
  maxWidth: 1200,
  position: 'relative',
  margin: '0 auto',
  padding: spacing.size4,
});

const imageViewCloseButton = css({
  position: 'absolute',
  top: 30,
  right: 30,
  border: 'none',
  height: 40,
  width: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  zIndex: 1,
  '&:hover': {
    backgroundColor: colors.neutralColors.slate20,
  },
});

const imageViewerCloseIconStyle = css({
  width: 16,
  height: 16,
});

const spinnerWrapperStyle = () =>
  css({
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    top: '30%',
  });

const ImageModal = ({ imageId, closeImageModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Overlay isOpen={true} onClick={closeImageModal}>
      <div css={imageViewerContainerStyle}>
        <button
          aria-label="Close image modal"
          css={imageViewCloseButton}
          onClick={closeImageModal}
        >
          <Cross css={imageViewerCloseIconStyle} />
        </button>
        {isLoading && (
          <div css={spinnerWrapperStyle}>
            <Spinner />
          </div>
        )}
        <S3Image
          css={imageViewerImageStyle}
          level="public"
          imgKey={imageId}
          onLoad={() => setIsLoading((prevState) => !prevState)}
        />
      </div>
    </Overlay>
  );
};

ImageModal.propTypes = {
  imageId: PropTypes.string.isRequired,
  closeImageModal: PropTypes.func.isRequired,
};

export { ImageModal };
