import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesMov = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-mov_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h.1a.75.75 0 000-1.5h-.1a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path d="M10.809 23.955a.75.75 0 01-.75-.751v-5.008l-.931 1.362a.71.71 0 01-.61.331.75.75 0 01-.621-.32l-1.001-1.412v5.007a.751.751 0 11-1.501 0v-7.401a.761.761 0 01.53-.721.75.75 0 01.84.29l1.742 2.484 1.681-2.484a.75.75 0 011.371.431v7.44a.742.742 0 01-.75.752z" />
        <path
          clipRule="evenodd"
          d="M14.912 23.955c-1.571 0-2.802-1.943-2.802-4.416 0-2.474 1.231-4.417 2.802-4.417 1.572 0 2.803 1.943 2.803 4.416 0 2.474-1.231 4.417-2.803 4.417zm0-7.33c-.66 0-1.39 1.251-1.39 2.913 0 1.663.73 2.915 1.39 2.915.66 0 1.381-1.242 1.381-2.915 0-1.672-.73-2.914-1.38-2.914z"
          fillRule="evenodd"
        />
        <path d="M20.775 23.766a.79.79 0 00.667.01.801.801 0 00.276-.202 12.644 12.644 0 002.272-7.76.76.76 0 00-1.512 0 12.715 12.715 0 01-1.37 6.008 15.091 15.091 0 01-1.332-6.009.731.731 0 00-.76-.69.73.73 0 00-.751.69c0 .2.03 4.937 2.242 7.741a.79.79 0 00.268.212z" />
      </g>
      <defs>
        <clipPath id="files-mov_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesMov.displayName = 'FilesMov';
