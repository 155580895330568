import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const AnnouncementBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M20.712 3.09a.754.754 0 01.398.664V20.34a.754.754 0 01-1.172.628l-1.14-.76a21.663 21.663 0 00-11.214-3.622 6.523 6.523 0 001.983 3.962.754.754 0 11-1.04 1.093 8.03 8.03 0 01-2.46-5.07V7.524h.72a21.672 21.672 0 0012.011-3.637l1.14-.76a.754.754 0 01.774-.038zM1.325 8.848a4.524 4.524 0 013.199-1.324v9.047a4.524 4.524 0 01-3.2-7.723zm21.545.56a1.13 1.13 0 00-1.132 1.131v3.016a1.13 1.13 0 002.262 0v-3.016a1.13 1.13 0 00-1.13-1.13z" />
      </g>
    );
  }
);
AnnouncementBold.displayName = 'AnnouncementBold';
