import { init as initApm } from '@elastic/apm-rum';
import { apmBase } from '@elastic/apm-rum';

const logger = {
  init: () => {
    initApm({
      serviceName: 'mcp-admin-ui',
      serverUrl: 'https://partner-operations.apm.ece.legogroup.io',
      environment: process.env.MCP_ENV,
      propagateTracestate: true,
      breakdownMetrics: true,
      distributedTracingOrigins: [process.env.GRAPHQL_URL],
      serviceVersion: process.env.GIT_COMMIT_SHA,
    });
  },
  logError: (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    apmBase.captureError(error);
  },
};

export { logger };
