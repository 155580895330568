import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Stack = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M23.581 11.991a.75.75 0 00-.38-.65l-3.252-1.832L23.2 7.67a.72.72 0 00.38-.681.75.75 0 00-.38-.65L11.813.092a.78.78 0 00-.74 0L.376 6.367a.76.76 0 00-.37.62.73.73 0 00.38.651L3.538 9.46.376 11.311a.74.74 0 00-.37.68.73.73 0 00.38.65l3.212 1.822-3.222 1.86a.75.75 0 000 1.302l11.007 6.254a.69.69 0 00.43.12.74.74 0 00.37-.1l11.008-6.254a.74.74 0 00.38-.66.75.75 0 00-.38-.651l-3.312-1.831 3.312-1.871a.73.73 0 00.39-.64zM11.483 1.614L21.3 6.988l-9.487 5.423-9.556-5.423 9.226-5.374zm9.817 15.38l-9.487 5.374-9.556-5.374 2.852-1.7 6.294 3.572a.69.69 0 00.41.13.74.74 0 00.37-.1l6.215-3.513 2.902 1.611zm-9.487.36l-9.556-5.363L5.05 10.36l6.354 3.602c.13.052.274.062.41.03a.74.74 0 00.37-.1l6.265-3.542 2.852 1.641-9.487 5.364z" />
    </g>
  );
});
Stack.displayName = 'Stack';
