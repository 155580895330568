import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MusicMultiBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.44 24H4.11c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h17.33c.58 0 1.05-.47 1.05-1.05V4.11c0-.41.34-.75.75-.75s.76.34.76.75v17.33c0 1.41-1.15 2.56-2.56 2.56z" />
        <path d="M13.52 13.37a1.181 1.181 0 001.5-.15c.23-.22.35-.52.35-.84 0-.23-.07-.46-.2-.66s-.31-.35-.53-.44a1.17 1.17 0 00-.69-.07 1.22 1.22 0 00-.94.94c-.04.23-.02.47.07.69.09.21.24.4.44.53zm-6.38-.03a1.188 1.188 0 00-1.5.15c-.23.22-.35.52-.35.84 0 .23.07.46.2.66s.31.35.53.44c.22.09.46.12.69.07a1.22 1.22 0 00.94-.94c.04-.23.02-.47-.07-.69-.09-.21-.24-.4-.44-.53z" />
        <path
          clipRule="evenodd"
          d="M1.8 0h18.12c1 0 1.81.81 1.8 1.8v18.12c0 .99-.8 1.8-1.8 1.8H1.8c-.99 0-1.8-.8-1.8-1.8V1.8C0 .81.81 0 1.8 0zm5.74 16.93c-.33.14-.7.21-1.06.21-.42.01-.84-.08-1.21-.27-.38-.18-.72-.44-.98-.77s-.44-.71-.54-1.12c-.1-.41-.1-.83-.01-1.24.1-.41.28-.8.54-1.12.26-.32.59-.59.97-.77.38-.18.79-.27 1.21-.27.42 0 .83.1 1.21.28V6.68c0-.18.06-.37.18-.51.12-.15.29-.24.47-.27l7.7-1.3a.832.832 0 01.66.18c.09.07.16.16.21.27.05.11.07.22.07.34v7c0 .55-.16 1.08-.47 1.54-.31.45-.74.81-1.25 1.02a2.775 2.775 0 01-3.63-3.63c.21-.51.56-.94 1.02-1.25.45-.31.99-.47 1.54-.47.41 0 .82.09 1.19.27V6.36l-6.11 1v7c0 .37-.07.73-.21 1.07-.14.33-.34.64-.6.9-.25.26-.56.46-.9.6z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
MusicMultiBold.displayName = 'MusicMultiBold';
