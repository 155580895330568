import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { font, spacing, media } from '../config';

const infoRowWrapperStyle = css({
  display: 'flex',
  fontSize: font.size.sizeM1,
  marginTop: spacing.size8,
  [media.medium]: {
    fontSize: font.size.size05,
  },
});

const infoRowLabelStyle = css({
  textAlign: 'left',
  marginRight: spacing.size4,
  width: 90,
  [media.medium]: {
    minWidth: 210,
    textAlign: 'right',
    marginRight: spacing.size8,
  },
});
const infoRowValueStyle = (bold, color) =>
  css({
    fontWeight: bold ? font.weight.bold : font.weight.medium,
    color: color ? color : 'inherit',
    width: 202,
    [media.medium]: {
      minWidth: 260,
    },
  });

const AssessmentInfoRow = ({ label, value, bold, color }) => (
  <div css={infoRowWrapperStyle}>
    <span css={infoRowLabelStyle}>{label}</span>
    <span css={infoRowValueStyle(bold, color)}>{value ? value : '-'}</span>
  </div>
);

AssessmentInfoRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  bold: PropTypes.bool,
  color: PropTypes.string,
};

export { AssessmentInfoRow };
