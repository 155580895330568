import { __extends } from "tslib";
import { AddLayerVersionPermissionCommand, } from "./commands/AddLayerVersionPermissionCommand";
import { AddPermissionCommand, } from "./commands/AddPermissionCommand";
import { CreateAliasCommand } from "./commands/CreateAliasCommand";
import { CreateCodeSigningConfigCommand, } from "./commands/CreateCodeSigningConfigCommand";
import { CreateEventSourceMappingCommand, } from "./commands/CreateEventSourceMappingCommand";
import { CreateFunctionCommand, } from "./commands/CreateFunctionCommand";
import { DeleteAliasCommand } from "./commands/DeleteAliasCommand";
import { DeleteCodeSigningConfigCommand, } from "./commands/DeleteCodeSigningConfigCommand";
import { DeleteEventSourceMappingCommand, } from "./commands/DeleteEventSourceMappingCommand";
import { DeleteFunctionCodeSigningConfigCommand, } from "./commands/DeleteFunctionCodeSigningConfigCommand";
import { DeleteFunctionCommand, } from "./commands/DeleteFunctionCommand";
import { DeleteFunctionConcurrencyCommand, } from "./commands/DeleteFunctionConcurrencyCommand";
import { DeleteFunctionEventInvokeConfigCommand, } from "./commands/DeleteFunctionEventInvokeConfigCommand";
import { DeleteLayerVersionCommand, } from "./commands/DeleteLayerVersionCommand";
import { DeleteProvisionedConcurrencyConfigCommand, } from "./commands/DeleteProvisionedConcurrencyConfigCommand";
import { GetAccountSettingsCommand, } from "./commands/GetAccountSettingsCommand";
import { GetAliasCommand } from "./commands/GetAliasCommand";
import { GetCodeSigningConfigCommand, } from "./commands/GetCodeSigningConfigCommand";
import { GetEventSourceMappingCommand, } from "./commands/GetEventSourceMappingCommand";
import { GetFunctionCodeSigningConfigCommand, } from "./commands/GetFunctionCodeSigningConfigCommand";
import { GetFunctionCommand } from "./commands/GetFunctionCommand";
import { GetFunctionConcurrencyCommand, } from "./commands/GetFunctionConcurrencyCommand";
import { GetFunctionConfigurationCommand, } from "./commands/GetFunctionConfigurationCommand";
import { GetFunctionEventInvokeConfigCommand, } from "./commands/GetFunctionEventInvokeConfigCommand";
import { GetLayerVersionByArnCommand, } from "./commands/GetLayerVersionByArnCommand";
import { GetLayerVersionCommand, } from "./commands/GetLayerVersionCommand";
import { GetLayerVersionPolicyCommand, } from "./commands/GetLayerVersionPolicyCommand";
import { GetPolicyCommand } from "./commands/GetPolicyCommand";
import { GetProvisionedConcurrencyConfigCommand, } from "./commands/GetProvisionedConcurrencyConfigCommand";
import { InvokeAsyncCommand } from "./commands/InvokeAsyncCommand";
import { InvokeCommand } from "./commands/InvokeCommand";
import { ListAliasesCommand } from "./commands/ListAliasesCommand";
import { ListCodeSigningConfigsCommand, } from "./commands/ListCodeSigningConfigsCommand";
import { ListEventSourceMappingsCommand, } from "./commands/ListEventSourceMappingsCommand";
import { ListFunctionEventInvokeConfigsCommand, } from "./commands/ListFunctionEventInvokeConfigsCommand";
import { ListFunctionsByCodeSigningConfigCommand, } from "./commands/ListFunctionsByCodeSigningConfigCommand";
import { ListFunctionsCommand, } from "./commands/ListFunctionsCommand";
import { ListLayersCommand } from "./commands/ListLayersCommand";
import { ListLayerVersionsCommand, } from "./commands/ListLayerVersionsCommand";
import { ListProvisionedConcurrencyConfigsCommand, } from "./commands/ListProvisionedConcurrencyConfigsCommand";
import { ListTagsCommand } from "./commands/ListTagsCommand";
import { ListVersionsByFunctionCommand, } from "./commands/ListVersionsByFunctionCommand";
import { PublishLayerVersionCommand, } from "./commands/PublishLayerVersionCommand";
import { PublishVersionCommand, } from "./commands/PublishVersionCommand";
import { PutFunctionCodeSigningConfigCommand, } from "./commands/PutFunctionCodeSigningConfigCommand";
import { PutFunctionConcurrencyCommand, } from "./commands/PutFunctionConcurrencyCommand";
import { PutFunctionEventInvokeConfigCommand, } from "./commands/PutFunctionEventInvokeConfigCommand";
import { PutProvisionedConcurrencyConfigCommand, } from "./commands/PutProvisionedConcurrencyConfigCommand";
import { RemoveLayerVersionPermissionCommand, } from "./commands/RemoveLayerVersionPermissionCommand";
import { RemovePermissionCommand, } from "./commands/RemovePermissionCommand";
import { TagResourceCommand } from "./commands/TagResourceCommand";
import { UntagResourceCommand, } from "./commands/UntagResourceCommand";
import { UpdateAliasCommand } from "./commands/UpdateAliasCommand";
import { UpdateCodeSigningConfigCommand, } from "./commands/UpdateCodeSigningConfigCommand";
import { UpdateEventSourceMappingCommand, } from "./commands/UpdateEventSourceMappingCommand";
import { UpdateFunctionCodeCommand, } from "./commands/UpdateFunctionCodeCommand";
import { UpdateFunctionConfigurationCommand, } from "./commands/UpdateFunctionConfigurationCommand";
import { UpdateFunctionEventInvokeConfigCommand, } from "./commands/UpdateFunctionEventInvokeConfigCommand";
import { LambdaClient } from "./LambdaClient";
var Lambda = (function (_super) {
    __extends(Lambda, _super);
    function Lambda() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Lambda.prototype.addLayerVersionPermission = function (args, optionsOrCb, cb) {
        var command = new AddLayerVersionPermissionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.addPermission = function (args, optionsOrCb, cb) {
        var command = new AddPermissionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.createAlias = function (args, optionsOrCb, cb) {
        var command = new CreateAliasCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.createCodeSigningConfig = function (args, optionsOrCb, cb) {
        var command = new CreateCodeSigningConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.createEventSourceMapping = function (args, optionsOrCb, cb) {
        var command = new CreateEventSourceMappingCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.createFunction = function (args, optionsOrCb, cb) {
        var command = new CreateFunctionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteAlias = function (args, optionsOrCb, cb) {
        var command = new DeleteAliasCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteCodeSigningConfig = function (args, optionsOrCb, cb) {
        var command = new DeleteCodeSigningConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteEventSourceMapping = function (args, optionsOrCb, cb) {
        var command = new DeleteEventSourceMappingCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteFunction = function (args, optionsOrCb, cb) {
        var command = new DeleteFunctionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteFunctionCodeSigningConfig = function (args, optionsOrCb, cb) {
        var command = new DeleteFunctionCodeSigningConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteFunctionConcurrency = function (args, optionsOrCb, cb) {
        var command = new DeleteFunctionConcurrencyCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteFunctionEventInvokeConfig = function (args, optionsOrCb, cb) {
        var command = new DeleteFunctionEventInvokeConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteLayerVersion = function (args, optionsOrCb, cb) {
        var command = new DeleteLayerVersionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.deleteProvisionedConcurrencyConfig = function (args, optionsOrCb, cb) {
        var command = new DeleteProvisionedConcurrencyConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getAccountSettings = function (args, optionsOrCb, cb) {
        var command = new GetAccountSettingsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getAlias = function (args, optionsOrCb, cb) {
        var command = new GetAliasCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getCodeSigningConfig = function (args, optionsOrCb, cb) {
        var command = new GetCodeSigningConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getEventSourceMapping = function (args, optionsOrCb, cb) {
        var command = new GetEventSourceMappingCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getFunction = function (args, optionsOrCb, cb) {
        var command = new GetFunctionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getFunctionCodeSigningConfig = function (args, optionsOrCb, cb) {
        var command = new GetFunctionCodeSigningConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getFunctionConcurrency = function (args, optionsOrCb, cb) {
        var command = new GetFunctionConcurrencyCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getFunctionConfiguration = function (args, optionsOrCb, cb) {
        var command = new GetFunctionConfigurationCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getFunctionEventInvokeConfig = function (args, optionsOrCb, cb) {
        var command = new GetFunctionEventInvokeConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getLayerVersion = function (args, optionsOrCb, cb) {
        var command = new GetLayerVersionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getLayerVersionByArn = function (args, optionsOrCb, cb) {
        var command = new GetLayerVersionByArnCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getLayerVersionPolicy = function (args, optionsOrCb, cb) {
        var command = new GetLayerVersionPolicyCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getPolicy = function (args, optionsOrCb, cb) {
        var command = new GetPolicyCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.getProvisionedConcurrencyConfig = function (args, optionsOrCb, cb) {
        var command = new GetProvisionedConcurrencyConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.invoke = function (args, optionsOrCb, cb) {
        var command = new InvokeCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.invokeAsync = function (args, optionsOrCb, cb) {
        var command = new InvokeAsyncCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listAliases = function (args, optionsOrCb, cb) {
        var command = new ListAliasesCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listCodeSigningConfigs = function (args, optionsOrCb, cb) {
        var command = new ListCodeSigningConfigsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listEventSourceMappings = function (args, optionsOrCb, cb) {
        var command = new ListEventSourceMappingsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listFunctionEventInvokeConfigs = function (args, optionsOrCb, cb) {
        var command = new ListFunctionEventInvokeConfigsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listFunctions = function (args, optionsOrCb, cb) {
        var command = new ListFunctionsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listFunctionsByCodeSigningConfig = function (args, optionsOrCb, cb) {
        var command = new ListFunctionsByCodeSigningConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listLayers = function (args, optionsOrCb, cb) {
        var command = new ListLayersCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listLayerVersions = function (args, optionsOrCb, cb) {
        var command = new ListLayerVersionsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listProvisionedConcurrencyConfigs = function (args, optionsOrCb, cb) {
        var command = new ListProvisionedConcurrencyConfigsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listTags = function (args, optionsOrCb, cb) {
        var command = new ListTagsCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.listVersionsByFunction = function (args, optionsOrCb, cb) {
        var command = new ListVersionsByFunctionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.publishLayerVersion = function (args, optionsOrCb, cb) {
        var command = new PublishLayerVersionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.publishVersion = function (args, optionsOrCb, cb) {
        var command = new PublishVersionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.putFunctionCodeSigningConfig = function (args, optionsOrCb, cb) {
        var command = new PutFunctionCodeSigningConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.putFunctionConcurrency = function (args, optionsOrCb, cb) {
        var command = new PutFunctionConcurrencyCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.putFunctionEventInvokeConfig = function (args, optionsOrCb, cb) {
        var command = new PutFunctionEventInvokeConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.putProvisionedConcurrencyConfig = function (args, optionsOrCb, cb) {
        var command = new PutProvisionedConcurrencyConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.removeLayerVersionPermission = function (args, optionsOrCb, cb) {
        var command = new RemoveLayerVersionPermissionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.removePermission = function (args, optionsOrCb, cb) {
        var command = new RemovePermissionCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.tagResource = function (args, optionsOrCb, cb) {
        var command = new TagResourceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.untagResource = function (args, optionsOrCb, cb) {
        var command = new UntagResourceCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.updateAlias = function (args, optionsOrCb, cb) {
        var command = new UpdateAliasCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.updateCodeSigningConfig = function (args, optionsOrCb, cb) {
        var command = new UpdateCodeSigningConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.updateEventSourceMapping = function (args, optionsOrCb, cb) {
        var command = new UpdateEventSourceMappingCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.updateFunctionCode = function (args, optionsOrCb, cb) {
        var command = new UpdateFunctionCodeCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.updateFunctionConfiguration = function (args, optionsOrCb, cb) {
        var command = new UpdateFunctionConfigurationCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    Lambda.prototype.updateFunctionEventInvokeConfig = function (args, optionsOrCb, cb) {
        var command = new UpdateFunctionEventInvokeConfigCommand(args);
        if (typeof optionsOrCb === "function") {
            this.send(command, optionsOrCb);
        }
        else if (typeof cb === "function") {
            if (typeof optionsOrCb !== "object")
                throw new Error("Expect http options but get " + typeof optionsOrCb);
            this.send(command, optionsOrCb || {}, cb);
        }
        else {
            return this.send(command, optionsOrCb);
        }
    };
    return Lambda;
}(LambdaClient));
export { Lambda };
