import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesInddBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#files-indd-bold_svg__a)" clipRule="evenodd" fillRule="evenodd">
          <path d="M17.25 11.52v4.79a1.89 1.89 0 001.26-1.77v-1.25A1.87 1.87 0 0018 12a2 2 0 00-.75-.48z" />
          <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM5.62 17a.64.64 0 01-.69.59.64.64 0 01-.69-.59v-6.26a.65.65 0 01.69-.6.65.65 0 01.69.6V17zM11 17a.62.62 0 01-.47.61h-.16a.65.65 0 01-.55-.32l-2.24-4V17a.63.63 0 11-1.25 0v-6.14a.62.62 0 01.47-.61.61.61 0 01.7.3l2.24 4v-3.69a.63.63 0 011.26 0V17zm4.48-2.47a3.099 3.099 0 01-.92 2.21 3.14 3.14 0 01-2.22.92.63.63 0 01-.63-.63v-6.28a.63.63 0 01.63-.62c.83 0 1.628.327 2.22.91a3.14 3.14 0 01.92 2.22v1.27zm4.27 0a3.14 3.14 0 01-.92 2.22 3.17 3.17 0 01-2.22.91.589.589 0 01-.44-.18.63.63 0 01-.19-.44v-6.25a.63.63 0 01.63-.63 3.14 3.14 0 013.14 3.13v1.24z" />
          <path d="M13 11.49v4.79a1.85 1.85 0 00.7-.44 1.87 1.87 0 00.56-1.33v-1.25A1.89 1.89 0 0013 11.49z" />
        </g>
        <defs>
          <clipPath id="files-indd-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
FilesInddBold.displayName = 'FilesInddBold';
