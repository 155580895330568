import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Location = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M15.43 5.063a4.5 4.5 0 10-6.36 6.368 4.5 4.5 0 006.36-6.368zm-1.06 5.3a3 3 0 11-4.24-4.246 3 3 0 014.24 4.246z" />
      <path d="M18.08 2.413A8.25 8.25 0 004 8.243a11.07 11.07 0 00.87 3.61 43.988 43.988 0 002 4.32c1.48 2.93 3.22 5.82 4.13 7.15a1.5 1.5 0 002.5 0c.91-1.38 2.65-4.22 4.15-7.14a43.99 43.99 0 002-4.32 11.07 11.07 0 00.87-3.61 8.251 8.251 0 00-2.44-5.84zm.17 8.91a42.57 42.57 0 01-1.91 4.17c-1.48 2.87-3.18 5.67-4.07 7-.89-1.34-2.59-4.13-4.07-7a42.557 42.557 0 01-1.95-4.17 9.75 9.75 0 01-.77-3.08 6.75 6.75 0 0113.5 0 9.748 9.748 0 01-.73 3.08z" />
    </g>
  );
});
Location.displayName = 'Location';
