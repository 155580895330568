import { CircularProgressProps } from '@chakra-ui/progress';
import {
  chakra,
  ColorProps,
  CSSWithMultiValues,
  HTMLChakraProps,
  keyframes,
  RecursiveCSSObject,
  StylesProvider,
  SystemStyleObject,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import * as React from 'react';
import { CircularProgressLabel } from './CircularProgressLabel';

export interface ICircularProgressProps extends CircularProgressProps {
  dimensions?: number;
}
interface ICircleProps extends HTMLChakraProps<'circle'> {
  dimensions: number;
  thickness: number;
}

interface ICircularProgressContainerProps extends Omit<ICircularProgressProps, 'size'> {
  dimensions: number;
}

interface IShapeProps extends HTMLChakraProps<'svg'> {
  dimensions: number;
  isIndeterminate?: boolean;
}

interface IThemeStyles extends Record<string, RecursiveCSSObject<CSSWithMultiValues>> {
  track: {
    stroke: ColorProps['stroke'];
    height: number;
    width: number;
  };
  filledTrack: {
    stroke: ColorProps['stroke'];
  };
  label: {
    fontSize: number | string;
  };
}

const rotate = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// return type here is Keyframes, which should come from @emotion/css, but it can't be imported
const spin = (pathLength: number) => {
  return keyframes({
    '0%': {
      strokeDasharray: `1 ${pathLength}`,
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: `${pathLength} ${pathLength}`,
      strokeDashoffset: `-${pathLength / 2}`,
    },
    '100%': {
      strokeDasharray: `${pathLength} ${pathLength}`,
      strokeDashoffset: `-${pathLength}`,
    },
  });
};

const CircularProgressContainer: React.FC<ICircularProgressContainerProps> = ({
  dimensions,
  fontSize,
  children,
  ...rest
}) => {
  const rootStyles: SystemStyleObject = {
    display: 'inline-block',
    position: 'relative',
    verticalAlign: 'middle',
    fontSize,
    width: dimensions,
    height: dimensions,
  };

  return (
    <chakra.div __css={rootStyles} className="chakra-progress" {...rest}>
      {children}
    </chakra.div>
  );
};

const Shape: React.FC<IShapeProps> = ({ dimensions, isIndeterminate, children }) => {
  const styles: SystemStyleObject = {
    // rotate 270deg in order to start at the top and continue clockwise
    transform: 'rotate(270deg)',
    height: dimensions,
    width: dimensions,
    animation: isIndeterminate ? `${rotate} 2s linear infinite` : undefined,
  };

  return (
    <chakra.svg __css={styles} viewBox={`0 0 ${dimensions} ${dimensions}`}>
      {children}
    </chakra.svg>
  );
};

const Circle: React.FC<ICircleProps> = ({ dimensions, thickness = 4, ...rest }) => {
  const cx = dimensions / 2;
  const radius = cx - thickness;

  return (
    <chakra.circle
      cx={cx}
      cy={cx}
      fill="transparent"
      r={radius}
      {...rest}
      strokeWidth={thickness}
    />
  );
};

const CircularProgressComponent: React.FC<ICircularProgressProps> = (props) => {
  const {
    children,
    value = 0,
    thickness = '1px',
    color,
    trackColor,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    capIsRound = true,
    isIndeterminate,
    dimensions,
    ...rest
  } = props;

  const themeStyles = useMultiStyleConfig('CircularProgress', props) as IThemeStyles;

  const {
    track: { stroke: trackStroke, height: themeHeight, width: themeWidth },
    filledTrack: { stroke: filledStroke },
    label: { fontSize },
  } = themeStyles;

  const bgColor = trackColor ?? trackStroke;
  const filledColor = color ?? filledStroke;
  const calcDimensions = dimensions ?? Math.max(themeHeight, themeWidth);

  const strokeLinecap = capIsRound ? 'round' : 'butt';

  const thicknessNumber = typeof thickness === 'number' ? thickness : parseInt(thickness, 10);
  const cx = calcDimensions / 2;
  const radius = cx - thicknessNumber;

  const pathLength = 2 * Math.PI * radius;

  let dashoffset = pathLength * ((100 - value) / 100);

  if (capIsRound) {
    // need to add the thickness because of how the round linecap is drawn on the svg
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linecap
    dashoffset += thicknessNumber;
  }

  const indicatorProps = isIndeterminate
    ? {
        css: { animation: `${spin(pathLength)} 1.5s linear infinite` },
      }
    : {
        strokeDashoffset: dashoffset,
        strokeDasharray: pathLength,
        transition: `stroke-dasharray 0.6s ease 0s, stroke 0.6s ease`,
      };

  return (
    <CircularProgressContainer dimensions={calcDimensions} fontSize={fontSize} {...rest}>
      <StylesProvider value={themeStyles}>
        <Shape dimensions={calcDimensions} isIndeterminate={isIndeterminate}>
          <Circle
            __css={themeStyles.track}
            className="chakra-progress__track"
            dimensions={calcDimensions}
            stroke={bgColor}
            strokeWidth={thicknessNumber}
            thickness={thicknessNumber}
          />
          <Circle
            className="chakra-progress__indicator"
            {...indicatorProps}
            __css={themeStyles.filledTrack}
            dimensions={calcDimensions}
            stroke={filledColor}
            strokeLinecap={strokeLinecap}
            strokeWidth={thicknessNumber}
            thickness={thicknessNumber}
          />
        </Shape>
        {children}
      </StylesProvider>
    </CircularProgressContainer>
  );
};

type ICircularProgressComponent = React.FC<ICircularProgressProps> & {
  Label: typeof CircularProgressLabel;
};

export const CircularProgress: ICircularProgressComponent = (props) => {
  return <CircularProgressComponent {...props} />;
};

CircularProgress.Label = CircularProgressLabel;
