import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MenuNavigationVertical = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.75 16.903a1.1 1.1 0 01.12 2.194l-.12.006H2.25a1.1 1.1 0 01-.12-2.194l.12-.006h19.5zm0-6a1.1 1.1 0 01.12 2.194l-.12.006H2.25a1.1 1.1 0 01-.12-2.194l.12-.006h19.5zm0-6a1.1 1.1 0 01.12 2.194l-.12.006H2.25a1.1 1.1 0 01-.12-2.194l.12-.006h19.5z" />
      </g>
    );
  }
);
MenuNavigationVertical.displayName = 'MenuNavigationVertical';
