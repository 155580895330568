import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BrickSideview = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.576 5.924h-1.323V4.417A1.448 1.448 0 0019.919 3h-5.394a1.424 1.424 0 00-1.242 1.417v1.507h-2.566V4.417A1.448 1.448 0 009.384 3H4a1.424 1.424 0 00-1.242 1.417v1.507H1.424A1.422 1.422 0 000 7.351v12.222A1.428 1.428 0 001.424 21h21.152A1.422 1.422 0 0024 19.573V7.351a1.428 1.428 0 00-1.424-1.427zm-7.778-1.396h4.94v1.416h-4.94V4.528zm-10.525 0h4.929v1.416h-4.93V4.528zm18.181 14.964H1.516V7.442h20.97l-.03 12.05z" />
      </g>
    );
  }
);
BrickSideview.displayName = 'BrickSideview';
