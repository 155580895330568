import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StatusHelpBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M0 12C0 5.383 5.383 0 12 0s12 5.383 12 12-5.383 12-12 12S0 18.617 0 12zm12.934 1.731h-2.315a2.457 2.457 0 01-.223-.975c0-.812.61-1.502 1.604-2.294.832-.69 1.198-1.056 1.198-1.706 0-.73-.569-1.238-1.28-1.238-.75 0-1.299.528-1.684 1.543L8 8.086C8.589 6.016 10.091 5 12 5c2.213 0 4 1.401 4 3.553 0 1.198-.569 1.99-1.685 2.965l-.218.189c-.7.608-1.204 1.044-1.204 1.598 0 .162.02.304.041.426zM13.746 17c0 1.076-.812 1.888-1.888 1.888-1.097 0-1.909-.812-1.909-1.888 0-1.056.812-1.888 1.909-1.888A1.86 1.86 0 0113.746 17z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
StatusHelpBold.displayName = 'StatusHelpBold';
