import { useState, Fragment, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Delete, EditFile, LegoUser } from '@lego/klik-ui-icons';
import { Button, TextArea } from '@lego/klik-react';
import {
  FormControl,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@lego/klik-ui';
import { spacing, colors, media, padding } from '../../config';
import { Stepper, Step } from '../stepper';
import { QuestionReview } from '../question/QuestionReview';
import { AssessmentScore } from '../AssessmentScore';
import { AssessmentInfoSection } from '../AssessmentInfoSection';
import { OverlayPortal } from '../OverlayPortal';
import { Spinner } from '../spinner/Spinner';
import { Divider } from '../Divider';
import {
  GET_ASSESSMENT_DATA,
  APPROVE_ASSESSMENT,
  REJECT_ASSESSMENT,
  RESCORE_ASSESSMENT,
  DELETE_ASSESSMENT,
  GET_ASSESSMENT_SCORE,
  ADD_ASSESSMENT_COMMENT,
  GET_AVAILABLE_CHANNELS,
  UPDATE_ASSESSMENT_CHANNEL,
  UPDATE_ASSESSMENT_ASSIGNMENT,
  SOFT_DELETE_ASSESSMENT,
} from '../../queries/assessment';
import { routes } from '../RouteHandler';
import {
  ApproveModal,
  AssessmentEditModal,
  EmailInfoModal,
  GenericConfirmationModal,
  RejectModal,
} from '../modals';
import {
  actionTypes,
  actionTypesTranslations,
  assessmentStatus as assessmentStatusTypes,
} from '../../constants';
import {
  areAllAnswersComplete,
  isAnyQuestionMissingProof,
  enhanceQuestionsWithAnswersAndProof,
  adjustAssessmentsBasedOnFeatureFlags,
} from '../assessment-scoring/helpers';
import {
  addSnackbar,
  snackbarTypes,
  snackbarDefaultMessages,
} from '../../utils/snackbar';
import { isChannelLongTail } from '../../utils/isChannelLongTail';
import { Heading } from '../Heading';
import { generalApiErrorHandler } from '../../utils/generalApiErrorHandler';
import { useFetchImagesFromS3 } from '../../hooks/useFetchImagesFromS3';
import { CommentsTable } from '../CommentsTable';
import { validateEmailAddress } from '../../utils/validateEmailAddress';
import { assignmentTypes } from '../../constants/assignmentTypes';
import { MissingProofExplainer } from '../MissingProofExplainer';
import { useUser } from '../../providers/UserProvider';
import { userCanTriggerEmail } from './helpers';
import { EmailUnread } from '@lego/klik-icons-react';

const modalFormControlStyle = css({
  marginTop: spacing.size4,
});

const rejectModalCommentsStyle = css({
  marginBottom: '5%',
  marginTop: '5%',
});

const detailsPageContainerStyle = css({
  paddingBottom: 70, // height of action bottom bar
  [media.medium]: {
    paddingBottom: 0,
  },
});

const assessmentDetailsStyle = css({
  maxWidth: '768px',
  margin: 'auto',
  padding: `${spacing.size10}px ${padding.medium}px ${padding.medium}px ${padding.small}px`,
  [media.medium]: {
    padding: `${spacing.size10 * 2}px ${padding.medium}px ${padding.large}px`,
  },
});

const sectionContainerStyle = css({
  marginTop: spacing.size10,
  paddingBottom: spacing.size10,
});

const assessmentNotSubmittedTextStyle = css({
  margin: `${spacing.size16}px 0`,
});

const rejectModalactionButtonsWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: spacing.size10,
  '& > button': {
    marginRight: spacing.size4,
  },
});

const rejectButtonWrapperStyle = css({
  button: {
    background: colors.utilityColors.error.error500,
    '&:hover': {
      background: colors.utilityColors.error.error600,
    },
  },
});

const actionBarStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 70,
  background: colors.neutralColors.slate02,
  boxShadow: '0px -2px 4px rgba(137, 150, 159, 0.2)',
  paddingLeft: spacing.size12,
  paddingRight: spacing.size12,
});

const actionBarRightStyle = css({
  display: 'flex',
  button: {
    marginRight: spacing.size5,
  },
  'button:last-child': {
    marginRight: 0,
  },
  '&:empty': {
    display: 'none',
  },
});

const isReemeaLongTail = (channel) => channel.startsWith('RLT');

// hide RLT channels to avoid confusion with LTs
const doesChannelTypeMatch = (currentChannel, desiredChannel) =>
  isChannelLongTail(currentChannel) === isChannelLongTail(desiredChannel) &&
  !isReemeaLongTail(desiredChannel);

const DetailsPage = () => {
  const history = useHistory();
  const { roles } = useUser();
  const { customerId, assessmentYear, version } = useParams();
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isRescoreModalVisible, setIsRescoreModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [rejectEmailState, setRejectEmailState] = useState({
    isOpen: false,
    customerEmail: '',
    customerName: '',
  });
  const [rejectModalComment, setRejectModalComment] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSoftDeleteModalVisible, setIsSoftDeleteModalVisible] = useState(
    false
  );
  const [comments, setComments] = useState([]);
  const [isEditChannelModalVisible, setIsEditChannelModalVisible] = useState(
    false
  );
  const [
    isUpdateAssignmentModalVisible,
    setIsUpdateAssignmentModalVisible,
  ] = useState(false);
  const [isResendEmailModalVisible, setIsResendEmailModalVisible] = useState(
    false
  );
  const [selectedChannel, setSelectedChannel] = useState('');
  const [surveyDetails, setSurveyDetails] = useState();
  const [selectedAssignment, setSelectedAssignment] = useState();
  const [assignmentEmail, setAssignmentEmail] = useState('');
  const [actions, setActions] = useState([]);

  const handleDetailsDataResponse = (response) => {
    setComments(response.getSurveyInfo.comments);
    setSelectedChannel(response.getSurveyInfo.customerDetail.channel);
    setSurveyDetails(response.getSurveyInfo);
    setActions(response.getSurveyInfo.actions);
  };

  const [
    getDetailsData,
    {
      data: detailsData,
      loading: isDetailsPageLoading,
      error: detailsDataError,
    },
  ] = useLazyQuery(GET_ASSESSMENT_DATA, {
    variables: {
      customerId,
      year: Number(assessmentYear),
      version: Number(version),
    },
    fetchPolicy: 'network-only',
    onCompleted: handleDetailsDataResponse,
  });

  useEffect(() => {
    getDetailsData();
  }, [getDetailsData]);

  const {
    data: previousAssessment,
    loading: isPreviousAssessmentLoading,
  } = useQuery(GET_ASSESSMENT_SCORE, {
    variables: { customerId, year: Number(assessmentYear) - 1 },
    fetchPolicy: 'network-only',
  });

  const {
    data: availableChannels,
    loading: isAvailableChannelsLoading,
    error: getChannelsError,
  } = useQuery(GET_AVAILABLE_CHANNELS, {
    variables: { year: Number(assessmentYear) },
    fetchPolicy: 'network-only',
  });

  const [
    approveAssessment,
    { loading: approveAssessmentLoading },
  ] = useMutation(APPROVE_ASSESSMENT, {
    variables: {
      customerId,
      year: Number(assessmentYear),
      version: Number(version),
    },
    onCompleted: handleApproveAssessmentResponse,
  });

  const [
    rescoreAssessment,
    { loading: rescoringAssessmentLoading },
  ] = useMutation(RESCORE_ASSESSMENT, {
    variables: {
      customerId,
      year: Number(assessmentYear),
      version: Number(version),
    },
    onCompleted: handleRescoreAssessmentResponse,
  });

  const [rejectAssessment, { loading: rejectAssessmentLoading }] = useMutation(
    REJECT_ASSESSMENT,
    {
      variables: {
        customerId,
        year: Number(assessmentYear),
        version: Number(version),
      },
      onCompleted: handleRejectAssessmentResponse,
    }
  );

  const [deleteAssessment, { loading: deleteAssessmentLoading }] = useMutation(
    DELETE_ASSESSMENT,
    {
      variables: {
        customerId,
        year: Number(assessmentYear),
        version: Number(version),
      },
      onCompleted: handleDeleteAssessmentResponse,
    }
  );

  const [
    softDeleteAssessment,
    { loading: softDeleteAssessmentLoading },
  ] = useMutation(SOFT_DELETE_ASSESSMENT, {
    variables: {
      customerId,
      year: Number(assessmentYear),
      version: Number(version),
    },
    onCompleted: handleSoftDeleteAssessmentResponse,
  });

  const [addAssessmentComment, { loading: addCommentLoading }] = useMutation(
    ADD_ASSESSMENT_COMMENT,
    {
      variables: {
        customerId,
        year: Number(assessmentYear),
        version: Number(version),
      },
      onCompleted: (response) => setComments(response.addComment),
    }
  );

  const [
    updateCustomerChannel,
    { loading: updateChannelLoading },
  ] = useMutation(UPDATE_ASSESSMENT_CHANNEL, {
    variables: {
      input: {
        customerId,
        year: Number(assessmentYear),
        version: Number(version),
        channel: selectedChannel,
      },
    },
    onCompleted: handleUpdateChannelResponse,
  });

  const [updateAssignment, { loading: updateAssignmentLoading }] = useMutation(
    UPDATE_ASSESSMENT_ASSIGNMENT,
    {
      variables: {
        input: {
          id: customerId,
          year: Number(assessmentYear),
          version: Number(version),
          email: assignmentEmail.toLocaleLowerCase().trim(),
          assignment: selectedAssignment,
        },
      },
      onCompleted: handleUpdateAssignmentResponse,
    }
  );

  const { uploadedImagesKeys } = useFetchImagesFromS3({
    uploadId: detailsData?.getSurveyInfo?.uploadId,
    skip: !detailsData || !detailsData.getSurveyInfo.surveyState.isComplete,
  });

  async function handleApproveAssessment(comment) {
    try {
      await approveAssessment({
        variables: { customerId, year: Number(assessmentYear), comment },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  function handleApproveAssessmentResponse(response) {
    if (response.approveAssessment) {
      const { customerName } = response.approveAssessment;
      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: `${customerName}’s assessment was approved.`,
        type: snackbarTypes.SUCCESS,
      });
      history.push(routes.overview);
    }
  }

  async function handleRescoreAssessment(comment) {
    try {
      await rescoreAssessment({
        variables: { customerId, year: Number(assessmentYear), comment },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  function handleRescoreAssessmentResponse(response) {
    if (response.rescoreAssessment) {
      const { customerName } = response.rescoreAssessment;
      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: `${customerName}'s assessment rescoring has been requested.`,
        type: snackbarTypes.SUCCESS,
      });
      history.push(routes.overview);
    }
  }

  async function handleRejectAssessment(comment) {
    try {
      await rejectAssessment({
        variables: { customerId, year: Number(assessmentYear), comment },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  async function handleDeleteAssessment() {
    try {
      await deleteAssessment({
        variables: {
          customerId,
          year: Number(assessmentYear),
          version: Number(version),
        },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  async function handleSoftDeleteAssessment() {
    try {
      await softDeleteAssessment({
        variables: {
          customerId,
          year: Number(assessmentYear),
          version: Number(version),
        },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  async function handleAddComment(comment) {
    try {
      await addAssessmentComment({
        variables: { comment },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  function handleRejectAssessmentResponse(response) {
    if (response.rejectAssessment) {
      const { customerName, customerEmail } = response.rejectAssessment;
      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: `${customerName}'s assessment has been rejected.`,
        type: snackbarTypes.SUCCESS,
      });
      if (
        detailsData.getSurveyInfo.assessmentStatus ===
          assessmentStatusTypes.REVIEW &&
        customerEmail
      ) {
        const { customerName, customerEmail } = response.rejectAssessment;
        setIsRejectModalVisible(false);
        setRejectEmailState((prevState) => ({
          ...prevState,
          isOpen: true,
          customerEmail,
          customerName,
        }));
      } else {
        history.push(routes.overview);
      }
    }
  }

  function handleDeleteAssessmentResponse(response) {
    if (response.deleteAssessment) {
      setIsDeleteModalVisible(false);
      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: `The assessment has been deleted.`,
        type: snackbarTypes.SUCCESS,
      });
      history.push(routes.overview);
    }
  }

  function handleSoftDeleteAssessmentResponse(response) {
    if (response.softDeleteAssessment) {
      setIsSoftDeleteModalVisible(false);
      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: `The assessment has been deleted.`,
        type: snackbarTypes.SUCCESS,
      });
      history.push(routes.overview);
    }
  }

  async function handleUpdateChannelResponse() {
    setIsEditChannelModalVisible(false);
    getDetailsData();
  }

  const closeEditAssignmentModal = () => {
    setIsUpdateAssignmentModalVisible(false);
    setSelectedAssignment('');
    setAssignmentEmail('');
  };

  async function handleUpdateAssignmentResponse(response) {
    closeEditAssignmentModal();
    const updatedDetails = {
      ...surveyDetails,
      perform: response.updateAssessmentAssignment.perform,
      review: response.updateAssessmentAssignment.review,
      approve: response.updateAssessmentAssignment.approve,
      pendingUser: response.updateAssessmentAssignment.pendingUser,
    };
    setSurveyDetails(updatedDetails);
    setActions(response.updateAssessmentAssignment.actions);
  }

  function handleRejectSendEmailAction() {
    const { customerName, customerEmail } = rejectEmailState;
    window.open(
      `mailto:${customerEmail}?subject=Assignment%20Rejected&body=Hi,%20${customerName}`
    );
    history.push(routes.overview);
  }

  async function handleSaveCustomerChannel() {
    try {
      await updateCustomerChannel();
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  async function handleUpdateAssignment() {
    try {
      await updateAssignment();
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  // Error boundary catches this error
  if (detailsDataError) throw detailsDataError;

  if (
    isPreviousAssessmentLoading ||
    isDetailsPageLoading ||
    approveAssessmentLoading ||
    rejectAssessmentLoading ||
    rescoringAssessmentLoading ||
    deleteAssessmentLoading ||
    addCommentLoading ||
    updateChannelLoading ||
    updateAssignmentLoading ||
    softDeleteAssessmentLoading ||
    !surveyDetails
  ) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  const {
    getAnswers: savedAnswers,
    getSurvey: questions,
    getSurveyInfo: { surveyState: assessmentState, assessmentStatus },
  } = detailsData;

  const previousAssessmentScore =
    previousAssessment?.getLatestApprovedAssessment?.score;

  const isInKAMReview = assessmentStatus === assessmentStatusTypes.REVIEW;
  const isCoreCustomer = assessmentStatus === assessmentStatusTypes.ASSESSMENT;

  const isEmailValid = validateEmailAddress(assignmentEmail);
  const disableAssignmentSaveCondition = () =>
    !isEmailValid || !selectedAssignment;

  const surveyDetailsForFeatureFlag = {
    year: Number(assessmentYear),
    channel: selectedChannel,
  };

  let enhancedQuestions = enhanceQuestionsWithAnswersAndProof(
    questions,
    savedAnswers,
    uploadedImagesKeys
  );
  enhancedQuestions = adjustAssessmentsBasedOnFeatureFlags(
    enhancedQuestions,
    surveyDetailsForFeatureFlag
  );

  return (
    <div css={detailsPageContainerStyle}>
      <Heading type="h1" text="Assessment details" />

      <AssessmentInfoSection
        assessmentInfo={surveyDetails}
        previousAssessmentScore={previousAssessmentScore}
      />
      {isUpdateAssignmentModalVisible && (
        <AssessmentEditModal
          header="Edit Assessment Assignment"
          onClose={closeEditAssignmentModal}
          onSave={handleUpdateAssignment}
          saveDisableCondition={disableAssignmentSaveCondition}
        >
          <div
            style={{
              marginTo: spacing.size5,
            }}
          >
            <p>
              Changing the assessment assignment will apply to all assessments
              for this customer.
            </p>
            <FormControl
              isRequired={true}
              isInvalid={!selectedAssignment}
              css={modalFormControlStyle}
            >
              <FormControl.Label>
                Select the assignment to update
              </FormControl.Label>
              <RadioGroup value={selectedAssignment}>
                <Stack
                  direction="row"
                  spacing="8"
                  onChange={(event) =>
                    setSelectedAssignment(event.target.value)
                  }
                >
                  <Radio value={assignmentTypes.PERFORM}>Performer</Radio>
                  <Radio value={assignmentTypes.REVIEW}>Reviewer</Radio>
                  <Radio value={assignmentTypes.APPROVE}>Approver</Radio>
                </Stack>
              </RadioGroup>
              <FormControl.ErrorMessage>
                Select an assignment type
              </FormControl.ErrorMessage>
            </FormControl>
            <FormControl
              isRequired={true}
              isInvalid={!isEmailValid}
              css={modalFormControlStyle}
            >
              <FormControl.Label>Assignment Email address</FormControl.Label>
              <Input
                placeholder="New assignment email address"
                value={assignmentEmail}
                onChange={(event) => setAssignmentEmail(event.target.value)}
              />
              <FormControl.ErrorMessage>
                Enter valid LEGO user email address
              </FormControl.ErrorMessage>
            </FormControl>
          </div>
        </AssessmentEditModal>
      )}

      {isEditChannelModalVisible && (
        <AssessmentEditModal
          header="Edit Customer Channel"
          onClose={() => setIsEditChannelModalVisible(false)}
          onSave={handleSaveCustomerChannel}
          status="error"
        >
          <div
            style={{
              marginTop: spacing.size5,
            }}
          >
            <p>
              Please note that changing the customer channel could change the
              survey questions for the assessment and all questions already
              answered will be deleted.
            </p>

            <p
              style={{
                color: colors.utilityColors.information.information400,
                marginTop: spacing.size10,
              }}
            >
              You can only change the channel to another one of the same type
              (Long Tail to Long Tail and Core to Core). Please delete the
              assessment and upload a new one with the correct channel if you
              need to change the channel type.
            </p>
            <div
              style={{
                marginTop: spacing.size10,
              }}
            >
              <Select
                value={selectedChannel}
                onChange={(event) => setSelectedChannel(event.target.value)}
              >
                {availableChannels.getAvailableChannelsForYear
                  .filter((channel) =>
                    doesChannelTypeMatch(selectedChannel, channel)
                  )
                  .map((channel) => (
                    <option key={channel} value={channel}>
                      {channel}
                    </option>
                  ))}
              </Select>
            </div>
          </div>
        </AssessmentEditModal>
      )}

      {isDeleteModalVisible && (
        <GenericConfirmationModal
          header="Customer Assessment Delete"
          body="Are you sure you want to delete the customer assessment? All customer assessment data for this year will be deleted."
          onClose={() => setIsDeleteModalVisible(false)}
          onConfirm={() => handleDeleteAssessment()}
          status="error"
          icon={<Delete />}
        />
      )}

      {isSoftDeleteModalVisible && (
        <GenericConfirmationModal
          header="Customer Assessment Delete"
          body="Are you sure you want to delete the customer assessment? The assessment will be removed from the overview and if Long Tail will not be available to the customer in self-service"
          onClose={() => setIsSoftDeleteModalVisible(false)}
          onConfirm={() => handleSoftDeleteAssessment()}
          status="error"
          icon={<Delete />}
        />
      )}

      {isApproveModalVisible && (
        <ApproveModal
          header="Are you sure you want to approve the customer assesment?"
          onApprove={handleApproveAssessment}
          onCancel={() => setIsApproveModalVisible(false)}
          onClose={() => setIsApproveModalVisible(false)}
        />
      )}

      {isRejectModalVisible && (
        <RejectModal
          header="Are you sure you want to reject the customer assessment?"
          onClose={() => setIsRejectModalVisible(false)}
          pageIndicator={isInKAMReview ? [2, 1] : false}
        >
          <div css={rejectModalCommentsStyle}>
            Additional comments:
            <TextArea
              onChange={(event) => setRejectModalComment(event.target.value)}
              placeholder="Add option comments for justification"
            />
          </div>
          <div css={rejectModalactionButtonsWrapperStyle}>
            <Button
              label="Cancel"
              variant="ghost"
              onClick={() => setIsRejectModalVisible(false)}
            />
            <span css={rejectButtonWrapperStyle}>
              <Button
                label="Yes, continue"
                data-transaction-name="Reject assessment"
                onClick={() => handleRejectAssessment(rejectModalComment)}
              />
            </span>
          </div>
        </RejectModal>
      )}
      {rejectEmailState.isOpen && isInKAMReview && (
        <RejectModal
          header="Send an email to customer"
          bodyText="Send an email to customer notifying the assessment was rejected with the justification needed."
          pageIndicator={[2, 2]}
        >
          <div css={rejectModalactionButtonsWrapperStyle}>
            <Button
              label="Back to overview"
              onClick={() => history.push(routes.overview)}
              variant="ghost"
            />
            <Button
              label="Send an email"
              data-transaction-name="Send rejection email"
              onClick={handleRejectSendEmailAction}
              variant="outline"
            />
          </div>
        </RejectModal>
      )}

      {isRescoreModalVisible && (
        <ApproveModal
          header="Are you sure you want to request a rescore of the customer assessment?"
          onApprove={handleRescoreAssessment}
          onCancel={() => setIsRescoreModalVisible(false)}
          onClose={() => setIsRescoreModalVisible(false)}
        />
      )}

      {isResendEmailModalVisible && (
        <EmailInfoModal
          customerEmail={surveyDetails.email}
          customerId={surveyDetails.customerDetail.id}
          onClose={() => setIsResendEmailModalVisible(false)}
        />
      )}

      {areAllAnswersComplete(savedAnswers) ? (
        <Fragment>
          <div css={assessmentDetailsStyle}>
            <Stepper activeColor={colors.neutralColors.slate60}>
              {enhancedQuestions.map((question) => {
                const {
                  questionId,
                  headline,
                  savedAnswer,
                  questionImageKeys,
                  isMissingProof,
                } = question;

                return (
                  <Step
                    stepId={questionId}
                    key={questionId}
                    header={headline}
                    isValid={savedAnswer.isValid}
                    isWarning={isMissingProof}
                    isComplete={savedAnswer.isComplete}
                  >
                    <QuestionReview
                      question={question}
                      answer={savedAnswer}
                      uploadImageKeys={questionImageKeys}
                    />
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <Divider />
          <div css={sectionContainerStyle}>
            {isAnyQuestionMissingProof(enhancedQuestions) && (
              <MissingProofExplainer />
            )}
            <Heading type="h2" text="Score" />
            <AssessmentScore
              scoreText="The calculated functional discount score is:"
              score={assessmentState.score}
            />
            <CommentsTable
              comments={comments}
              addCommentHandler={(commentValue) =>
                handleAddComment(commentValue)
              }
            />
          </div>
        </Fragment>
      ) : (
        <div css={assessmentNotSubmittedTextStyle}>
          The assessment has not been filled in yet.
        </div>
      )}

      <div css={actionBarStyle}>
        <span>
          <Link to={routes.overview}>
            <Button label="Back to overview" variant="ghost" />
          </Link>
        </span>
        <span css={actionBarRightStyle}>
          {surveyDetails?.active &&
            surveyDetails.email &&
            userCanTriggerEmail(roles) && (
              <Button
                label="Resend Invitation"
                data-transaction-name="Resend Invitation"
                icon={<EmailUnread />}
                variant="ghost"
                onClick={() => setIsResendEmailModalVisible(true)}
              />
            )}
          {actions.includes(actionTypes.REASSIGN) && (
            <Button
              label="Edit Assignment"
              data-transaction-name="Edit assignment"
              icon={<LegoUser />}
              variant="ghost"
              onClick={() => setIsUpdateAssignmentModalVisible(true)}
            />
          )}
          {!isAvailableChannelsLoading &&
            !getChannelsError &&
            actions.includes(actionTypes.CHANNELUPDATE) && (
              <Button
                label="Change Channel"
                data-transaction-name="Change Channel"
                icon={<EditFile />}
                variant="ghost"
                onClick={() => setIsEditChannelModalVisible(true)}
              />
            )}
          {actions.includes(actionTypes.SOFTDELETE) && (
            <Button
              label="Delete Assessment"
              data-transaction-name="Soft delete assessment"
              icon={<Delete />}
              variant="ghost"
              onClick={() => setIsSoftDeleteModalVisible(true)}
            />
          )}
          {actions.includes(actionTypes.DELETE) && (
            <Button
              label="Delete Assessment"
              data-transaction-name="Hard delete assessment"
              icon={<Delete />}
              variant="ghost"
              onClick={() => setIsDeleteModalVisible(true)}
            />
          )}
          {(assessmentStatus === assessmentStatusTypes.ASSESSMENT ||
            assessmentStatus === assessmentStatusTypes.SELF_ASSESSMENT ||
            assessmentStatus === assessmentStatusTypes.RE_ASSESSMENT) &&
            actions.includes(actionTypes.PERFORM) && (
              <Link to={routes.scoring(customerId, assessmentYear, version)}>
                <Button
                  label={
                    isCoreCustomer
                      ? 'Fill in assessment'
                      : 'Fill in on behalf of customer'
                  }
                  data-transaction-name={
                    isCoreCustomer
                      ? 'Fill in assessment'
                      : 'Fill in on behalf of customer'
                  }
                  variant={isCoreCustomer ? '' : 'outline'}
                />
              </Link>
            )}
          {assessmentStatus === assessmentStatusTypes.COMPLETED &&
            actions.includes(actionTypes.RESCORE) && (
              <Button
                label="Open rescoring"
                data-transaction-name="Open rescoring"
                variant="outline"
                onClick={() => setIsRescoreModalVisible(true)}
              />
            )}
          {assessmentState.isComplete &&
            actions.includes(actionTypes.REJECT) && (
              <Button
                label={actionTypesTranslations[actionTypes.REJECT]}
                variant="outline"
                data-transaction-name="Reject assessment"
                onClick={() =>
                  setIsRejectModalVisible((prevState) => !prevState)
                }
              />
            )}
          {assessmentState.isComplete &&
            actions.includes(actionTypes.APPROVE) && (
              <Button
                label={actionTypesTranslations[actionTypes.APPROVE]}
                data-transaction-name="Approve assessment"
                onClick={() =>
                  setIsApproveModalVisible((prevState) => !prevState)
                }
              />
            )}
        </span>
      </div>
    </div>
  );
};

export { DetailsPage };
