import { assessmentStatusTranslations } from '../../../../constants';

const sortByStatus = (rowA, rowB, id) => {
  const rowAStatusTranslated = assessmentStatusTranslations[rowA.original[id]];
  const rowBStatusTranslated = assessmentStatusTranslations[rowB.original[id]];

  if (rowAStatusTranslated[0] > rowBStatusTranslated[0]) return 1;
  if (rowBStatusTranslated[0] > rowAStatusTranslated[0]) return -1;
  return 0;
};

export { sortByStatus };
