import { Slider as SliderComponent, SliderProps } from '@chakra-ui/slider';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import * as React from 'react';
import { SliderFilledTrack } from './SliderFilledTrack';
import { SliderThumb } from './SliderThumb';
import { SliderTrack } from './SliderTrack';

export type ISliderProps = SliderProps;

type ISliderComponent = ComponentWithAs<'div', ISliderProps> & {
  FilledTrack: typeof SliderFilledTrack;
  Thumb: typeof SliderThumb;
  Track: typeof SliderTrack;
};

export const Slider = forwardRef<ISliderProps, 'div'>((props, ref) => {
  return (
    <SliderComponent {...props} ref={ref}>
      {props.children}
    </SliderComponent>
  );
}) as ISliderComponent;

Slider.FilledTrack = SliderFilledTrack;
Slider.Track = SliderTrack;
Slider.Thumb = SliderThumb;
