import { getValidChildren } from '@chakra-ui/react-utils';
import { chakra, useStyles } from '@chakra-ui/system';
import { CheckTickBold } from '@lego/klik-ui-icons';
import * as React from 'react';
import { useStepperContext } from './StepperProvider';

export type StepperState = 'active' | 'complete' | 'incomplete';

export type IStepProps = React.HTMLAttributes<HTMLButtonElement> & {
  label?: string;
  index?: number;
  state?: StepperState;
  isReadonly?: boolean;
};

const nodeWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '4',
};

/**
 * StepperItem
 * use StepperItems in kombination with a stepper
 *
 * @returns Step
 * @memberof stepper
 * @author Simon Groenborg
 */
export const Step: React.FunctionComponent<IStepProps> = ({
  state = 'incomplete',
  children,
  isReadonly,
  index,
  ...props
}) => {
  const { orientation, readonlySteps } = useStepperContext();
  const styles = useStyles();

  const isReadonlyComputed = React.useMemo(() => {
    return readonlySteps?.includes(index as number) ?? isReadonly;
  }, [state, index, readonlySteps, isReadonly]);

  const tabIndex = React.useMemo(() => {
    if (isReadonlyComputed) {
      return -1;
    }

    return state === 'complete' || state === 'active' ? 0 : -1;
  }, [isReadonlyComputed, state]);

  const linkAs = isReadonlyComputed ? 'span' : 'button';

  const childNodeWrapper = React.useMemo(() => {
    const validChildren = getValidChildren(children).map(
      (child: React.ReactElement<IStepProps>) => {
        return React.cloneElement(child, {
          index,
          ...child.props,
        });
      }
    );

    if (orientation === 'vertical') {
      return <chakra.div __css={nodeWrapperStyles}>{validChildren}</chakra.div>;
    }

    return validChildren;
  }, [orientation, children]);

  return (
    <chakra.li aria-current={state === 'active'} role="presentation" sx={styles.step}>
      <chakra.div className="step-graph" data-state={state} sx={styles.graph}>
        <chakra.button
          aria-readonly={isReadonlyComputed}
          as={linkAs}
          className="step-link"
          data-state={state}
          sx={styles.link}
          tabIndex={tabIndex}
          {...props}
        >
          {state === 'complete' ? <CheckTickBold /> : index}
        </chakra.button>
      </chakra.div>
      {childNodeWrapper}
    </chakra.li>
  );
};
