import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesVtt = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-vtt_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h4.08a.75.75 0 000-1.5H3.75a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path d="M11.916 23.916a.68.68 0 01-.47-.21c-2.18-2.26-2.2-7.39-2.2-7.6a.67.67 0 01.67-.67.67.67 0 01.68.67s0 3.91 1.34 6a9.73 9.73 0 001.34-6 .671.671 0 01.63-.71.66.66 0 01.72.63c.3 5.21-2.12 7.59-2.22 7.69a.7.7 0 01-.49.2zm6.84-7h-.69v6.3a.7.7 0 01-1.4 0v-6.3h-.7a.7.7 0 010-1.39h2.79a.7.7 0 110 1.39zm4.46 0h-.7v6.3a.7.7 0 11-1.39 0v-6.3h-.7a.7.7 0 110-1.39h2.79a.7.7 0 110 1.39z" />
      </g>
      <defs>
        <clipPath id="files-vtt_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesVtt.displayName = 'FilesVtt';
