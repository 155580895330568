import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesImage = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.81 6.59L15.97.71a2.331 2.331 0 00-.72-.52 2.34 2.34 0 00-.87-.19H3.75c-.3 0-.595.061-.87.18a2.06 2.06 0 00-.74.5 2.15 2.15 0 00-.48.74 2.41 2.41 0 00-.16.88v19.4c-.002.3.052.6.16.88.107.277.27.53.48.74.207.219.46.39.74.5.275.119.57.18.87.18h16.5a2.25 2.25 0 002.25-2.25V8.18a2.243 2.243 0 00-.69-1.59zM15 1.86l5.69 5.69h-4.94A.75.75 0 0115 6.8V1.86zm6 19.84a.75.75 0 01-.75.75H3.75A.75.75 0 013 21.7V2.3a.75.75 0 01.75-.75h9.75v5.26a2.25 2.25 0 002.25 2.25H21V21.7z" />
        <path d="M9.12 13.54a2.52 2.52 0 01-1.78-.74A2.48 2.48 0 016.6 11a2.54 2.54 0 01.74-1.78 2.59 2.59 0 013.56 0 2.54 2.54 0 01.74 1.78 2.5 2.5 0 01-.74 1.78 2.54 2.54 0 01-1.78.76zm0-3.54a1 1 0 00-.923 1.39 1 1 0 00.223.33 1.05 1.05 0 001.44 0A1 1 0 009.12 10zm8.28 10.18a.73.73 0 01-.62-.33l-3.09-4.63-2.19 3a.71.71 0 01-.51.31.72.72 0 01-.57-.15l-1.31-1-1.73 2.5A.764.764 0 116.13 19l1.68-2.48a1.6 1.6 0 01.46-.44 1.56 1.56 0 01.6-.23 1.51 1.51 0 01.63 0c.208.056.402.155.57.29l.68.55 1.66-2.37a1.54 1.54 0 012.55.03L18.02 19a.75.75 0 01-.2 1 .81.81 0 01-.42.18z" />
      </g>
    );
  }
);
FilesImage.displayName = 'FilesImage';
