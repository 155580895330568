import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import { Table as TableComponent, TableProps } from '@chakra-ui/table';
import * as React from 'react';
import { TableBody } from './TableBody';
import { TableCell } from './TableCell';
import { TableColumnHeader } from './TableColumnHeader';
import { TableFooter } from './TableFooter';
import { TableHead } from './TableHead';
import { TableRow } from './TableRow';

export type ITableProps = TableProps;
export interface ITableStatic {
  Cell: typeof TableCell;
  Body: typeof TableBody;
  Head: typeof TableHead;
  ColumnHeader: typeof TableColumnHeader;
  Row: typeof TableRow;
  Footer: typeof TableFooter;
}

type ITableComponent = ComponentWithAs<'table', ITableProps> & ITableStatic;

export const Table = forwardRef<ITableProps, 'table'>((props, ref) => {
  return <TableComponent {...props} ref={ref} />;
}) as ITableComponent;

Table.Cell = TableCell;
Table.Body = TableBody;
Table.Head = TableHead;
Table.ColumnHeader = TableColumnHeader;
Table.Row = TableRow;
Table.Footer = TableFooter;
