import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesEpsBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#files-eps-bold_svg__a)">
          <path d="M11.77 11.37v2.5a1.25 1.25 0 000-2.5z" />
          <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM8.79 13.23a.64.64 0 110 1.28H6.91v1.76h1.88a.64.64 0 110 1.28H6.27a.64.64 0 01-.64-.64v-6.09a.64.64 0 01.64-.64h2.52a.641.641 0 010 1.28H6.91v1.77h1.88zm4.75 1.15a2.52 2.52 0 01-1.77.74V17a.63.63 0 01-1.25 0v-6.25a.63.63 0 01.63-.63h.63a2.55 2.55 0 011.77.73c.468.47.73 1.107.73 1.77a2.47 2.47 0 01-.74 1.76zm3.31-1a2.86 2.86 0 011.47 2.35 1.88 1.88 0 01-1.87 1.88h-1.23a.64.64 0 01-.63-.63.63.63 0 01.63-.63h1.26a.64.64 0 00.63-.63c0-.59-.36-.89-1-1.38A2.879 2.879 0 0114.6 12a1.88 1.88 0 011.88-1.88h1.26a.63.63 0 010 1.26h-1.26a.64.64 0 00-.63.63c0 .58.36.88 1 1.36v.01z" />
        </g>
        <defs>
          <clipPath id="files-eps-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
FilesEpsBold.displayName = 'FilesEpsBold';
