import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FolderBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.5 5H11.236a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 008.5 2h-7A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h21a1.5 1.5 0 001.5-1.5v-14A1.5 1.5 0 0022.5 5z" />
      </g>
    );
  }
);
FolderBold.displayName = 'FolderBold';
