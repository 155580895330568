import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const EditBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M19.592 0a4.389 4.389 0 013.126 1.279l.002.002a4.386 4.386 0 01.076 6.115 1.005 1.005 0 00-.09-.103l-6-6a1.003 1.003 0 00-.103-.09A4.388 4.388 0 0119.592 0zm1.7 8.707a.971.971 0 00.104.09L9.304 20.89 3.11 14.695 15.201 2.603c.028.036.058.071.091.104l6 6zm-19.509 7.49l-1.76 6.866a.75.75 0 00.913.913l6.865-1.761-6.018-6.018z" />
    </g>
  );
});
EditBold.displayName = 'EditBold';
