import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowDown = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M.445 8.495a.75.75 0 011.06-.05L12 17.986l10.495-9.541a.75.75 0 011.01 1.11l-11 10a.75.75 0 01-1.01 0l-11-10a.75.75 0 01-.05-1.06z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowDown.displayName = 'ArrowDown';
