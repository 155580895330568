const uploadPreparedAssessmentColumnHeaders = {
  YEAR: 'Year',
  MARKET_GROUP: 'MG (C2) Description',
  BUSINESS_UNIT: 'BU (C3) Description',
  C6_ID: 'C6 ID',
  C6_DESCRIPTION: 'C6 Description',
  CUSTOMER_ID: 'Customer ID',
  CUSTOMER_DESCRIPTION: 'Customer Description',
  CHANNEL: 'Channel',
  OMNI: 'Omni',
  PERFORM_USER: 'Perform',
  REVIEW_USER: 'Review',
  APPROVE_USER: 'Approve',
};

const uploadPreparedAssessmentColumnHeaderAccessors = {
  [uploadPreparedAssessmentColumnHeaders.YEAR]: 'year',
  [uploadPreparedAssessmentColumnHeaders.MARKET_GROUP]: 'marketGroup',
  [uploadPreparedAssessmentColumnHeaders.BUSINESS_UNIT]: 'businessUnit',
  [uploadPreparedAssessmentColumnHeaders.C6_ID]: 'c6Id',
  [uploadPreparedAssessmentColumnHeaders.C6_DESCRIPTION]: 'c6Name',
  [uploadPreparedAssessmentColumnHeaders.CUSTOMER_ID]: 'customerId',
  [uploadPreparedAssessmentColumnHeaders.CUSTOMER_DESCRIPTION]: 'customerName',
  [uploadPreparedAssessmentColumnHeaders.CHANNEL]: 'channel',
  [uploadPreparedAssessmentColumnHeaders.OMNI]: 'omni',
  [uploadPreparedAssessmentColumnHeaders.PERFORM_USER]: 'perform',
  [uploadPreparedAssessmentColumnHeaders.REVIEW_USER]: 'review',
  [uploadPreparedAssessmentColumnHeaders.APPROVE_USER]: 'approve',
};

export {
  uploadPreparedAssessmentColumnHeaders,
  uploadPreparedAssessmentColumnHeaderAccessors,
};
