import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesPhone = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#devices-phone_svg__a)">
          <path d="M16.465 23.598a6.222 6.222 0 01-3.33-.863 47.837 47.837 0 01-11.68-10.95 7.262 7.262 0 010-8.722l1.203-1.646A2.247 2.247 0 014.202.413a1.743 1.743 0 011.334.392l3.78 3.01a1.877 1.877 0 01.521 2.27l-.852 2.087a.884.884 0 00.15 1.003l6.016 5.65c.19.192.682 0 1.003-.21l1.323-.763a1.913 1.913 0 012.407.412l3.61 3.533a1.907 1.907 0 01-.191 2.77l-1.424 1.244a9.018 9.018 0 01-5.414 1.787zM4.423 1.939c-.223.07-.414.215-.542.412L2.618 3.946a5.815 5.815 0 000 6.956 46.47 46.47 0 0011.32 10.588c1.875 1.205 5.364.532 7.018-.823l1.474-1.214a.42.42 0 00.15-.301.392.392 0 00-.1-.312l-3.61-3.532a.461.461 0 00-.59-.14l-1.324.762c-1.554.893-2.436.381-2.827 0l-6.016-5.65a2.35 2.35 0 01-.482-2.69l.823-2.008a.432.432 0 00-.09-.522L4.613 1.94a.3.3 0 00-.191 0z" />
        </g>
        <defs>
          <clipPath id="devices-phone_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
DevicesPhone.displayName = 'DevicesPhone';
