/* eslint-disable no-console */
import Auth from '@aws-amplify/auth';
import { Lambda } from '@aws-sdk/client-lambda';
import { logger } from './logger';
import {
  addSnackbar,
  snackbarTypes,
  snackbarDefaultMessages,
} from './snackbar';

const requestDetailedAssessmentExtract = async (year) => {
  const credentials = await Auth.currentCredentials();

  const lambda = new Lambda({
    credentials: Auth.essentialCredentials(credentials),
    region: 'eu-west-1',
  });

  const session = await Auth.currentSession();
  const payload = JSON.stringify({
    extractType: 'ongoing',
    includeDetail: true,
    includeAttachments: true,
    year,
    jwt: session.idToken.jwtToken,
  });

  lambda
    .invoke({
      LogType: 'Tail',
      InvocationType: 'Event',
      Payload: payload,
      FunctionName: process.env.LAMBDA_EXTRACT_FUNCTION,
    })
    .then(() => {
      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: `The detailed export will be by email to ${session.idToken.payload.email}.`,
        type: snackbarTypes.SUCCESS,
      });
    })
    .catch((error) => {
      logger.logError(error);
      addSnackbar({
        header: snackbarDefaultMessages.errorHeader,
        text: `There was an error generating the detailed export`,
        type: snackbarTypes.ERROR,
      });
    });
};

export { requestDetailedAssessmentExtract };
