import { TemplateReplacementInstructions } from '../constants/templatedTerms';

/**
 * Replaces the term with the value in the TemplateReplacementInstructions map
 * @param {string} text - The text to replace the term in
 * @param {string} term - The term to replace
 * @returns {string} - The text with the term replaced
 */

export const replaceTerm = (text, term) => {
  const termReplacement = TemplateReplacementInstructions.get(term);
  if (termReplacement === undefined) {
    return text;
  }

  return text.replaceAll(term, termReplacement);
};

/**
 * Replaces all terms in the TemplateReplacementInstructions map with their values
 * @param {string} string - The string to replace the terms in
 * @returns {string} - The string with the terms replaced
 */
export const replaceTerms = (string) => {
  let replacedString = string;
  for (const [term, replacement] of TemplateReplacementInstructions) {
    replacedString = replacedString.replaceAll(term, replacement);
  }

  return replacedString;
};
