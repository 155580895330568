import { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/core';
import { Button, Checkbox } from '@lego/klik-react';
import { Select } from '@lego/klik-ui';
import { matchSorter } from 'match-sorter';
import { spacing, media } from '../../config';
import { OverviewTable } from './overview-table/OverviewTable';
import { AssessmentExportMenu } from './components/AssessmentExportMenu';
import { TableRowCounter } from './components/TableRowCounter';
import { TableSearchField } from './components/TableSearchField';
import { Divider } from '../Divider';
import { OverlayPortal } from '../OverlayPortal';
import { Spinner } from '../spinner/Spinner';
import { routes } from '../RouteHandler';
import { overviewColumns } from './overview-table/columns';
import { GET_ASSESSMENTS } from '../../queries/assessment';
import { useUser } from '../../providers/UserProvider';
import { Heading } from '../Heading';
import { withTransaction } from '@elastic/apm-rum-react';

const pendingUserFilterStatusLocalStorageKey = 'pendingUserFilterIsChecked';
const selectedYearLocalStorageKey = 'selectedYear';

const overviewWrapperStyle = css({
  marginBottom: spacing.size32,
});

const yearSelectStyle = css({
  width: '7%',
});

const headerHeadingStyle = css({
  display: 'block',
  [media.large]: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: '-20px',
  },
});

const headerActionsStyle = css({
  display: 'block',
  margin: `${spacing.size6}px 0`,
  [media.large]: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
});

const actionButtonsLeft = css({
  display: 'block',
  [media.large]: {
    display: 'flex',
    alignItems: 'center',
  },
});

const resetButtonWrapperStyle = css({
  display: 'block',
  marginTop: spacing.size4,
  [media.large]: {
    marginTop: 0,
  },
});

const tableSearchWrapperStyle = css({
  marginRight: spacing.size6,
});

const tableInfoAndExportStyle = css({
  display: 'flex',
  margin: `${spacing.size6}px 0`,
  justifyContent: 'space-between',
  alignItems: 'center',
});

const filterByPendingUser = (allAssessments, currentUserEmail) => {
  return allAssessments.filter(
    (assessment) =>
      assessment.pendingUser.toLowerCase() === currentUserEmail.toLowerCase()
  );
};
const hasActiveFilters = (initialFilterState, currentFilters) => {
  const isPendingUserActive = currentFilters.pendingUser;
  const isSearchTextActive = currentFilters.searchText;

  if (isPendingUserActive || isSearchTextActive) return true;
  return false;
};

const determineDefaultYear = () => {
  const currentDate = new Date();
  const defaultYear = currentDate.getFullYear();
  const monthOfYear = currentDate.getMonth() + 1;
  return monthOfYear < 8 ? defaultYear - 1 : defaultYear;
};

export const tablePageSizes = [15, 25, 50, 100, 200];

const Overview = () => {
  const pendingUserStatusFromLocalStorage = useMemo(() => {
    const pendingUserStatus = window.localStorage.getItem(
      pendingUserFilterStatusLocalStorageKey
    );

    if (pendingUserStatus) return JSON.parse(pendingUserStatus);
    return false;
  }, []);

  const selectedYearForLocalStorage = useMemo(() => {
    const selectedYear = window.localStorage.getItem(
      selectedYearLocalStorageKey
    );
    return selectedYear ? JSON.parse(selectedYear) : determineDefaultYear();
  }, []);

  const initialFilterState = {
    pendingUser: pendingUserStatusFromLocalStorage,
    searchText: '',
  };

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      window.localStorage.removeItem(selectedYearLocalStorageKey);
    });
  }, []);

  const [filters, updateFilters] = useState(initialFilterState);
  const [queryYear, setQueryYear] = useState(selectedYearForLocalStorage);

  const history = useHistory();
  const { email: currentUserEmail, isLoggedIn } = useUser();

  const {
    data: overviewData,
    loading: isOverviewDataLoading,
    error: overviewDataError,
  } = useQuery(GET_ASSESSMENTS, {
    variables: {
      year: queryYear,
    },
    fetchPolicy: 'network-only',
    skip: !isLoggedIn,
  });

  const handleTableRowClick = (rowData) => {
    const { customerId, year, version } = rowData;
    history.push(routes.details(customerId, year, version));
  };

  const handleTextSearchUpdate = (searchText) =>
    updateFilters((prevState) => ({
      ...prevState,
      searchText,
    }));

  const filter = (assessments, filters, currentUserEmail) => {
    const { pendingUser, searchText } = filters;

    let filteredData = [...assessments];
    if (pendingUser) {
      filteredData = filterByPendingUser(assessments, currentUserEmail);
    }

    if (searchText) {
      filteredData = matchSorter(filteredData, searchText, {
        keys: ['customerId', 'customerName', 'c6Name'],
      });
    }

    return filteredData;
  };

  const columns = useMemo(() => overviewColumns, []);

  // Error boundary catches this error
  if (overviewDataError) throw overviewDataError;

  if (isOverviewDataLoading || !isLoggedIn) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  const filteredTableData = filter(
    overviewData.getAssessments,
    filters,
    currentUserEmail
  );

  const handleYearSelect = (value) => {
    window.localStorage.setItem(selectedYearLocalStorageKey, value);
    setQueryYear(value);
  };

  return (
    <div css={overviewWrapperStyle}>
      <div css={headerHeadingStyle}>
        <Heading type="h1" text="Assessment Overview" />
        <div css={yearSelectStyle}>
          <Select
            value={queryYear}
            onChange={(value) => handleYearSelect(parseInt(value.target.value))}
          >
            <option value={2021}>2021</option>
            <option value={2022}>2022</option>
            <option value={2023}>2023</option>
            <option value={2024}>2024</option>
          </Select>
        </div>
      </div>
      <div css={headerActionsStyle}>
        <div css={actionButtonsLeft}>
          <span css={tableSearchWrapperStyle}>
            <TableSearchField
              onChange={handleTextSearchUpdate}
              value={filters.searchText}
            />
          </span>
          <Checkbox
            label="Show only my action required"
            checked={filters.pendingUser}
            onChange={() =>
              updateFilters((prevState) => {
                window.localStorage.setItem(
                  pendingUserFilterStatusLocalStorageKey,
                  !prevState.pendingUser
                );
                return {
                  ...prevState,
                  pendingUser: !prevState.pendingUser,
                };
              })
            }
          />
        </div>
        <span css={resetButtonWrapperStyle}>
          <Button
            label="Reset filters"
            variant="ghost"
            disabled={!hasActiveFilters(initialFilterState, filters)}
            onClick={() => {
              window.localStorage.setItem(
                pendingUserFilterStatusLocalStorageKey,
                false
              );
              updateFilters({
                ...initialFilterState,
                pendingUser: false,
              });
            }}
          />
        </span>
      </div>
      <Divider />
      <div css={tableInfoAndExportStyle}>
        <TableRowCounter
          numberOfFilteredRows={filteredTableData.length}
          searchText={filters.searchText}
        />
        <AssessmentExportMenu
          assessments={overviewData.getAssessments}
          year={queryYear}
        />
      </div>
      <OverviewTable
        rowClick={handleTableRowClick}
        pageSizes={tablePageSizes}
        overviewTableData={filteredTableData}
        columns={columns}
      />
    </div>
  );
};

const OverviewPage = withTransaction('OverviewPage', 'component')(Overview);
export { OverviewPage, pendingUserFilterStatusLocalStorageKey };
