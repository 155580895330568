import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesQueryFile = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.869 6.59L15.99.71A2.254 2.254 0 0014.399 0H3.723a2.256 2.256 0 00-2.07 1.43 2.25 2.25 0 00-.153.87v19.4a2.247 2.247 0 001.358 2.115c.273.118.567.181.865.185h16.523a2.255 2.255 0 002.253-2.25V8.18a2.248 2.248 0 00-.631-1.59zm-6.85-4.73l5.728 5.7H15.74a.752.752 0 01-.75-.75l.03-4.95zm6.009 19.84a.75.75 0 01-.751.75H3.724a.751.751 0 01-.751-.75V2.3a.75.75 0 01.75-.75h9.764v5.26a2.248 2.248 0 002.253 2.25h5.258l.03 12.64z" />
        <path d="M15.86 11.75a1.84 1.84 0 00-1.932-1.69h-.611a.641.641 0 00-.453 1.092c.12.12.283.188.453.188h.691a.581.581 0 01.6.5v1.05a1.729 1.729 0 00.892 1.46l.38.22-.39.2a1.701 1.701 0 00-.891 1.46v1.05a.57.57 0 01-.601.5h-.691a.641.641 0 100 1.28h.721a1.834 1.834 0 001.823-1.69v-1.09a.418.418 0 01.23-.36l1.342-.77a.63.63 0 00.32-.55.61.61 0 00-.31-.57l-1.342-.77a.461.461 0 01-.23-.37v-1.14zm-8.25-.057a1.84 1.84 0 011.933-1.69h.611a.641.641 0 110 1.28h-.691a.581.581 0 00-.6.5v1.05a1.728 1.728 0 01-.892 1.46l-.38.22.39.2a1.7 1.7 0 01.891 1.46v1.05a.57.57 0 00.601.5h.691a.641.641 0 01.453 1.092.642.642 0 01-.453.188h-.721a1.834 1.834 0 01-1.823-1.69v-1.09a.42.42 0 00-.23-.36l-1.342-.77a.63.63 0 01-.32-.55.61.61 0 01.31-.57l1.342-.77a.461.461 0 00.23-.37v-1.14z" />
      </g>
    );
  }
);
FilesQueryFile.displayName = 'FilesQueryFile';
