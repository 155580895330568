import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FeedScrollBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M5.92 16.822a.253.253 0 00-.041-.22.248.248 0 00-.2-.1H5.5a1 1 0 01-1-1V3.5a1 1 0 011-1h14a1 1 0 011 1v10.68c0 .117.08.218.194.244 1.084.25 2.08.787 2.884 1.555A.25.25 0 0024 15.8V2.5A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v14.003a2.5 2.5 0 002.5 2.501h3.152a.251.251 0 00.242-.312 3.602 3.602 0 01.026-1.87z" />
        <path d="M23 24a.75.75 0 00.75-.75v-3A4.757 4.757 0 0019 15.497h-2a.25.25 0 01-.25-.25v-5.5a2.25 2.25 0 10-4.5 0v7.061a.25.25 0 01-.442.16l-.578-.7a2.25 2.25 0 00-3.46 2.878l3.806 4.58c.143.172.354.27.577.271L23 24z" />
      </g>
    );
  }
);
FeedScrollBold.displayName = 'FeedScrollBold';
