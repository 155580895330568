import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const UnlockBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M2.177 2.487A6.375 6.375 0 0113.06 6.995v2h9.875a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75h-16.5a.75.75 0 01-.75-.75v-13.5a.75.75 0 01.75-.75h4.375v-2a4.125 4.125 0 10-8.25 0v2.25a1.125 1.125 0 01-2.25 0v-2.25a6.38 6.38 0 011.867-4.508zM14.685 13.87c-.621 0-1.125.504-1.125 1.125v3a1.125 1.125 0 102.25 0v-3c0-.621-.504-1.125-1.125-1.125z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
UnlockBold.displayName = 'UnlockBold';
