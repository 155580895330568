import { forwardRef, useStyles } from '@chakra-ui/system';
import { IInputProps, Input } from '@lego/klik-ui-input';
import * as React from 'react';
import { useNumberInputContext } from './NumberInputProvider';

export type INumberInputFieldProps = IInputProps;

export const NumberInputField = forwardRef<INumberInputFieldProps, 'input'>(
  ({ size, ...rest }, ref) => {
    const styles = useStyles();
    const { getInputProps } = useNumberInputContext();
    const input = getInputProps(rest, ref);

    const styleOverrides = {
      ...styles.field,
    };

    return (
      <Input textAlign="center" {...input} {...rest} ref={ref} size={size} sx={styleOverrides} />
    );
  }
);
