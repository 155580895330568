import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { awsConfig } from '../awsConfig';
import { logger } from '../utils/logger';
import { onError } from '@apollo/client/link/error';
import { apmBase } from '@elastic/apm-rum';
import {
  addSnackbar,
  snackbarDefaultMessages,
  snackbarTypes,
} from '../utils/snackbar';

Amplify.configure(awsConfig);

const appSyncLinkConfig = {
  url: process.env.GRAPHQL_URL,
  region: process.env.AWS_REGION,
  auth: {
    type: 'AWS_IAM',
    credentials: async () => {
      try {
        const credentials = await Auth.currentCredentials();
        return credentials;
      } catch (error) {
        logger.logError(error);
      }
    },
  },
  disableOffline: true,
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const currentTransaction = apmBase?.getCurrentTransaction();
    graphQLErrors.forEach((graphQLError) => {
      apmBase?.captureError(graphQLError);
    });

    const filteredGraphQLErrors = graphQLErrors.filter(
      (error) => error?.extensions?.code !== 'VALIDATION_ERROR'
    );

    if (filteredGraphQLErrors.length > 0) {
      const uniqueMessages = [
        ...new Set(filteredGraphQLErrors.map(({ message }) => message)),
      ];

      let errorMessage = `${uniqueMessages.join('\n')}`;
      errorMessage += `\nTime: ${new Date().toLocaleString()}`;
      errorMessage += `\nID: ${currentTransaction?.id ?? 'Unknown'}`;

      addSnackbar({
        header: snackbarDefaultMessages.errorHeader,
        text: errorMessage,
        type: snackbarTypes.ERROR,
      });
    }

    if (networkError) {
      addSnackbar({
        header: 'Network error',
        text: `${networkError.name}: ${networkError.message}`,
        type: snackbarTypes.ERROR,
      });
    }
  }
});

const loggerLink = new ApolloLink((operation, forward) => {
  const currentTransaction = apmBase?.getCurrentTransaction();
  const span = currentTransaction?.startSpan(
    `Call ${operation.operationName}`,
    'graphql',
    {
      blocking: true,
      sync: false,
    }
  );

  // Add the request payload and name the context for debugging purpose
  apmBase?.setCustomContext({
    operation: operation.operationName,
    variables: operation.variables,
  });

  return forward(operation).map((data) => {
    span?.end();
    return data;
  });
});

// from https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-no-offline-support
const client = new ApolloClient({
  link: ApolloLink.from([
    loggerLink,
    errorLink,
    createAuthLink(appSyncLinkConfig),
    createSubscriptionHandshakeLink(appSyncLinkConfig),
  ]),
  cache: new InMemoryCache(),
});

const ApolloClientProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

ApolloClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ApolloClientProvider };
