import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudAvailable = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M17.75 19.498H4.711c-1.564-.113-4.523-1.096-4.69-4.012a4.54 4.54 0 011.386-3.734 4.565 4.565 0 013.126-1.106A7.36 7.36 0 016.94 6.354a7.461 7.461 0 014.587-1.841c4.523-.218 6.577 2.42 7.17 3.878A6.04 6.04 0 0122.2 9.756a5.43 5.43 0 011.39 1.96c.31.748.447 1.556.403 2.363a4.982 4.982 0 01-.47 2.084 5.019 5.019 0 01-1.302 1.701 6.543 6.543 0 01-4.47 1.634zM5.753 12.021a.878.878 0 01-.72.217 3.776 3.776 0 00-.562 0 2.932 2.932 0 00-1.98.704 3.008 3.008 0 00-.907 2.461c.136 2.275 3.127 2.544 3.23 2.554H17.75a4.984 4.984 0 003.387-1.23 3.464 3.464 0 001.292-2.658 3.954 3.954 0 00-1.198-3.103 4.554 4.554 0 00-2.866-1.034.944.944 0 01-.66-.156.931.931 0 01-.382-.558S16.28 5.826 11.6 6.054c-5.054.258-5.586 5.233-5.586 5.284-.005.25-.097.492-.26.683z" />
      </g>
    );
  }
);
CloudAvailable.displayName = 'CloudAvailable';
