import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilterBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.571 1.029a1.495 1.495 0 00-1.028-.515H1.49C1.097.514.72.67.446.96A1.436 1.436 0 000 2.023v.103c.377 5.143 4.029 9.48 8.966 10.765v9.275c0 .394.154.771.445 1.063.292.291.652.445 1.063.445.343 0 .686-.12.96-.343l3-2.502c.343-.292.549-.703.549-1.149v-6.806c4.954-1.285 8.606-5.623 8.966-10.765a1.47 1.47 0 00-.378-1.08z" />
      </g>
    );
  }
);
FilterBold.displayName = 'FilterBold';
