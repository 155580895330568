import { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { css } from '@emotion/react';

import { LinearProgress } from '@lego/klik-react';
import { CheckTickBold, PlusBold } from '@lego/klik-icons-react';
import { Select, Box, Table, Tag } from '@lego/klik-ui';

import { colors, media, spacing } from '../../../config';
import { Heading } from '../../Heading';
import { OverlayPortal } from '../../OverlayPortal';
import { Spinner } from '../../spinner/Spinner';
import {
  ACTIVATE_BUSINESS_UNIT_YEAR,
  GET_ELIGIBLE_ASSESSMENT_YEARS,
} from '../../../queries/assessmentAdmin';
import { generalApiErrorHandler } from '../../../utils/generalApiErrorHandler';
import { GenericConfirmationModal } from '../../../components/modals/GenericConfirmationModal';
import {
  addSnackbar,
  snackbarTypes,
  snackbarDefaultMessages,
} from '../../../utils/snackbar';
import { spinnerOverlayText } from '../../../styles/common';

const assessmentYearSelect = css({
  width: 150,
});

const buTag = css({
  margin: spacing.size2,
});

const activateIcon = css({
  width: 14,
  height: 14,
  marginLeft: 5,
});

const activeBuTable = css`
  margin-top: 10px;
  width: 'auto';
  td:nth-child(1) /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
    border-right-color: ${colors.slate1};
    border-right-width: 2px;
  }
`;

const progressBarContainer = css({
  [media.medium]: {
    width: 400,
  },
});

const ActivateYearAndBusinessUnit = () => {
  const [eligibleYears, setEligibleYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedBu, setSelectedBu] = useState('');
  const [
    activateConfirmModalVisibility,
    setActivateConfirmModalVisibility,
  ] = useState(false);

  const handleGetEligibleYearsResponse = (response) => {
    setEligibleYears(response.getEligibleAssessmentYears);
  };

  const [getEligibleYears, { loading: eligibleYearsLoading }] = useLazyQuery(
    GET_ELIGIBLE_ASSESSMENT_YEARS,
    {
      fetchPolicy: 'network-only',
      onCompleted: handleGetEligibleYearsResponse,
    }
  );

  const getEligibleYearOptions = () =>
    eligibleYears.map((assessmentYear) => assessmentYear.year);

  useEffect(() => {
    getEligibleYears();

    const handleCustomerDataUpdateEvent = () => {
      getEligibleYears();
    };

    document.addEventListener(
      'customerDataGenerated',
      handleCustomerDataUpdateEvent
    );
    return () => {
      document.removeEventListener(
        'customerDataGenerated',
        handleCustomerDataUpdateEvent
      );
    };
  }, [getEligibleYears]);

  if (eligibleYears.length > 0 && !selectedYear) {
    setSelectedYear(
      Math.max(...eligibleYears.map((assessmentYear) => assessmentYear.year))
    );
  }

  const getBusinessUnitsForYear = (activeStatus) =>
    eligibleYears
      .filter((assessmentYear) => assessmentYear.year === selectedYear)
      .flatMap((assessmentYear) => assessmentYear.businessUnits)
      .filter((businessUnit) => businessUnit.active === activeStatus);

  const getInActiveBusinessUnitsForYear = () => getBusinessUnitsForYear(false);
  const getActiveBusinessUnitsForYear = () => getBusinessUnitsForYear(true);

  const showConfirmActivateModal = async (buName) => {
    setSelectedBu(buName);
    setActivateConfirmModalVisibility(true);
  };

  const [
    activateBuForYear,
    { loading: activateBusinessUnitLoading },
  ] = useMutation(ACTIVATE_BUSINESS_UNIT_YEAR, {
    onCompleted: handleActivateBuResponse,
  });

  async function handleBusinessUnitActivation() {
    try {
      await activateBuForYear({
        variables: { businessUnit: selectedBu, year: selectedYear },
      });
    } catch (error) {
      generalApiErrorHandler(error);
      setActivateConfirmModalVisibility(false);
    }
  }

  function handleActivateBuResponse(response) {
    if (response.activateBusinessUnitForYear) {
      const { year, businessUnits } = response.activateBusinessUnitForYear;

      let updatedEligibleYears = eligibleYears.map((eligibleYear) => {
        if (eligibleYear.year === year) {
          return {
            year,
            businessUnits,
          };
        } else {
          return eligibleYear;
        }
      });

      setEligibleYears([...updatedEligibleYears]);

      setActivateConfirmModalVisibility(false);
      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: 'Business Unit has been activated',
        type: snackbarTypes.SUCCESS,
      });
    }
  }

  if (activateBusinessUnitLoading) {
    return (
      <OverlayPortal>
        <Spinner />
        <p css={spinnerOverlayText}>
          Activating the business unit. This may take a few minutes.
        </p>
      </OverlayPortal>
    );
  }

  return (
    <div>
      {activateConfirmModalVisibility && (
        <GenericConfirmationModal
          status="warning"
          header="Confirm activation"
          body={`Are you sure you want to activate the Business Unit:${selectedBu} for year:${selectedYear}? </br></br>
               This will activate all Long Tail assessments for ${selectedBu} and will allow customers to access the self-assessment scoring.`}
          onClose={() => setActivateConfirmModalVisibility(false)}
          onConfirm={handleBusinessUnitActivation}
        />
      )}
      <Heading type="h2" text="Active Year" />
      Determines which surveys are visible for long-tail customers and what MCP
      version will be flagged active.
      {eligibleYearsLoading ? (
        <div css={progressBarContainer}>
          <LinearProgress />{' '}
        </div>
      ) : (
        <div>
          <Heading type="h3" text="Eligible years" />
          {eligibleYears.length === 0 ? (
            <p data-testid="noResponseMessage">
              No eligible years are available. Please upload a customer/channel
              mapping first.
            </p>
          ) : (
            <div>
              <div css={assessmentYearSelect}>
                <Select
                  data-testid="eligibleYearSelect"
                  size="sm"
                  value={selectedYear}
                  onChange={(event) =>
                    setSelectedYear(parseInt(event.target.value))
                  }
                >
                  {getEligibleYearOptions().map((yearOption, i) => (
                    <option key={i}>{yearOption}</option>
                  ))}
                </Select>
              </div>
              <Heading type="h3" text="Eligibile Business Units" />
              <Table
                data-testid="eligibleBuTable"
                size="sm"
                variant="simple"
                css={activeBuTable}
              >
                <Table.Head>
                  <Table.Row>
                    <Table.ColumnHeader textAlign={'center'}>
                      In-Active
                    </Table.ColumnHeader>
                    <Table.ColumnHeader textAlign={'center'}>
                      Active
                    </Table.ColumnHeader>
                  </Table.Row>
                </Table.Head>
                <Table.Body data-testid="eligibleBuTableBody">
                  <Table.Row>
                    <Table.Cell>
                      <Box>
                        {getInActiveBusinessUnitsForYear().map((bu, i) => (
                          <Tag key={i} colorScheme="light-blue" css={buTag}>
                            <Tag.Label
                              as="button"
                              onClick={(event) =>
                                showConfirmActivateModal(
                                  event.currentTarget.innerText
                                )
                              }
                            >
                              {bu.businessUnitName}
                              <PlusBold css={activateIcon} />
                            </Tag.Label>
                          </Tag>
                        ))}
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Box>
                        {getActiveBusinessUnitsForYear().map((bu, i) => (
                          <Tag key={i} colorScheme="teal" css={buTag}>
                            <Tag.Label>
                              {bu.businessUnitName}
                              <CheckTickBold css={activateIcon} />
                            </Tag.Label>
                          </Tag>
                        ))}
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { ActivateYearAndBusinessUnit };
