/**
 * Extracts the Sold-to ID from a joined customer ID
 * @param {string} customerId - The joined customer ID
 * @param {string} c6Id - The C6 ID
 * @returns {string} The Sold-to ID
 */
export const extractSoldToFromJoinedCustomerId = (customerId, c6Id) => {
  if (customerId === c6Id) return '';

  return customerId.slice(c6Id.length);
};
