import { css } from '@emotion/core';
import { spacing, colors, media } from '../config';
import { Header } from '../components/Header';

const layoutWrapperStyle = css({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

const mainStyle = css({
  flexGrow: 1,
  backgroundColor: colors.neutralColors.white,
  padding: spacing.size4,
  [media.large]: {
    padding: `${spacing.size4}px ${spacing.size12}px ${spacing.size16 * 2}px`,
  },
});

function withMainLayout(Component) {
  function WithMainLayout({ ...props }) {
    return (
      <div css={layoutWrapperStyle}>
        <Header />
        <main css={mainStyle}>
          <Component {...props} />
        </main>
      </div>
    );
  }

  WithMainLayout.displayName = `WithMainLayout(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithMainLayout;
}

export { withMainLayout };
