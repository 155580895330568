import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StatusInformationBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M12 0a12 12 0 100 24 12 12 0 000-24zm1.57 6.008c0 .87-.71 1.508-1.526 1.508-.829 0-1.544-.633-1.544-1.508 0-.851.71-1.508 1.544-1.508.823 0 1.526.662 1.526 1.508zm-2.728 12.614V9.4h2.386v9.222h-2.386z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
StatusInformationBold.displayName = 'StatusInformationBold';
