import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SeoSearchBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M2.251 3.743h8.754a1 1 0 100-2H1.58c-.85-.02-1.557.65-1.581 1.5v3c.024.849.73 1.52 1.58 1.5h9.425a1 1 0 100-2H2.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25z" />
        <path
          clipRule="evenodd"
          d="M21.378 6.94a.249.249 0 00.043.3l2.298 2.299a1 1 0 01-1.414 1.414l-2.296-2.3a.251.251 0 00-.3-.042 4.185 4.185 0 01-5.354-1.162 4.18 4.18 0 01.387-5.462 4.186 4.186 0 015.464-.396 4.18 4.18 0 011.172 5.348zm-5.685-1.163a2.18 2.18 0 104.029-1.67 2.18 2.18 0 00-4.03 1.67z"
          fillRule="evenodd"
        />
        <path d="M3.001 16.74a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0 5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm11.505-5.999H5.002a1 1 0 100 2h9.504a1 1 0 100-2zM3.001 11.742a1.5 1.5 0 11-3-.001 1.5 1.5 0 013 0zm11.505-1H5.002a1 1 0 100 2h9.504a1 1 0 100-2zm0 9.998H5.002a1 1 0 100 2h9.504a1 1 0 100-2z" />
      </g>
    );
  }
);
SeoSearchBold.displayName = 'SeoSearchBold';
