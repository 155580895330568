import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Modal, Overlay } from '@lego/klik-react';
import { spacing } from '../../../config';

const bodyText = css({
  marginTop: spacing.size6,
  '& > p': {
    marginBottom: spacing.size4,
  },
});

const InfoModal = ({ header, body, closeInfoModal }) => (
  <Overlay isOpen={true} onClick={closeInfoModal}>
    <Modal
      heading={<Modal.Heading>{header}</Modal.Heading>}
      body={
        <Modal.Body>
          <div css={bodyText} dangerouslySetInnerHTML={{ __html: body }} />
        </Modal.Body>
      }
      status="information"
      exitClickHandler={closeInfoModal}
    />
  </Overlay>
);

InfoModal.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  closeInfoModal: PropTypes.func.isRequired,
};

export { InfoModal };
