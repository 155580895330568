/* eslint-disable react/jsx-no-bind */
import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import { Button } from '@lego/klik-ui-button';
import { IconButton } from '@lego/klik-ui-icon-button';
import { MenuNavigationHorizontal } from '@lego/klik-ui-icons';
import * as React from 'react';
import { usePagination } from './usePagination';

interface IPaginationOptions {
  nextLabel?: string;
  prevLabel?: string;
  firstLabel?: string;
  lastLabel?: string;
  showLabel?: boolean;
  showFirstLast?: boolean;
  page: number;
  totalPages: number;
  onPageClick?: (page: number) => void;
  isDisabled?: boolean;
}

export interface IPaginationProps
  extends HTMLChakraProps<'ol'>,
    IPaginationOptions,
    ThemingProps<'Pagination'> {}

export const Pagination = forwardRef<IPaginationProps, 'div'>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { showFirstLast, ...rest } = omitThemingProps(props);
  const { getPaginationItems, getNavigation } = usePagination({ ...props });

  const styles = useMultiStyleConfig('Pagination', props);

  const renderFirstButton = () => {
    const { label, onClick, navAction, ...restProps } = getNavigation().first();

    return (
      <Button onClick={() => void onClick(navAction)} sx={styles.buttons} {...restProps}>
        {label}
      </Button>
    );
  };

  const renderLastButton = () => {
    const { label, onClick, navAction, ...restProps } = getNavigation().last();

    return (
      <Button onClick={() => void onClick(navAction)} sx={styles.buttons} {...restProps}>
        {label}
      </Button>
    );
  };

  const renderPrevButton = () => {
    const { onClick, navAction, label, ...restProps } = getNavigation().prev();

    if (label) {
      return (
        <Button onClick={() => void onClick(navAction)} sx={styles.buttons} {...restProps}>
          {label}
        </Button>
      );
    }

    return (
      <IconButton onClick={() => void onClick(navAction)} sx={styles.buttons} {...restProps} />
    );
  };

  const renderNextButton = () => {
    const { onClick, navAction, label, ...restProps } = getNavigation().next();

    if (label) {
      return (
        <Button onClick={() => void onClick(navAction)} sx={styles.buttons} {...restProps}>
          {label}
        </Button>
      );
    }

    return (
      <IconButton onClick={() => void onClick(navAction)} sx={styles.buttons} {...restProps} />
    );
  };

  return (
    <chakra.ol aria-label="pagination navigation" ref={ref} sx={styles.container} {...rest}>
      {showFirstLast && renderFirstButton()}
      {renderPrevButton()}

      {getPaginationItems().map(({ key, page, isEllipsis, isSelected, onClick, ...item }) => {
        if (isEllipsis) {
          return <MenuNavigationHorizontal key={key} verticalAlign="-webkit-baseline-middle" />;
        }

        return (
          <Button
            borderRadius="full"
            isActive={isSelected}
            key={key}
            onClick={() => {
              if (!onClick) {
                return;
              }

              void onClick(page);
            }}
            size="sm"
            sx={styles.buttons}
            variant="ghost"
            {...item}
          >
            {page}
          </Button>
        );
      })}
      {renderNextButton()}

      {showFirstLast && renderLastButton()}
    </chakra.ol>
  );
});
