import { PropTypes } from 'prop-types';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Button } from '@lego/klik-react';
import { routes } from './RouteHandler';
import { font, spacing, colors, media } from '../config';
import legoErrorPageSvg from '../assets/lego-error-page.svg';

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'red',
  background: `linear-gradient(${colors.boldColors.darkBlue.darkBlue80}, ${colors.boldColors.darkBlue.base})`,
  color: colors.neutralColors.white,
  textAlign: 'center',
  paddingBottom: 120,
});

const imageWrapperStyle = css({
  display: 'flex',
  alignItems: 'flex-end',
  marginTop: 40,
  width: 200,
  [media.medium]: {
    marginTop: 80,
    width: 'auto',
  },
});

const svgStyle = css({
  width: '100%',
  height: 'auto',
});

const svgWrapperLeftStyle = css({
  width: 120,
  marginRight: spacing.size5,
  '& > img': {
    transform: 'matrix(-1, 0, 0, 1, 0, 0);',
  },
});

const svgWrapperRightStyle = css({
  width: 220,
});

const headerStyle = css({
  fontWeight: font.weight.medium,
  marginBottom: spacing.size7,
  marginTop: spacing.size12,
  fontSize: font.size.size3,
  lineHeight: font.lineHeight.s,
  padding: `0 ${spacing.size4}px`,
  [media.medium]: {
    fontSize: font.size.size5,
  },
});

const bodyTextStyle = css({
  maxWidth: 500,
  lineHeight: font.lineHeight.m,
  padding: `0 ${spacing.size4}px`,
});

const contactMailLinkStyle = css({
  marginTop: spacing.size6,
  marginBottom: spacing.size12,
  '&:link, &:hover, &:active, &:visited': {
    color: colors.neutralColors.white,
    textDecoration: 'underline',
  },
});

const ErrorPage = ({ withBackToOverviewButton }) => {
  return (
    <div>
      <div css={containerStyle}>
        <div css={imageWrapperStyle}>
          <div css={svgWrapperLeftStyle}>
            <img src={legoErrorPageSvg} css={svgStyle} />
          </div>
          <div css={svgWrapperRightStyle}>
            <img src={legoErrorPageSvg} css={svgStyle} />
          </div>
        </div>
        <h1 css={headerStyle}>Oops, something went missing!</h1>
        <p css={bodyTextStyle}>Please contact your LEGO representative.</p>
        <a css={contactMailLinkStyle} href="mailto:something@here">
          contact@person.lego.com
        </a>
        {withBackToOverviewButton && (
          <Link to={routes.overview}>
            <Button
              data-transaction-name="Back to overview from error page"
              label="Back to overview"
            />
          </Link>
        )}
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  withBackToOverviewButton: PropTypes.bool,
};

export { ErrorPage };
