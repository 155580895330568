import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Share = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M6.15 9.5a.65.65 0 00-.65.65v11.2a.65.65 0 00.65.65h12.6a.65.65 0 00.65-.65v-11.2a.65.65 0 00-.65-.65h-1.4a.75.75 0 010-1.5h1.4a2.15 2.15 0 012.15 2.15v11.2a2.15 2.15 0 01-2.15 2.15H6.15A2.15 2.15 0 014 21.35v-11.2A2.15 2.15 0 016.15 8h1.4a.75.75 0 010 1.5h-1.4z"
        fillRule="evenodd"
      />
      <path d="M11.92 1.22a.748.748 0 011.06 0l3.5 3.5a.75.75 0 01-1.06 1.06L13.2 3.56v7.99a.75.75 0 01-1.5 0V3.56L9.48 5.78a.75.75 0 01-1.06-1.06l3.5-3.5z" />
    </g>
  );
});
Share.displayName = 'Share';
