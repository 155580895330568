import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CrossBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M3.06.94A1.5 1.5 0 10.94 3.06L9.878 12l-8.94 8.94a1.5 1.5 0 002.122 2.12L12 14.122l8.94 8.94a1.5 1.5 0 002.12-2.122L14.122 12l8.94-8.94A1.5 1.5 0 0020.939.94L12 9.878 3.06.939z" />
      </g>
    );
  }
);
CrossBold.displayName = 'CrossBold';
