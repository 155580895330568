import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Edit = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M19.592 0a4.39 4.39 0 013.128 1.28l.002.002a4.386 4.386 0 01-.04 6.231l-.41.411-.001.001-14.08 14.08-.005.005a.75.75 0 01-.344.196l-6.906 1.77a.75.75 0 01-.912-.913l1.77-6.904a.75.75 0 01.196-.344L16.486 1.318A4.388 4.388 0 0119.592 0zM2.921 17.802l-1.13 4.406 4.41-1.13-3.28-3.276zm4.738 2.612l-4.077-4.07L16.605 3.32l4.074 4.074-13.02 13.02zM22.27 5.507a2.85 2.85 0 01-.531.825l-4.07-4.07a2.89 2.89 0 013.991.079 2.887 2.887 0 01.61 3.166z"
        fillRule="evenodd"
      />
    </g>
  );
});
Edit.displayName = 'Edit';
