import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BookBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M23.29 1.586c-1.986-.23-7.45-.089-11.29 2.437C8.17 1.497 2.704 1.334.72 1.586a.861.861 0 00-.72.863v16.4a.865.865 0 00.84.853 26.428 26.428 0 0110.57 2.69c.41.144.856.144 1.266 0a26.82 26.82 0 0110.57-2.69.85.85 0 00.753-.82v-16.4a.865.865 0 00-.71-.896zM9.425 16.706a.83.83 0 01-.698.426.871.871 0 01-.404 0 15.144 15.144 0 00-5.88-1.224.828.828 0 01-.828-.831.832.832 0 01.829-.83c2.254.014 4.48.49 6.545 1.399a.83.83 0 01.436 1.06zm0-4.646a.83.83 0 01-1.102.393 15.035 15.035 0 00-5.88-1.235.828.828 0 01-.316-1.592c.1-.04.208-.06.317-.06 2.253.015 4.48.487 6.545 1.39a.841.841 0 01.436 1.104zm12.13 4.209a15.037 15.037 0 00-5.88 1.235.85.85 0 01-.36.077.818.818 0 01-.717-1.157.82.82 0 01.358-.385 16.332 16.332 0 016.6-1.4.826.826 0 01.85.486.833.833 0 01-.85 1.166v-.022zm0-4.647a14.904 14.904 0 00-5.88 1.236.872.872 0 01-.36.087.827.827 0 01-.797-.645.833.833 0 01.438-.93 16.527 16.527 0 016.6-1.399.827.827 0 01.923.826.833.833 0 01-.579.792.826.826 0 01-.345.033z" />
    </g>
  );
});
BookBold.displayName = 'BookBold';
