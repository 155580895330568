import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const HumanResourcesOfferManBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.856 3.919c0 1.983-1.674 3.59-3.74 3.59-2.066 0-3.741-1.607-3.741-3.59 0-1.984 1.675-3.591 3.74-3.591 2.067 0 3.741 1.607 3.741 3.59zm-3.74 4.897c-2.73 0-5.129 1.74-5.877 4.26a.238.238 0 00.042.213.255.255 0 00.202.098h11.264c.08 0 .154-.037.202-.098a.235.235 0 00.041-.213c-.748-2.52-3.145-4.258-5.874-4.26zM1.013 14.675c-.56 0-1.013.436-1.013.973V22.7c0 .537.454.972 1.013.972.56 0 1.013-.435 1.013-.972v-7.052c0-.537-.453-.973-1.013-.973zm14.691 4.62H6.46a.621.621 0 01-.633-.608.62.62 0 01.633-.607h2.56c.34 0 .658-.164.846-.437a.94.94 0 00.085-.92c-.356-.8-1.176-1.32-2.084-1.318h-4.32a.497.497 0 00-.506.486v6.322c0 .269.226.487.506.487h10.906c1.25-.002 2.264-.975 2.266-2.176v-.256c0-.537-.454-.973-1.014-.973z" />
      </g>
    );
  }
);
HumanResourcesOfferManBold.displayName = 'HumanResourcesOfferManBold';
