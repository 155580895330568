import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const HomeBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M22.933 11.288l-9.55-9.56a2.48 2.48 0 00-3.51 0l-9.56 9.56a1.08 1.08 0 00.76 1.84h1.73v8.06a1.08 1.08 0 001.08 1.08h4.63a1.08 1.08 0 001.11-1.08v-5.63a.33.33 0 01.33-.33h3.37a.33.33 0 01.33.33v5.63a1.08 1.08 0 001.08 1.08h4.63a1.08 1.08 0 001.08-1.08v-8.06h1.76a1.08 1.08 0 00.73-1.84z" />
    </g>
  );
});
HomeBold.displayName = 'HomeBold';
