import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Minus = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M23.913 12a.75.75 0 01-.75.75H.837a.75.75 0 010-1.5h22.326a.75.75 0 01.75.75z"
        fillRule="evenodd"
      />
    </g>
  );
});
Minus.displayName = 'Minus';
