import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LanguageBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M4.571 3.538a.336.336 0 00-.24.103.356.356 0 00-.1.247v.692h.701v-.692a.37.37 0 00-.11-.252.35.35 0 00-.25-.098z" />
        <path d="M15.72 6.142V.67a.689.689 0 00-.195-.477.65.65 0 00-.467-.193H.34a.336.336 0 00-.238.1A.355.355 0 000 .34V11.8c0 .092.036.181.1.247s.15.103.24.103h2.738c.087 0 .172.036.234.1.062.063.097.15.097.24v3.775a.642.642 0 00.112.372c.075.108.182.19.304.232a.595.595 0 00.378.003.612.612 0 00.308-.225l3.008-4.023c.22-.297.342-.658.35-1.031V8.091c.003-.338.135-.662.368-.901.232-.24.547-.375.876-.378h5.945c.173 0 .34-.07.464-.195a.69.69 0 00.197-.475zm-9.294.284a.793.793 0 01-.22.547.75.75 0 01-.532.226.734.734 0 01-.531-.226.775.775 0 01-.22-.547v-.3H4.23v.3a.79.79 0 01-.22.547.741.741 0 01-.532.226.74.74 0 01-.532-.226.785.785 0 01-.22-.547V4.033a2.04 2.04 0 01.413-1.334 1.934 1.934 0 011.16-.729 1.812 1.812 0 011.498.449 1.909 1.909 0 01.628 1.469v2.538z" />
        <path d="M23.258 8.425H10.135a.731.731 0 00-.524.223.775.775 0 00-.218.54v9.726c0 .202.079.397.218.54a.731.731 0 00.524.223h6.426a.265.265 0 01.221.114l2.947 3.96a.605.605 0 00.707.21.625.625 0 00.304-.248.653.653 0 00.102-.385v-3.352a.297.297 0 01.079-.21.28.28 0 01.202-.089h2.135c.197 0 .386-.08.525-.223a.775.775 0 00.217-.54V9.188a.775.775 0 00-.217-.54.731.731 0 00-.525-.223zm-3.218 4.981h-1.674a.729.729 0 01-.1.495c-.22.36-.46.704-.722 1.032a7.282 7.282 0 002.426 1.33.747.747 0 01.447.378.789.789 0 01.054.592.764.764 0 01-.267.395.728.728 0 01-.445.151.815.815 0 01-.22 0 8.818 8.818 0 01-3.008-1.732 9.2 9.2 0 01-2.175 1.454.9.9 0 01-.32.072.738.738 0 01-.477-.155.776.776 0 01-.276-.428.794.794 0 01.045-.512.764.764 0 01.346-.37 7.948 7.948 0 003.088-2.66h-2.807c-.2 0-.39-.082-.532-.227a.785.785 0 010-1.094.742.742 0 01.532-.226h2.306v-.774c0-.205.079-.402.22-.547a.741.741 0 01.531-.227c.2 0 .391.082.532.227.141.145.22.342.22.547v.773h2.276c.2 0 .39.082.532.227a.785.785 0 010 1.094.741.741 0 01-.532.227v-.042z" />
      </g>
    );
  }
);
LanguageBold.displayName = 'LanguageBold';
