import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ShoppingCartAddItemBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#shopping-cart-add-item-bold_svg__a)">
          <path d="M18 11.67a6 6 0 100 12 6 6 0 000-12zm2 6.76h-1.19v1.36a.75.75 0 11-1.5 0v-1.37H16a.75.75 0 110-1.5h1.29v-1.33a.75.75 0 111.5 0v1.33H20a.75.75 0 110 1.5v.01zM8.12 22.62a1.76 1.76 0 100-3.52 1.76 1.76 0 000 3.52z" />
          <path d="M19.76 10.95h.09a1 1 0 001-.72l1-3.32A1.84 1.84 0 0020 4.56H4.88l-.57-3A1.78 1.78 0 002.59.15H1.05a1 1 0 000 2h1.36l2.86 15.06a1.84 1.84 0 001.8 1.49h3a1 1 0 100-2H7.21l-.36-1.88h4a1 1 0 00.83-.48 7.69 7.69 0 018.08-3.39z" />
        </g>
        <defs>
          <clipPath id="shopping-cart-add-item-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
ShoppingCartAddItemBold.displayName = 'ShoppingCartAddItemBold';
