import { IModalBodyProps, ModalBody as ModalBodyInternal } from './ModalBody';
import {
  IModalCloseButtonProps,
  ModalCloseButton as ModalCloseButtonInternal,
} from './ModalCloseButton';
import { IModalContentProps, ModalContent as ModalContentInternal } from './ModalContent';
import { IModalFooterProps, ModalFooter as ModalFooterInternal } from './ModalFooter';
import { IModalHeaderProps, ModalHeader as ModalHeaderInternal } from './ModalHeader';
import { IModalOverlayProps, ModalOverlay as ModalOverlayInternal } from './ModalOverlay';

export * from './Modal';
export {
  IModalBodyProps,
  IModalContentProps,
  IModalCloseButtonProps,
  IModalFooterProps,
  IModalHeaderProps,
  IModalOverlayProps,
};

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Modal.Body` instead
 */
export const ModalBody = ModalBodyInternal;
/**
 * @deprecated use `Modal.CloseButton` instead
 */
export const ModalCloseButton = ModalCloseButtonInternal;
/**
 * @deprecated use `Modal.Content` instead
 */
export const ModalContent = ModalContentInternal;
/**
 * @deprecated use `Modal.Footer` instead
 */
export const ModalFooter = ModalFooterInternal;
/**
 * @deprecated use `Modal.Header` instead
 */
export const ModalHeader = ModalHeaderInternal;
/**
 * @deprecated use `Modal.Overlay` instead
 */
export const ModalOverlay = ModalOverlayInternal;
