import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { CheckTickBold } from '@lego/klik-icons-react';
import { colors, spacing, font } from '../../config';

const dropDownItemStyle = (isSelected) =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    padding: `${spacing.size1}px ${spacing.size3}px`,
    fontSize: font.size.sizeM1,
    userSelect: 'none',
    backgroundColor: isSelected
      ? colors.boldColors.lightBlue.lightBlue05
      : colors.neutralColors.white,
    '&:hover': {
      backgroundColor: colors.neutralColors.slate05,
    },
  });

const isSelectedIconStyle = css({
  width: 12,
  height: 12,
});

const DropDownItem = ({ label, value, isSelected = false, onClick }) => {
  const onClickHandler = useCallback(
    (event) => {
      event.stopPropagation();
      if (onClick) onClick(value);
    },
    [value, onClick]
  );

  return (
    <div css={dropDownItemStyle(isSelected)} onClick={onClickHandler}>
      {label}
      {isSelected && <CheckTickBold css={isSelectedIconStyle} />}
    </div>
  );
};

DropDownItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export { DropDownItem };
