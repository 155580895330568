import { mode } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['container', 'track', 'thumb'];

const baseStyleTrack = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    borderRadius: 'full',
    p: '1px',
    width: 'var(--slider-track-width)',
    height: 'var(--slider-track-height)',
    transition: 'all 120ms',
    border: '1px solid',
    borderColor: 'slate.300',
    boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)',
    bg: mode('slate.100', 'slate.50')(props),
    _focus: {
      outline: 'none',
      boxShadow: 'focusVisible',
    },
    _disabled: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    _checked: {
      borderColor: mode(`${c}.700`, `${c}.100`)(props),
      bg: mode(`${c}.500`, `${c}.200`)(props),
    },
  };
};

const baseStyleThumb = {
  bg: 'white',
  boxShadow: '1px 0px 0px rgba(0, 0, 0, 0.1)',
  transition: 'transform 250ms',
  borderRadius: 'inherit',
  width: 'var(--slider-track-height)',
  height: 'var(--slider-track-height)',
  _checked: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    transform: 'translateX(var(--slider-thumb-x))',
  },
};

const baseStyle = (props: Dict) => ({
  container: {
    height: '6',
    display: 'block',
    '--slider-track-diff': 'calc(var(--slider-track-width) - var(--slider-track-height))',
    '--slider-thumb-x': 'var(--slider-track-diff)',
    _rtl: {
      '--slider-thumb-x': 'calc(-1 * var(--slider-track-diff))',
    },
  },
  track: baseStyleTrack(props),
  thumb: baseStyleThumb,
});

const sizes = {
  md: {
    container: {
      '--slider-track-width': '2.75rem',
      '--slider-track-height': '1.25rem',
    },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'light-blue',
};

export const Switch = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
