import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StatusWarning = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 25', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.775 20.727L14.302 1.424C13.993.807 13.48.344 12.827.138a2.58 2.58 0 00-1.957.12 2.56 2.56 0 00-1.166 1.166L.23 20.727a2.271 2.271 0 001.03 3.034c.309.154.652.24.995.24h19.477a2.262 2.262 0 002.265-2.263 2.035 2.035 0 00-.223-1.011zm-1.493 1.543a.794.794 0 01-.55.223H2.257a.79.79 0 01-.704-1.114L11.06 2.091c.103-.205.275-.377.48-.48.155-.068.31-.102.464-.102.395 0 .772.222.944.582l9.49 19.303a.781.781 0 01.085.343c-.017.206-.086.395-.24.532z" />
        <path d="M11.57 16.119h1.153l.252-8.118h-1.676l.27 8.118zm.576 3.96c.63 0 1.135-.468 1.135-1.134 0-.666-.504-1.134-1.135-1.134-.613 0-1.135.468-1.135 1.134 0 .666.523 1.134 1.135 1.134z" />
      </g>
    );
  }
);
StatusWarning.displayName = 'StatusWarning';
