import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Overlay, Button, Modal } from '@lego/klik-react';
import { EmailUnreadBold } from '@lego/klik-icons-react';
import { font, spacing } from '../../config';
import { useQuery, useMutation } from '@apollo/client';
import { userStatusType } from '../../constants/userStatusTypes';
import {
  GET_USER_CONFIRMATION_STATUS,
  SEND_INVITATION_EMAIL,
} from '../../queries/assessment';
import { generalApiErrorHandler } from '../../utils/generalApiErrorHandler';
import { useParams } from 'react-router-dom';
import {
  addSnackbar,
  snackbarDefaultMessages,
  snackbarTypes,
} from '../../utils/snackbar';
import { logger } from '../../utils/logger';
import { Spinner } from '../spinner/Spinner';
import { Link } from '@lego/klik-ui';

const modalHeaderStyle = css({
  lineHeight: 'normal',
  fontSize: font.size.size1,
  fontWeight: font.weight.medium,
});

const bodyStyle = css({
  paddingTop: spacing.size8,
});

const canResendEmail = (created, confirmationStatus, isLinkedToCustomer) => {
  return (
    created &&
    (confirmationStatus == userStatusType.CONFIRMED ||
      confirmationStatus == userStatusType.FORCE_CHANGE_PASSWORD) &&
    isLinkedToCustomer
  );
};

const EmailInfoBody = ({
  created,
  isLinkedToCustomer,
  customerEmail,
  confirmationStatus,
}) => {
  if (!created) {
    return <p>User has not been created yet - Please contact support</p>;
  }

  if (confirmationStatus == userStatusType.FORCE_CHANGE_PASSWORD) {
    return (
      <p>
        This will send a new temporary password to <b>{customerEmail}</b>.
      </p>
    );
  }

  if (confirmationStatus != userStatusType.CONFIRMED) {
    return (
      <p>
        Something went wrong during the creation of this user account. Please
        contact support.
      </p>
    );
  }

  if (!isLinkedToCustomer) {
    return (
      <p>
        Email <b>{customerEmail}</b> is no longer linked to this customer.
        <br />
        Please make sure that SAP Master Data is up to date.
      </p>
    );
  }

  return (
    <p>
      This will resend an invitation to <b>{customerEmail}</b>.
      <br />
      <br />
      This customer already has a valid account.
      <br />
      They can access the survey or reset their password by visiting the login
      page at{' '}
      <Link isExternal isInline href="https://survey.mcp.b2b.lego.com">
        {' '}
        survey.mcp.b2b.lego.com
      </Link>
      .
    </p>
  );
};

const EmailInfoModal = ({ customerEmail, customerId, onClose }) => {
  const { assessmentYear, version } = useParams();

  const {
    data: userStatus,
    loading: isUserStatusLoading,
    error: getUserStatusError,
  } = useQuery(GET_USER_CONFIRMATION_STATUS, {
    variables: {
      customerEmail,
      customerId,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      onClose();
    },
  });

  const handleResendEmailResponse = (data) => {
    const { sendInvitationEmail } = data;

    if (!sendInvitationEmail?.wasEmailSent) {
      logger.logError('Failed to send reminder email');
      showErrorSnackbar(
        `Failed to send reminder email to ${customerEmail} at ${data.sendInvitationEmail}.`
      );
      return;
    }

    addSnackbar({
      header: snackbarDefaultMessages.successHeader,
      text: `An invitation email has been sent to ${customerEmail}`,
      type: snackbarTypes.SUCCESS,
    });
    onClose();
  };

  const [resendEmail, { loading: isResendEmailLoading }] = useMutation(
    SEND_INVITATION_EMAIL,
    {
      variables: {
        customerId,
        year: Number(assessmentYear),
        version: Number(version),
        customerEmail,
      },
      onCompleted: handleResendEmailResponse,
      onError: (error) => {
        generalApiErrorHandler(error, error.message);
      },
    }
  );

  const showErrorSnackbar = (text) => {
    addSnackbar({
      header: snackbarDefaultMessages.errorHeader,
      text,
      type: snackbarTypes.ERROR,
    });
  };

  async function handleResendEmail() {
    try {
      await resendEmail({
        variables: {
          customerId,
          year: Number(assessmentYear),
          version: Number(version),
          customerEmail,
        },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  const { created, confirmationStatus, linkToCustomer } =
    userStatus?.getUserConfirmationStatus || {};
  const isLinkedToCustomer = linkToCustomer?.isLinkedToCustomer;

  if (isUserStatusLoading || isResendEmailLoading)
    return (
      <Overlay isOpen={true}>
        <Spinner />
      </Overlay>
    );

  return (
    <Overlay isOpen={true} onClick={onClose}>
      <Modal
        status={
          !canResendEmail(created, confirmationStatus, isLinkedToCustomer)
            ? 'error'
            : 'information'
        }
        icon={<EmailUnreadBold />}
        heading={
          <Modal.Heading css={modalHeaderStyle}>
            Resend invitation email
          </Modal.Heading>
        }
        body={
          <Modal.Body>
            <div css={bodyStyle}>
              {!isUserStatusLoading && !getUserStatusError && (
                <EmailInfoBody
                  created={created}
                  isLinkedToCustomer={isLinkedToCustomer}
                  customerEmail={customerEmail}
                  confirmationStatus={confirmationStatus}
                />
              )}
            </div>
          </Modal.Body>
        }
        action={
          <Modal.Action>
            <Button
              aria-label="Confirm action"
              data-transaction-name="Resend Invitation"
              label="Resend invitation"
              title="Resend invitation email to user"
              onClick={() => {
                handleResendEmail();
              }}
              disabled={
                isUserStatusLoading ||
                isResendEmailLoading ||
                !canResendEmail(created, confirmationStatus, isLinkedToCustomer)
              }
            />
            <Button
              aria-label="Close modal"
              label="Cancel"
              variant="outline"
              onClick={onClose}
            />
          </Modal.Action>
        }
        exitClickHandler={onClose}
      />
    </Overlay>
  );
};

EmailInfoBody.propTypes = {
  created: PropTypes.bool.isRequired,
  isLinkedToCustomer: PropTypes.bool.isRequired,
  customerEmail: PropTypes.string.isRequired,
  confirmationStatus: PropTypes.string.isRequired,
};

EmailInfoModal.propTypes = {
  customerEmail: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { EmailInfoModal };
