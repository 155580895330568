import { Popover as PopoverComponent, PopoverProps } from '@chakra-ui/popover';
import * as React from 'react';
import { PopoverArrow } from './PopoverArrow';
import { PopoverBody } from './PopoverBody';
import { PopoverCloseButton } from './PopoverCloseButton';
import { PopoverContent } from './PopoverContent';
import { PopoverFooter } from './PopoverFooter';
import { PopoverHeader } from './PopoverHeader';
import { PopoverTrigger } from './PopoverTrigger';

export type IPopoverProps = PopoverProps;

type IPopoverComponent = React.FunctionComponent<IPopoverProps> & {
  Arrow: typeof PopoverArrow;
  Body: typeof PopoverBody;
  CloseButton: typeof PopoverCloseButton;
  Content: typeof PopoverContent;
  Footer: typeof PopoverFooter;
  Header: typeof PopoverHeader;
  Trigger: typeof PopoverTrigger;
};

export const Popover: IPopoverComponent = ({ autoFocus = false, ...rest }) => {
  return <PopoverComponent autoFocus={autoFocus} {...rest} />;
};

Popover.Arrow = PopoverArrow;
Popover.Body = PopoverBody;
Popover.CloseButton = PopoverCloseButton;
Popover.Content = PopoverContent;
Popover.Footer = PopoverFooter;
Popover.Header = PopoverHeader;
Popover.Trigger = PopoverTrigger;
