import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { font, colors } from '../config';

const dateFormatStyle = css({
  fontSize: font.size.sizeM2,
  verticalAlign: 'super',
  fontStyle: 'italic',
  color: colors.neutralColors.slate60,
});

const AssessmentDateLabel = ({ label, dateFormat }) => (
  <span>
    {label} <br />
    <span css={dateFormatStyle}>{` ${dateFormat}`}</span>
  </span>
);

AssessmentDateLabel.propTypes = {
  label: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export { AssessmentDateLabel };
