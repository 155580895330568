import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudUploadDownload = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M8.85 13.199v8.982a.779.779 0 001.094.804.78.78 0 00.455-.804V13.2l1.78 1.779a.78.78 0 101.1-1.098l-3.1-3.097a.77.77 0 00-.55-.285.77.77 0 00-.538.22l-3.11 3.162a.78.78 0 001.098 1.098l1.77-1.78zm7.045-2.207a.78.78 0 01.78.78v8.982l1.781-1.78a.779.779 0 011.151-.051.78.78 0 01-.052 1.15l-3.132 3.096a.77.77 0 01-.506.231.77.77 0 01-.539-.23l-3.1-3.097a.78.78 0 011.1-1.098l1.78 1.779V11.77a.78.78 0 01.737-.78z" />
        <path d="M3.958 16.438h-.22c-1.528-.45-4.265-2.075-3.65-5.303a4.764 4.764 0 014.386-3.932 8.486 8.486 0 012.704-4.886 7.523 7.523 0 018.794-.56 7.866 7.866 0 012.858 2.954 5.916 5.916 0 015.144 5.216 6.43 6.43 0 01-3.045 6.061.826.826 0 01-.846-1.416 4.789 4.789 0 002.198-4.459 4.39 4.39 0 00-4.023-3.821 1.034 1.034 0 01-.835-.604 6.03 6.03 0 00-2.352-2.536 5.951 5.951 0 00-6.837.395 6.97 6.97 0 00-2.199 4.392.966.966 0 01-.945.857 3.3 3.3 0 00-3.375 2.624c-.472 2.482 2.199 3.294 2.474 3.371a.824.824 0 01-.22 1.614l-.011.033z" />
      </g>
    );
  }
);
CloudUploadDownload.displayName = 'CloudUploadDownload';
