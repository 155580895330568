import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const EmailUnreadBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M11.118 14.58a1.25 1.25 0 001.77 0l9.679-9.68a.5.5 0 00.12-.51c-.11-.33-.42-.39-.69-.39H2c-.27 0-.58 0-.69.36a.5.5 0 00.12.51l9.688 9.709z" />
        <path d="M23.997 5.93a.18.18 0 00-.28 0l-9.749 9.74a2.75 2.75 0 01-3.89 0L.31 5.9a.18.18 0 00-.2 0A.18.18 0 000 6v12a2 2 0 002 1.999h19.997a2 2 0 002-2V6.001a.183.183 0 000-.07z" />
      </g>
    );
  }
);
EmailUnreadBold.displayName = 'EmailUnreadBold';
