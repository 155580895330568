import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFacelessMale1Bold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.202 18.82c-.84-.84-1.93-1.27-3.25-1.27H5.842c-3.65 0-4.62 3.52-4.62 5.39 0 .55.45 1 1 1s1-.45 1-1c0-.35.1-3.39 2.62-3.39h12.11c2.73 0 2.81 3.04 2.81 3.38 0 .55.44 1 1 1 .55 0 1-.44 1-1 .01-.09 0-2.54-1.56-4.11zm-15.97-6.71v.49c0 2.09 1.7 3.79 3.79 3.79h5.34c2.09 0 3.79-1.7 3.79-3.79v-.76c.68-.46 1.23-1.31 1.6-2.54.5-1.62.83-4.63-.39-5.85-.16-.16.09-.6.2-.78.31-.49.33-1.1.05-1.61s-.8-.83-1.38-.83c-.48 0-.99-.04-1.53-.07-2.18-.14-4.88-.32-8.1.9-1.14.41-2.76 1.94-3.25 2.59-.06.02-.18.06-.38.07h-.11c-1.32.06-2.04.61-2.13 1.64-.31 3.22.08 5.25 1.18 6.21.48.42.97.53 1.32.54zm10.93.49c0 .99-.81 1.79-1.79 1.79h-5.35c-.99 0-1.79-.8-1.79-1.79l-.01-4c.02-.03.27-.26 1.65-.26 1.24-.01 2.52-.27 3.79-.78l1.88-.75c.91.09 1.62.86 1.62 1.79v4zM4.702 5.74c.08-.01.18-.01.24-.01l.14-.01c.91-.05 1.6-.39 1.88-.89.25-.34 1.64-1.65 2.36-1.92 2.81-1.06 5.18-.91 7.26-.77l.98.06c-.61 1.41.05 2.32.39 2.66.24.27.37 1.54.12 2.93a3.795 3.795 0 00-3.7-2.98h-.19l-2.25.9c-1.06.41-2.09.62-3.07.63-.9 0-3.63.02-3.63 2.26v1.46c-.22-.2-.82-1.04-.53-4.32z" />
      </g>
    );
  }
);
LegoUserFacelessMale1Bold.displayName = 'LegoUserFacelessMale1Bold';
