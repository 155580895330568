import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudFolderBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#cloud-folder-bold_svg__a)">
          <path d="M18.773 4.698a.25.25 0 01-.195-.119A7.58 7.58 0 0010.752.8a7.802 7.802 0 00-6.257 6.153.25.25 0 01-.227.206A4.62 4.62 0 00.63 9.498a4.781 4.781 0 00-.628 2.546 4.552 4.552 0 001.234 3.25 4.837 4.837 0 002.565 1.322.973.973 0 00.834-.217 1.083 1.083 0 00.368-.802 1.008 1.008 0 00-.79-1.007 2.932 2.932 0 01-1.57-.759 2.525 2.525 0 01-.638-1.928 2.645 2.645 0 012.576-2.676c.178 0 .356.018.53.054a.962.962 0 00.812-.195 1.083 1.083 0 00.39-.812c.086-5.96 8.291-7.65 10.824-2.167a.975.975 0 00.942.596 3.678 3.678 0 013.01 1.419 4.14 4.14 0 01.887 2.914 3.588 3.588 0 01-1.559 2.817 1.019 1.019 0 00-.422.845 1.084 1.084 0 00.53.91.962.962 0 001.029-.054 5.676 5.676 0 002.435-4.637 5.919 5.919 0 00-1.374-4.147 5.909 5.909 0 00-3.843-2.072z" />
          <path d="M20.028 17.72H11.12c-.595 0-.931 0-1.083.402 0 .13-.097.281-.173.476l-.887 2.698a.271.271 0 01-.26.184h-.563a.27.27 0 01-.173-.347l.898-2.73c.076-.195.13-.346.173-.466.26-.769.433-1.224 1.58-1.224h7.372v-2.09a.672.672 0 00-.671-.673h-5.412v-.671a.672.672 0 00-.671-.672H6.584a.67.67 0 00-.67.672v8.374A1.67 1.67 0 007.406 23.3h10.695a1.73 1.73 0 001.688-1.343l.866-3.424a.662.662 0 00-.352-.74.681.681 0 00-.276-.072z" />
        </g>
        <defs>
          <clipPath id="cloud-folder-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
CloudFolderBold.displayName = 'CloudFolderBold';
