import { forwardRef } from '@chakra-ui/system';
import { TableColumnHeaderProps, Th as TableColumnHeaderComponent } from '@chakra-ui/table';
import { dataAttr } from '@chakra-ui/utils';
import * as React from 'react';

export interface ITableColumnHeaderProps extends TableColumnHeaderProps {
  /* determines whether this column will be sortable */
  isSortable?: boolean;
  /* determines whether this column is sorted */
  isSorted?: boolean;
  /* determines whether this column is expandable */
  isExpandable?: boolean;
  /* determines whether this column is expanded */
  isExpanded?: boolean;
}

export const TableColumnHeader = forwardRef<ITableColumnHeaderProps, 'th'>(
  (
    {
      isTruncated = true,
      isSortable = false,
      isSorted = false,
      isExpandable = false,
      isExpanded = false,
      ...rest
    },
    ref
  ) => {
    return (
      <TableColumnHeaderComponent
        data-expandable={dataAttr(isExpandable)}
        data-expanded={dataAttr(isExpanded)}
        data-sortable={dataAttr(isSortable)}
        data-sorted={dataAttr(isSorted)}
        isTruncated={isTruncated}
        {...rest}
        ref={ref}
      />
    );
  }
);
