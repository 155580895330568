import { mode } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const baseStyle = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    display: 'inline-block',
    transition: `all 0.15s ease-out`,
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
    color: mode(`${c}.500`, 'white')(props),
    fontWeight: 'medium',
    _hover: {
      color: mode(`${c}.600`, 'white')(props),
      textDecoration: 'underline',
      textDecorationThickness: '1px',
    },
    _active: {
      color: `${c}.600`,
    },
    _focusVisible: {
      boxShadow: 'focusVisible',
    },
  };
};

const sizes = {
  sm: {
    textStyle: 'sm',
  },
  md: {
    textStyle: 'md',
  },
  lg: {
    textStyle: 'lg',
  },
};

const variantInline = {
  _hover: {
    textUnderlineOffset: '1px',
  },
};

const variantStandalone = {
  py: '1',
  _hover: {
    textUnderlineOffset: '4px',
  },
};

const variants = {
  inline: variantInline,
  standalone: variantStandalone,
};

const defaultProps = {
  variant: 'standalone',
  colorScheme: 'light-blue',
  size: 'md',
};

export const Link = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
