import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LogIn = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M21.793 1l.04.001a2.324 2.324 0 011.574.711c.401.422.613.977.591 1.546v17.485a2.125 2.125 0 01-.59 1.545 2.325 2.325 0 01-1.615.712H9.297a2.325 2.325 0 01-1.615-.712 2.125 2.125 0 01-.592-1.546V16.38c0-.432.367-.782.819-.782.451 0 .818.35.818.782v4.38l-.001.035a.6.6 0 00.167.442.657.657 0 00.429.2h12.446a.656.656 0 00.428-.2.6.6 0 00.166-.478V3.242l.001-.036a.6.6 0 00-.167-.442.656.656 0 00-.428-.2H9.322a.657.657 0 00-.429.2.6.6 0 00-.166.478V7.62c0 .432-.367.782-.818.782-.452 0-.819-.35-.819-.782V3.257a2.125 2.125 0 01.592-1.545A2.325 2.325 0 019.297 1h12.496z" />
      <path d="M12.457 7.802c-.32.305-.32.8 0 1.106l2.421 2.314H.818c-.452 0-.818.35-.818.782 0 .432.366.782.818.782h14.06L12.457 15.1c-.32.306-.32.8 0 1.106a.845.845 0 001.157 0l3.814-3.646a.797.797 0 00.177-.246.751.751 0 00-.173-.863L13.614 7.8a.845.845 0 00-1.157 0z" />
    </g>
  );
});
LogIn.displayName = 'LogIn';
