import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowLeftBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M16.006.388a1.5 1.5 0 01.106 2.118L7.522 12l8.59 9.494a1.5 1.5 0 01-2.224 2.012l-9.5-10.5a1.5 1.5 0 010-2.012l9.5-10.5a1.5 1.5 0 012.118-.106z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowLeftBold.displayName = 'ArrowLeftBold';
