import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LayoutListBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M3.13 1a3.13 3.13 0 100 6.26 3.13 3.13 0 000-6.26zm0 8.018a3.13 3.13 0 100 6.26 3.13 3.13 0 000-6.26zm-1.198 8.256a3.13 3.13 0 013.41 5.105 3.13 3.13 0 11-3.41-5.105zM8.761 3.298a1.5 1.5 0 000 3h13.364a1.5 1.5 0 100-3H8.761zm-1.5 9.518a1.5 1.5 0 011.5-1.5h13.364a1.5 1.5 0 110 3H8.761a1.5 1.5 0 01-1.5-1.5zm1.5 6.518a1.5 1.5 0 000 3h13.364a1.5 1.5 0 100-3H8.761z" />
      </g>
    );
  }
);
LayoutListBold.displayName = 'LayoutListBold';
