import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Cross = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M2.53 1.47a.75.75 0 00-1.06 1.06L10.94 12l-9.47 9.47a.75.75 0 101.06 1.06L12 13.06l9.47 9.47a.75.75 0 101.06-1.06L13.06 12l9.47-9.47a.75.75 0 00-1.06-1.06L12 10.94 2.53 1.47z" />
    </g>
  );
});
Cross.displayName = 'Cross';
