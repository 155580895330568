import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesResponsiveWebSite = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M0 .75A.75.75 0 01.75 0h7.5A.75.75 0 019 .75v12a.75.75 0 01-.75.75H.75a.75.75 0 01-.75-.75v-12zm1.5 9.75V12h6v-1.5h-6zm6-1.5h-6V1.5h6V9z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M10.5 5.25a.75.75 0 01.75-.75h10.5A2.25 2.25 0 0124 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-5.115l.5 3h1.615a.75.75 0 010 1.5H8.25a.75.75 0 010-1.5h1.615l.5-3H5.25A2.25 2.25 0 013 17.25v-1.5a.75.75 0 01.75-.75H22.5V6.75a.75.75 0 00-.75-.75h-10.5a.75.75 0 01-.75-.75zm12 11.25h-18v.75a.75.75 0 00.75.75h16.5a.75.75 0 00.75-.75v-.75zm-10.615 3h3.23l.5 3h-4.23l.5-3z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
DevicesResponsiveWebSite.displayName = 'DevicesResponsiveWebSite';
