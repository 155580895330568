import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFacelessMale2Bold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.202 18.753c-.84-.84-1.93-1.27-3.25-1.27H5.842c-3.66 0-4.62 3.52-4.62 5.38 0 .55.45 1 1 1s1-.45 1-1c0-.35.1-3.39 2.62-3.39h12.11c2.73 0 2.81 3.04 2.81 3.38 0 .55.44 1 1 1 .55 0 1-.44 1-1 .01-.08 0-2.53-1.56-4.1zm-15.67-6.22c0 2.09 1.7 3.79 3.79 3.79h5.34c2.09 0 3.79-1.7 3.79-3.79v-1.3c.56-.94.89-1.81 1.03-2.73.63-2.52.3-4.54-.98-6-2.29-2.63-6.79-2.46-8.61-2.27-2.23.24-3.16 1.08-3.49 1.95-1.02-.04-2.06.48-2.5 1.69-1.01 2.78.98 6.34 1.63 7.38v1.28zm10.93 0c0 .99-.8 1.79-1.79 1.79h-5.34c-.99 0-1.79-.81-1.79-1.79v-4c0-.93.71-1.7 1.62-1.79l1.88.75c1.28.51 2.55.77 3.79.78 1.38.01 1.63.23 1.64.26v4h-.01zm-10.68-7.97c.19-.52.71-.37.82-.33.38.13.8.08 1.12-.15.32-.22.42-.6.52-.98.2-.74 1.02-.79 1.87-.88 2.44-.26 5.53.02 6.89 1.59.65.75.89 1.8.73 3.16-.87-.68-2.3-.69-2.9-.7-.99 0-2.02-.22-3.06-.64l-2.25-.9h-.19c-1.56 0-2.9.95-3.48 2.3-.24-.87-.33-1.76-.07-2.47z" />
      </g>
    );
  }
);
LegoUserFacelessMale2Bold.displayName = 'LegoUserFacelessMale2Bold';
