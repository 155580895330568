import { useLayoutEffect, useCallback } from 'react';

const useClickOutsideElement = (focusedElement, clickOutsideCallback) => {
  const detectClickOutside = useCallback(
    (event) => {
      if (Array.isArray(focusedElement)) {
        const res = focusedElement.map((elm) => {
          if (elm.current) {
            if (elm.current.contains(event.target)) {
              return true;
            }
          }

          return false;
        });

        // If none of the elements were clicked call callback
        if (res.every((item) => !item)) {
          clickOutsideCallback(event);
        }

        return;
      }

      if (focusedElement.current) {
        if (!focusedElement.current.contains(event.target)) {
          clickOutsideCallback(event);
        }
      }
    },
    [focusedElement, clickOutsideCallback]
  );

  useLayoutEffect(() => {
    document.addEventListener('mousedown', detectClickOutside);
    document.addEventListener('touchstart', detectClickOutside);

    return () => {
      document.removeEventListener('mousedown', detectClickOutside);
      document.removeEventListener('touchstart', detectClickOutside);
    };
  }, [focusedElement, detectClickOutside]);
};

export { useClickOutsideElement };
