import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Video = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M9.41 17.39c-.14 0-.27-.03-.39-.1a.806.806 0 01-.4-.69V7.31c0-.14.04-.28.12-.41a.79.79 0 01.31-.29c.13-.07.27-.1.41-.09.14.01.28.05.4.13l7.18 4.9c.11.07.2.17.26.29.06.12.09.25.09.38s-.04.26-.11.38-.16.21-.27.28l-7.18 4.39c-.13.07-.27.11-.42.12zm.79-8.57v6.35l4.92-3-4.92-3.35z" />
      <path d="M22.2 1.5c.17 0 .3.14.3.3v20.4a.3.3 0 01-.3.3H1.8a.3.3 0 01-.3-.3V1.8c0-.17.14-.3.3-.3h20.4zm0-1.5H1.8C.81 0 0 .81 0 1.8v20.4c0 1 .81 1.8 1.8 1.8h20.4c1 0 1.8-.81 1.8-1.8V1.8c0-.99-.81-1.8-1.8-1.8z" />
    </g>
  );
});
Video.displayName = 'Video';
