import { AvatarBadge as AvatarBadgeInternal, IAvatarBadgeProps } from './AvatarBadge';

export * from './Avatar';
export { IAvatarBadgeProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Avatar.Badge` instead
 */
export const AvatarBadge = AvatarBadgeInternal;
