import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFacelessFemale2 = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.14 20.19A5.621 5.621 0 0019.91 18a3.721 3.721 0 00-1.69-.4H6c-3.46.15-4.37 3.68-4.37 5.52a.75.75 0 00.75.75.73.73 0 00.75-.75c0-.16.05-3.9 2.91-4h12.19a2.29 2.29 0 011 .22 4.1 4.1 0 011.62 1.59c.37.653.54 1.4.49 2.15a.749.749 0 00.7.8h.05a.75.75 0 00.75-.7 5.33 5.33 0 00-.7-2.99zM18.53 8A3.58 3.58 0 0015 4.37a.75.75 0 00-.28.05l-2.1.84a8.75 8.75 0 01-3.2.66C8.53 5.93 6 5.94 6 8v4a3.58 3.58 0 003.58 3.58H15A3.58 3.58 0 0018.53 12V8zM17 12a2.08 2.08 0 01-2 2.09H9.54A2.08 2.08 0 017.46 12V8c0-.43 1-.52 1.92-.53a10.24 10.24 0 003.75-.77l1.74-.7a.84.84 0 01.46-.06A2.09 2.09 0 0117 8v4z" />
        <path d="M5.73 15.87c.11.018.205.034.308.018a.75.75 0 00.256-1.396c-.09-.05-.184-.062-.294-.092l-1.885-.545S3.088 13.476 4 12.7a3.06 3.06 0 001.06-2.65l-.22-2.46a5.3 5.3 0 01.53-2.29 5.67 5.67 0 011.5-1.9 7.8 7.8 0 015-1.78c1.29 0 3.58.26 4.38 2a.75.75 0 001 .38 2.1 2.1 0 011.11-.16 1.46 1.46 0 011.06 1 6.44 6.44 0 01-.07 2.56s-.675 3.135 1.247 5.45c0 0 .38.648-.14.928 0 0-.907.402-2.057.662a.75.75 0 00.16 1.48h.16a11.75 11.75 0 002.28-.67c.506-.185.941-.466 1.172-.954.111-.241.148-.6.148-.866a2.43 2.43 0 00-.7-1.43c-1.43-1.69-.83-4.34-.83-4.34a6.82 6.82 0 000-3.38 2.89 2.89 0 00-2.17-1.9 3.25 3.25 0 00-1.31 0C16.3.93 14.4.11 11.86.11a9.31 9.31 0 00-6 2.13A7.16 7.16 0 004 4.65a6.84 6.84 0 00-.68 3l.24 2.46a2.34 2.34 0 01-.168 1.033c-.134.328-.252.647-.514.884-.263.236-.326.319-.563.57-.294.416-.3.541-.338.829-.001.27.031.511.144.755.234.47.577.784 1.074.955l2.535.734z" />
      </g>
    );
  }
);
LegoUserFacelessFemale2.displayName = 'LegoUserFacelessFemale2';
