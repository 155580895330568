import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const History = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M17.987 10.543v-.789h1.508A.76.76 0 0020.248 9a.76.76 0 00-.753-.754h-4.506a.76.76 0 00-.754.754.76.76 0 00.754.754h1.508v.789c-3.375.377-5.996 3.24-5.996 6.703 0 3.72 3.032 6.754 6.75 6.754C20.967 24 24 20.966 24 17.246a6.782 6.782 0 00-6.013-6.703zm-.754 11.948a5.244 5.244 0 01-5.242-5.245A5.244 5.244 0 0117.233 12a5.244 5.244 0 015.242 5.246 5.244 5.244 0 01-5.242 5.245z" />
      <path d="M19.272 17.246h-1.285v-3a.76.76 0 00-.754-.755.76.76 0 00-.753.755V18a.76.76 0 00.753.754h2.039a.76.76 0 00.754-.754.76.76 0 00-.754-.754zM8.24 15.754H1.884a.38.38 0 01-.377-.377V5.246h17.234V6a.76.76 0 00.754.754.76.76 0 00.753-.754V1.869C20.231.84 19.392 0 18.381 0H1.884A1.865 1.865 0 000 1.869v13.525a1.898 1.898 0 001.884 1.869h6.373a.76.76 0 00.754-.754c0-.412-.36-.755-.771-.755zM1.867 1.51h16.48c.205 0 .377.171.377.36v1.885H1.507V1.87c0-.206.155-.36.36-.36z" />
    </g>
  );
});
History.displayName = 'History';
