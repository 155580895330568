import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FaqBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M2.25 0A2.25 2.25 0 000 2.25v15a2.25 2.25 0 002.25 2.25H4.5v3.75a.75.75 0 001.2.6l5.8-4.35h10.25A2.25 2.25 0 0024 17.25v-15A2.25 2.25 0 0021.75 0H2.25zm10.759 11.094h-1.88a1.996 1.996 0 01-.182-.792c0-.66.495-1.22 1.303-1.864.676-.561.973-.858.973-1.386 0-.594-.462-1.006-1.039-1.006-.61 0-1.056.429-1.37 1.253L9 6.508C9.478 4.825 10.7 4 12.25 4c1.798 0 3.25 1.138 3.25 2.887 0 .973-.462 1.617-1.37 2.409l-.176.153c-.57.495-.978.849-.978 1.299 0 .131.016.247.033.346zm.66 2.656c0 .874-.66 1.534-1.535 1.534-.89 0-1.55-.66-1.55-1.534 0-.858.66-1.534 1.55-1.534a1.51 1.51 0 011.535 1.534z"
        fillRule="evenodd"
      />
    </g>
  );
});
FaqBold.displayName = 'FaqBold';
