import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MessageBubbleBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M2.25 0A2.25 2.25 0 000 2.25v15a2.25 2.25 0 002.25 2.25H4.5v3.75a.75.75 0 001.2.6l5.8-4.35h10.25A2.25 2.25 0 0024 17.25v-15A2.25 2.25 0 0021.75 0H2.25z" />
      </g>
    );
  }
);
MessageBubbleBold.displayName = 'MessageBubbleBold';
