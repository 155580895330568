import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesQueryFileBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M16.297.29L21.71 5.7a1 1 0 01.29.71V22a2 2 0 01-2.001 2H4.001A2.001 2.001 0 012 22V2a2 2 0 012.001-2h11.596a1 1 0 01.7.29zM19.51 22a.5.5 0 00.5-.5V7a.5.5 0 00-.15-.35l-4.532-4.5a.5.5 0 00-.32-.15H4.5a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h15.01z" />
        <path d="M20.22 5.67l-3.953-3.88-12.896-.26v21.11h17.008l-.16-16.97zM8.072 13.19a2.08 2.08 0 01.93 1.67V16c0 .17.26.21.42.21h.581a1.001 1.001 0 010 2h-.6a2.282 2.282 0 01-2.4-2.21v-1a.25.25 0 00-.11-.21l-1.461-1a1 1 0 010-1.68l1.46-1a.24.24 0 00.11-.11v-1a2.279 2.279 0 012.382-2.25h.62a1.001 1.001 0 010 2h-.6c-.16 0-.42 0-.42.21v1.17a2.08 2.08 0 01-.881 1.67.24.24 0 000 .38l-.03.01zm10.495.68l-1.71 1c-.08.05-.11.09-.11.15v1a2.37 2.37 0 01-2.632 2.21h-.69a1.001 1.001 0 010-2h.69c.44 0 .63-.16.63-.21v-1.16a2 2 0 01.94-1.66.24.24 0 000-.39 2 2 0 01-.93-1.67V10c0-.05-.19-.21-.63-.21h-.69a1.001 1.001 0 010-2h.69A2.373 2.373 0 0116.757 10v1a.24.24 0 00.1.16l1.711.94a1 1 0 010 1.74v.03z" />
      </g>
    );
  }
);
FilesQueryFileBold.displayName = 'FilesQueryFileBold';
