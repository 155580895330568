import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LockBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M12 .62a6.375 6.375 0 00-6.375 6.375v2H3.75a.75.75 0 00-.75.75v13.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75v-13.5a.75.75 0 00-.75-.75h-1.875v-2A6.375 6.375 0 0012 .62zm4.125 6.375v2h-8.25v-2a4.125 4.125 0 018.25 0zm-5.25 8a1.125 1.125 0 012.25 0v3a1.125 1.125 0 01-2.25 0v-3z"
        fillRule="evenodd"
      />
    </g>
  );
});
LockBold.displayName = 'LockBold';
