import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, spacing } from '../../config';

const containerStyle = css({
  display: 'flex',
});

const dotStyle = (isActive) =>
  css({
    display: 'block',
    width: 10,
    height: 10,
    backgroundColor: isActive
      ? colors.boldColors.lightBlue.lightBlue80
      : colors.neutralColors.slate40,
    borderRadius: 5,
    margin: spacing.size2,
  });

const ModalPageIndicator = ({ numberOfPages, currentPageIndex }) => (
  <div css={containerStyle}>
    {[...Array(numberOfPages)].map((_, index) => {
      return (
        <span
          key={`dot${index}`}
          css={dotStyle(currentPageIndex === index + 1)}
        ></span>
      );
    })}
  </div>
);

ModalPageIndicator.propTypes = {
  numberOfPages: PropTypes.number.isRequired,
  currentPageIndex: PropTypes.number,
};

export { ModalPageIndicator };
