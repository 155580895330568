import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Agreement = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.23 14.455h-2.022a.223.223 0 01-.223-.189V7.894a.223.223 0 01.223-.189h2.038A.76.76 0 0024 6.951c0-.41-.36-.753-.77-.753h-1.988a1.68 1.68 0 00-1.336.582l-4.368-1.593a3.12 3.12 0 00-2.45.137L10.672 6.54s-.017 0-.017-.017a2.863 2.863 0 00-1.696-.548c-.36 0-.72.069-1.062.206L4.454 7.568a1.708 1.708 0 00-1.679-1.353H.754A.735.735 0 000 6.95a.76.76 0 00.754.754h2.021c.103 0 .206.086.223.189v6.355a.243.243 0 00-.034.103.232.232 0 01-.189.103H.754a.76.76 0 00-.754.753.76.76 0 00.754.754h2.038c.531 0 1.028-.257 1.336-.65H5.79l3.272 3.665c.36.428.874.685 1.422.72h.154c.497 0 .977-.172 1.37-.514a2.14 2.14 0 00.703-1.268c.976.017 1.918-.548 2.295-1.508.018-.05.035-.102.052-.154h.051c.052 0 .086 0 .137-.017l4.66-.89c.308.36.77.582 1.285.582h2.038a.76.76 0 00.754-.754c0-.411-.343-.72-.754-.72zM13.755 6.66a1.56 1.56 0 011.268-.068l4.454 1.627v5.705l-2.365.445c.018-.103.035-.223.035-.325 0-.48-.154-.943-.446-1.337l-2.5-3.169.428-.137a.756.756 0 00.48-.942.756.756 0 00-.943-.48l-3.46 1.13c-.189.07-.412-.033-.497-.222a.406.406 0 01.188-.548l3.358-1.679zm-1.32 9.696l-.033-.017-.874-.874a.755.755 0 00-1.062 0 .755.755 0 000 1.063l.754.753v.309a.553.553 0 01-.206.445.564.564 0 01-.428.137.634.634 0 01-.411-.206L6.68 14.043a.764.764 0 00-.565-.256H4.488V9.16L8.48 7.568c.154-.068.325-.103.496-.103.052 0 .103 0 .155.017a1.9 1.9 0 001.456 3.135c.205 0 .394-.034.6-.102l1.524-.497 2.81 3.597c.085.12.136.274.136.428a.725.725 0 01-.205.514.697.697 0 01-.514.206h-.171l-1.25-1.25a.755.755 0 00-1.063 0 .755.755 0 000 1.062l1.2 1.199c0 .017-.018.051-.018.068-.206.48-.72.703-1.2.514z" />
      </g>
    );
  }
);
Agreement.displayName = 'Agreement';
