import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BalloonBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M20.599 22.66c-.993-2.6-3.355-3.97-6.74-3.97-.615 0-.833-.219-.893-.298a1.768 1.768 0 01-.268-1.062 8.417 8.417 0 007.355-8.625C20.053 3.97 16.46 0 12.023 0c-4.437 0-8.02 3.97-8.02 8.715a8.496 8.496 0 006.71 8.546 3.772 3.772 0 00.695 2.372 3.107 3.107 0 002.481 1.052c2.531-.05 4.169.863 4.864 2.68a.993.993 0 00.923.635c.121 0 .242-.02.357-.06a.992.992 0 00.566-1.28zM14.445 4.963a1.29 1.29 0 11.02 2.58 1.29 1.29 0 01-.02-2.58zm-4.963 0a1.29 1.29 0 11-1.29 1.28 1.28 1.28 0 011.32-1.28h-.03zm-.903 6.332a.784.784 0 01.08-1.101.794.794 0 011.101.069 3.702 3.702 0 002.372 1.201 2.878 2.878 0 002.125-1.191.784.784 0 011.101-.09.776.776 0 01.169 1.102 4.367 4.367 0 01-3.305 1.747 5.271 5.271 0 01-3.643-1.737z" />
      </g>
    );
  }
);
BalloonBold.displayName = 'BalloonBold';
