import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesSvg = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-svg_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h1.775a.75.75 0 000-1.5H3.75a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path d="M9.33 17.27c0 .704.43 1.065 1.2 1.648a3.457 3.457 0 011.76 2.832 2.265 2.265 0 01-.659 1.588c-.42.421-.988.66-1.581.662H8.58a.758.758 0 01-.76-.753.775.775 0 01.76-.764h1.51a.758.758 0 00.75-.753c0-.703-.43-1.065-1.2-1.647a3.476 3.476 0 01-1.8-2.832c.003-.6.24-1.172.662-1.596a2.255 2.255 0 011.588-.664h1.5a.768.768 0 01.76.763.765.765 0 01-.76.753h-1.5a.768.768 0 00-.76.764zm6.36 6.52a.68.68 0 01-.47-.212C13 21.308 13 16.156 13 15.945a.675.675 0 111.35 0s0 3.927 1.34 6.076A9.808 9.808 0 0017 15.945a.676.676 0 01.63-.713.655.655 0 01.656.373.69.69 0 01.064.26c.3 5.232-2.12 7.623-2.22 7.723a.699.699 0 01-.44.201zm6.12.19a3.04 3.04 0 01-1.91-.613c-.47-.448-.83-1-1.05-1.612-.22-.612-.26-1.19-.26-1.933.053-.83.074-1.475.343-2.173a4.822 4.822 0 011.247-1.825 3.802 3.802 0 013.05-.633.748.748 0 01.566.615.756.756 0 01-.623.863.746.746 0 01-.293-.011 2.299 2.299 0 00-1.79.361 3.447 3.447 0 00-.817 1.288c-.173.485-.22.958-.22 1.515 0 .459.024.834.144 1.234.12.399.323.769.593 1.086a2.075 2.075 0 001.71.2v-1.747l-.81.05a.748.748 0 01-.75-.753.755.755 0 01.75-.753h.86c.383 0 .75.152 1.022.423.271.27.425.639.428 1.023v1.798c0 .326-.107.642-.307.899a1.45 1.45 0 01-.793.517 4.67 4.67 0 01-1.09.18z" />
      </g>
      <defs>
        <clipPath id="files-svg_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesSvg.displayName = 'FilesSvg';
