import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowLeft = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M15.505.445a.75.75 0 01.05 1.06L6.014 12l9.541 10.495a.75.75 0 01-1.11 1.01l-10-11a.75.75 0 010-1.01l10-11a.75.75 0 011.06-.05z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowLeft.displayName = 'ArrowLeft';
