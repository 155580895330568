import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Expand = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M21.746 3.806v4.69a.75.75 0 001.5 0v-6.5a.747.747 0 00-.75-.75h-6.5a.75.75 0 000 1.5h4.69l-7.22 7.22a.75.75 0 101.06 1.06l7.22-7.22zM1.996 14.974a.75.75 0 01.75.75v4.689l7.22-7.22a.75.75 0 011.06 1.061l-7.22 7.22h4.69a.75.75 0 110 1.5h-6.5a.746.746 0 01-.679-.43.747.747 0 01-.071-.32v-6.5a.75.75 0 01.75-.75z" />
    </g>
  );
});
Expand.displayName = 'Expand';
