import { css } from '@emotion/core';

import { spacing, colors } from '../config';

export const infoButtonWrapperStyle = css({
  position: 'absolute',
  marginLeft: spacing.size2,
  verticalAlign: 'text-top',
  cursor: 'pointer',
  display: 'inline-block',
});

export const infoButtonStyle = css({
  color: colors.boldColors.lightBlue.base,
});

export const spinnerOverlayText = css({
  textAlign: 'center',
});
