import {
  BreadcrumbLink as BreadcrumbLinkComponent,
  BreadcrumbLinkProps,
} from '@chakra-ui/breadcrumb';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IBreadcrumbLinkProps = BreadcrumbLinkProps;

export const BreadcrumbLink = forwardRef<IBreadcrumbLinkProps, 'a'>((props, ref) => {
  return <BreadcrumbLinkComponent {...props} ref={ref} />;
});
