import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesGifBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM10.14 16.78a1.33 1.33 0 01-1 1.3 3.93 3.93 0 01-1 .13 2.82 2.82 0 01-1.77-.57 3.87 3.87 0 01-1.2-3.24 4.46 4.46 0 011.43-3.67 3.54 3.54 0 012.83-.58.69.69 0 01-.05 1.353.681.681 0 01-.27-.013 2.192 2.192 0 00-1.66.34 3.19 3.19 0 00-.89 2.58 2.8 2.8 0 00.65 2.14 1.909 1.909 0 001.59.18l-.05-1.61-.75.05a.7.7 0 010-1.39h.8a1.34 1.34 0 011.34 1.34v1.66zm2.86.83a.78.78 0 01-1.54 0v-6.95a.78.78 0 011.54 0v6.95zm5.14-6.22h-2.43v2.73h1.64a.69.69 0 010 1.38h-1.64v2.08a.7.7 0 01-1.39 0V10.7A.69.69 0 0115 10h3.13a.7.7 0 010 1.39h.01z" />
      </g>
    );
  }
);
FilesGifBold.displayName = 'FilesGifBold';
