import {
  useDisclosure,
  UseDisclosureReturn,
  useEventListener,
  useOutsideClick,
} from '@chakra-ui/hooks';
import { usePopper, UsePopperProps, UsePopperReturn } from '@chakra-ui/popper';
import { createContext, getValidChildren } from '@chakra-ui/react-utils';
import { chakra, forwardRef, useStyles } from '@chakra-ui/system';
import { runIfFn } from '@chakra-ui/utils';
import * as React from 'react';
import { useNavbarContext } from './NavbarProvider';

interface INavBarMenuProps extends UsePopperProps {
  closeOnBlur: boolean;
}

type IMenuContext = UseDisclosureReturn &
  UsePopperReturn & {
    closeOnBlur: boolean;
  };

export const [MenuProvider, useMenuContext] = createContext<IMenuContext>({
  name: 'MenuContext',
  errorMessage:
    'useMenuContext: `context` is undefined. Seems you forgot to wrap the menu items in `<MenuContext />` ',
});

/**
 * Internal Menu
 * The Menu is a slim context wrapper for controlling the nested NavBar menus.
 * It should not be exposed as a public facing API.
 *
 * @description The menu is primary used to control
 * the Open and closed state and popper.js positions
 *
 * @param
 * @returns
 */
export const Menu: React.FunctionComponent<INavBarMenuProps> = ({
  offset,
  closeOnBlur,
  ...props
}) => {
  const { children } = props;
  const offsetOverride = offset ? offset : [0, 0];

  const disclosure = useDisclosure();
  const popper = usePopper({ offset: offsetOverride as UsePopperProps['offset'], ...props });

  const context = React.useMemo(
    () => ({ ...disclosure, ...popper, closeOnBlur }),
    [disclosure, popper, closeOnBlur]
  );

  return <MenuProvider value={context}>{children}</MenuProvider>;
};

interface IMenuListProps {
  render?: (props: IMenuContext) => JSX.Element;
}

/**
 * Internal MenuList
 *
 * The menulist wraps the menu options and provides the poppoer positioning
 * as well as controlling visibility
 */
export const MenuList = forwardRef<IMenuListProps, 'div'>(({ children, render }, ref) => {
  const styles = useStyles();
  const { isOpen, onClose, closeOnBlur, ...popper } = useMenuContext();
  const { orientation } = useNavbarContext();

  const validChildren = getValidChildren(children);

  const menuRef = React.useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: menuRef,
    handler: (event) => {
      if (!menuRef.current) {
        return;
      }

      if (isOpen && closeOnBlur && !menuRef.current.contains(event.target as HTMLElement)) {
        onClose();
      }
    },
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        onClose();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <chakra.div
      display="flex"
      height={orientation === 'vertical' ? 'auto' : '100%'}
      justifyContent="inherit"
      ref={menuRef}
      width={orientation === 'vertical' ? '100%' : 'auto'}
    >
      {render && runIfFn(render({ isOpen, onClose, closeOnBlur, ...popper }))}
      {isOpen && (
        <chakra.div
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            onClose();
          }}
          ref={popper.popperRef}
          sx={styles.menuList}
        >
          {validChildren.map((child) =>
            React.cloneElement(child, {
              onClick: () => {
                if (child.props.onClick) {
                  child.props.onClick();
                }

                onClose();
              },
            })
          )}
        </chakra.div>
      )}
    </chakra.div>
  );
});
