import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesSvgBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#files-svg-bold_svg__a)">
          <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM7.44 13.28A2.94 2.94 0 019 15.69a1.91 1.91 0 01-1.91 1.9H5.78a.64.64 0 110-1.27h1.28a.64.64 0 00.64-.64c0-.6-.36-.9-1-1.4a2.92 2.92 0 01-1.52-2.41A1.91 1.91 0 017.06 10h1.28a.64.64 0 01.64.64.65.65 0 01-.64.64H7.06a.64.64 0 00-.64.63c0 .57.37.88 1.02 1.37zm4.77 4a.59.59 0 01-.4.16.55.55 0 01-.4-.18C9.55 15.29 9.53 11 9.53 10.77a.57.57 0 01.57-.57.56.56 0 01.57.57s0 3.32 1.15 5.12A8.27 8.27 0 0013 10.78a.571.571 0 011.14-.07c.21 4.41-1.85 6.44-1.93 6.52v.05zm6.65-1a1.22 1.22 0 01-.94 1.19c-.3.078-.61.118-.92.12a2.58 2.58 0 01-1.62-.59 3.56 3.56 0 01-1.08-3 4.06 4.06 0 011.32-3.36 3.288 3.288 0 012.59-.54.64.64 0 01-.3 1.24 2 2 0 00-1.52.31 3 3 0 00-.82 2.35 2.61 2.61 0 00.6 2 1.78 1.78 0 001.45.17v-1.48l-.68.05a.641.641 0 01-.64-.64.64.64 0 01.64-.63h.73a1.23 1.23 0 011.23 1.22l-.04 1.59z" />
        </g>
        <defs>
          <clipPath id="files-svg-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
FilesSvgBold.displayName = 'FilesSvgBold';
