import { css } from '@emotion/core';
import { Button } from '@lego/klik-react';
import { Delete } from '@lego/klik-icons-react';
import PropTypes from 'prop-types';

const formatSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
};

const uploadFileWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const UploadedFile = ({ uploadedFile, onDelete }) => {
  return (
    <div css={uploadFileWrapperStyle}>
      <h4>
        {uploadedFile.path} - {formatSize(uploadedFile.size)}
      </h4>
      <Button
        aria-label="Delete file bin icon button"
        data-transaction-name="Delete File"
        icon={<Delete />}
        variant="ghost"
        onClick={onDelete}
      />
    </div>
  );
};

UploadedFile.propTypes = {
  uploadedFile: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export { UploadedFile };
