import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesMp4 = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-mp4_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h.9c.555 0 .89-.336.89-.75s-.335-.75-.89-.75h-.9a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M14.743 14.98a.745.745 0 00-.529.22.755.755 0 00-.219.532v7.5c0 .2.079.391.22.532a.746.746 0 001.276-.532v-2.249c.794 0 1.555-.317 2.116-.882a3.022 3.022 0 000-4.26 2.983 2.983 0 00-2.116-.882l-.748.02zm.748 1.505v3.012a1.49 1.49 0 001.383-.93 1.516 1.516 0 00-.325-1.64 1.495 1.495 0 00-1.058-.442z"
          fillRule="evenodd"
        />
        <path d="M12.29 23.945a.735.735 0 01-.694-.463.747.747 0 01-.054-.29v-5.02l-.928 1.365a.74.74 0 01-.618.332.744.744 0 01-.618-.322l-.998-1.415v5.02c0 .2-.079.39-.22.532a.746.746 0 01-1.276-.532v-7.4a.747.747 0 01.515-.735.734.734 0 01.841.304l1.716 2.48 1.686-2.48a.739.739 0 011.219-.017c.095.13.146.287.147.448v7.46a.746.746 0 01-.718.733z" />
        <path
          clipRule="evenodd"
          d="M22.835 23.71c.129.14.307.224.496.235a.727.727 0 00.669-.753v-7.45a.777.777 0 00-.47-.723.654.654 0 00-.767.252l-3.99 5.491a.826.826 0 00-.09.803.702.702 0 00.629.432h3.331v1.195a.736.736 0 00.192.517zm-.192-3.229h-1.895l1.895-2.56v2.56z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="files-mp4_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesMp4.displayName = 'FilesMp4';
