import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LogOutBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.257.5a2.767 2.767 0 012.013.954c.49.561.751 1.298.729 2.054v16.984a2.987 2.987 0 01-.73 2.054 2.767 2.767 0 01-2.012.954H10.076c-.025 0-.05 0-.075-.002a2.768 2.768 0 01-1.939-.952 2.987 2.987 0 01-.73-2.053v-2.066c0-.861.672-1.56 1.5-1.56.83 0 1.5.699 1.5 1.56v1.955H21V3.618H10.333v1.818c0 .861-.672 1.56-1.5 1.56-.829 0-1.5-.699-1.5-1.56V3.508a2.987 2.987 0 01.73-2.054A2.768 2.768 0 0110.075.5h11.18z" />
        <path d="M5.977 7.351a1.46 1.46 0 00-2.121 0L.45 10.891a1.553 1.553 0 00-.283.398 1.602 1.602 0 00-.167.715 1.6 1.6 0 00.45 1.113l3.406 3.54a1.46 1.46 0 002.121 0 1.602 1.602 0 000-2.205l-.856-.89H15.85c.828 0 1.5-.697 1.5-1.558 0-.861-.672-1.56-1.5-1.56H5.121l.856-.888a1.602 1.602 0 000-2.205z" />
      </g>
    );
  }
);
LogOutBold.displayName = 'LogOutBold';
