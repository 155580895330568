import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../config';
import checkmark from '../../assets/checkmark.svg';

// Styles
import {
  wrapperStyle,
  indicatorStyle,
  numberStyle,
  headerStyle,
  contentWrapperStyle,
  contentStyle,
} from './Step.style';

const Step = ({
  activeColor = colors.boldColors.lightBlue.base,
  number,
  header,
  isLast,
  isActive,
  isValid,
  isComplete,
  isWarning,
  children,
  additionalHeight,
  shouldHideInactiveStepsContent,
}) => {
  // Ref to question so that we can scroll it into view if necessary
  const questionRef = useRef(null);
  // Ref to question content wrapper so that we can measure height of content
  const questionContentRef = useRef(null);
  // We need to get the actual height of each question in order to animate properly
  // We update the questionHeight state as soon as the question has been rendered to the DOM
  // and we can measure the height
  const [questionHeight, setQuestionHeight] = useState(0);

  useEffect(() => {
    const questionHeightNew = questionContentRef.current.offsetHeight;
    setQuestionHeight(questionHeightNew);
  }, []);

  useEffect(() => {
    if (isActive) {
      var timer = setTimeout(() => {
        questionRef.current.scrollIntoViewIfNeeded();
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [isActive]);

  return (
    <li ref={questionRef} css={wrapperStyle}>
      <div css={indicatorStyle(activeColor, isLast, isValid, isComplete)}>
        <span
          css={numberStyle(
            activeColor,
            isActive,
            isComplete,
            isValid,
            isWarning
          )}
          data-testid="stepNumber"
        >
          {isComplete && !isActive ? (
            <img src={checkmark} alt="Checkmark icon" />
          ) : (
            number
          )}
        </span>
      </div>
      <div ref={questionContentRef} css={contentWrapperStyle}>
        <h3 css={headerStyle(activeColor, isActive, isValid, isWarning)}>
          {header}
        </h3>
        <div
          css={
            shouldHideInactiveStepsContent &&
            contentStyle(isActive, questionHeight + additionalHeight)
          }
        >
          {children}
        </div>
      </div>
    </li>
  );
};

Step.propTypes = {
  activeColor: PropTypes.string,
  number: PropTypes.number,
  header: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  isActive: PropTypes.bool,
  isValid: PropTypes.bool,
  isComplete: PropTypes.bool,
  isWarning: PropTypes.bool,
  children: PropTypes.node,
  stepId: PropTypes.string.isRequired,
  additionalHeight: PropTypes.number,
  shouldHideInactiveStepsContent: PropTypes.bool,
};

export { Step };
