import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesCameraBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21 5.576h-2.786L16.03 2.664A1.286 1.286 0 0015 2.15H9a1.286 1.286 0 00-1.029.514L5.786 5.576H3a3.005 3.005 0 00-3 2.998v10.278a3 3 0 003 2.998h18a3.004 3.004 0 003-2.998V8.574a3 3 0 00-3-2.998zm-9 13.276a5.575 5.575 0 01-5.147-3.437 5.564 5.564 0 014.06-7.59 5.575 5.575 0 015.72 2.367c.612.915.938 1.992.938 3.093A5.572 5.572 0 0112 18.852z" />
        <path d="M9 13.285a2.997 2.997 0 003 2.998 3.001 3.001 0 003-2.998 2.997 2.997 0 00-3-2.998 3.001 3.001 0 00-3 2.998z" />
      </g>
    );
  }
);
DevicesCameraBold.displayName = 'DevicesCameraBold';
