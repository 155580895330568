import { FormErrorIcon as FormErrorIconInternal } from './FormErrorIcon';
import {
  FormErrorMessage as FormErrorMessageInternal,
  IFormErrorMessageProps,
} from './FormErrorMessage';
import { FormHelperText as FormHelperTextInternal, IFormHelperTextProps } from './FormHelperText';
import { FormLabel as FormLabelInternal, IFormLabelProps } from './FormLabel';

export * from './FormControl';
export { IFormLabelProps, IFormHelperTextProps, IFormErrorMessageProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `FormControl.ErrorIcon` instead
 */
export const FormErrorIcon = FormErrorIconInternal;
/**
 * @deprecated use `FormControl.ErrorMessage` instead
 */
export const FormErrorMessage = FormErrorMessageInternal;
/**
 * @deprecated use `FormControl.HelperText` instead
 */
export const FormHelperText = FormHelperTextInternal;
/**
 * @deprecated use `FormControl.Label` instead
 */
export const FormLabel = FormLabelInternal;
