import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Alert = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M13.934 21.021a.73.73 0 00-.924.514 1.34 1.34 0 01-.908.907 1.297 1.297 0 01-1.01-.102 1.265 1.265 0 01-.633-.788.764.764 0 00-.924-.514.764.764 0 00-.514.925c.205.719.702 1.318 1.352 1.695.428.222.89.342 1.353.342.274 0 .53-.034.804-.12a2.836 2.836 0 001.918-1.917.76.76 0 00-.514-.942zm7.258-2.858c-.137-.172-1.232-1.73-1.232-7.67 0-4.296-3.304-7.823-7.498-8.2V.754A.76.76 0 0011.709 0a.76.76 0 00-.754.753v1.54c-4.176.395-7.463 3.921-7.463 8.2 0 5.872-1.335 7.772-1.335 7.772a.769.769 0 00-.086.788c.12.257.394.428.668.428h17.974c.342 0 .668-.189.753-.514.086-.308 0-.616-.274-.804zM3.937 17.99c.48-1.232 1.044-3.543 1.044-7.497 0-3.715 3.03-6.745 6.745-6.745 3.714 0 6.744 3.03 6.744 6.745 0 4.04.497 6.282.959 7.497H3.937z" />
    </g>
  );
});
Alert.displayName = 'Alert';
