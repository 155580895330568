import { colors } from '../config';
import { Step } from './stepper';

const MissingProofExplainer = () => (
  <div data-testid="missingProofExplainer">
    <Step
      header="- Missing Attachments"
      stepId="-1"
      isComplete={true}
      isLast={true}
      isWarning={true}
    ></Step>
    <h1 css={{ color: colors.utilityColors.warning.warning400 }}>
      Some of the answers are missing attachments.
    </h1>
  </div>
);

export { MissingProofExplainer };
