import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DiscountBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.255 1.973a1.75 1.75 0 00-2.475-.035l-18 17.5a1.75 1.75 0 002.44 2.509l18-17.5a1.75 1.75 0 00.035-2.474zM5 8.558a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm17.5 10.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z" />
      </g>
    );
  }
);
DiscountBold.displayName = 'DiscountBold';
