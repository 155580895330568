import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesZipBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.68 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.32-.7zM10.08 16.9a.61.61 0 010 1.22H6.26a.61.61 0 01-.53-.31.6.6 0 010-.62l3.26-5.39H6.26a.62.62 0 010-1.23h3.82a.59.59 0 01.53.32.58.58 0 010 .61l-3.26 5.4h2.73zm3.28.62a.71.71 0 01-1.4 0v-6.38a.71.71 0 011.4 0v6.38zm4.25-2.62a2.539 2.539 0 01-1.8.74v1.91a.64.64 0 01-1.27 0v-6.38a.63.63 0 01.64-.63h.64a2.53 2.53 0 011.79 4.36z" />
        <path d="M15.81 11.8v2.54a1.32 1.32 0 00.9-.37 1.28 1.28 0 000-1.8 1.32 1.32 0 00-.9-.37z" />
      </g>
    );
  }
);
FilesZipBold.displayName = 'FilesZipBold';
