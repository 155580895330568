import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FeedScroll = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M2.25 2.25A.75.75 0 001.5 3v13.5a.75.75 0 00.75.75h5.253a.75.75 0 010 1.5H2.25A2.25 2.25 0 010 16.5V3A2.25 2.25 0 012.25.75h19.5A2.25 2.25 0 0124 3v12a.75.75 0 01-1.5 0V3a.75.75 0 00-.75-.75H19.5v8.25a.75.75 0 01-1.5 0V2.25H2.25z" />
        <path d="M5.25 5.25a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zm9.223 6.97a.75.75 0 011.28.53v3c0 .367.265.68.627.74l1.993.332a2.25 2.25 0 011.88 2.22V22.5a.75.75 0 001.5 0v-3.46a3.75 3.75 0 00-3.134-3.698l-1.366-.227V12.75a2.25 2.25 0 10-4.5 0v4.039l-.094-.047a2.387 2.387 0 00-3.384 1.556v.002a2.387 2.387 0 00.831 2.442l.002.001 2.926 2.343a.75.75 0 00.938-1.172l-2.93-2.344-.002-.001a.886.886 0 01.948-1.485l1.18.587A.75.75 0 0014.254 18v-5.25a.75.75 0 01.22-.53zM4.5 9a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 014.5 9zm.75 2.25a.75.75 0 000 1.5H9a.75.75 0 000-1.5H5.25z" />
      </g>
    );
  }
);
FeedScroll.displayName = 'FeedScroll';
