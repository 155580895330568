import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const AddToListBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6.41 3.86H20a1 1 0 100-2H6.41a1 1 0 000 2zm0 5.62H20a1 1 0 100-2H6.41a1 1 0 000 2zM9.16 13H6.41a1 1 0 000 2h2.75a1 1 0 000-2zM3.02 4.22a1.36 1.36 0 100-2.72 1.36 1.36 0 000 2.72zm14.18 6.57a6 6 0 100 12 6 6 0 000-12zm2 6.76H18v1.36a.75.75 0 11-1.5 0v-1.37h-1.31a.75.75 0 110-1.5h1.29v-1.33a.75.75 0 111.5 0V16h1.23a.75.75 0 110 1.5l-.01.05zM3.02 9.84a1.36 1.36 0 100-2.72 1.36 1.36 0 000 2.72zm0 5.56a1.36 1.36 0 100-2.72 1.36 1.36 0 000 2.72z" />
      </g>
    );
  }
);
AddToListBold.displayName = 'AddToListBold';
