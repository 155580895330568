import {
  CircularProgressLabel as CircularProgressLabelInternal,
  ICircularProgressLabelProps,
} from './CircularProgressLabel';

export * from './CircularProgress';
export * from './Progress';
export { ICircularProgressLabelProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `CircularProgress.Label` instead
 */
export const CircularProgressLabel = CircularProgressLabelInternal;
