import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserMale2 = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#lego-user-male-2_svg__a)">
          <path d="M22.34 20.08A5.93 5.93 0 0020 17.72a3.709 3.709 0 00-1.76-.43H5.49C1.9 17.46 1 21.25 1 23.24a.75.75 0 001.5 0c0-.18.05-4.31 3.06-4.45h12.72c.349.002.692.087 1 .25a4.44 4.44 0 012.23 4.15.75.75 0 00.7.8h.05a.75.75 0 00.75-.7 5.93 5.93 0 00-.67-3.21zM5.39 12.27a3.3 3.3 0 00.93 2.31 3.132 3.132 0 002.26 1h6.31a3.13 3.13 0 001.23-.25 3.17 3.17 0 001-.72 3.31 3.31 0 00.93-2.31v-1.2a7.5 7.5 0 001.16-2.8c.58-2.42.24-4.37-1-5.81C15.69-.44 10.32 0 10 .05 7.64.43 6.93 1.3 6.69 2.19a2.29 2.29 0 00-1.27.11A2.12 2.12 0 004.2 3.52C3.1 6.3 5 10.24 5.39 11.1v1.17zm10.69 1.26a1.681 1.681 0 01-.55.38 1.61 1.61 0 01-.64.13H8.58a1.643 1.643 0 01-1.19-.51 1.83 1.83 0 01-.5-1.26V7.43a1.54 1.54 0 01.28-.95c.06-.08.41-.46 1.28-.16l.45.16a38.361 38.361 0 007.65 2.07v4.08c-.02.113-.05.223-.09.33a1.8 1.8 0 01-.38.58v-.01zM5.59 4.06A.65.65 0 016 3.7a.77.77 0 01.62 0 1 1 0 001 .05 1.14 1.14 0 00.56-1c0-.38.13-1 2.14-1.27 0 0 4.82-.42 6.86 1.93a4.43 4.43 0 01.83 3.71l-.56-.05a33.77 33.77 0 01-8-2.07L9 4.84a3.35 3.35 0 00-1.1-.2 2.28 2.28 0 00-1.89.9 3 3 0 00-.48 1.08 5.09 5.09 0 01.06-2.56z" />
          <path d="M9.86 9.63a.6.6 0 100-1.2.6.6 0 000 1.2zm3.64 0a.6.6 0 100-1.2.6.6 0 000 1.2zm.22 1.25a.31.31 0 00-.4.18s-.31.71-1.69.71c-1.32 0-1.57-.67-1.58-.71a.313.313 0 00-.6.18s.36 1.15 2.18 1.15c1.85 0 2.26-1.07 2.28-1.12a.31.31 0 00-.19-.39z" />
        </g>
        <defs>
          <clipPath id="lego-user-male-2_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
LegoUserMale2.displayName = 'LegoUserMale2';
