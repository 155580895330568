import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Pin = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M16.654.127a2.25 2.25 0 00-2.644.914l-3.877 6.094a8.271 8.271 0 00-5.561 1l.377.649-.38-.647a2.25 2.25 0 00-.45 3.533l3.574 3.575L.22 22.718a.75.75 0 001.06 1.06l7.474-7.473 3.576 3.576a2.25 2.25 0 003.534-.45l.001-.002a8.271 8.271 0 001-5.561l6.093-3.878a2.251 2.251 0 00.384-3.488L17.498.659a2.25 2.25 0 00-.845-.532zM5.082 9.664a.75.75 0 01.245-.233 6.772 6.772 0 014.986-.728.75.75 0 00.808-.327l4.154-6.529a.75.75 0 011.163-.127l5.843 5.842a.75.75 0 01-.128 1.163l-6.529 4.155a.75.75 0 00-.327.808 6.771 6.771 0 01-.728 4.985.75.75 0 01-1.177.15l-8.213-8.214a.75.75 0 01-.097-.945z"
        fillRule="evenodd"
      />
    </g>
  );
});
Pin.displayName = 'Pin';
