import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFacelessFemale1Bold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M4.928 12.14c0 1.01.39 1.95 1.1 2.66.71.71 1.66 1.1 2.66 1.1h5.24c.84 0 1.64-.29 2.29-.8.41.3.98.51 1.77.51h.21c.59.03 1.19-.09 1.72-.33.5-.22 1.45-.81 1.87-1.29.22-.25.3-.59.21-.91-.09-.32-.32-.58-.63-.69-.51-.18-.78-.48-.98-1.1-.12-.37-.07-.77-.01-1.28.02-.13.03-.27.05-.4.56-1.91 1.03-5.59-.6-7.75-.57-.75-1.68-1.62-3.55-1.6-.75.02-1.5.2-2.17.54-.16.08-.92.47-1.46.98-1.77-.68-4.95-.83-6.9.94-1.24 1.13-2.38 3.43-.82 7.92v1.5zm9.01 1.76h-5.25c-.47 0-.92-.18-1.25-.52-.33-.33-.52-.78-.52-1.25V8.4c1.63-.13 4.94-.54 6.88-1.77.32-.21.72-.25 1.07-.13.18.06.49.22.63.61.1.27.16.59.2.93v4.1c0 .47-.18.92-.52 1.25-.33.33-.77.51-1.24.51zm-6.84-9.69c1.32-1.2 3.8-1.04 5.02-.47.6.28 1.33.11 1.85-.44.21-.22.7-.53 1.04-.7.41-.21.86-.32 1.31-.33.9-.04 1.5.25 1.92.8 1.08 1.43.73 4.48.25 6.06-.02.07-.03.13-.04.2-.01.15-.03.3-.05.44-.08.64-.16 1.36.1 2.15.14.43.37.97.8 1.44-.06.03-.12.06-.17.08-.27.12-.56.18-.93.16-.38.01-.6-.04-.74-.09.12-.31.18-.62.24-.9l.05-.24c.02-.07.02-.14.02-.21V8.62c-.01-.21-.04-.4-.06-.6v-.27h-.03c-.06-.48-.15-.94-.29-1.33-.31-.86-.99-1.52-1.87-1.82-.92-.31-1.96-.19-2.79.34-1.65 1.05-5.07 1.43-6.56 1.51.04-.88.31-1.68.93-2.24zm14.1 14.41c-.84-.84-1.93-1.27-3.25-1.27H5.838c-3.65 0-4.62 3.53-4.62 5.39 0 .55.45 1 1 1s1-.45 1-1c0-.14.03-3.38 2.62-3.38h12.11c2.73 0 2.81 3.04 2.82 3.38 0 .55.44 1 1 1 .55 0 1-.44 1-1 0-.1-.01-2.55-1.57-4.12z" />
      </g>
    );
  }
);
LegoUserFacelessFemale1Bold.displayName = 'LegoUserFacelessFemale1Bold';
