import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Settings = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M12 7.25a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5zM8.75 12a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M20.581 6.954l.611-.61a1.5 1.5 0 000-2.122l-1.415-1.413a1.5 1.5 0 00-2.121 0l-.612.612c-.418.417-1.059.498-1.604.272-.545-.227-.94-.736-.94-1.327V1.5A1.5 1.5 0 0013 0h-2a1.5 1.5 0 00-1.5 1.5v.866c0 .59-.395 1.1-.94 1.327-.546.227-1.187.145-1.605-.272l-.612-.612a1.5 1.5 0 00-2.121 0L2.807 4.223a1.5 1.5 0 000 2.122l.612.611c.418.418.499 1.059.272 1.604-.226.545-.735.94-1.325.94H1.5A1.5 1.5 0 000 11v2a1.5 1.5 0 001.5 1.5h.865c.59 0 1.1.395 1.327.94.226.546.145 1.187-.273 1.605l-.613.613a1.5 1.5 0 000 2.121l1.414 1.414a1.545 1.545 0 002.122 0l.611-.61c.419-.42 1.06-.5 1.606-.273.546.226.941.737.941 1.328v.862A1.5 1.5 0 0011 24h2a1.5 1.5 0 001.5-1.5v-.863c0-.59.395-1.1.94-1.327.546-.227 1.187-.145 1.605.272l.612.612a1.5 1.5 0 002.121 0l1.414-1.414a1.5 1.5 0 000-2.121l-.611-.611c-.418-.418-.5-1.059-.273-1.605.227-.546.737-.941 1.328-.941h.864A1.5 1.5 0 0024 13v-2a1.5 1.5 0 00-1.5-1.5h-.865c-.59 0-1.1-.395-1.327-.94-.226-.547-.145-1.188.273-1.606zM18.717 3.87l1.415 1.413-.61.61c-.914.913-1.02 2.224-.6 3.24C19.343 10.15 20.346 11 21.635 11h.865v2.002h-.864c-1.29 0-2.293.852-2.713 1.866-.421 1.016-.316 2.328.597 3.24l.611.611-1.413 1.414-.613-.612c-.913-.912-2.224-1.018-3.24-.597C13.85 19.346 13 20.348 13 21.637v.863h-2v-.863c0-1.289-.85-2.291-1.866-2.713-1.016-.422-2.328-.316-3.24.597l-.587.585a.045.045 0 01-.052 0l-1.388-1.388.613-.613c.913-.913 1.018-2.224.597-3.24C4.657 13.852 3.655 13 2.365 13H1.5v-2h.866c1.29 0 2.29-.85 2.71-1.865.422-1.015.317-2.327-.597-3.24l-.612-.611L5.282 3.87l.613.612c.912.912 2.224 1.018 3.24.597C10.15 4.657 11 3.655 11 2.366V1.5h2v.866c0 1.29.85 2.291 1.865 2.712 1.015.421 2.326.316 3.239-.596l.613-.612z"
        fillRule="evenodd"
      />
    </g>
  );
});
Settings.displayName = 'Settings';
