import {
  BreadcrumbItem as BreadcrumbItemComponent,
  BreadcrumbItemProps,
} from '@chakra-ui/breadcrumb';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IBreadcrumbItemProps = BreadcrumbItemProps;

export const BreadcrumbItem = forwardRef<IBreadcrumbItemProps, 'li'>((props, ref) => {
  return <BreadcrumbItemComponent {...props} ref={ref} />;
});
