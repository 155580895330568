import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const AdvertisingMegaphone = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.926 11.583l.936-2.47a1.673 1.673 0 00-.647-1.99l-2.231-1.445-.687-2.549a1.673 1.673 0 00-1.693-1.235l-2.599.1-2.08-1.623a1.673 1.673 0 00-2.102 0L9.782 1.994l-2.639-.1A1.673 1.673 0 005.451 3.13l-.697 2.55-2.211 1.443a1.673 1.673 0 00-.647 1.992l.936 2.469-.349.906a.747.747 0 101.394.528l.448-1.165a.747.747 0 000-.528L3.33 8.596a.18.18 0 01.03-.23l2.449-1.592a.747.747 0 00.309-.429l.766-2.818a.18.18 0 01.16-.139l2.987.15a.747.747 0 00.508-.17l2.28-1.832a.18.18 0 01.22 0l2.28 1.832a.747.747 0 00.507.17l2.868-.15a.18.18 0 01.18.13l.766 2.817c.055.186.18.343.348.439l2.45 1.593a.18.18 0 01.07.209l-.996 2.728a.746.746 0 000 .528l.995 2.728a.179.179 0 01-.07.21l-2.449 1.612a.747.747 0 00-.308.428l-.767 2.819a.179.179 0 01-.18.129l-2.917-.15a.747.747 0 00-.508.17l-2.29 1.842a.748.748 0 00.936 1.165l2.051-1.653 2.639.13a1.673 1.673 0 001.693-1.235l.697-2.55 2.21-1.433a1.673 1.673 0 00.648-1.992l-.966-2.47z" />
        <path d="M15.557 18.354a.747.747 0 101.265-.797L10.529 7.71a.747.747 0 10-1.265.796v.07L1.14 19.01a.747.747 0 00-.996 1.076l.319.497.996 1.564.318.497a.747.747 0 001.374-.467l1.574-.498.557.856a2.778 2.778 0 003.983.826 2.987 2.987 0 001.076-3.425l5.217-1.583zm-6.522 2.898a1.504 1.504 0 01-.587.926 1.285 1.285 0 01-1.822-.389l-.339-.518 2.688-.836c.096.261.116.544.06.817zm.07-2.45l-.07.02-4.122 1.294h-.06l-2.39.797-.398-.657L10.2 9.96l4.52 7.12-5.615 1.722z" />
      </g>
    );
  }
);
AdvertisingMegaphone.displayName = 'AdvertisingMegaphone';
