import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserMale1 = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#lego-user-male-1_svg__a)">
          <path d="M22 20.21a5.769 5.769 0 00-2.24-2.29 3.659 3.659 0 00-1.71-.42H5.66c-3.48.16-4.39 3.85-4.39 5.77a.73.73 0 001.46 0c0-.17 0-4.18 3-4.32h12.35a2 2 0 011 .24 4.31 4.31 0 012.16 4 .729.729 0 00.68.78H22a.73.73 0 00.73-.68 5.76 5.76 0 00-.73-3.08zM19.77 5l-.9-.29.74-1.41a.81.81 0 000-.91.83.83 0 00-.93-.39l-.68.18.38-.42a1 1 0 00.3-.76.83.83 0 00-.68-.63C14.55-.27 7.36 2 7 2.11a2.52 2.52 0 00-1.15 1l-.5.86a1.52 1.52 0 00-1.21.22 1 1 0 00-.45.76c-.3 3.27.06 5.52 1.07 6.68.252.304.58.535.95.67v.8a3.1 3.1 0 003.1 3.1h6.13a3.1 3.1 0 003.1-3.1v-.25c1.39-.93 2.09-3.54 2.64-6.22 0 0 .32-1.35-.91-1.63zm-3.28 8.1a1.64 1.64 0 01-1.64 1.64H8.73a1.64 1.64 0 01-1.64-1.64V8.77a17.61 17.61 0 007.09-1.52 5 5 0 002.31 1v4.85zM5 5.4l.27.11a.94.94 0 001-.43l.74-1.26a1.07 1.07 0 01.42-.35c.06 0 5.6-1.75 9-1.76l-.89 1a.88.88 0 00-.09.94.85.85 0 00.83.44l1.35-.31-.36.69a1 1 0 00-.13.85.88.88 0 00.61.56l1.34.43A21.127 21.127 0 0118 10.47v-2.6a1 1 0 00-.9-1A3.45 3.45 0 0115 6a.93.93 0 00-1.1-.26c-.71.32-4.39 1.89-7.35 1.49 0 0-.9-.13-.94.48v2.66C5.24 9.8 4.8 8.43 5 5.4z" />
          <path d="M9.97 10.34a.6.6 0 100-1.2.6.6 0 000 1.2zm3.65 0a.6.6 0 100-1.2.6.6 0 000 1.2zm.22 1.24a.31.31 0 00-.4.18s-.31.71-1.69.71c-1.32 0-1.57-.67-1.58-.71a.313.313 0 10-.6.18s.36 1.15 2.18 1.15C13.59 13.09 14 12 14 12a.309.309 0 00-.16-.42z" />
        </g>
        <defs>
          <clipPath id="lego-user-male-1_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
LegoUserMale1.displayName = 'LegoUserMale1';
