import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const PrintBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M4.5 2.25A2.25 2.25 0 016.75 0h10.5a2.25 2.25 0 012.25 2.25V6h2.25a2.25 2.25 0 012.249 2.25v7.5a2.25 2.25 0 01-2.25 2.25H19.5v5.25a.75.75 0 01-.75.749H5.25a.75.75 0 01-.75-.75V18H2.25A2.25 2.25 0 010 15.75v-7.5A2.25 2.25 0 012.25 6H4.5V2.25zm13 11.25h-11V22h11v-8.5zm0-8V2.25a.25.25 0 00-.25-.25H6.75a.25.25 0 00-.25.25V5.5h11zM2.75 9.75a1 1 0 011-1h1.5a1 1 0 010 2h-1.5a1 1 0 01-1-1z"
          fillRule="evenodd"
        />
        <path d="M8.25 14.75a1 1 0 000 2h7.5a1 1 0 000-2h-7.5zm0 3a1 1 0 100 2h5.25a1 1 0 000-2H8.25z" />
      </g>
    );
  }
);
PrintBold.displayName = 'PrintBold';
