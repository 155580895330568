import { gql } from '@apollo/client';

const GET_ELIGIBLE_ASSESSMENT_YEARS = gql`
  query GetEligibleAssessmentYears {
    getEligibleAssessmentYears {
      year
      businessUnits {
        businessUnitName
        active
      }
    }
  }
`;

const ACTIVATE_BUSINESS_UNIT_YEAR = gql`
  mutation ActiveBusinessUnitForYear($businessUnit: String!, $year: Int!) {
    activateBusinessUnitForYear(businessUnit: $businessUnit, year: $year) {
      year
      businessUnits {
        businessUnitName
        active
      }
    }
  }
`;

export { GET_ELIGIBLE_ASSESSMENT_YEARS, ACTIVATE_BUSINESS_UNIT_YEAR };
