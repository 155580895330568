import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUser = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M8 15.59h8a3.15 3.15 0 003.15-3.15V6A3.151 3.151 0 0016 2.83h-.3v-1.1a1.49 1.49 0 00-1.49-1.5H10a1.49 1.49 0 00-1.54 1.5v1.1H8A3.15 3.15 0 004.83 6v6.46A3.15 3.15 0 008 15.59zM10 1.7h4.27v1.13H9.94L10 1.7zM6.3 6A1.68 1.68 0 018 4.3h8A1.68 1.68 0 0117.7 6v6.46a1.681 1.681 0 01-1.7 1.66H8a1.682 1.682 0 01-1.7-1.68V6zm14.27 11.64a4.1 4.1 0 00-1.84-.42H5C1.23 17.38.23 21.09.23 23a.735.735 0 101.47 0c0-.17 0-4.2 3.35-4.34h13.68c.408-.006.812.083 1.18.26A4.25 4.25 0 0122.3 23a.73.73 0 101.46.01 5.721 5.721 0 00-3.19-5.37z" />
      <path d="M9.8 8.84a.72.72 0 100-1.44.72.72 0 000 1.44zm4.4 0a.72.72 0 100-1.44.72.72 0 000 1.44zm.26 1.5a.38.38 0 00-.49.21s-.37.86-2 .86c-1.6 0-1.89-.81-1.91-.85a.38.38 0 00-.72.22c0 .06.43 1.39 2.63 1.39 2.23 0 2.73-1.29 2.75-1.35a.37.37 0 00-.26-.48z" />
    </g>
  );
});
LegoUser.displayName = 'LegoUser';
