import { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';
import { Dropzone } from './Dropzone';
import { logger } from '../utils/logger';
import {
  addSnackbar,
  snackbarTypes,
  snackbarDefaultMessages,
} from '../utils/snackbar';
import { imageUploadErrorTypes } from '../constants';

const ImageUpload = ({ onSuccess, isDisabled, questionId, uploadId }) => {
  const acceptedFiles = ['image/*'];
  const [uploadProgress, setUploadProgress] = useState(0);

  const imageUploadErrorHandler = (errorsArray) => {
    const error = errorsArray[0].errors[0];
    let errorMessage;

    switch (error.code) {
      case imageUploadErrorTypes.FILE_INVALID_TYPE:
        errorMessage = 'Invalid file type. Only images are allowed.';
        break;

      case imageUploadErrorTypes.TOO_MANY_FILES:
        errorMessage = 'Too many files. Please upload images one by one.';
        break;

      case imageUploadErrorTypes.FILE_TOO_LARGE:
        errorMessage = 'Image is too large. Max size is 10 MB.';
        break;

      default:
        errorMessage = snackbarDefaultMessages.errorText;
    }

    return addSnackbar({
      header: snackbarDefaultMessages.errorHeader,
      text: errorMessage,
      type: snackbarTypes.ERROR,
    });
  };

  const uploadToS3 = async (file) => {
    if (!file) return false;

    const uuid = uuidv4();
    const filePath = `${uploadId}/qid-${questionId}/${uuid}/${file.path}`;
    try {
      await Storage.put(filePath, file, {
        contentType: file.type,
        progressCallback(progress) {
          const uploadProgressInPercent = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setUploadProgress(uploadProgressInPercent);
        },
      });
      setUploadProgress(0);
      onSuccess(filePath);
    } catch (error) {
      addSnackbar({
        header: snackbarDefaultMessages.errorHeader,
        text: snackbarDefaultMessages.errorText,
        type: snackbarTypes.ERROR,
      });
      logger.logError(error);
    }
  };

  return (
    <Dropzone
      acceptedFiles={acceptedFiles}
      uploadProgress={uploadProgress}
      onDrop={(acceptedFiles) => uploadToS3(acceptedFiles[0])}
      onDropRejected={imageUploadErrorHandler}
      isDisabled={isDisabled || !!uploadProgress}
    />
  );
};

ImageUpload.propTypes = {
  onSuccess: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
  questionId: PropTypes.string.isRequired,
  uploadId: PropTypes.string.isRequired,
};

export { ImageUpload };
