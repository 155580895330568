import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const AnalyticsBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M18.876 6.451A2.978 2.978 0 0118.5 5a.153.153 0 00-.06-.121.157.157 0 00-.133-.025l-5 1.4a.253.253 0 00-.154.357c.227.428.346.905.347 1.389a.208.208 0 00.263.2l4.961-1.386a.25.25 0 00.152-.363zm-.058 9.495a.248.248 0 00-.17-.136l-4.842-1.127a.248.248 0 00-.307.249V15c0 .445-.098.884-.29 1.285a.251.251 0 00.17.352l4.813 1.121a.255.255 0 00.213-.049.25.25 0 00.094-.2V17.5c0-.463.108-.92.315-1.335a.255.255 0 00.004-.219z" />
        <path d="M1 0a1 1 0 00-1 1v21.522c.004.819.67 1.48 1.489 1.478H23a1 1 0 100-2H2.25a.249.249 0 01-.25-.25v-.05a.25.25 0 01.119-.213l6.314-3.881a.25.25 0 00.039-.4 3 3 0 01-.778-1.147.25.25 0 00-.365-.124l-4.948 3.043A.251.251 0 012 18.765v-2.649a.25.25 0 01.1-.2l6.636-5.1a.25.25 0 000-.4 3.022 3.022 0 01-.9-1.056.25.25 0 00-.375-.084L2.4 13.162a.25.25 0 01-.4-.2V1a1 1 0 00-1-1z" />
        <path d="M12.5 8a2 2 0 11-4 0 2 2 0 014 0zm11-3a2 2 0 11-4 0 2 2 0 014 0zm0 12.5a2 2 0 11-4 0 2 2 0 014 0zm-11-2.5a2 2 0 11-4 0 2 2 0 014 0z" />
      </g>
    );
  }
);
AnalyticsBold.displayName = 'AnalyticsBold';
