import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ListSearchBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M3 17.5h6.25a1 1 0 110 2H3a3 3 0 01-3-3V3a3 3 0 013-3h15.5a3 3 0 013 3v6.75a1 1 0 11-2 0V7.5A.5.5 0 0019 7H2.5a.5.5 0 00-.5.5v9a1 1 0 001 1zm8-14a1 1 0 10-2 0 1 1 0 002 0zm-4-1a1 1 0 110 2 1 1 0 010-2zm-2 1a1 1 0 10-2 0 1 1 0 002 0z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M21.64 19.948a.25.25 0 00.034.311l2.033 2.033a1 1 0 01-1.414 1.415l-2.033-2.034a.251.251 0 00-.311-.033 5.5 5.5 0 111.69-1.692zM13.5 17a3.5 3.5 0 107 0 3.5 3.5 0 00-7 0z"
          fillRule="evenodd"
        />
        <path d="M10.528 9.059l-3.41 3.558a.25.25 0 01-.361 0l-.535-.558a1 1 0 10-1.444 1.383l1.438 1.5a1 1 0 001.443 0l4.313-4.5a1 1 0 10-1.444-1.383z" />
      </g>
    );
  }
);
ListSearchBold.displayName = 'ListSearchBold';
