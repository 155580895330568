import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BookmarkFavourite = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M18.75 0A2.25 2.25 0 0121 2.25v20.252a1.5 1.5 0 01-2.382 1.21L12.44 19.22a.75.75 0 00-.882 0l-6.177 4.492A1.5 1.5 0 013 22.5V2.25A2.25 2.25 0 015.25 0h13.5zm0 1.5H5.25a.75.75 0 00-.75.75v20.248l6.177-4.492a2.25 2.25 0 012.646 0l6.177 4.492V2.25a.75.75 0 00-.75-.75z" />
      </g>
    );
  }
);
BookmarkFavourite.displayName = 'BookmarkFavourite';
