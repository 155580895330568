import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesWavBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M12.89 11.52a.63.63 0 00-.63.63v1.35h1.26v-1.35a.63.63 0 00-.63-.63z" />
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM8.57 17.89a.66.66 0 01-.58.07.62.62 0 01-.39-.43l-.53-1.89-.48 1.88a.61.61 0 01-.4.44.57.57 0 01-.22 0 .62.62 0 01-.38-.07c-2.18-1.59-2-6.46-1.93-7a.64.64 0 111.27.07 12 12 0 00.74 5.05l.76-3a.65.65 0 01.62-.48.64.64 0 01.62.47l.79 2.89a11.65 11.65 0 00.54-5 .655.655 0 011.31 0c.28 5.46-1.53 6.86-1.74 7zm6.22-.5a.64.64 0 01-1.27 0v-2.61h-1.26v2.62a.64.64 0 11-1.28 0v-5.25a1.91 1.91 0 113.81 0v5.24zm3.52.44a.61.61 0 01-.42.17.63.63 0 01-.43-.19c-2-2.07-2-6.74-2-6.94a.61.61 0 01.62-.61.61.61 0 01.61.61s0 3.57 1.23 5.52a8.88 8.88 0 001.19-5.5.61.61 0 01.58-.64.6.6 0 01.65.57c.27 4.75-1.93 6.93-2.03 7.07v-.06z" />
      </g>
    );
  }
);
FilesWavBold.displayName = 'FilesWavBold';
