import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LevelIndicatorBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M12.047 4C8.835 4 5.723 5.305 3.514 7.614 1.204 9.924 0 12.935 0 16.248v2.109a2.228 2.228 0 002.209 2.208H7.83c.502 0 .401-1.004.401-1.506 0-1.907 1.406-3.413 3.213-3.714l2.108-6.325c.1-.402.502-.603.904-.502.402.1.602.502.502.903l-2.008 5.924c1.406.401 2.41 1.506 2.71 2.911 0 .201.101.502.101.703 0 .502-.301 1.506.502 1.506h5.522a2.228 2.228 0 002.209-2.209v-2.108C24.195 9.522 18.774 4.1 12.047 4zm7.028 7.931c0 .803-.602 1.406-1.405 1.406-.804 0-1.406-.603-1.406-1.406 0-.702.602-1.305 1.305-1.405h.1c.804 0 1.406.703 1.406 1.405zm.1 5.12c-.803 0-1.405-.602-1.405-1.405s.602-1.406 1.406-1.406c.803 0 1.405.603 1.405 1.406 0 .803-.602 1.405-1.405 1.405zM7.029 13.137c-.201.1-.402.2-.603.2-.803 0-1.405-.702-1.405-1.405 0-.803.602-1.405 1.405-1.405.201 0 .402.1.603.2.401.201.803.703.803 1.205 0 .502-.301 1.004-.803 1.205zm-2.11 1.103c.804 0 1.406.603 1.406 1.406 0 .803-.602 1.405-1.406 1.405-.803 0-1.405-.602-1.405-1.405 0-.703.602-1.406 1.405-1.406zm4.518-4.015c-.803 0-1.405-.603-1.405-1.306 0-.2.1-.401.2-.602a1.235 1.235 0 011.105-.703h.1c.502 0 .904.301 1.205.703.1.2.2.402.2.602 0 .703-.702 1.306-1.405 1.306z" />
      </g>
    );
  }
);
LevelIndicatorBold.displayName = 'LevelIndicatorBold';
