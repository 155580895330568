import { mode } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['popper', 'content', 'header', 'body', 'footer', 'arrow'];

const baseStylePopper = {
  w: '100%',
  maxW: 'xs',
  zIndex: 10,
};

const baseStyleContent = (props: Dict) => {
  const backgroundColor = mode('white', 'slate.600')(props) as string;
  const borderColor = mode('slate.300', 'slate.800')(props) as string;

  /**
   * Note, ideally the box-shadow for the popper-arrow should have a 0 blur and a 1px spread (to
   * make it sharp, like the other borders on the popover), but that definition is buried deep in
   * chakra ( packages/popper/src/utils.ts #getBoxShadow ) with hardcoded values.
   */
  return {
    backgroundColor,
    '--popper-arrow-bg': `colors.${backgroundColor}`,
    '--popper-arrow-shadow-color': `colors.${borderColor}`,
    '--popper-arrow-size': '9px',
    p: '5',
    border: '1px solid',
    borderColor,
    borderRadius: 'base',
    boxShadow: '2',
    zIndex: 'inherit',
    _focusVisible: {
      outline: 0,
      boxShadow: 'focusVisible',
    },
    _focus: {
      outline: 0,
    },
  };
};

const baseStyleArrow = {
  //TODO Apply arrow bg + size here if/when Chakra UI actually applies the arrow part of the theme
  // to the arrow component. See: https://github.com/chakra-ui/chakra-ui/blob/7ee4f9c5f8097b84970f27727382d4686264ce95/packages/popover/src/popover.tsx#L204
};

const baseStyleHeader = {
  textStyle: 'md',
  fontWeight: 'medium',
};

const baseStyleBody = {
  textStyle: 'sm',
  pt: 1,
};

const baseStyleFooter = {
  pt: 4,
};

const baseStyle = (props: Dict) => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: baseStyleArrow,
});

export const Popover = {
  parts,
  baseStyle,
};
