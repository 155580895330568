import { css } from '@emotion/core';
import { colors } from '../config';

const sectionDividerStyle = css({
  borderTop: `2px solid ${colors.neutralColors.slate20}`,
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'none',
});

const Divider = () => <hr css={sectionDividerStyle} />;

export { Divider };
