import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ProfitAndLoss = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.334 4.067l-3-3h-.09l-.12-.06h-.24a.75.75 0 00-.21 0h-.06a.749.749 0 00-.23.16l-3 3-.07.08a.75.75 0 00.07 1l.08.07a.75.75 0 001-.07l1.72-1.72v5.17a.75.75 0 001.37-.1v-5.19l1.72 1.72.08.07a.754.754 0 101-1.13h-.02zm-16.08 13.93a.75.75 0 00-1 .07l-1.7 1.72v-5.29a.75.75 0 00-1.49.1v5.19l-1.73-1.72-.08-.07a.754.754 0 10-1 1.13l3 3h.09l.12.06h.34c.07.01.14.01.21 0h.06a.75.75 0 00.23-.15l3-3 .07-.08a.75.75 0 00-.07-1l-.05.04zm5.86-6.68l-2.06-.83h-.08a.59.59 0 01.3-1.1h2.13a.75.75 0 00-.1-1.49h-.75v-.1a.75.75 0 00-1.49.1 2.09 2.09 0 00-.56 4l2.07.83h.08a.59.59 0 01-.3 1.1h-2.15a.75.75 0 00.1 1.49h.75v.1a.75.75 0 001.49-.1 2.09 2.09 0 00.56-4h.01z" />
        <path d="M11.805 4.847a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zm0 12a5.25 5.25 0 110-10.5 5.25 5.25 0 010 10.5z" />
      </g>
    );
  }
);
ProfitAndLoss.displayName = 'ProfitAndLoss';
