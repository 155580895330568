import { actionTypes } from './actionTypes';

const actionTypesTranslations = {
  [actionTypes.APPROVE]: 'Approve',
  [actionTypes.REJECT]: 'Reject',
  [actionTypes.RESCORE]: 'Rescore',
  [actionTypes.PERFORM]: 'Fill in on behalf of customer',
};

export { actionTypesTranslations };
