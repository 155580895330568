import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BrickBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6.77 15.22a2 2 0 100 4 2 2 0 000-4zm-.18-6.44a2 2 0 100-4 2 2 0 000 4zm10.64 0a2 2 0 100-4 2 2 0 000 4z" />
        <path d="M22.17 0H1.83A1.83 1.83 0 000 1.83v20.34A1.83 1.83 0 001.83 24h20.4A1.772 1.772 0 0024 22.23V1.83A1.83 1.83 0 0022.17 0zM6.59 3.23a3.53 3.53 0 11.02 7.06 3.53 3.53 0 01-.02-7.06zm.18 17.54a3.53 3.53 0 110-7.06 3.53 3.53 0 010 7.06zM17.23 3.23a3.53 3.53 0 110 7.06 3.53 3.53 0 010-7.06zm.18 17.54a3.53 3.53 0 11-.02-7.06 3.53 3.53 0 01.02 7.06z" />
        <path d="M17.41 15.22a2 2 0 100 4 2 2 0 000-4z" />
      </g>
    );
  }
);
BrickBold.displayName = 'BrickBold';
