import { gql } from '@apollo/client';

const GET_ASSESSMENTS = gql`
  query GetAssessments($year: Int!) {
    getAssessments(year: $year) {
      customerId
      year
      version
      c6Id
      c6Name
      customerName
      customerEmail
      channel
      omni
      marketGroup
      approve
      review
      perform
      status
      pendingUser
      score
      actions
      tier
      businessUnit
    }
  }
`;

const GET_ASSESSMENT_DATA = gql`
  query GetAssessmentData($customerId: String!, $year: Int!, $version: Int!) {
    getAnswers(customerId: $customerId, year: $year, version: $version) {
      questionId
      isValid
      isComplete
      selectedAnswer
      comment
      images
    }
    getSurvey(customerId: $customerId, year: $year, version: $version) {
      surveyId
      questionId
      headline
      subHeader
      questionType
      conditions
      explanation
      answerOptions {
        answerId
        text
        score
      }
      answersExemptFromAttachments {
        answerId
      }
      furtherDetails
      commentsInTextBox
      uploadHeadline
    }
    getSurveyInfo(customerId: $customerId, year: $year, version: $version) {
      active
      email
      version
      uploadId
      assessmentStatus
      actions
      review
      approve
      assessmentDate
      approvalDate
      pendingUser
      perform
      comments {
        text
        date
        userEmail
      }
      surveyState {
        isComplete
        isEditable
        score
      }
      customerDetail {
        id
        name
        businessUnit
        c6Id
        c6Name
        channel
        omni
        tier
      }
    }
  }
`;

const GET_ASSESSMENT_SCORE = gql`
  query GetAssessmentData($customerId: String!, $year: Int!) {
    getLatestApprovedAssessment(customerId: $customerId, year: $year) {
      score
    }
  }
`;

const APPROVE_ASSESSMENT = gql`
  mutation ApproveAssessment(
    $customerId: String!
    $year: Int!
    $version: Int!
    $comment: String
  ) {
    approveAssessment(
      customerId: $customerId
      year: $year
      version: $version
      comment: $comment
    ) {
      customerName
      customerEmail
    }
  }
`;

const REJECT_ASSESSMENT = gql`
  mutation RejectAssessment(
    $customerId: String!
    $year: Int!
    $version: Int!
    $comment: String
  ) {
    rejectAssessment(
      customerId: $customerId
      year: $year
      version: $version
      comment: $comment
    ) {
      customerName
      customerEmail
    }
  }
`;

const RESCORE_ASSESSMENT = gql`
  mutation RescoreAssessment(
    $customerId: String!
    $year: Int!
    $version: Int!
    $comment: String
  ) {
    rescoreAssessment(
      customerId: $customerId
      year: $year
      version: $version
      comment: $comment
    ) {
      customerName
      customerEmail
    }
  }
`;

const UPDATE_ANSWER = gql`
  mutation UpdateAnswer(
    $customerId: String!
    $answer: AnswerInput!
    $year: Int!
    $version: Int!
  ) {
    updateAnswer(
      customerId: $customerId
      answer: $answer
      year: $year
      version: $version
    ) {
      questionId
    }
  }
`;

const FINISH_SURVEY = gql`
  mutation FinishSurvey($customerId: String!, $year: Int!, $version: Int!) {
    finishSurvey(customerId: $customerId, year: $year, version: $version)
  }
`;

const UPDATE_ALL_ANSWERS = gql`
  mutation UpdateAllAnswers(
    $customerId: String!
    $answers: [AnswerInput!]!
    $year: Int!
    $version: Int!
  ) {
    updateAllAnswers(
      customerId: $customerId
      answers: $answers
      year: $year
      version: $version
    )
  }
`;

const DELETE_ASSESSMENT = gql`
  mutation DeleteAssessment($customerId: String!, $year: Int!, $version: Int!) {
    deleteAssessment(customerId: $customerId, year: $year, version: $version)
  }
`;

const SOFT_DELETE_ASSESSMENT = gql`
  mutation SoftDeleteAssessment(
    $customerId: String!
    $year: Int!
    $version: Int!
  ) {
    softDeleteAssessment(
      customerId: $customerId
      year: $year
      version: $version
    ) {
      customerId
      year
      version
    }
  }
`;

const GET_COMPLETE_PAGE_DATA = gql`
  query getCompletePageData($customerId: String!, $year: Int!, $version: Int!) {
    getSurvey(customerId: $customerId, year: $year, version: $version) {
      headline
      questionId
      questionType
      answersExemptFromAttachments {
        answerId
      }
    }
    getAnswers(customerId: $customerId, year: $year, version: $version) {
      questionId
      isComplete
      selectedAnswer
      images
    }
    getSurveyInfo(customerId: $customerId, year: $year, version: $version) {
      uploadId
      assessmentStatus
      actions
      channelLead
      customerDeveloperResponsible
      assessmentDate
      approvalDate
      pendingUser
      primaryKam
      surveyState {
        isComplete
        isEditable
        score
      }
      customerDetail {
        id
        name
        businessUnit
        c6Id
        c6Name
        channel
        omni
        tier
      }
    }
  }
`;

const ADD_ASSESSMENT_COMMENT = gql`
  mutation AddComment(
    $customerId: String!
    $year: Int!
    $version: Int!
    $comment: String!
  ) {
    addComment(
      customerId: $customerId
      year: $year
      version: $version
      comment: $comment
    ) {
      text
      date
      userEmail
    }
  }
`;

const UPDATE_ASSESSMENT_CHANNEL = gql`
  mutation UpdateAssessmentChannel($input: AssessmentChannelUpdateInput!) {
    updateAssessmentChannel(input: $input) {
      customerId
      year
      version
      channel
      status
      actions
    }
  }
`;

const UPDATE_ASSESSMENT_ASSIGNMENT = gql`
  mutation UpdateAssessmentAssignment($input: AssessmentAssignmentInput!) {
    updateAssessmentAssignment(input: $input) {
      perform
      review
      approve
      actions
      pendingUser
    }
  }
`;

const GET_AVAILABLE_CHANNELS = gql`
  query GetAvailableChannels($year: Int!) {
    getAvailableChannelsForYear(year: $year)
  }
`;

const GET_USER_CONFIRMATION_STATUS = gql`
  query GetUserConfirmationStatus(
    $customerEmail: String!
    $customerId: String!
  ) {
    getUserConfirmationStatus(
      customerEmail: $customerEmail
      customerId: $customerId
    ) {
      created
      linkToCustomer {
        isLinkedToCustomer
        customerId
      }
      confirmationStatus
    }
  }
`;

const SEND_INVITATION_EMAIL = gql`
  mutation SendInvitationEmail(
    $customerId: String!
    $year: Int!
    $version: Int!
    $customerEmail: String!
  ) {
    sendInvitationEmail(
      customerId: $customerId
      year: $year
      version: $version
      customerEmail: $customerEmail
    ) {
      wasEmailSent
    }
  }
`;

export {
  GET_ASSESSMENTS,
  GET_ASSESSMENT_DATA,
  GET_ASSESSMENT_SCORE,
  APPROVE_ASSESSMENT,
  REJECT_ASSESSMENT,
  RESCORE_ASSESSMENT,
  UPDATE_ANSWER,
  FINISH_SURVEY,
  UPDATE_ALL_ANSWERS,
  GET_COMPLETE_PAGE_DATA,
  DELETE_ASSESSMENT,
  ADD_ASSESSMENT_COMMENT,
  UPDATE_ASSESSMENT_CHANNEL,
  GET_AVAILABLE_CHANNELS,
  UPDATE_ASSESSMENT_ASSIGNMENT,
  SOFT_DELETE_ASSESSMENT,
  GET_USER_CONFIRMATION_STATUS,
  SEND_INVITATION_EMAIL,
};
