import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudDownload = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M12.185 12.194a.812.812 0 01.812.811v7.768l2.674-2.672a.812.812 0 011.148 1.147l-4.06 4.014a.812.812 0 01-.564.238.813.813 0 01-.53-.238l-4.114-4.014A.826.826 0 118.74 18.1l2.675 2.672v-7.768a.81.81 0 01.769-.811z" />
        <path d="M19.222 16.608a.813.813 0 01-.695-1.249.812.812 0 01.457-.341c.14 0 3.4-1.083 3.4-4.328a4.326 4.326 0 00-3.768-4.458 1.083 1.083 0 01-.953-.703 5.649 5.649 0 00-5.944-3.42c-4.396.25-5.413 4.329-5.717 5.692a1.147 1.147 0 01-1.082.93 3.023 3.023 0 00-3.248 2.965A2.746 2.746 0 002.3 13.86a4.138 4.138 0 002.62 1.082.812.812 0 01.79.833.865.865 0 01-.823.79 5.751 5.751 0 01-3.778-1.59 4.327 4.327 0 01-1.083-3.366 4.554 4.554 0 014.472-4.425C5.894 1.948 9.13.692 11.622.541a7.356 7.356 0 017.416 4.155 5.934 5.934 0 014.959 6.037 6.338 6.338 0 01-4.558 5.832.793.793 0 01-.217.043z" />
      </g>
    );
  }
);
CloudDownload.displayName = 'CloudDownload';
