import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StatusInformation = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M3.515 3.515a12 12 0 1116.97 16.97 12 12 0 01-16.97-16.97zM12 1.5a10.5 10.5 0 100 21 10.5 10.5 0 000-21z"
          fillRule="evenodd"
        />
        <path d="M12.044 7.016a1.01 1.01 0 001.026-1.008C13.07 5.45 12.602 5 12.044 5 11.468 5 11 5.45 11 6.008c0 .576.468 1.008 1.044 1.008zm-.702 11.106h1.386V9.356h-1.386v8.766z" />
      </g>
    );
  }
);
StatusInformation.displayName = 'StatusInformation';
