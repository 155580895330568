import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MinusBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M23.935 12a1.5 1.5 0 01-1.5 1.5H1.565a1.5 1.5 0 010-3h20.87a1.5 1.5 0 011.5 1.5z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
MinusBold.displayName = 'MinusBold';
