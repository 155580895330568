import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudUploadBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M24 10.35a6.172 6.172 0 00-1.772-4.335 5.616 5.616 0 00-3.446-1.649.248.248 0 01-.191-.125C16.974 1.43 13.87-.072 10.763.454 7.654.98 5.17 3.427 4.493 6.628a.255.255 0 01-.227.208c-2.4.178-4.26 2.254-4.262 4.757a4.6 4.6 0 001.236 3.34 5.398 5.398 0 003.604 1.436.995.995 0 00.858-.534 1.077 1.077 0 00-.015-1.04.992.992 0 00-.873-.507 3.213 3.213 0 01-2.172-.832 2.53 2.53 0 01-.639-1.863 2.728 2.728 0 01.954-2.09 2.525 2.525 0 012.167-.548.97.97 0 00.81-.193c.23-.183.372-.461.39-.762.153-2.7 2.046-4.947 4.598-5.46 2.552-.512 5.113.842 6.22 3.288.163.383.539.62.94.593a3.62 3.62 0 012.758 1.093A3.98 3.98 0 0122 10.349c-.014 2.033-1.494 3.73-3.437 3.94-.525.069-.907.551-.872 1.1.035.549.475.975 1.004.971a.927.927 0 00.13-.009C21.77 16.016 24 13.43 24 10.35z" />
        <path d="M12.709 8.868a.973.973 0 00-1.414 0l-3.5 3.64a1.07 1.07 0 00-.22 1.138 1 1 0 00.927.642h1.75c.066 0 .13.027.177.076a.264.264 0 01.073.184v7.542c0 .861.672 1.56 1.5 1.56s1.5-.699 1.5-1.56v-7.541a.27.27 0 01.072-.185.244.244 0 01.178-.076h1.75a1 1 0 00.923-.642c.155-.388.07-.836-.217-1.133l-3.5-3.645z" />
      </g>
    );
  }
);
CloudUploadBold.displayName = 'CloudUploadBold';
