import { IListItemProps, ListItem as ListItemInternal } from './ListItem';

export * from './List';
export * from './OrderedList';
export * from './UnorderedList';
export { IListItemProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `List.Item` instead
 */
export const ListItem = ListItemInternal;
