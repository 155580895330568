// eslint-disable-next-line import/no-named-as-default
import CSSReset from '@chakra-ui/css-reset';
import { PortalManager } from '@chakra-ui/portal';
import {
  ColorModeProvider,
  ColorModeProviderProps,
  GlobalStyle,
  ThemeProvider,
} from '@chakra-ui/system';
import { Dict } from '@chakra-ui/utils';
import { theme as defaultTheme, Theme } from '@lego/klik-ui-theme';
import * as React from 'react';

export interface IKlikProviderProps {
  /**
   * a theme. if omitted, uses the default theme provided by klik
   */
  theme?: Dict | Theme;
  /**
   * Common z-index to use for `Portal`
   *
   * @default undefined
   */
  portalZIndex?: number;
  /**
   * If `true`, `CSSReset` component will be mounted to help
   * you reset browser styles
   *
   * @default true
   */
  resetCSS?: boolean;
  /**
   * manager to persist a users color mode preference in
   *
   * omit if you don't render server-side
   * for SSR: choose `cookieStorageManager`
   *
   * @default localStorageManager
   */
  colorModeManager?: ColorModeProviderProps['colorModeManager'];
  children?: React.ReactNode;
}

/**
 * The global provider that must be added to make all Klik components work correctly
 */
export const KlikProvider: React.FunctionComponent<IKlikProviderProps> = ({
  children,
  colorModeManager,
  portalZIndex,
  resetCSS: shouldResetCSS = true,
  theme = defaultTheme,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <ColorModeProvider colorModeManager={colorModeManager} options={theme.config}>
        {shouldResetCSS && <CSSReset />}
        <GlobalStyle />
        {portalZIndex ? <PortalManager zIndex={portalZIndex}>{children}</PortalManager> : children}
      </ColorModeProvider>
    </ThemeProvider>
  );
};
