import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SeoSearch = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#seo-search_svg__a)" clipRule="evenodd" fillRule="evenodd">
          <path d="M18.821 2.274a2.4 2.4 0 100 4.8 2.4 2.4 0 000-4.8zm-3.916 2.4a3.916 3.916 0 117.832 0 3.916 3.916 0 01-7.832 0z" />
          <path d="M20.518 6.37a.758.758 0 011.072 0l2.188 2.189a.758.758 0 11-1.072 1.072l-2.188-2.19a.758.758 0 010-1.071zM2.274 3.537a.758.758 0 00-.758.758V5.81c0 .418.34.757.758.757h10.61a.758.758 0 110 1.516H2.274A2.274 2.274 0 010 5.811V4.295A2.274 2.274 0 012.274 2.02h10.61a.758.758 0 110 1.516H2.274zm-.758 7.958a1.137 1.137 0 112.273 0 1.137 1.137 0 01-2.273 0zm3.789.379c0-.419.34-.758.758-.758H18.19a.758.758 0 110 1.516H6.062a.758.758 0 01-.758-.758zM1.516 16.8a1.137 1.137 0 112.273 0 1.137 1.137 0 01-2.273 0zm3.789.379c0-.419.34-.758.758-.758H18.19a.758.758 0 110 1.516H6.062a.758.758 0 01-.758-.758zm-3.789 4.926a1.137 1.137 0 112.274 0 1.137 1.137 0 01-2.274 0zm3.789.379c0-.418.34-.758.758-.758H18.19a.758.758 0 010 1.516H6.062a.758.758 0 01-.758-.758z" />
        </g>
        <defs>
          <clipPath id="seo-search_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
SeoSearch.displayName = 'SeoSearch';
