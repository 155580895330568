import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StopwatchBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M13.5 1.5h.75a.75.75 0 000-1.5h-4.5a.75.75 0 000 1.5h.75v3.116a9.75 9.75 0 108.901 3.287l1.32-1.319a.75.75 0 00.06-1.115l-.75-.75a.75.75 0 00-1.11.054l-1.424 1.424a9.755 9.755 0 00-3.997-1.58V1.5zM7.395 12.395l3.307 3.307c.484.484 1.351.402 1.937-.184.586-.586.668-1.453.184-1.938l-3.307-3.306c-.484-.484-1.351-.402-1.937.184-.586.586-.668 1.453-.184 1.937z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
StopwatchBold.displayName = 'StopwatchBold';
