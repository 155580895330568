import {
  SliderFilledTrack as SliderFilledTrackComponent,
  SliderInnerTrackProps,
} from '@chakra-ui/slider';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type ISliderFilledTrackProps = SliderInnerTrackProps;

export const SliderFilledTrack = forwardRef<ISliderFilledTrackProps, 'div'>((props, ref) => {
  return <SliderFilledTrackComponent {...props} ref={ref} />;
});
