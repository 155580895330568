import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFacelessFemale1 = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#lego-user-faceless-female-1_svg__a)">
          <path d="M22.06 14.57a.71.71 0 000-1A3.52 3.52 0 0121 9.71c.59-2 1.11-5.89-.58-8.13A4.23 4.23 0 0016.84 0 5.16 5.16 0 0013 1.86c-1.34-.25-5-.7-7.09 1.42C4.33 4.83 4 7.36 4.86 10.8V12A3.77 3.77 0 006 14.7a3.72 3.72 0 002.65 1.1h5.68a3.71 3.71 0 002.2-.73s3.13 1.63 5.53-.5zm-7.77-.27H8.61A2.251 2.251 0 016.36 12V8.14c1.87-.12 6.59-.63 8.49-2.6a1.82 1.82 0 011 .55 3.5 3.5 0 01.7 1.91v4a2.25 2.25 0 01-.659 1.591c-.422.422-1.004.709-1.6.709zm3.27-.3c.29-.385.47-.841.52-1.32 0-.05.06-2.29.06-2.35V8.68c0-.68-.003-1.447-.26-2.123A4.46 4.46 0 0016.66 4.8a3.07 3.07 0 00-2.21-.8.75.75 0 00-.53.3C12.7 6 8 6.57 5.92 6.68a3.82 3.82 0 011-2.34c2-2 6.13-.93 6.17-.92a.75.75 0 00.84-.35l.11-.17a3.67 3.67 0 012.81-1.42 2.76 2.76 0 012.36 1c1.26 1.67.88 5 .32 6.87 0 0-1 2.92.94 4.65a3.5 3.5 0 01-2.91 0zm4.86 6a6.09 6.09 0 00-2.37-2.42 3.63 3.63 0 00-1.8-.44H5.09c-3.67.17-4.64 4.06-4.64 6.1a.75.75 0 001.49 0s0-4.46 3.18-4.61h13.14a2.29 2.29 0 011.07.26 4.6 4.6 0 012.31 4.3.75.75 0 00.69.79h.05a.75.75 0 00.74-.7 6.08 6.08 0 00-.7-3.28z" />
        </g>
        <defs>
          <clipPath id="lego-user-faceless-female-1_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
LegoUserFacelessFemale1.displayName = 'LegoUserFacelessFemale1';
