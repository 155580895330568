import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LogOut = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M21.874 1l.04.001a2.196 2.196 0 012.084 2.272v17.454A2.195 2.195 0 0121.875 23H9.947l-.04-.001a2.197 2.197 0 01-2.085-2.272v-4.355a.8.8 0 011.6 0v4.371l-.001.036a.596.596 0 00.55.622H21.85a.597.597 0 00.55-.658V3.22a.595.595 0 00-.55-.62H9.972a.597.597 0 00-.55.657v4.371a.8.8 0 01-1.6 0V3.273A2.195 2.195 0 019.948 1h11.927z" />
      <path d="M5.01 7.795a.8.8 0 00-1.131 0L.242 11.431a.797.797 0 000 1.147l3.637 3.634a.8.8 0 001.131-1.13l-2.279-2.278h13.375a.8.8 0 100-1.6H2.73l2.28-2.278a.8.8 0 000-1.13z" />
    </g>
  );
});
LogOut.displayName = 'LogOut';
