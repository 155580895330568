import { ThemeComponentProps } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

const parts = ['container', 'thumb', 'track', 'filledTrack'];

const baseStyleContainer = (props: ThemeComponentProps) => {
  return {
    _disabled: {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
    },
    w: '100%',
  };
};

const baseStyleTrack = (props: ThemeComponentProps) => {
  return {
    borderRadius: 'sm',
    bg: mode('slate.100', 'slate.200')(props),
    height: '4px',
  };
};

const baseStyleThumb = (props: ThemeComponentProps) => {
  const { colorScheme: c } = props;

  return {
    width: '6',
    height: '6',
    zIndex: 1,
    borderRadius: 'full',
    bg: mode(`${c}.500`, `${c}.200`)(props),
    boxShadow: 'button',
    transition: 'transform 0.1s',
    top: '50%',
    _focus: {
      boxShadow: 'outline',
    },
    transform: `translateY(-50%)`,
    _hover: {
      bg: mode(`${c}.600`, `${c}.200`)(props),
      transform: `translateY(-50%) scale(1.15)`,
    },
  };
};

const baseStyleFilledTrack = (props: ThemeComponentProps) => {
  const { colorScheme: c } = props;

  return {
    bg: mode(`${c}.400`, `${c}.200`)(props),
  };
};

const baseStyle = (props: ThemeComponentProps) => ({
  container: baseStyleContainer(props),
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props),
  filledTrack: baseStyleFilledTrack(props),
});

const defaultProps = {
  size: 'md',
  colorScheme: 'light-blue',
};

export const Slider = {
  parts,
  baseStyle,
  defaultProps,
};
