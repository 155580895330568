import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SaveBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M22 0H5.914c-.53-.001-1.04.21-1.414.587L.586 4.5A2.009 2.009 0 000 5.915V22a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-3.5 2a.5.5 0 01.5.5v4A1.5 1.5 0 0117.5 8h-10A1.5 1.5 0 016 6.5v-4a.5.5 0 01.5-.5h12zm-14 19.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-8A1.5 1.5 0 0019 12H6a1.5 1.5 0 00-1.5 1.5v8z"
        fillRule="evenodd"
      />
      <path d="M15 4.251v1.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-1.5 0zm-8 12h6.5a.75.75 0 000-1.5H7a.75.75 0 000 1.5zm0 4h11a.75.75 0 000-1.5H7a.75.75 0 000 1.5z" />
    </g>
  );
});
SaveBold.displayName = 'SaveBold';
