import { Input as InputComponent, InputProps } from '@chakra-ui/input';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import * as React from 'react';
import { InputLeftAddon, InputRightAddon } from './InputAddon';
import { InputLeftElement, InputRightElement } from './InputElement';
import { InputGroup } from './InputGroup';

export type IInputProps = InputProps;

type IInputComponent = ComponentWithAs<'input', IInputProps> & {
  RightAddon: typeof InputRightAddon;
  LeftAddon: typeof InputLeftAddon;
  LeftElement: typeof InputLeftElement;
  RightElement: typeof InputRightElement;
  Group: typeof InputGroup;
};

export const Input = forwardRef<IInputProps, 'input'>(({ isReadOnly, ...rest }, ref) => {
  return (
    <InputComponent isReadOnly={isReadOnly} tabIndex={isReadOnly ? -1 : 0} {...rest} ref={ref} />
  );
}) as IInputComponent;

Input.id = 'Input';

Input.LeftAddon = InputLeftAddon;
Input.RightAddon = InputRightAddon;
Input.LeftElement = InputLeftElement;
Input.RightElement = InputRightElement;
Input.Group = InputGroup;
