import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesToDo = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M10.12 9.053c.33.25.398.723.149 1.056l-2.132 2.856a1.503 1.503 0 01-1.094.599 1.49 1.49 0 01-1.166-.438L4.72 11.965a.756.756 0 010-1.066.747.747 0 011.06 0l1.157 1.161L9.07 9.204a.748.748 0 011.05-.15zm.149 7.84a.756.756 0 00-.15-1.056.748.748 0 00-1.05.151l-2.132 2.856-1.157-1.163a.747.747 0 00-1.06 0 .757.757 0 000 1.066l1.157 1.163a1.498 1.498 0 001.778.258c.188-.103.353-.246.482-.419l2.132-2.856zM12 12.814c0-.416.336-.754.75-.754h4.5c.414 0 .75.338.75.754a.752.752 0 01-.75.754h-4.5a.752.752 0 01-.75-.754zm.75 5.276a.752.752 0 00-.75.755c0 .416.336.753.75.753h4.5c.414 0 .75-.337.75-.753a.752.752 0 00-.75-.754h-4.5z" />
        <path
          clipRule="evenodd"
          d="M22.5 21.739c0 .6-.237 1.175-.659 1.599A2.244 2.244 0 0120.25 24H3.75a2.244 2.244 0 01-1.591-.662c-.422-.424-.659-1-.659-1.6V2.262c0-.6.237-1.175.659-1.599A2.244 2.244 0 013.75 0h10.629c.596 0 1.168.238 1.59.662l5.873 5.902c.421.424.658.998.658 1.598v13.577zM3.22 1.729a.748.748 0 01.53-.221h9.75v5.276c0 .6.237 1.175.659 1.599.422.424.994.662 1.591.662H21V21.74a.756.756 0 01-.75.753H3.75A.748.748 0 013 21.74V2.26c0-.2.079-.391.22-.533zm17.47 5.809h-4.94a.748.748 0 01-.75-.754V1.82l5.69 5.718z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
FilesToDo.displayName = 'FilesToDo';
