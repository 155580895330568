import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesPhoneBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#devices-phone-bold_svg__a)">
          <path d="M5.48.642l4 3.331a1.2 1.2 0 01.31 1.47l-.942 2.241a1.728 1.728 0 00.301 2.001l5.562 5.362c.541.56 1.393.3 2.074-.07l1.443-.79a1.264 1.264 0 011.574.3l3.858 3.91a1.998 1.998 0 01-.07 2.332l-1.604 1.25c-2.114 1.67-6.363 2.47-8.598 1A50.736 50.736 0 011.261 11.176a7.343 7.343 0 01.27-8.603L2.876.833A2.006 2.006 0 015.48.642z" />
        </g>
        <defs>
          <clipPath id="devices-phone-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
DevicesPhoneBold.displayName = 'DevicesPhoneBold';
