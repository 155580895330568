import { Select as SelectComponent, SelectProps } from '@chakra-ui/select';
import { forwardRef } from '@chakra-ui/system';
import { ArrowDownBold } from '@lego/klik-ui-icons';
import * as React from 'react';

export type ISelectProps = SelectProps;

export const Select = forwardRef<ISelectProps, 'select'>(({ icon, ...rest }, ref) => {
  const selectIcon = icon ? icon : <ArrowDownBold data-icon-fixed="true" />;

  return <SelectComponent icon={selectIcon} {...rest} ref={ref} />;
});
