import { Menu as MenuComponent, MenuProps } from '@chakra-ui/menu';
import * as React from 'react';
import { MenuButton } from './MenuButton';
import { MenuDivider } from './MenuDivider';
import { MenuGroup } from './MenuGroup';
import { MenuIcon } from './MenuIcon';
import { MenuItem } from './MenuItem';
import { MenuItemOption } from './MenuItemOption';
import { MenuList } from './MenuList';
import { MenuOptionGroup } from './MenuOptionGroup';

export type IMenuProps = MenuProps;

type IMenuComponent = React.FunctionComponent<IMenuProps> & {
  Button: typeof MenuButton;
  Divider: typeof MenuDivider;
  Group: typeof MenuGroup;
  Icon: typeof MenuIcon;
  Item: typeof MenuItem;
  ItemOption: typeof MenuItemOption;
  List: typeof MenuList;
  OptionGroup: typeof MenuOptionGroup;
};

export const Menu: IMenuComponent = ({ autoSelect = false, gutter = 4, ...props }) => {
  return <MenuComponent autoSelect={autoSelect} gutter={gutter} {...props} />;
};

Menu.Button = MenuButton;
Menu.Divider = MenuDivider;
Menu.Group = MenuGroup;
Menu.Icon = MenuIcon;
Menu.Item = MenuItem;
Menu.ItemOption = MenuItemOption;
Menu.List = MenuList;
Menu.OptionGroup = MenuOptionGroup;
