import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Discount = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M5 1.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0 1.5a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zm13.5 12a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0 1.5a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5z"
        fillRule="evenodd"
      />
      <path d="M21.726 2.22a.75.75 0 00-1.06 0L1.22 21.312l-.067.076a.75.75 0 001.127.984L21.726 3.28l.067-.076a.75.75 0 00-.067-.984z" />
    </g>
  );
});
Discount.displayName = 'Discount';
