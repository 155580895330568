import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesWord = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.247 14.998a.75.75 0 00-.75.75v7.5a.75.75 0 00.75.75 3.75 3.75 0 003.75-3.75v-1.5a3.75 3.75 0 00-3.75-3.75zm.75 7.371v-5.742a2.249 2.249 0 011.5 2.121v1.5a2.25 2.25 0 01-1.5 2.121zm5.159-6.712a2.25 2.25 0 013.841 1.591v4.5a2.25 2.25 0 01-4.5 0v-4.5c0-.597.237-1.169.66-1.591zm1.591.841a.75.75 0 00-.75.75v4.5a.75.75 0 101.5 0v-4.5a.75.75 0 00-.75-.75z"
          fillRule="evenodd"
        />
        <path d="M23.247 14.998a3.75 3.75 0 00-3.75 3.75v1.5a3.75 3.75 0 003.75 3.75.75.75 0 000-1.5 2.25 2.25 0 01-2.25-2.25v-1.5a2.25 2.25 0 012.25-2.25.75.75 0 000-1.5z" />
      </g>
    );
  }
);
FilesWord.displayName = 'FilesWord';
