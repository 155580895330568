import { MenuItemOptionProps, StyledMenuItemProps, useMenuOption } from '@chakra-ui/menu';
import { MaybeRenderProp } from '@chakra-ui/react-utils';
import { chakra, forwardRef, SystemStyleObject, useStyles } from '@chakra-ui/system';
import { cx, isFunction } from '@chakra-ui/utils';
import { CheckTickBold } from '@lego/klik-ui-icons';
import * as React from 'react';
import { MenuIcon } from './MenuIcon';

export type IMenuItemOptionProps = MenuItemOptionProps & {
  children: MaybeRenderProp<{
    isChecked?: boolean;
    isDisabled?: boolean;
    value?: string;
    type?: 'checkbox' | 'radio';
  }>;
};

const StyledMenuItem = forwardRef<StyledMenuItemProps, 'button'>((props, ref) => {
  const { type, ...rest } = props;
  const styles = useStyles();

  /**
   * Given another component, use its type if present
   * Else, use no type to avoid invalid html, e.g. <a type="button" />
   * Else, fall back to "button"
   */
  const btnType = rest.as ? type ?? undefined : 'button';

  const buttonStyles: SystemStyleObject = {
    textDecoration: 'none',
    color: 'inherit',
    userSelect: 'none',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    textAlign: 'start',
    flex: '0 0 auto',
    outline: 0,
    ...styles.item,
  };

  return <chakra.button ref={ref} type={btnType} {...rest} __css={buttonStyles} />;
});

export const MenuItemOption = forwardRef<IMenuItemOptionProps, 'button'>((props, ref) => {
  const { icon, iconSpacing = '0.75rem', ...rest } = props;

  const optionProps = useMenuOption(rest, ref) as StyledMenuItemProps;

  const render = React.useMemo(() => {
    const { isChecked, value, isDisabled, type } = props;

    if (isFunction(optionProps.children)) {
      return optionProps.children({ isChecked, isDisabled, value, type }) as JSX.Element;
    }

    return (
      <React.Fragment>
        <chakra.span flex="1">{optionProps.children}</chakra.span>
        <MenuIcon fontSize="0.8em" marginStart={iconSpacing} opacity={props.isChecked ? 1 : 0}>
          {icon ?? <CheckTickBold />}
        </MenuIcon>
      </React.Fragment>
    );
  }, [optionProps.children, props.isChecked, props.value, props.isDisabled, props.type]);

  return (
    <StyledMenuItem {...optionProps} className={cx('chakra-menu__menuitem-option', rest.className)}>
      {render}
    </StyledMenuItem>
  );
});

MenuItemOption.id = 'MenuItemOption';
