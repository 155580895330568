import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StarHalf = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M4.074 22.308L6.214 15 .476 10.14a1.31 1.31 0 01.82-2.32h6.996l2.49-6.96c.086-.25.25-.464.466-.619.217-.155.477-.24.743-.24s.526.085.742.24c.217.155.38.368.467.62l2.51 7.01h6.996a1.32 1.32 0 01.81 2.34l-5.897 4.839 2.289 7.19a1.289 1.289 0 01-.379 1.48 1.28 1.28 0 01-.753.28 1.285 1.285 0 01-.778-.21l-5.984-4.07-6.01 4.07a1.3 1.3 0 01-.754.21 1.294 1.294 0 01-.734-.27 1.288 1.288 0 01-.442-1.42zm7.94-4.295c.252 0 .498.073.71.21l5.374 3.696-1.999-6.38a1.55 1.55 0 01.43-1.63l5.298-4.34h-6.308a1.33 1.33 0 01-1.24-.89l-2.291-6.37c0 9.69.026 11.69.026 15.704z" />
    </g>
  );
});
StarHalf.displayName = 'StarHalf';
