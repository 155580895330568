import { Drawer as DrawerComponent, DrawerProps } from '@chakra-ui/modal';
import * as React from 'react';
import { DrawerBody } from './DrawerBody';
import { DrawerCloseButton } from './DrawerCloseButton';
import { DrawerContent } from './DrawerContent';
import { DrawerFooter } from './DrawerFooter';
import { DrawerHeader } from './DrawerHeader';
import { DrawerOverlay } from './DrawerOverlay';

export type IDrawerProps = DrawerProps;

type IDrawerComponent = React.FunctionComponent<IDrawerProps> & {
  Body: typeof DrawerBody;
  CloseButton: typeof DrawerCloseButton;
  Content: typeof DrawerContent;
  Footer: typeof DrawerFooter;
  Header: typeof DrawerHeader;
  Overlay: typeof DrawerOverlay;
};

export const Drawer: IDrawerComponent = (props) => <DrawerComponent {...props} />;

Drawer.Body = DrawerBody;
Drawer.CloseButton = DrawerCloseButton;
Drawer.Content = DrawerContent;
Drawer.Footer = DrawerFooter;
Drawer.Header = DrawerHeader;
Drawer.Overlay = DrawerOverlay;
