import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesXlsBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zm-12.65 11a.63.63 0 11-1.12.56s-.86-1.71-.88-1.76a.07.07 0 00-.12 0l-.88 1.76a.62.62 0 01-1.035.131.62.62 0 01-.085-.691l1.3-2.61a.25.25 0 000-.22l-1.3-2.61a.63.63 0 011.12-.56l.88 1.75a.07.07 0 00.104.032.07.07 0 00.026-.032l.88-1.75a.63.63 0 111.12.56l-1.3 2.61a.25.25 0 000 .22l1.29 2.61zm4.44.91h-2A1.63 1.63 0 019.87 16v-5a.63.63 0 011.25 0v5a.38.38 0 00.38.37h2a.63.63 0 010 1.25zm2.92-4.76l1.84 1.23a1.93 1.93 0 01-1.07 3.53H15.5a.63.63 0 110-1.25h1.7a.68.68 0 00.38-1.24l-1.84-1.23a1.93 1.93 0 011.07-3.53h1.7a.63.63 0 010 1.25H16.8a.68.68 0 00-.38 1.24z" />
      </g>
    );
  }
);
FilesXlsBold.displayName = 'FilesXlsBold';
