import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudFolder = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.7 16.497a.744.744 0 00-.573-.27h-2.224V14.07a.734.734 0 00-.745-.733h-5.917v-.733a.732.732 0 00-.745-.733H6.324a.736.736 0 00-.745.733v9.111c.002.44.163.866.454 1.197a1.867 1.867 0 001.49.636h11.38a1.9 1.9 0 001.188-.41c.338-.272.571-.653.658-1.078l1.08-4.97a.732.732 0 00-.13-.593zm-14.037 5.53a.378.378 0 01-.162.055h-.118a.377.377 0 01-.227-.119.366.366 0 01-.087-.237v-8.432h3.704v.733a.732.732 0 00.745.734h5.917v1.466h-7.418a.735.735 0 00-.713.528l-1.511 5.068a.367.367 0 01-.162.205h.032zm11.672-.258a.4.4 0 01-.4.313H9.25l1.318-4.4h9.63l-.863 4.087z" />
        <path d="M3.884 16.108a.82.82 0 01-.27 0A5.192 5.192 0 01.02 10.717c.27-2.156 1.911-3.601 4.405-3.86.443-1.8 1.609-5.995 6.824-6.372a7.145 7.145 0 017.147 4.032 6.623 6.623 0 015.496 4.83 5.266 5.266 0 01-2.829 5.79.839.839 0 01-1.022-1.284.839.839 0 01.256-.203c.097 0 2.57-1.37 1.965-3.914a4.927 4.927 0 00-4.006-3.548 1.383 1.383 0 01-1.263-.808 5.394 5.394 0 00-5.582-3.235c-4.157.302-5.032 3.547-5.377 5.11A1.563 1.563 0 014.65 8.443c-1.242.108-2.753.625-2.98 2.405-.324 2.609 2.364 3.59 2.483 3.633a.841.841 0 01-.27 1.628z" />
      </g>
    );
  }
);
CloudFolder.displayName = 'CloudFolder';
