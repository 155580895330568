import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Plus = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M12.75 1a.75.75 0 00-1.5 0v10.25H1a.75.75 0 000 1.5h10.25V23a.75.75 0 001.5 0V12.75H23a.75.75 0 000-1.5H12.75V1z" />
    </g>
  );
});
Plus.displayName = 'Plus';
