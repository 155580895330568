import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { spacing, font } from '../../config';
import { TemplatedTerms } from '../../constants/templatedTerms';
import { replaceTerm } from '../../utils/replaceTerm';
import { HighlightedText } from '../HighlightedText';

const explanationFieldStyle = css({
  whiteSpace: 'pre-wrap',
  fontSize: font.size.size1,
  fontWeight: font.weight.medium,
  marginTop: spacing.size1,
  marginBottom: spacing.size4,
});

const conditionFieldStyle = css({
  fontSize: font.size.size05,
  whiteSpace: 'pre-wrap',
});

const termToHighlight = TemplatedTerms.IF_OMNI;

const highLightSections = (text) => {
  const restOfTheText = text.split(termToHighlight);

  return restOfTheText.map((section, index) => (
    <Fragment key={index}>
      {section}
      {index !== restOfTheText.length - 1 && (
        <HighlightedText text={replaceTerm(termToHighlight, termToHighlight)} />
      )}
    </Fragment>
  ));
};

const QuestionDescription = ({ explanation, conditions }) => (
  <Fragment>
    {explanation && (
      <p css={explanationFieldStyle}>{highLightSections(explanation)}</p>
    )}
    {conditions && <p css={conditionFieldStyle}>{conditions}</p>}
  </Fragment>
);

QuestionDescription.propTypes = {
  explanation: PropTypes.string,
  conditions: PropTypes.string,
};

export { QuestionDescription };
