import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesJpgBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M10.94 11.16v2.45a1.23 1.23 0 100-2.45z" />
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zm-13 9.41a2.14 2.14 0 01-2.14 2.15H5.9a.6.6 0 01-.59-.59.59.59 0 01.59-.59h.68a1 1 0 001-1v-4.64a.6.6 0 01.55-.62.59.59 0 01.59.59l-.01 4.7zm4-1a2.47 2.47 0 01-1.74.72v1.83a.62.62 0 01-.856.573.611.611 0 01-.374-.573v-6.12a.61.61 0 01.61-.61h.62a2.45 2.45 0 011.74 4.18zm6 1.81a1.2 1.2 0 01-.92 1.17 3.742 3.742 0 01-.91.12 2.54 2.54 0 01-1.6-.52 3.47 3.47 0 01-1.06-2.93 4 4 0 011.29-3.31 3.22 3.22 0 012.56-.52.623.623 0 01-.3 1.21 2 2 0 00-1.5.31 2.879 2.879 0 00-.8 2.33 2.551 2.551 0 00.53 1.93 1.72 1.72 0 001.43.16v-1.45h-.68a.63.63 0 010-1.25h.73a1.21 1.21 0 011.21 1.21l.02 1.54z" />
      </g>
    );
  }
);
FilesJpgBold.displayName = 'FilesJpgBold';
