import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MenuNavigationHorizontalBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6.5 12a3 3 0 11-6 0 3 3 0 016 0zm8.5 0a3 3 0 11-6 0 3 3 0 016 0zm5.5 3a3 3 0 100-6 3 3 0 000 6z" />
      </g>
    );
  }
);
MenuNavigationHorizontalBold.displayName = 'MenuNavigationHorizontalBold';
