import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const IdeasBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M12.025 3.737a7.598 7.598 0 00-5.254 2.226 7.62 7.62 0 00-2.23 5.259 7.352 7.352 0 003.742 6.495h7.484a7.339 7.339 0 003.742-6.505 7.62 7.62 0 00-2.233-5.253 7.598 7.598 0 00-5.251-2.222zm-.748 12.74V13.86L9.56 12.08c-.389-.389-.758-.998-.369-1.378a.79.79 0 011.218.09l1.616 1.629 1.477-1.53c.389-.389.908-.579 1.297-.19.39.39.23.83-.16 1.22l-1.866 1.869v2.688h-1.496zm-2.994 1.989v1a2.25 2.25 0 002.245 2.247h.5v1.27a1 1 0 00.658.929.678.678 0 00.668 0 .998.998 0 00.669-.93v-1.258h.499a2.244 2.244 0 002.245-2.249v-.999H8.283v-.01zm3.742-15.129a.997.997 0 00.998-.999V1a1 1 0 00-.998-1 .997.997 0 00-.998 1v1.398a1 1 0 00.998.95v-.01zm8.9-.249a.997.997 0 00-1.406 0l-.998.999a.998.998 0 101.377 1.439l.998-1a.998.998 0 000-1.438h.03zM5.57 5.436a1 1 0 00-.03-1.439l-.999-1a1.03 1.03 0 10-1.396 1.52l.997.999a.997.997 0 001.407 0l.02-.08zm16.832 4.666h-1.397a.997.997 0 00-.997 1 1 1 0 00.997.999h1.397a.997.997 0 00.998-1 1 1 0 00-.998-.999zm-20.804.13a.997.997 0 00-.998 1 1 1 0 00.998.999h1.447a.997.997 0 00.998-1 1 1 0 00-.998-.999H1.598z" />
      </g>
    );
  }
);
IdeasBold.displayName = 'IdeasBold';
