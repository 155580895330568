import { logger } from './logger';
import {
  addSnackbar,
  snackbarTypes,
  snackbarDefaultMessages,
} from './snackbar';

const generalApiErrorHandler = (
  error,
  errorMessage = snackbarDefaultMessages.errorText
) => {
  addSnackbar({
    header: snackbarDefaultMessages.errorHeader,
    text: errorMessage,
    type: snackbarTypes.ERROR,
  });
  logger.logError(error);
};

export { generalApiErrorHandler };
