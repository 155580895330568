import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SupportChatBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M11.101.045a9.097 9.097 0 019.943 8.085A4.01 4.01 0 0124 12v2.905a4.01 4.01 0 01-2.912 3.857 4.01 4.01 0 01-4.004 3.785H15.76A2.558 2.558 0 0113.453 24H12a2.558 2.558 0 010-5.116h1.453a2.558 2.558 0 012.306 1.453h1.325a1.8 1.8 0 001.77-1.468 1.831 1.831 0 01-1.422-1.785V9.821a1.831 1.831 0 011.372-1.773 6.885 6.885 0 00-13.608 0A1.831 1.831 0 016.568 9.82v7.263a1.831 1.831 0 01-1.831 1.832H4.01A4.01 4.01 0 010 14.906V12a4.01 4.01 0 012.957-3.87A9.095 9.095 0 0111.1.045z" />
        <path d="M13.8 10.049a1.105 1.105 0 012.21 0v1.27a1.105 1.105 0 11-2.21 0v-1.27zm-5.522 4.54a1.105 1.105 0 011.561-.073 3.208 3.208 0 004.322 0 1.105 1.105 0 111.489 1.634 5.419 5.419 0 01-7.3 0 1.105 1.105 0 01-.072-1.561zm.817-5.646c-.61 0-1.106.495-1.106 1.106v1.27a1.105 1.105 0 002.211 0v-1.27c0-.61-.495-1.106-1.105-1.106z" />
      </g>
    );
  }
);
SupportChatBold.displayName = 'SupportChatBold';
