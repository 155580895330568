import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowLongUp = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M11.496.945a.75.75 0 011.008 0l8.002 7.272a.75.75 0 11-1.009 1.11L12.75 3.195V22.5a.75.75 0 01-1.5 0V3.195l-6.746 6.13a.75.75 0 01-1.008-1.11l8-7.27z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowLongUp.displayName = 'ArrowLongUp';
