import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BarGraphBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M1.25 17h3.5a.249.249 0 00.25-.25v-2a1 1 0 00-1-1H2a1 1 0 00-1 1v2a.25.25 0 00.25.25zM8 8.75a1 1 0 00-1 1v7a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-7a1 1 0 00-1-1H8zm6 2a1 1 0 00-1 1v5a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-5a1 1 0 00-1-1h-2zM19.25 17h3.5a.25.25 0 00.25-.25v-12a1 1 0 00-1-1h-2a1 1 0 00-1 1v12a.25.25 0 00.25.25zM1 20.25h22a1 1 0 100-2H1a1 1 0 100 2z" />
      </g>
    );
  }
);
BarGraphBold.displayName = 'BarGraphBold';
