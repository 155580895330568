import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const UsbStickBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M11 3a.5.5 0 00-.5.5v1a.5.5 0 001 0v-1A.5.5 0 0011 3zm2 0a.5.5 0 00-.5.5v1a.5.5 0 001 0v-1A.5.5 0 0013 3z" />
        <path
          clipRule="evenodd"
          d="M16.643 6.652a.25.25 0 01-.143-.226V2a2 2 0 00-2-2h-5a2 2 0 00-2 2v4.426a.252.252 0 01-.143.226A1.5 1.5 0 006.5 8v11.5A4.505 4.505 0 0011 24h2a4.505 4.505 0 004.5-4.5V8a1.5 1.5 0 00-.857-1.348zM13.5 11.5h-3a1 1 0 110-2h3a1 1 0 110 2zM9.75 2a.25.25 0 00-.25.25v3.5c0 .138.112.25.25.25h4.5a.25.25 0 00.25-.25v-3.5a.25.25 0 00-.25-.25h-4.5z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
UsbStickBold.displayName = 'UsbStickBold';
