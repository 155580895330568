import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ProgrammingBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M3 22.5a1.5 1.5 0 01-3 0v-21a1.5 1.5 0 013 0v.025a.25.25 0 00.335.236l.717-.261a9.779 9.779 0 018.226 1.067 9.36 9.36 0 007.45 1.025L21.9 2.9A1.652 1.652 0 0124 4.485v13.159a1.655 1.655 0 01-1.2 1.583L20 20.1a10.33 10.33 0 01-8.248-1.129A8.766 8.766 0 004.371 18l-1.206.435A.25.25 0 003 18.67v3.83zM15.005 7.297a.75.75 0 00.215.734l2.293 2.293a.252.252 0 010 .354l-2.293 2.293a.75.75 0 101.06 1.06l3-3a.75.75 0 000-1.06l-3-3a.75.75 0 00-1.275.326zm-3.992 6.916a.751.751 0 01-.475-.949l2-6a.75.75 0 011.424.474l-2 6a.751.751 0 01-.949.475zM5 10.501c0 .199.08.39.22.53l3 3a.75.75 0 101.06-1.06l-2.293-2.293a.252.252 0 010-.354L9.28 8.031a.75.75 0 00-1.06-1.06l-3 3a.749.749 0 00-.22.53z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ProgrammingBold.displayName = 'ProgrammingBold';
