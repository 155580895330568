import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const GiftBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M21.667 13.596h-8.903V24h8.903a.483.483 0 00.455-.274.47.47 0 00.044-.18V14.05a.47.47 0 00-.314-.426.483.483 0 00-.185-.028zm-19.334 0a.482.482 0 00-.455.274.47.47 0 00-.044.18v9.496a.47.47 0 00.314.425c.06.022.122.031.185.029h8.903V13.596H2.333zM23.5 6.63h-6.926c1.66-.333 3.341-1.008 3.922-2.167.448-.948.204-2.017-.754-3.186A2.864 2.864 0 0018.566.27a2.9 2.9 0 00-1.534-.253c-2.455.373-4.36 4.375-4.89 5.615C11.603 4.392 9.699.39 7.254.017A2.9 2.9 0 005.719.27c-.478.222-.885.57-1.176 1.007-.957 1.17-1.202 2.238-.754 3.186.581 1.2 2.252 1.834 3.922 2.167H.5a.493.493 0 00-.46.296.48.48 0 00-.039.188v4.788a.501.501 0 00.5.484h10.736V7.053h1.528v5.363h10.737a.492.492 0 00.5-.484V7.115a.48.48 0 00-.31-.45.494.494 0 00-.19-.034zM5.176 3.838c-.224-.464.173-1.12.56-1.583.51-.625.917-.726 1.141-.726h.143c1.16.171 2.505 2.198 3.35 3.921-2.18-.15-4.737-.675-5.195-1.612zm12.092-2.32h.142c.224 0 .632.102 1.141.727.377.463.784 1.119.56 1.582-.458 1.009-3.056 1.482-5.195 1.634.846-1.755 2.16-3.77 3.352-3.942z" />
    </g>
  );
});
GiftBold.displayName = 'GiftBold';
