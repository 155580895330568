import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Heart = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M11.632 22.5a1.115 1.115 0 01-.774-.322l-8.53-8.535a9.044 9.044 0 01-1.325-1.749A7.04 7.04 0 01.782 5.05a6.533 6.533 0 015.344-3.53h.231a5.944 5.944 0 015.676 3.016A6.03 6.03 0 0117.58 1.52h.19a6.607 6.607 0 015.426 3.62 6.93 6.93 0 01-.151 6.754 8.834 8.834 0 01-1.346 1.76l-9.323 8.574c-.203.183-.47.28-.743.271zM6.739 2.997h-.492a4.94 4.94 0 00-4.13 2.744 5.511 5.511 0 00.172 5.429c.311.52.678 1.006 1.095 1.447l8.268 8.263 8.97-8.273a7.59 7.59 0 001.086-1.438 5.41 5.41 0 00.15-5.327 5.025 5.025 0 00-4.169-2.775h-.05a4.609 4.609 0 00-4.792 3.308.875.875 0 01-.854.573.904.904 0 01-.854-.654 4.403 4.403 0 00-4.4-3.297z" />
    </g>
  );
});
Heart.displayName = 'Heart';
