import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const PinBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M15.663.013a2.25 2.25 0 011.836.646l5.843 5.843a2.25 2.25 0 01-.384 3.488l-6.093 3.877a8.271 8.271 0 01-1 5.562l-.001.002a2.25 2.25 0 01-3.534.45l-3.045-3.045L2.56 23.56a1.5 1.5 0 01-1.503.373.75.75 0 01-.991-.993 1.5 1.5 0 01.373-1.501l6.724-6.724L4.12 11.67a2.25 2.25 0 01.45-3.533l.38.647-.377-.649a8.271 8.271 0 015.56-1l3.878-6.094A2.249 2.249 0 0115.663.013z" />
    </g>
  );
});
PinBold.displayName = 'PinBold';
