import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Programming = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M15.954 7.07a.75.75 0 10-1.06 1.061l2.404 2.405-2.404 2.405a.75.75 0 001.06 1.06l2.935-2.935a.75.75 0 000-1.06L15.954 7.07zm-6.848 0a.75.75 0 010 1.061l-2.404 2.405 2.404 2.405A.75.75 0 018.046 14L5.11 11.066a.75.75 0 010-1.06L8.046 7.07a.75.75 0 011.06 0zm4.584.768a.75.75 0 10-1.423-.474l-1.957 5.87a.75.75 0 101.423.474l1.957-5.87z" />
        <path
          clipRule="evenodd"
          d="M.75.003a.75.75 0 01.75.75v1.392l2.184-.672a10.364 10.364 0 018.477 1.078 8.862 8.862 0 007.076.974l2.37-.677a1.876 1.876 0 012.389 1.802v12.87a1.874 1.874 0 01-1.36 1.803l-2.987.854a10.367 10.367 0 01-8.278-1.138 8.859 8.859 0 00-7.25-.922l-2.621.81v4.26a.75.75 0 01-1.5 0V.754a.75.75 0 01.75-.75zm.75 17.353V3.714l2.625-.807a8.864 8.864 0 017.25.922 10.36 10.36 0 008.274 1.138l2.368-.677a.378.378 0 01.479.361v12.87a.375.375 0 01-.272.36l-2.987.854a8.864 8.864 0 01-7.08-.974 10.357 10.357 0 00-8.478-1.078l-2.179.673z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
Programming.displayName = 'Programming';
