import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const TimeBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 000-22.5zM13.5 8a1.5 1.5 0 00-3 0v4.621l4.94 4.94a1.5 1.5 0 002.12-2.122l-4.06-4.06V8z"
        fillRule="evenodd"
      />
    </g>
  );
});
TimeBold.displayName = 'TimeBold';
