import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const RobotArm = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#robot-arm_svg__a)">
        <path
          clipRule="evenodd"
          d="M10.974 2.052a1.75 1.75 0 012.555 2.392.76.76 0 00-.114.11 1.743 1.743 0 01-1.901.284 1.75 1.75 0 01-.54-2.786zm4.525 1.198c0 .638-.184 1.233-.502 1.735l1.363 1.393A2.75 2.75 0 0120.2 7.5a3.75 3.75 0 013.8 3.751.75.75 0 01-1.5-.002 2.25 2.25 0 00-2.013-2.239 2.75 2.75 0 01-2.476 2.478 2.254 2.254 0 001.282 1.799c.299.14.625.213.956.213a.75.75 0 010 1.5 3.75 3.75 0 01-3.75-3.8 2.75 2.75 0 01-1.179-3.74l-1.395-1.426a3.256 3.256 0 01-2.485.364l-4.906 4.778 4.444 3.295A3.75 3.75 0 0116 18v4.501h7a.75.75 0 010 1.5H1a.75.75 0 010-1.5h7.5v-3.24l-6.07-4.364a3.251 3.251 0 01-1.026-5.82l8.439-8.012a3.25 3.25 0 015.656 2.185zM10 18.892V18a2.25 2.25 0 011.164-1.97.75.75 0 00.12-.063A2.249 2.249 0 0114.5 18v4.501H10v-3.61zm-.383-3.563a3.75 3.75 0 00-1.076 2.114l-3.857-2.774a3.26 3.26 0 001.619-1.797l3.314 2.457zm7.858-5.358l-.012-.003a1.25 1.25 0 01-.648-2.046l.003-.004a1.246 1.246 0 01.931-.417 1.25 1.25 0 11-.274 2.47zM9.059 3.877l-4.982 4.73A3.253 3.253 0 015.83 9.771l4.23-4.12a3.245 3.245 0 01-1-1.774zm-6.72 6.38l-.532.505a1.75 1.75 0 001.019 2.687.754.754 0 01.13.027 1.75 1.75 0 10-.618-3.22z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="robot-arm_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
RobotArm.displayName = 'RobotArm';
