import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ImageMulti = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M8.1 10.05c-.67 0-1.31-.27-1.78-.74-.23-.24-.42-.52-.55-.83a2.52 2.52 0 01.55-2.75 2.57 2.57 0 011.78-.71c.66 0 1.3.25 1.78.71a2.52 2.52 0 01.55 2.74 2.583 2.583 0 01-2.33 1.58zm0-3.54c-.27 0-.52.1-.71.29-.18.19-.29.44-.29.71 0 .13.03.27.08.39s.13.23.22.33c.2.18.46.28.72.28.27 0 .53-.1.72-.28.1-.09.17-.21.23-.33.05-.12.08-.26.08-.39 0-.14-.03-.27-.08-.39s-.13-.23-.23-.33a.949.949 0 00-.74-.28zm8.28 10.18a.73.73 0 01-.35-.09.78.78 0 01-.27-.24l-3.09-4.63-2.19 3c-.06.08-.13.16-.22.21-.09.05-.19.09-.29.1-.1.02-.2.01-.3-.01a.698.698 0 01-.27-.14l-1.31-1-1.73 2.5c-.12.17-.29.28-.49.31-.2.03-.41-.01-.57-.13a.726.726 0 01-.31-.49c-.03-.2.01-.4.13-.57l1.68-2.48c.12-.18.28-.33.46-.44.18-.12.39-.2.6-.23.21-.04.42-.04.63 0 .21.06.4.15.57.29l.68.55 1.66-2.37c.14-.21.34-.37.56-.49.22-.12.47-.17.72-.17s.5.07.72.19c.22.12.41.29.55.5L17 15.51c.1.16.13.35.1.54a.75.75 0 01-.3.46c-.12.1-.26.16-.42.18z" />
        <path d="M19.92 1.5c.17 0 .3.14.3.3v18.12a.3.3 0 01-.3.3H1.8a.3.3 0 01-.3-.3V1.8c0-.17.14-.3.3-.3h18.12zm0-1.5H1.8C.81 0 0 .81 0 1.8v18.12c0 1 .81 1.8 1.8 1.8h18.12c1 0 1.8-.81 1.8-1.8V1.8c.01-.99-.8-1.8-1.8-1.8z" />
        <path d="M21.44 24H4.11c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h17.33c.58 0 1.05-.47 1.05-1.05V4.11c0-.41.34-.75.75-.75s.76.34.76.75v17.33c0 1.41-1.15 2.56-2.56 2.56z" />
      </g>
    );
  }
);
ImageMulti.displayName = 'ImageMulti';
