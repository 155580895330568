import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const VideoPlay = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6.808 21.998A1.794 1.794 0 015 20.178V3.828a1.829 1.829 0 01.887-1.572 1.8 1.8 0 011.796-.028l15.381 8.15a1.84 1.84 0 010 3.2l-15.381 8.15a1.83 1.83 0 01-.875.27zm0-18.43a.336.336 0 00-.159 0 .32.32 0 00-.149.27v16.3a.312.312 0 00.152.266.306.306 0 00.305.005l15.382-8.16a.3.3 0 00.159-.27.292.292 0 00-.16-.27l-15.38-8.15a.277.277 0 00-.15-.04v.05z" />
      </g>
    );
  }
);
VideoPlay.displayName = 'VideoPlay';
