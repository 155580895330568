import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ZoomInBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M10 .5a9.5 9.5 0 100 19c2.082 0 4.008-.67 5.573-1.806l5.366 5.367a1.5 1.5 0 002.122-2.122l-5.367-5.366A9.458 9.458 0 0019.5 10 9.5 9.5 0 0010 .5zM3.5 10a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z"
          fillRule="evenodd"
        />
        <path d="M10 6.75a.916.916 0 00-.916.917V9.25H7.5a.916.916 0 100 1.833h1.584v1.583a.916.916 0 101.832 0v-1.583H12.5a.916.916 0 100-1.833h-1.584V7.667A.916.916 0 0010 6.75z" />
      </g>
    );
  }
);
ZoomInBold.displayName = 'ZoomInBold';
