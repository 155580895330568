import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Prize = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M15 10.38l2.08-2a.74.74 0 00-.4-1.26l-2.85-.44-1.25-2.59a.76.76 0 00-.67-.42.77.77 0 00-.66.41L10 6.66l-2.9.39a.75.75 0 00-.42 1.27l2.05 2-.5 2.84a.74.74 0 00.29.73.75.75 0 00.78.11l2.56-1.33L14.4 14a.731.731 0 00.78-.05.74.74 0 00.3-.72L15 10.38zm-1.31-.8a.8.8 0 00-.22.66l.3 1.76-1.56-.84a.73.73 0 00-.35-.09.722.722 0 00-.34.09L10 12l.31-1.74a.76.76 0 00-.21-.66L8.79 8.32l1.75-.25a.71.71 0 00.56-.4l.8-1.58.77 1.59a.76.76 0 00.56.41l1.77.27-1.31 1.22z" />
      <path d="M23.83 17.58l-4-4.9a8.518 8.518 0 00.71-3.43A8.681 8.681 0 004.08 5.333 8.68 8.68 0 004 12.86L.17 17.58a.73.73 0 00-.11.76.73.73 0 00.63.45l3.88.26 1 3.83a.73.73 0 00.59.54h.12a.74.74 0 00.62-.33l3.55-5.31c.466.083.937.127 1.41.13a8.532 8.532 0 001.61-.16l3.57 5.34a.74.74 0 00.62.33h.12a.76.76 0 00.6-.54l1-3.83 3.88-.26a.73.73 0 00.63-.45.73.73 0 00-.06-.76zM6.6 20.92l-.74-2.77a.74.74 0 00-.66-.54l-3-.2 2.6-3.18A8.69 8.69 0 009 17.4l-2.4 3.52zM4.7 9.25a7.19 7.19 0 117.19 7.19A7.2 7.2 0 014.7 9.25zm14.1 8.36a.74.74 0 00-.66.54l-.74 2.77-2.4-3.59a8.699 8.699 0 004.08-3.24l2.7 3.32-2.98.2z" />
    </g>
  );
});
Prize.displayName = 'Prize';
