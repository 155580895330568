import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const NetworkMonitorBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M16.873 0H7.127a.751.751 0 00-.75.747v6.725a.746.746 0 00.75.747h3.868v1.176H9.506a1.001 1.001 0 00-1 .996.994.994 0 001 .996h4.998a1.001 1.001 0 001-.996.995.995 0 00-1-.996h-1.51V8.219h3.879a.751.751 0 00.75-.747V.747a.746.746 0 00-.75-.747zm-1.25 6.257H8.367V1.654h7.256v4.603zm-5.127 6.356H.75a.75.75 0 00-.75.747v6.725a.746.746 0 00.75.747h3.878v1.175h-1.49a1.001 1.001 0 00-.999.997.994.994 0 001 .996h4.998a1.001 1.001 0 001-.996.994.994 0 00-1-.997h-1.51v-1.175h3.869a.75.75 0 00.75-.747V13.36a.746.746 0 00-.75-.747zm-1.25 6.256H2v-4.592h7.247v4.592zm13.994-6.256h-9.746a.75.75 0 00-.75.747v6.725a.746.746 0 00.75.747h3.879v1.175h-1.49a1 1 0 00-1 .997.994.994 0 001 .996h4.998a1.001 1.001 0 001-.996.994.994 0 00-1-.997h-1.51v-1.175h3.88a.75.75 0 00.749-.747V13.36a.745.745 0 00-.76-.747zm-1.25 6.256h-7.246v-4.592h7.247v4.592z" />
      </g>
    );
  }
);
NetworkMonitorBold.displayName = 'NetworkMonitorBold';
