import { useParams, useHistory, Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { useQuery, useMutation } from '@apollo/client';
import { Button } from '@lego/klik-react';
import { spacing, padding, media } from '../../config';
import { AssessmentInfoSection } from '../AssessmentInfoSection';
import { Step, Stepper } from '../stepper';
import { OverlayPortal } from '../OverlayPortal';
import { Spinner } from '../spinner/Spinner';
import {
  GET_COMPLETE_PAGE_DATA,
  FINISH_SURVEY,
} from '../../queries/assessment';
import { Divider } from '../Divider';
import { AssessmentScore } from '../AssessmentScore';
import { routes } from '../RouteHandler';
import {
  areAllAnswersComplete,
  isAnyQuestionMissingProof,
  enhanceQuestionsWithAnswersAndProof,
  adjustAssessmentsBasedOnFeatureFlags,
} from '../assessment-scoring/helpers';
import { Heading } from '../Heading';
import { generalApiErrorHandler } from '../../utils/generalApiErrorHandler';
import {
  addSnackbar,
  snackbarTypes,
  snackbarDefaultMessages,
} from '../../utils/snackbar';
import { useState } from 'react';
import { MissingProofExplainer } from '../MissingProofExplainer';
import { MissingProofWarningModal } from '../modals/MissingProofWarningModal';

const assessmentSurveyContainer = css({
  maxWidth: '768px',
  margin: 'auto',
  padding: `${spacing.size10}px ${padding.medium}px ${spacing.size12}px`,
  [media.medium]: {
    padding: `${spacing.size12}px ${padding.medium}px ${spacing.size12}px`,
  },
});

const sectionContainerStyle = css({
  marginTop: spacing.size10,
  paddingBottom: spacing.size10,
});

const actionButtonsWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: spacing.size8,
  marginBottom: spacing.size16,
  width: 306,
});

const CompletePage = () => {
  const history = useHistory();
  const { customerId, assessmentYear, version } = useParams();

  const [isProofWarningModalVisible, setIsProofWarningModalVisible] = useState(
    false
  );

  const {
    data: getCompletePageData,
    loading: getCompletePageDataLoading,
    error: getCompletePageDataError,
  } = useQuery(GET_COMPLETE_PAGE_DATA, {
    variables: {
      customerId,
      year: Number(assessmentYear),
      version: Number(version),
    },
    fetchPolicy: 'network-only',
  });

  const [finishSurvey, { loading: finishSurveyLoading }] = useMutation(
    FINISH_SURVEY,
    {
      onCompleted: handleFinishSurveyResponse,
    }
  );

  async function submitSurvey() {
    try {
      await finishSurvey({
        variables: {
          customerId,
          year: Number(assessmentYear),
          version: Number(version),
        },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  async function handleFinishSurvey() {
    if (isAnyQuestionMissingProof(enhancedQuestions)) {
      setIsProofWarningModalVisible(true);
      return;
    }

    await submitSurvey();
  }

  function handleFinishSurveyResponse(response) {
    if (response.finishSurvey) {
      const {
        getSurveyInfo: {
          customerDetail: { name },
        },
      } = getCompletePageData;

      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: `${name}'s assessment was submitted.`,
        type: snackbarTypes.SUCCESS,
      });

      history.push(routes.overview);
    }
  }

  // Error boundary catches this error
  if (getCompletePageDataError) throw getCompletePageDataError;

  if (getCompletePageDataLoading || finishSurveyLoading) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  const {
    getSurvey: questions,
    getSurveyInfo: surveyInfo,
    getAnswers: answers,
  } = getCompletePageData;

  const surveyDetailsForFeatureFlag = {
    year: Number(assessmentYear),
    channel: surveyInfo.customerDetail.channel,
  };

  let enhancedQuestions = enhanceQuestionsWithAnswersAndProof(
    questions,
    answers
  );
  enhancedQuestions = adjustAssessmentsBasedOnFeatureFlags(
    enhancedQuestions,
    surveyDetailsForFeatureFlag
  );

  return (
    <div>
      {isProofWarningModalVisible && (
        <MissingProofWarningModal
          onClose={() => {
            setIsProofWarningModalVisible(false);
          }}
          onConfirm={() => {
            setIsProofWarningModalVisible(false);
            history.push(
              `${routes.scoring(customerId, assessmentYear, version)}?mode=edit`
            );
          }}
        />
      )}
      <Heading type="h1" text="Complete Assessment Form" />

      <AssessmentInfoSection assessmentInfo={surveyInfo} />

      <div css={assessmentSurveyContainer}>
        <Stepper>
          {enhancedQuestions.map((question) => {
            return (
              <Step
                stepId={question.questionId}
                key={question.questionId}
                header={question.headline}
                isValid={true}
                isWarning={question.isMissingProof}
                isComplete={true}
              />
            );
          })}
        </Stepper>
      </div>
      <Divider />
      <div css={sectionContainerStyle}>
        <Heading type="h2" text="Score" />
        <AssessmentScore
          scoreText="The calculated functional discount score is:"
          score={surveyInfo.surveyState.score}
        />
      </div>
      {isAnyQuestionMissingProof(enhancedQuestions) && (
        <MissingProofExplainer />
      )}
      <div css={actionButtonsWrapper}>
        <Link to={routes.scoringReview(customerId, assessmentYear, version)}>
          <Button
            label="Review"
            data-transaction-name="Review assessment before submit"
            variant="outline"
          />
        </Link>
        {!surveyInfo.surveyState.isComplete &&
          areAllAnswersComplete(answers) && (
            <Button
              label="Submit assessment"
              data-transaction-name="Submit assessment - from complete"
              onClick={handleFinishSurvey}
            />
          )}
      </div>
    </div>
  );
};

export { CompletePage };
