import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Tag } from '@lego/klik-ui';
import { AssessmentInfoRow } from './AssessmentInfoRow';
import { AssessmentStatusIndicator } from './AssessmentStatusIndicator';
import { AssessmentDateLabel } from './AssessmentDateLabel';
import { formatDate } from '../utils/formatDate';
import { spacing, colors, media, font } from '../config';

const assessmentInfoContainerStyle = css({
  display: 'flex',
  backgroundColor: colors.neutralColors.slate02,
  border: `1px solid ${colors.neutralColors.slate20}`,
  marginTop: spacing.size7,
  padding: spacing.size4,
  flexWrap: 'wrap',
  [media.medium]: {
    padding: spacing.size10,
  },
});

const assessmentInfoColumnStyle = css({
  flexGrow: 1,
});

const customerNameStyle = css({
  color: colors.boldColors.lightBlue.lightBlue80,
  fontSize: font.size.size2,
});

const assignmentInfoHeaderStyle = css({
  display: 'flex',
  width: '100%',
});

const versionTagStyle = css({
  marginLeft: 'auto',
});

const AssessmentInfoSection = ({ assessmentInfo, previousAssessmentScore }) => {
  const {
    email,
    version,
    assessmentDate,
    approvalDate,
    assessmentStatus,
    review,
    approve,
    pendingUser,
    perform,
    customerDetail: {
      id,
      name,
      c6Id,
      c6Name,
      channel,
      businessUnit,
      omni,
      tier,
    },
    surveyState: { score },
  } = assessmentInfo;

  return (
    <div>
      <div css={assessmentInfoContainerStyle}>
        <div css={assignmentInfoHeaderStyle}>
          <h3 css={customerNameStyle}>{name}</h3>
          <Tag css={versionTagStyle}>
            <Tag.Label>v.{version}</Tag.Label>
          </Tag>
        </div>
        <div css={assessmentInfoColumnStyle}>
          <AssessmentInfoRow label="BU" value={businessUnit} />
          <AssessmentInfoRow label="C6 ID" value={c6Id} />
          <AssessmentInfoRow label="C6" value={c6Name} />
          <AssessmentInfoRow label="Customer ID" value={id} />
          <AssessmentInfoRow label="Customer Channel" value={channel} />
          <AssessmentInfoRow label="Omni" value={omni} />
          <AssessmentInfoRow label="Tier" value={tier} />
          {email && <AssessmentInfoRow label="Email" value={email} />}
        </div>
        <div css={assessmentInfoColumnStyle}>
          <AssessmentInfoRow
            label="Status"
            value={<AssessmentStatusIndicator status={assessmentStatus} />}
          />
          <AssessmentInfoRow label="Score" value={`${score} %`} bold />
          <AssessmentInfoRow
            label="Last Year Score"
            value={`${
              previousAssessmentScore
                ? previousAssessmentScore + ' %'
                : 'Not available'
            }`}
            bold={!!previousAssessmentScore}
          />
          <AssessmentInfoRow label="Next Action By" value={pendingUser} />
          <AssessmentInfoRow label="Performer" value={perform} />
          <AssessmentInfoRow label="Reviewer" value={review} />
          <AssessmentInfoRow label="Approver" value={approve} />
          <AssessmentInfoRow
            label={
              <AssessmentDateLabel
                label="Assessment Date"
                dateFormat="(dd.mm.yyyy)"
              />
            }
            value={assessmentDate ? formatDate(new Date(assessmentDate)) : ''}
          />
          <AssessmentInfoRow
            label={
              <AssessmentDateLabel
                label="Approval Date"
                dateFormat="(dd.mm.yyyy)"
              />
            }
            value={approvalDate ? formatDate(new Date(approvalDate)) : ''}
          />
        </div>
      </div>
    </div>
  );
};

AssessmentInfoSection.propTypes = {
  assessmentInfo: PropTypes.object.isRequired,
  previousAssessmentScore: PropTypes.number,
};

export { AssessmentInfoSection };
