import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DownwardTrend = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M3.817.44A.75.75 0 014.81.067l14.42 6.549-.558-1.486a.75.75 0 011.404-.527l1.194 3.18.011.033a.747.747 0 01-.45.933l-3.18 1.194a.75.75 0 01-.528-1.404l1.486-.558L4.19 1.433A.75.75 0 013.818.44z" />
        <path
          clipRule="evenodd"
          d="M3 9a1.5 1.5 0 00-1.5 1.5v12H.75a.75.75 0 000 1.5h22.5a.75.75 0 000-1.5h-.75V18a1.5 1.5 0 00-1.5-1.5h-3a1.5 1.5 0 00-1.5 1.5v4.5H15v-9a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 009 13.5v9H7.5v-12A1.5 1.5 0 006 9H3zm3 13.5v-12H3v12h3zm7.5-9v9h-3v-9h3zm7.5 9V18h-3v4.5h3z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
DownwardTrend.displayName = 'DownwardTrend';
