/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { mergeWith } from '@chakra-ui/utils';
import { sizes as themeSizes } from '../foundations/sizes';
import { Dict, Variant } from '../types';

/**
 * BaseStyles, avoid colors or any value that we want to have our end-users theme. we need to
 * discuss this with the designers. also try to use fully-written css props (don't use bg, use
 * backgroundColor, don't use h or w, use height and width) however, if you really want to use the
 * shorthand notation, group them together in "base" and "magic" categories
 */
const baseStyle = {
  alignContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
  boxSizing: 'border-box',
  borderColor: 'transparent',
  borderRadius: '0.25rem',
  borderStyle: 'solid',
  borderWidth: '0', // only outline variant should have a border.
  display: 'inline-flex',
  fontWeight: '500',
  paddingStart: '1rem',
  paddingEnd: '1rem',
  transition: 'all 0.05s ease-out',
  userSelect: 'none',
  _disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  _active: {
    ':enabled': {
      transform: 'translateY(1px)',
    },
    '&.klik-ui-menu__button': {
      transform: 'none', // Prevent MenuList position bug when used inside Menu
    },
  },
  _focusVisible: {
    boxShadow: 'focusVisible',
  },
};

type possibleSizes = typeof themeSizes;

const getSize = (size: keyof possibleSizes) => {
  return themeSizes[size];
};

const iconAndSpinnerSize = (size: 'lg' | 'md' | 'sm') => {
  const iconAndSpinnerSizes = {
    sm: {
      iconOnly: getSize('4'),
      spinner: getSize('3.5'),
      spinnerOnly: getSize('4'),
    },
    md: {
      iconOnly: getSize('5'),
      spinner: getSize('4.5'),
      spinnerOnly: getSize('7'),
    },
    lg: {
      iconOnly: getSize('6'),
      spinner: getSize('5.5'),
      spinnerOnly: getSize('9'),
    },
  };

  const iconSize = iconAndSpinnerSizes[size];

  return {
    '&[data-icononly]': {
      fontSize: iconSize.iconOnly,
      '.klik-ui-spinner-inline': {
        fontSize: 'inherit',
      },
    },
    '.klik-ui-spinner-inline': {
      fontSize: iconSize.spinner,
    },
    '&[data-spinneronly] .klik-ui-spinner-inline': {
      fontSize: iconSize.spinnerOnly,
    },
  };
};

const sizes = {
  sm: {
    textStyle: 'md',
    paddingStart: '0.75rem',
    paddingEnd: '0.75rem',
    minHeight: '2rem',
    minWidth: '2rem',
    ...iconAndSpinnerSize('sm'),
  },
  md: {
    textStyle: 'lg',
    minHeight: '3rem',
    minWidth: '3rem',
    ...iconAndSpinnerSize('md'),
  },
  lg: {
    textStyle: 'xl',
    paddingStart: '1.5rem',
    paddingEnd: '1.5rem',
    minHeight: '4rem',
    minWidth: '4rem',
    ...iconAndSpinnerSize('lg'),
  },
};

const variantPlain: Variant = (props) => {
  const { colorScheme: c } = props;

  return {
    backgroundColor: mode(`${c}.500`, 'white')(props),
    boxShadow: 'button',
    color: mode('white', `${c}.500`)(props),
    _hover: {
      ':enabled': {
        backgroundColor: mode(`${c}.600`, 'slate.50')(props),
      },
    },
    _active: {
      ':enabled': {
        boxShadow: 'none',
        backgroundColor: mode(`${c}.600`, 'slate.50')(props),
      },
    },
  };
};

const variantGhost: Variant = (props) => {
  const { colorScheme: c, theme } = props;

  const backgroundColor = mode(
    transparentize('slate.900', 0.05)(theme),
    transparentize('slate.900', 0.4)(theme)
  )(props);

  return {
    color: mode(`${c}.500`, 'white')(props),
    backgroundColor: 'transparent',
    _hover: {
      ':enabled': {
        backgroundColor,
      },
    },
    _active: {
      ':enabled': {
        boxShadow: '-1',
        backgroundColor,
      },
    },
    _focusVisible: {
      borderColor: mode(`${c}.600`, 'slate.600')(props),
      backgroundColor,
    },
  };
};

const variantOutline = (props: Dict) => {
  const { colorScheme: c } = props;

  const ghostStyles = variantGhost(props);

  const outlineStyles = mergeWith(ghostStyles, {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: mode(`${c}.500`, 'white')(props),
    _active: {
      ':enabled': {
        boxShadow: 'none',
      },
    },
  });

  return outlineStyles;
};

const variantAttached = (props: Dict) => {
  const plainStyles = variantPlain(props);

  const attachedStyles = mergeWith(plainStyles, {
    fontWeight: 'normal',
    backgroundColor: mode(`slate.50`, 'slate.500')(props),
    boxShadow: 'button',
    color: 'inherit',
    _hover: {
      ':enabled': {
        backgroundColor: mode(`slate.50`, 'slate.600')(props),
      },
    },
    _active: {
      ':enabled': {
        backgroundColor: mode(`slate.50`, 'slate.600')(props),
      },
    },
  });

  return attachedStyles;
};

const variantLink = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    color: mode(`${c}.500`, 'white')(props),
    backgroundColor: 'transparent',
    _hover: {
      ':enabled': {
        textDecoration: 'underline',
      },
    },
    _active: {
      ':enabled': {
        textDecoration: 'underline',
      },
    },
    _focusVisible: {
      textDecoration: 'underline',
    },
  };
};

const variantAttachedActive = (props: Dict) => {
  const ghostStyles = variantGhost(props);

  const { colorScheme: c } = props;

  const attachedStyles = mergeWith(ghostStyles, {
    fontWeight: 'normal',
    backgroundColor: mode(`${c}.500`, 'slate.500')(props),
    boxShadow: 'none',
    color: 'white',
    _hover: {
      ':enabled': {
        backgroundColor: mode(`${c}.500`, 'slate.600')(props),
      },
    },
    _active: {
      ':enabled': {
        backgroundColor: mode(`${c}.500`, 'slate.600')(props),
      },
    },
    _focusVisible: {
      backgroundColor: mode(`${c}.500`, 'slate.600')(props),
    },
  });

  return attachedStyles;
};

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  plain: variantPlain,
  attached: variantAttached,
  'attached-active': variantAttachedActive,
  link: variantLink,
};

const defaultProps = {
  variant: 'plain',
  size: 'md',
  colorScheme: 'light-blue',
};

export const Button = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
