import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesPsd = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-psd_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81zm5.25 13.171a.749.749 0 00-.75.742v7.475a.752.752 0 00.75.752 3.756 3.756 0 002.645-1.1A3.772 3.772 0 0024 20.202V18.71a3.752 3.752 0 00-1.106-2.64 3.736 3.736 0 00-2.644-1.088zm.75 7.345v-5.731a2.25 2.25 0 011.5 2.114v1.493a2.258 2.258 0 01-1.5 2.124z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.25 14.981a.75.75 0 00-.75.742v7.475a.752.752 0 00.75.752.75.75 0 00.75-.752v-2.204a2.994 2.994 0 002.13-.872 3.01 3.01 0 000-4.239A3 3 0 009 14.981h-.75zM9 16.464v3.007a1.498 1.498 0 001.06-.481 1.484 1.484 0 000-2.115 1.498 1.498 0 00-1.06-.41z"
          fillRule="evenodd"
        />
        <path d="M16.2 18.87c-.77-.592-1.2-.953-1.2-1.654a.762.762 0 01.75-.722h1.51a.769.769 0 00.76-.761.762.762 0 00-.76-.752h-1.51a2.248 2.248 0 00-2.23 2.255 3.443 3.443 0 001.79 2.846c.77.581 1.2.942 1.2 1.643a.772.772 0 01-.76.762h-1.51a.759.759 0 00-.75.751.762.762 0 00.75.762h1.51a2.255 2.255 0 002.085-1.414 2.27 2.27 0 00.165-.87 3.473 3.473 0 00-1.8-2.847z" />
      </g>
      <defs>
        <clipPath id="files-psd_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesPsd.displayName = 'FilesPsd';
