import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StatusSuccess = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 25', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M18.53 8.531a.75.75 0 00-1.06-1.06L9 15.94l-3.47-3.47a.75.75 0 00-1.06 1.06l4 4a.75.75 0 001.06 0l9-9z" />
        <path
          clipRule="evenodd"
          d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12zM1.5 12C1.5 6.201 6.201 1.5 12 1.5S22.5 6.201 22.5 12 17.799 22.5 12 22.5 1.5 17.799 1.5 12z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
StatusSuccess.displayName = 'StatusSuccess';
