import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Link = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M22.2 4.3c-.9-.9-2-1.3-3.2-1.3-1.2 0-2.4.5-3.2 1.3-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0 1.2-1.2 3.2-1.2 4.4 0 .4.6.7 1.4.7 2.2 0 .8-.3 1.6-.9 2.2l-5.6 5.6c-.5.5-1.3.9-2.1.9s-1.5-.2-2.1-.7c-.6-.5-1-1.2-1.1-2 0-.8.2-1.6.6-2.2.2-.3.2-.8-.2-1-.3-.2-.8-.2-1 .2-.7.9-1 2.1-.8 3.2.1 1.1.7 2.2 1.6 2.9.8.7 1.9 1.1 3 1.1h.2c1.2 0 2.2-.5 3.1-1.3l5.6-5.6c.9-.9 1.3-2 1.3-3.2 0-1.2-.6-2.5-1.4-3.4z" />
      <path d="M7.8 17.8c-1.2 1.2-3.2 1.2-4.4 0-.6-.6-.9-1.4-.9-2.2 0-.8.3-1.6.9-2.2L9 7.8c.5-.5 1.3-.8 2-.9.8 0 1.5.2 2.1.7.6.5 1 1.2 1.1 2 .1.8-.1 1.5-.6 2.2-.2.3-.2.8.2 1 .3.2.8.2 1-.2.7-.9 1-2.1.8-3.2-.1-1.1-.7-2.2-1.6-2.9-.9-.8-2-1.1-3.2-1.1-1 .1-2.1.6-2.9 1.4l-5.6 5.6c-.9.9-1.3 2-1.3 3.2 0 1.2.5 2.4 1.3 3.2.9.9 2 1.3 3.2 1.3 1.2 0 2.4-.5 3.2-1.3.3-.3.3-.8 0-1.1-.3-.3-.6-.2-.9.1z" />
    </g>
  );
});
Link.displayName = 'Link';
