import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowLongDownBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M12 0a1.5 1.5 0 011.5 1.5v17.61l5.493-4.992a1.5 1.5 0 012.017 2.22l-8 7.272a1.5 1.5 0 01-2.018 0l-8-7.27a1.5 1.5 0 012.018-2.22l5.491 4.99V1.5A1.5 1.5 0 0112 0z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowLongDownBold.displayName = 'ArrowLongDownBold';
