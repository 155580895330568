import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ImageBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.2 24H1.8c-1 0-1.8-.81-1.8-1.8V1.8C0 .81.81 0 1.8 0h20.4c1 0 1.8.81 1.8 1.8v20.4c0 .99-.81 1.8-1.8 1.8zM10.84 10.42c.24-.23.42-.51.55-.82a2.52 2.52 0 00-.55-2.74 2.57 2.57 0 00-1.78-.71c-.66 0-1.3.25-1.78.71a2.539 2.539 0 00-.55 2.75c.13.31.32.59.55.83.47.47 1.11.74 1.78.74a2.54 2.54 0 001.78-.76zM9.46 7.71c.13.04.24.12.34.21a.979.979 0 01.31.72.979.979 0 01-.31.72c-.2.19-.45.29-.72.29-.27 0-.53-.1-.72-.28a.911.911 0 01-.22-.33 1.033 1.033 0 01.21-1.11.99.99 0 01.71-.29c.14 0 .27.02.4.07zm8.3 9.93c.15-.11.26-.28.3-.46a.73.73 0 00-.1-.54l-3.06-4.65c-.14-.21-.33-.38-.55-.5-.22-.12-.47-.19-.72-.19s-.5.06-.72.17c-.22.11-.41.28-.56.49l-1.66 2.37-.68-.55c-.17-.14-.36-.23-.57-.29-.21-.04-.42-.04-.63 0-.21.03-.42.11-.6.23-.18.11-.34.26-.46.44l-1.68 2.48c-.12.16-.16.37-.13.57.03.2.15.38.31.49.17.12.37.16.57.13.2-.04.38-.15.49-.31l1.73-2.5 1.31 1c.08.06.17.11.27.14.1.02.2.03.3.01.1-.01.2-.05.29-.1.09-.05.16-.13.22-.21l2.19-3 3.09 4.63c.07.1.16.19.27.24.11.06.23.09.35.09.16-.02.31-.08.43-.18z" />
      </g>
    );
  }
);
ImageBold.displayName = 'ImageBold';
