import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MoneyBag = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#money-bag_svg__a)">
        <path d="M12.905 17.868a.59.59 0 01-.38.14h-2a.75.75 0 000 1.5h.75a.75.75 0 001.5 0 2.09 2.09 0 00.56-4l-2.06-.831a.59.59 0 01.22-1.14h2a.75.75 0 000-1.5h-.75a.75.75 0 10-1.5 0 2.09 2.09 0 00-.56 4l2.06.83a.59.59 0 01.3.26.59.59 0 01-.14.741zM6.743 8.995h10.504a.75.75 0 100-1.5H6.743a.75.75 0 000 1.5zm1.001-3.681a.75.75 0 001.34-.67l-1.41-2.821 1.89.95a1.49 1.49 0 001.921-.51l.51-.77.5.77a1.5 1.5 0 001.921.51l1.9-.95-1.43 2.87a.75.75 0 001.34.67l1.441-2.88a1.5 1.5 0 00-2-2l-1.901 1-.5-.76a1.51 1.51 0 00-.56-.55 1.53 1.53 0 00-1.411 0 1.5 1.5 0 00-.54.49l-.51.76-1.901-.95A1.51 1.51 0 006.792.61a1.5 1.5 0 00-.459 1.882l1.41 2.82z" />
        <path d="M21.538 16.658a6.702 6.702 0 00-1.38-3.482l-2.761-3.17a.758.758 0 10-1.14 1l2.74 3.17a5.182 5.182 0 01-4.1 8.344H9.103a5.182 5.182 0 01-4.111-8.343l2.76-3.201a.758.758 0 10-1.14-1l-2.81 3.27A6.682 6.682 0 009.103 24h5.792a6.682 6.682 0 006.652-7.342h-.01z" />
      </g>
      <defs>
        <clipPath id="money-bag_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
MoneyBag.displayName = 'MoneyBag';
