import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, spacing, cssVariables } from '../../../config';
import { SearchBold } from '@lego/klik-icons-react';

const searchFieldWrapperStyle = css({
  position: 'relative',
});

const searchFieldStyle = css({
  width: 320,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.neutralColors.slate40,
  boxShadow: `${colors.neutralColors.slate20} 0px 2px 0px 0px inset`,
  borderRadius: cssVariables.borderRadius,
  cursor: 'pointer',
  color: 'inherit',
  padding: spacing.size3 - 1,
  paddingLeft: spacing.size10 - 1,
  '&:hover': {
    borderWidth: 2,
    borderColor: colors.neutralColors.slate60,
    padding: spacing.size3 - 2,
    paddingLeft: spacing.size10 - 2,
    boxShadow: 'none',
  },
  '&:focus': {
    borderWidth: 2,
    borderColor: colors.utilityColors.information.base,
    padding: spacing.size3 - 2,
    paddingLeft: spacing.size10 - 2,
    boxShadow: 'none',
  },
  '&::placeholder': {
    color: colors.neutralColors.slateBase,
  },
});

const searchIconStyle = css({
  color: colors.neutralColors.slate60,
  position: 'absolute',
  top: 14,
  left: spacing.size3,
  width: 20,
  height: 20,
});

const TableSearchField = ({ onChange, value }) => {
  return (
    <div css={searchFieldWrapperStyle}>
      <SearchBold css={searchIconStyle} />
      <input
        value={value}
        placeholder="Customer Name, ID or C6 Name"
        css={searchFieldStyle}
        onChange={(event) => onChange(event.target.value)}
      ></input>
    </div>
  );
};

TableSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export { TableSearchField };
