import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LaptopSmiley = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#laptop-smiley_svg__a)">
          <path d="M10.497 5.5a.75.75 0 00-1.5 0V7a.75.75 0 101.5 0V5.5zm-1.74 4a.75.75 0 10-1 1.11 6.32 6.32 0 008.51 0 .75.75 0 10-1-1.11 4.82 4.82 0 01-6.51 0zm5.49-4.75a.75.75 0 01.75.75V7a.75.75 0 01-1.5 0V5.5a.75.75 0 01.75-.75zm-3.75 12.75a.75.75 0 000 1.5h3a.75.75 0 100-1.5h-3z" />
          <path d="M4.497 1a2.25 2.25 0 00-2.25 2.25v11.09l-2.05 4.49A2.25 2.25 0 002.247 22h19.5a2.249 2.249 0 002.06-3.16l-2.06-4.5V3.25A2.25 2.25 0 0019.498 1h-15zm15.75 12.75h-16.5V3.25a.75.75 0 01.75-.75h15a.75.75 0 01.75.75v10.5zm-18.69 5.7l1.92-4.2h17l1.92 4.2a.75.75 0 01-.65 1.05h-19.5a.75.75 0 01-.69-1.05z" />
        </g>
        <defs>
          <clipPath id="laptop-smiley_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
LaptopSmiley.displayName = 'LaptopSmiley';
