import PropTypes from 'prop-types';
import { Modal, Overlay, Button } from '@lego/klik-react';
import { Delete } from '@lego/klik-icons-react';

const DeleteImageModal = ({
  imageId,
  closeDeleteImageModal,
  deleteImageHandler,
}) => (
  <Overlay isOpen={true} onClick={closeDeleteImageModal}>
    <Modal
      status="error"
      icon={<Delete />}
      heading={<Modal.Heading>Delete image?</Modal.Heading>}
      body={<Modal.Body>Are you sure you want to delete the image?</Modal.Body>}
      action={
        <Modal.Action>
          <Button
            aria-label="Confirm delete image button"
            label="Delete"
            onClick={() => deleteImageHandler(imageId)}
          />
          <Button
            label="Cancel"
            variant="outline"
            onClick={closeDeleteImageModal}
          />
        </Modal.Action>
      }
      exitClickHandler={closeDeleteImageModal}
    />
  </Overlay>
);

DeleteImageModal.propTypes = {
  imageId: PropTypes.string.isRequired,
  closeDeleteImageModal: PropTypes.func.isRequired,
  deleteImageHandler: PropTypes.func.isRequired,
};

export { DeleteImageModal };
