import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesPng = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-png_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h.834a.75.75 0 000-1.5H3.75a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M7.609 15.101a.742.742 0 00-.732.741v7.33a.74.74 0 001.291.58.74.74 0 00.183-.58V20.98a2.95 2.95 0 002.085-.861 2.915 2.915 0 000-4.157 2.947 2.947 0 00-2.085-.86h-.742zm.742 1.472v2.935a1.475 1.475 0 001.595-1.468 1.471 1.471 0 00-1.595-1.467z"
          fillRule="evenodd"
        />
        <path d="M21.805 23.874a3.009 3.009 0 01-1.925-.621 4.206 4.206 0 01-1.047-1.604c-.22-.608-.233-1.235-.233-1.901 0-.78.04-1.491.31-2.187a4.798 4.798 0 011.25-1.819 3.85 3.85 0 013.068-.63.753.753 0 11-.35 1.461 2.338 2.338 0 00-1.805.36 3.494 3.494 0 00-1.003 2.795 3.071 3.071 0 00.702 2.313 2.107 2.107 0 001.724.2V20.5l-.822.05a.752.752 0 110-1.502h.872A1.456 1.456 0 0124 20.499v1.873a1.431 1.431 0 01-1.113 1.402 4.123 4.123 0 01-1.082.1zm-4.762.05a.742.742 0 01-.652-.39l-2.687-4.797v4.436a.75.75 0 01-1.504 0v-7.31a.76.76 0 01.562-.732.733.733 0 01.842.36l2.687 4.808v-4.437a.75.75 0 011.283-.53.75.75 0 01.22.53v7.31a.74.74 0 01-.56.722.632.632 0 01-.191.03z" />
      </g>
      <defs>
        <clipPath id="files-png_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesPng.displayName = 'FilesPng';
