import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const GameBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M19.275 5.55h-6.061a5.842 5.842 0 00-1.88-3.942A7.842 7.842 0 005.711.048a1 1 0 00-.75 1.485 1 1 0 001 .505 5.772 5.772 0 014 1.07 3.8 3.8 0 011.181 2.441h-6.88A4.251 4.251 0 000 9.8v10.433a4.14 4.14 0 00.64 2.27c1.381 2.1 3.662 1.78 4.552.33l2.33-3.86a2 2 0 011.75-1h4.932a2 2 0 011.72 1l1.93 3.09c1.051 1.9 3.352 2.52 4.872 1a4.712 4.712 0 00.81-2.67V9.8a4.25 4.25 0 00-4.26-4.25zM7.142 13.84h-1.14v1.13a.76.76 0 11-1.51 0v-1.13h-1.15a.76.76 0 010-1.51h1.15v-1.14a.76.76 0 011.51 0v1.14h1.14a.76.76 0 010 1.51zm9.773.93a1.45 1.45 0 110-2.9 1.45 1.45 0 010 2.9zm2.36-2.47a1.2 1.2 0 111.2-1.19 1.189 1.189 0 01-1.2 1.19z" />
    </g>
  );
});
GameBold.displayName = 'GameBold';
