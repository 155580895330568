import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LayoutGridViewBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M0 .773a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v8.974a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75V.773zm0 13.474a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v9a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-9zM13.5.773a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v8.974a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75V.773zm0 13.474a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v9a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-9z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
LayoutGridViewBold.displayName = 'LayoutGridViewBold';
