import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Home = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M4.516 13.37c.388 0 .703.316.703.704v7.031h4.218v-4.922a2.11 2.11 0 012.11-2.11h1.406a2.11 2.11 0 012.11 2.11v4.922h4.218v-7.031a.703.703 0 011.407 0v7.734a.703.703 0 01-.704.703H14.36a.703.703 0 01-.703-.703v-5.625a.703.703 0 00-.703-.703h-1.406a.703.703 0 00-.703.703v5.625a.703.703 0 01-.703.703H4.516a.703.703 0 01-.704-.703v-7.734c0-.389.315-.703.704-.703z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.25 3.406a.703.703 0 00-.497.206L2.2 13.165a.703.703 0 11-.994-.995l9.552-9.552a2.11 2.11 0 012.984 0l9.552 9.552a.703.703 0 01-.994.995l-9.553-9.553a.703.703 0 00-.497-.206z"
        fillRule="evenodd"
      />
    </g>
  );
});
Home.displayName = 'Home';
