import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesMp4Bold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M18.16 15.2v-2.19l-1.61 2.19h1.61z" />
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM9.87 17.51a.64.64 0 01-.64.63.63.63 0 01-.63-.63v-4.25l-.79 1.16a.64.64 0 01-.52.28.63.63 0 01-.52-.28l-.83-1.19v4.28a.63.63 0 01-.63.63.64.64 0 01-.64-.63V11.2a.63.63 0 01.846-.6.64.64 0 01.314.24l1.46 2.1 1.43-2.1a.64.64 0 011.037-.02.63.63 0 01.123.38l-.01 6.31zm3.92-2.63a2.55 2.55 0 01-1.8.74v1.9a.64.64 0 01-1.27 0v-6.34a.63.63 0 01.63-.63H12a2.53 2.53 0 011.8 4.33h-.01zm5.55 2.6a.62.62 0 01-.59.64.61.61 0 01-.59-.64v-1h-2.83a.57.57 0 01-.53-.37.67.67 0 01.07-.67l3.42-4.65a.57.57 0 01.66-.2.64.64 0 01.39.6v6.29z" />
        <path d="M12 11.82v2.53a1.252 1.252 0 00.9-.37 1.24 1.24 0 000-1.79 1.251 1.251 0 00-.9-.37z" />
      </g>
    );
  }
);
FilesMp4Bold.displayName = 'FilesMp4Bold';
