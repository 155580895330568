import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesAiBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#files-ai-bold_svg__a)">
          <path d="M10.92 11.24a.61.61 0 00-.61.61v1.31h1.21v-1.31a.61.61 0 00-.6-.61z" />
          <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zm-9 11.22a.62.62 0 01-1.24 0V14.4h-1.16v2.53a.62.62 0 11-1.24 0v-5.08a1.85 1.85 0 013.69 0l-.05 5.08zm2.17 0a.7.7 0 01-1.39 0v-6.3a.7.7 0 011.39 0v6.3z" />
        </g>
        <defs>
          <clipPath id="files-ai-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
FilesAiBold.displayName = 'FilesAiBold';
