import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const VideoMulti = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M8.04 16.29c-.14 0-.27-.03-.39-.1a.834.834 0 01-.3-.29.802.802 0 01-.11-.4V6.22c0-.14.04-.28.12-.41a.79.79 0 01.31-.29c.13-.07.27-.1.41-.09.14.01.28.05.4.13l7.18 4.9c.11.07.2.17.26.29.06.12.09.25.09.38s-.04.26-.11.38-.16.21-.27.28l-7.18 4.39c-.13.07-.27.11-.41.11zm.79-8.56v6.35l4.92-3-4.92-3.35z" />
        <path d="M19.92 1.5c.17 0 .3.14.3.3v18.12a.3.3 0 01-.3.3H1.8a.3.3 0 01-.3-.3V1.8c0-.17.14-.3.3-.3h18.12zm0-1.5H1.8C.81 0 0 .81 0 1.8v18.12c0 1 .81 1.8 1.8 1.8h18.12c1 0 1.8-.81 1.8-1.8V1.8c.01-.99-.8-1.8-1.8-1.8z" />
        <path d="M21.44 24H4.11c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h17.33c.58 0 1.05-.47 1.05-1.05V4.11c0-.41.34-.75.75-.75s.76.34.76.75v17.33c0 1.41-1.15 2.56-2.56 2.56z" />
      </g>
    );
  }
);
VideoMulti.displayName = 'VideoMulti';
