import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { spacing, font } from '../../config';

const answerLabelTextStyle = css({
  fontSize: font.size.size05,
  marginRight: spacing.size2,
  whiteSpace: 'pre-wrap',
});

const answerLabelScoreStyle = css({
  fontWeight: font.weight.bold,
  fontSize: font.size.sizeM1,
  fontStyle: 'italic',
});

const AnswerLabel = ({ labelText, score }) => (
  <span>
    <span css={answerLabelTextStyle}>{labelText}</span>
    {typeof score === 'number' && (
      <span css={answerLabelScoreStyle}>{`(${score}%)`}</span>
    )}
  </span>
);

AnswerLabel.propTypes = {
  labelText: PropTypes.string.isRequired,
  score: PropTypes.number,
};

export { AnswerLabel };
