import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const VirtualRealityBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M18.53 20.81a9.54 9.54 0 01-6.19 2.92 9.42 9.42 0 01-6.5-2 1 1 0 01-.18-1.31.861.861 0 011.24-.22 7.71 7.71 0 005.32 1.66 7.81 7.81 0 005.06-2.39.858.858 0 011.26 0 1 1 0 01-.01 1.34zM3.75 9.19A8.22 8.22 0 0111.1 1a10 10 0 011.74 0 8.34 8.34 0 017.41 8.22 4.06 4.06 0 013.65 3.22 4.582 4.582 0 01-.06 2 4 4 0 01-4.45 2.94 2.2 2.2 0 01-2.14 1.56h-3s-.33.06-2.25-1.35l-1.83 1.22s-.19.24-3.42.13a2.32 2.32 0 01-2.14-1.56S1.53 18 .16 14.44a8.13 8.13 0 01-.07-2 4.21 4.21 0 013.66-3.25zm.19 2.09a1.92 1.92 0 00-2 1.55 2.06 2.06 0 002 2.63.3.3 0 00.3-.3V11.5a.33.33 0 00-.3-.22zm13.51 1a.65.65 0 00-.68-.64H7.18a.62.62 0 00-.68.64v3.77a.61.61 0 00.44.66c.916.048 1.834.048 2.75 0l2-1.24a.58.58 0 01.62.07c1.35.89 1.88 1.17 1.88 1.17h2.53a.629.629 0 00.68-.64l.05-3.79zM17.81 9a6.58 6.58 0 00-.63-3 6.101 6.101 0 00-1.75-2.09 6 6 0 00-2.9-1.14c-.5-.03-1-.03-1.5 0a5.88 5.88 0 00-2.94 1.41 6.06 6.06 0 00-1.55 2.07A6.41 6.41 0 006 9c.173.152.324.327.45.52 0 0 .12-.14.77-.2h9.08a2.16 2.16 0 011 .21 1.4 1.4 0 01.51-.53zm2 2.51v3.66a.3.3 0 00.3.3 2.06 2.06 0 002-2.63 1.92 1.92 0 00-2-1.55.331.331 0 00-.29.2l-.01.02z" />
        <path d="M18 11l-11.94-.1H6l.21 6.21h12L18 11zm-2.25 3.4h-7.5a.75.75 0 110-1.5h7.5a.75.75 0 010 1.5z" />
      </g>
    );
  }
);
VirtualRealityBold.displayName = 'VirtualRealityBold';
