const userStatusType = {
  CONFIRMED: 'CONFIRMED',
  FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
  UNKNOWN: 'UNKNOWN',
  UNCONFIRMED: 'UNCONFIRMED',
  ARCHIVED: 'ARCHIVED',
  COMPROMISED: 'COMPROMISED',
  RESET_REQUIRED: 'RESET_REQUIRED',
};

const userStatusTypeTranslations = {
  [userStatusType.CONFIRMED]: 'Confirmed',
  [userStatusType.FORCE_CHANGE_PASSWORD]: "Hasn't logged in yet",
  [userStatusType.UNKNOWN]: 'Unknown',
  [userStatusType.UNCONFIRMED]: 'Unconfirmed',
  [userStatusType.ARCHIVED]: 'Archived',
  [userStatusType.COMPROMISED]: 'Compromised',
  [userStatusType.RESET_REQUIRED]: 'Reset required',
};

export { userStatusType, userStatusTypeTranslations };
