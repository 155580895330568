import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesPpt = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.499 15.748a.75.75 0 01.75-.75H9a3 3 0 010 6h-.001v2.25a.75.75 0 01-1.5 0v-7.5zm1.5 3.75v-3H9a1.5 1.5 0 110 3h-.001zm5.25-4.5H15a3 3 0 010 6h-.001v2.25a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75zm.75 1.5H15a1.5 1.5 0 110 3h-.001v-3z"
        fillRule="evenodd"
      />
      <path d="M22.499 16.498h.75a.75.75 0 000-1.5h-3a.75.75 0 000 1.5h.75v6.75a.75.75 0 001.5 0v-6.75z" />
    </g>
  );
});
FilesPpt.displayName = 'FilesPpt';
