import { getValidChildren } from '@chakra-ui/react-utils';
import { forwardRef } from '@chakra-ui/system';
import { ButtonGroup, IButtonGroupProps } from '@lego/klik-ui-button-group';
import * as React from 'react';

// TODO: Add `isRound` to the official chakra and ask them to propagate it, imho that's an
// oversight on their behalf. Once they have
export interface IToggleButtonGroupProps extends IButtonGroupProps {
  defaultActiveButtons?: number[];
  multiple?: boolean;
  onChange?: (props: any) => void;
}

export const ToggleButtonGroup = forwardRef<IToggleButtonGroupProps, 'div'>(
  (
    {
      children,
      spacing = '2px',
      isAttached = true,
      defaultActiveButtons = [],
      multiple = true,
      isRound = false,
      onChange: onChangeProp,
      ...rest
    },
    ref
  ) => {
    // TODO: useControllableState // useControllableProp ?
    const [activeButtons, setActiveButtons] = React.useState(defaultActiveButtons);

    const toggleButton = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
        const isActive = activeButtons.includes(index);

        let newActiveButtons: number[];

        if (isActive) {
          newActiveButtons = activeButtons.filter((i) => i !== index);
        } else {
          newActiveButtons = multiple ? [...activeButtons, index] : [index];
        }

        onChangeProp?.(newActiveButtons);
        setActiveButtons(newActiveButtons);
      },
      [activeButtons, multiple]
    );

    const validChildren = getValidChildren(children);

    return (
      // Getting error: Expression produces a union type that is too complex to represent
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <ButtonGroup isAttached={isAttached} isRound={isRound} ref={ref} spacing={spacing} {...rest}>
        {validChildren.map((child, index) =>
          React.cloneElement(child, {
            ...rest,
            ...child.props,
            index,
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => void toggleButton(e, index),
            variant: activeButtons.includes(index) ? 'attached-active' : 'attached',
          })
        )}
      </ButtonGroup>
    );
  }
);
