import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const HardDriveBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M.5 15.251v4a2.007 2.007 0 002 2h19a2.006 2.006 0 002-2v-4a2.005 2.005 0 00-2-2h-19a2.006 2.006 0 00-2 2zm19.5-1a1 1 0 110 2 1 1 0 010-2zm.5 3.249h-17a.75.75 0 000 1.5h17a.75.75 0 000-1.5z"
          fillRule="evenodd"
        />
        <path d="M2.5 11.751h19c.46 0 .917.094 1.34.276a.25.25 0 00.34-.291l-1.69-6.673a3.213 3.213 0 00-2.99-2.312h-13a3.213 3.213 0 00-2.989 2.312L.82 11.736a.249.249 0 00.34.291c.423-.182.88-.276 1.34-.276z" />
      </g>
    );
  }
);
HardDriveBold.displayName = 'HardDriveBold';
