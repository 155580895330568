import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesCamera = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M7.238 2.464a.75.75 0 01.671-.414h8.182a.75.75 0 01.67.414L18.6 6.14h2.604A2.796 2.796 0 0124 8.933v10.223a2.794 2.794 0 01-2.796 2.794H2.796A2.796 2.796 0 010 19.156V8.933A2.794 2.794 0 012.795 6.14H5.4l1.838-3.675zM8.373 3.55L6.534 7.224a.75.75 0 01-.67.415H2.795A1.296 1.296 0 001.5 8.933v10.223a1.294 1.294 0 001.295 1.294h18.41a1.296 1.296 0 001.295-1.294V8.933a1.294 1.294 0 00-1.296-1.294h-3.068a.75.75 0 01-.67-.415L15.627 3.55H8.373zm.204 6.05a4.842 4.842 0 018.264 3.422 4.838 4.838 0 01-4.84 4.84 4.842 4.842 0 01-4.842-4.84A4.84 4.84 0 018.577 9.6zM12 9.683a3.342 3.342 0 00-3.34 3.34A3.338 3.338 0 0012 16.36a3.342 3.342 0 003.341-3.339 3.338 3.338 0 00-3.34-3.339z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
DevicesCamera.displayName = 'DevicesCamera';
