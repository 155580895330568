import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Retract = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M23.026 2.526a.75.75 0 10-1.06-1.06l-7.22 7.22v-4.69a.75.75 0 10-1.5 0v6.5a.747.747 0 00.75.75h6.5a.75.75 0 100-1.5h-4.69l7.22-7.22zm-12.53 18.448a.75.75 0 01-.75-.75v-4.69l-7.22 7.22a.75.75 0 11-1.06-1.06l7.22-7.22h-4.69a.75.75 0 010-1.5h6.5a.747.747 0 01.75.75v6.5a.75.75 0 01-.75.75z" />
    </g>
  );
});
Retract.displayName = 'Retract';
