import {
  AccordionButton as AccordionButtonComponent,
  AccordionButtonProps,
} from '@chakra-ui/accordion';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IAccordionButtonProps = AccordionButtonProps;

export const AccordionButton = forwardRef<IAccordionButtonProps, 'button'>((props, ref) => {
  return <AccordionButtonComponent {...props} ref={ref} />;
});
