import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import { Tabs as TabsComponent, TabsProps } from '@chakra-ui/tabs';
import * as React from 'react';
import { Tab } from './Tab';
import { TabList } from './TabList';
import { TabPanel } from './TabPanel';
import { TabPanels } from './TabPanels';

export type ITabsProps = TabsProps;

type ITabsComponent = ComponentWithAs<'div', ITabsProps> & {
  Tab: typeof Tab;
  TabList: typeof TabList;
  TabPanel: typeof TabPanel;
  TabPanels: typeof TabPanels;
};

export const Tabs = forwardRef<ITabsProps, 'div'>((props, ref) => {
  return <TabsComponent {...props} ref={ref} />;
}) as ITabsComponent;

Tabs.Tab = Tab;
Tabs.TabList = TabList;
Tabs.TabPanel = TabPanel;
Tabs.TabPanels = TabPanels;
