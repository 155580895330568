import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ViewShow = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M23.383 10.337c-2.606-2.863-6.909-5.88-11.246-5.88H12c-4.389-.068-8.76 3-11.383 5.88a2.435 2.435 0 000 3.24c2.589 2.863 6.892 5.88 11.229 5.88h.274c4.337 0 8.64-3.034 11.246-5.88.84-.926.84-2.314.017-3.24zm-1.114 2.229c-2.383 2.623-6.275 5.4-10.115 5.4H12c-3.891.051-7.851-2.743-10.251-5.383a.916.916 0 010-1.217c3.6-3.995 7.56-5.4 10.131-5.4h.137c2.554-.052 6.583 1.354 10.252 5.383a.916.916 0 010 1.217z" />
      <path d="M12 7.457c-2.486 0-4.509 2.023-4.509 4.492 0 1.2.463 2.331 1.32 3.188A4.444 4.444 0 0012 16.457c2.486 0 4.491-2.023 4.509-4.491 0-1.2-.463-2.332-1.32-3.189A4.474 4.474 0 0012 7.457zm3 4.509a2.981 2.981 0 01-3 3 2.981 2.981 0 01-3-3c0-1.646 1.354-3 3-3a2.981 2.981 0 013 3z" />
    </g>
  );
});
ViewShow.displayName = 'ViewShow';
