import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesPsdBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6.29 12.11v2.53a1.31 1.31 0 00.9-.37 1.27 1.27 0 00-.9-2.16zm10.2.11v4.84a2.14 2.14 0 00.71-.45 1.92 1.92 0 00.56-1.34V14a1.94 1.94 0 00-.56-1.34 2 2 0 00-.71-.44z" />
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM8.09 15.16a2.539 2.539 0 01-1.8.74v1.9a.64.64 0 01-.64.63.63.63 0 01-.63-.63v-6.32a.64.64 0 01.63-.64h.64a2.531 2.531 0 011.8 4.32zm4.29-1a2.91 2.91 0 011.49 2.39 1.91 1.91 0 01-1.9 1.9h-1.24a.64.64 0 110-1.28H12a.64.64 0 00.64-.64c0-.59-.36-.9-1-1.39a2.9 2.9 0 01-1.52-2.4 1.91 1.91 0 011.9-1.9h1.28a.64.64 0 110 1.27H12a.64.64 0 00-.64.64c0 .59.37.89 1.02 1.39v.02zm6.6 1.13a3.16 3.16 0 01-3.17 3.16.67.67 0 01-.45-.18.63.63 0 01-.18-.45v-6.34a.64.64 0 01.63-.64 3.22 3.22 0 012.25.93c.59.592.92 1.394.92 2.23v1.29z" />
      </g>
    );
  }
);
FilesPsdBold.displayName = 'FilesPsdBold';
