import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowUpAndDown = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M12.526 1.466a.75.75 0 00-1.052 0l-6.6 6.5a.75.75 0 101.052 1.068L12 3.053l6.074 5.981a.75.75 0 101.052-1.068l-6.6-6.5zm-6.6 13.5a.75.75 0 00-1.052 1.068l6.6 6.5a.75.75 0 001.052 0l6.6-6.5a.75.75 0 00-1.052-1.068L12 20.947l-6.074-5.981z" />
      </g>
    );
  }
);
ArrowUpAndDown.displayName = 'ArrowUpAndDown';
