import {
  CircularProgressLabel as CircularProgressLabelComponent,
  CircularProgressLabelProps,
} from '@chakra-ui/progress';
import { ChakraComponent } from '@chakra-ui/system';
import * as React from 'react';

export type ICircularProgressLabelProps = ChakraComponent<'div', CircularProgressLabelProps>;

export const CircularProgressLabel = ((props: CircularProgressLabelProps) => (
  <CircularProgressLabelComponent sx={{ fontSize: 'inherit' }} {...props} />
)) as ICircularProgressLabelProps;
