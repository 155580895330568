import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Controls = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M23.246 0H.754A.761.761 0 000 .754v22.492c0 .411.343.754.754.754h22.492a.761.761 0 00.754-.754V.754A.761.761 0 0023.246 0zM1.509 22.491V1.51h21v20.98h-21z" />
      <path d="M5.246 16.491h2.348c.343 1.286 1.509 2.246 2.897 2.246a3 3 0 002.898-2.246h5.348a.76.76 0 00.754-.754.76.76 0 00-.754-.754h-5.331a2.919 2.919 0 00-.789-1.372 2.981 2.981 0 00-2.126-.874 3 3 0 00-2.897 2.246H5.246a.76.76 0 00-.755.754.76.76 0 00.755.754zm5.245-2.245c.395 0 .772.154 1.063.445.292.292.446.652.446 1.063a1.51 1.51 0 01-1.509 1.509 1.5 1.5 0 01-1.508-1.509c.017-.823.686-1.508 1.508-1.508zM5.246 9h8.348c.343 1.286 1.509 2.246 2.897 2.246 1.646 0 3-1.337 3-3 0-1.646-1.354-3-3-3a3 3 0 00-2.897 2.245H5.246a.761.761 0 00-.755.755c0 .411.343.754.755.754zM16.49 6.754c.823 0 1.509.669 1.509 1.492a1.51 1.51 0 01-1.509 1.508A1.496 1.496 0 0115 8.246c0-.823.669-1.492 1.491-1.492z" />
    </g>
  );
});
Controls.displayName = 'Controls';
