import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Music = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M7.71 19.03a3.104 3.104 0 003.1-3.1v-7.8l6.81-1.11v3.91a3.055 3.055 0 00-3.05.21c-.51.34-.91.82-1.14 1.39-.23.57-.3 1.19-.18 1.79.12.6.41 1.15.85 1.59.43.43.99.73 1.59.85.6.12 1.22.06 1.79-.18a3.04 3.04 0 001.39-1.14c.34-.51.52-1.11.52-1.72v-7.8a.865.865 0 00-.06-.39.938.938 0 00-.23-.33.801.801 0 00-.35-.19.772.772 0 00-.4-.02L9.77 6.5c-.2.03-.39.14-.52.3-.14.16-.21.36-.21.57v5.77c-.42-.2-.88-.3-1.34-.3-.46 0-.92.11-1.34.31a3.09 3.09 0 00-1.66 2.1c-.1.45-.1.92 0 1.38.11.45.31.88.6 1.24s.66.66 1.08.86c.41.2.87.3 1.33.3zm8.58-4.02a1.333 1.333 0 01-1.33-1.33 1.333 1.333 0 011.33-1.33 1.333 1.333 0 011.33 1.33 1.333 1.333 0 01-1.33 1.33zm-8.58-.44a1.333 1.333 0 011.33 1.33 1.333 1.333 0 01-1.33 1.33 1.333 1.333 0 01-1.33-1.33 1.333 1.333 0 011.33-1.33z" />
      <path d="M22.2 1.5c.17 0 .3.14.3.3v20.4a.3.3 0 01-.3.3H1.8a.3.3 0 01-.3-.3V1.8c0-.17.14-.3.3-.3h20.4zm0-1.5H1.8C.81 0 0 .81 0 1.8v20.4c0 1 .81 1.8 1.8 1.8h20.4c1 0 1.8-.81 1.8-1.8V1.8c0-.99-.81-1.8-1.8-1.8z" />
    </g>
  );
});
Music.displayName = 'Music';
