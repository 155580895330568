import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const WifiOnBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M3.44 9.76a1.343 1.343 0 00.017 1.885 1.347 1.347 0 001.886.016c3.68-3.667 9.636-3.667 13.317 0 .252.252.594.393.951.393a1.334 1.334 0 001.34-1.313v-.03c0-.357-.141-.699-.394-.95-4.731-4.712-12.386-4.712-17.117 0z" />
        <path d="M23.606 5.644C17.192-.748 6.808-.748.394 5.644a1.343 1.343 0 000 1.901 1.346 1.346 0 001.903 0c5.363-5.344 14.043-5.344 19.406 0a1.346 1.346 0 002.251-.603 1.343 1.343 0 00-.348-1.298zm-9.041 6.392a7.81 7.81 0 00-8.082 1.84 1.343 1.343 0 101.902 1.899 5.114 5.114 0 017.024-.167c.067.06.141.108.205.167a1.343 1.343 0 002.19-.44 1.344 1.344 0 00-.292-1.464 7.738 7.738 0 00-2.947-1.835zm.125 8.426A2.69 2.69 0 0112 23.15a2.69 2.69 0 01-2.69-2.688A2.69 2.69 0 0112 17.774a2.69 2.69 0 012.69 2.688z" />
      </g>
    );
  }
);
WifiOnBold.displayName = 'WifiOnBold';
