import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ShoppingCartEmpty = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.44 6.39a1.63 1.63 0 00-1.32-.68H5.63l-.67-3.42A1.58 1.58 0 003.37 1H1.75a.75.75 0 000 1.5h1.62a.08.08 0 01.08.07L6.6 18.92a1.65 1.65 0 001.62 1.34h10.56a.75.75 0 100-1.5H8.22a.15.15 0 01-.14-.12l-.48-2.55h11.13a3.27 3.27 0 003.15-2.32l1.82-5.92a1.631 1.631 0 00-.26-1.46zm-3 6.93a1.78 1.78 0 01-1.71 1.26H7.3L5.91 7.27h16.21a.14.14 0 01.12.06.14.14 0 010 .12l-1.8 5.87zM18.3 23.54a1.31 1.31 0 10-.825-2.488 1.31 1.31 0 00.825 2.487zm-9.22.06a1.31 1.31 0 100-2.62 1.31 1.31 0 000 2.62z" />
      </g>
    );
  }
);
ShoppingCartEmpty.displayName = 'ShoppingCartEmpty';
