import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FactoryBuildingBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M21.437 3.679l-.082-2.637c0-.276-.108-.541-.3-.737a1.011 1.011 0 00-.72-.305H17.21c-.271 0-.531.11-.723.305-.191.196-.299.46-.299.737l-.102 2.637h5.352zm.337 11.463l-.286-9.9h-5.423l-.286 9.9h5.995zm-8.497 1.563h10.212c.136 0 .266.055.361.153.096.098.15.23.15.368v6.253c0 .138-.054.27-.15.368a.505.505 0 01-.36.153H13.204c.045-.17.069-.345.072-.521v-6.774zm2.297 4.43h1.532a.759.759 0 00.542-.23.79.79 0 000-1.105.758.758 0 00-.542-.229h-1.532a.758.758 0 00-.541.229.79.79 0 000 1.105.759.759 0 00.541.23zm4.596 0h1.532a.758.758 0 00.542-.23.79.79 0 000-1.105.758.758 0 00-.542-.229H20.17a.758.758 0 00-.541.229.79.79 0 000 1.105.759.759 0 00.541.23zm-8.936-6.514H.511a.506.506 0 00-.361.152.527.527 0 00-.15.369v8.337c0 .138.054.27.15.368A.506.506 0 00.51 24h3.412a.506.506 0 00.36-.153.527.527 0 00.15-.368v-5.43h2.87v5.43c0 .138.054.27.15.368a.506.506 0 00.36.153h3.422a.506.506 0 00.361-.153.527.527 0 00.15-.368v-8.337a.527.527 0 00-.15-.368.506.506 0 00-.36-.153zm.306-1.563H.194a.545.545 0 01-.112-.417 6.037 6.037 0 011.961-3.665 5.815 5.815 0 013.83-1.473 5.835 5.835 0 013.824 1.478 6.058 6.058 0 011.966 3.66.561.561 0 01-.123.417z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
FactoryBuildingBold.displayName = 'FactoryBuildingBold';
