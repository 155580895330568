import { mode, Styles } from '@chakra-ui/theme-tools';

export const globalStyles: Styles = {
  global: (props) => ({
    body: {
      position: 'static', // reset bad CSS Reset
      fontFamily: 'body',
      color: mode('slate.900', 'white')(props),
      backgroundColor: mode('white', 'slate.900')(props),
      transition: 'background-color 0.2s',
      textStyle: 'body1',
    },
    '*::placeholder': {
      color: mode('slate.400', 'white')(props),
    },
    '*, *::before, &::after': {
      borderColor: mode('slate.200', 'white')(props),
      wordWrap: 'break-word',
    },
    '.chakra-toast__inner > .klik-inline-notification': {
      width: { base: '100%', sm: '400px' },
      boxShadow: '2',
    },
    '.chakra-button__icon, .chakra-tab__icon': {
      alignItems: 'center',
      display: 'inline-flex',
      flexShrink: '0',
      justifyContent: 'center',
      position: 'relative',
    },
    '&[data-icon-fixed]': {
      fontSize: '0.75rem !important',
    },

    // custom scrollbar in modals
    '.chakra-modal__content &::-webkit-scrollbar': {
      width: '8px',
    },
    '.chakra-modal__content &::-webkit-scrollbar-track': {
      width: '8px',
      background: 'transparent'
    },
    '.chakra-modal__content &::-webkit-scrollbar-thumb': {
      backgroundColor: 'slate.200',
      borderRadius: '5px',
    },
    '.chakra-modal__content &::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'slate.500',
    },

  }),
};
