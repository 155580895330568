import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ServerBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.93 8.2a2.55 2.55 0 00-1.86-1.66 13.74 13.74 0 00-2.279-.28H6.174a6.577 6.577 0 00-2.54.38 2.19 2.19 0 00-1.249 1.29L.515 7.2a4.19 4.19 0 012.41-2.42 8.556 8.556 0 013.28-.51H17.81c.901.032 1.798.143 2.68.33a4.538 4.538 0 013.319 2.95l-1.88.65z" />
        <path d="M19.381 4.27H4.944C.516 4.24.006 7.83.006 9.8a5.09 5.09 0 004.779 5.32H19.24A5.619 5.619 0 0024 9.87c0-1.98-.19-5.63-4.619-5.6zM5.614 10.91a1.16 1.16 0 11.02-2.32 1.16 1.16 0 01-.02 2.32zm6.389-.16a1 1 0 010-2h1.4a1 1 0 010 2h-1.4zm4.419 0a1 1 0 010-2h3.149a1 1 0 110 2h-3.15z" />
        <path d="M19.291 14.88H5.464a3.789 3.789 0 00-2.679 6.47 3.79 3.79 0 002.68 1.11H19.29a3.789 3.789 0 002.68-6.47 3.789 3.789 0 00-2.68-1.11zM5.614 20a1.16 1.16 0 110-2.319 1.16 1.16 0 010 2.319zm6.389-.16a1 1 0 010-2h1.4a1 1 0 010 2h-1.4zm7.558 0h-3.15a1 1 0 010-2h3.15a1 1 0 010 2z" />
        <path d="M19.001 24H5.074a5.068 5.068 0 01-5.068-5v-.91A5.07 5.07 0 015.074 13h13.927A5.069 5.069 0 0124 18.06V19a5.07 5.07 0 01-4.999 5zM5.074 15a3.07 3.07 0 00-3.069 3.06V19a3.07 3.07 0 003.07 3H19a3.069 3.069 0 003-3v-.91A3.07 3.07 0 0019 15H5.074zM1.505 4s1.72-3.44 5-4h10.997C18.668.207 21.7 1.248 22.5 4c-1.618-.595-2.499-1-4.998-1H7.504c-1.9.05-4.839-.05-5.999 1z" />
      </g>
    );
  }
);
ServerBold.displayName = 'ServerBold';
