import { ThemeComponentProps } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

const parts = ['requiredIndicator', 'helperText'];

const baseStyleRequiredIndicator = (props: ThemeComponentProps) => {
  return {
    marginStart: 1,
    color: mode('error.400', 'error.300')(props),
  };
};

const baseStyleHelperText = (props: ThemeComponentProps) => {
  return {
    mt: 2,
    color: mode('slate.500', 'slate.600')(props),
    lineHeight: 'normal',
    fontSize: 'sm',
  };
};

const baseStyle = (props: ThemeComponentProps) => ({
  requiredIndicator: baseStyleRequiredIndicator(props),
  helperText: baseStyleHelperText(props),
});

const sizes = {
  sm: {
    helperText: {
      fontSize: 'xs',
    },
  },
  md: {
    helperText: {
      fontSize: 'sm',
    },
  },
  lg: {
    helperText: {
      fontSize: 'md',
    },
  },
};

export const Form = {
  parts,
  baseStyle,
  sizes,
};
