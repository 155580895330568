import { css } from '@emotion/core';
import { Download, StatusWarningBold } from '@lego/klik-icons-react';
import { Stack } from '@lego/klik-ui';
import PropTypes from 'prop-types';
import { colors } from '../../../config';

import { formatDate } from '../../../utils/formatDate';
import { Tooltip } from '../../tooltip/Tooltip';
import { Heading } from '../../Heading';

const exportIconStyle = css({
  height: 14,
});

const failureReportIconStyle = css({
  height: 14,
  color: colors.utilityColors.warning.warning400,
});

const downloadLinkStyle = css({
  display: 'inline-flex',
  alignItems: 'center',
});

const downloadLinkWrapperStyle = css({
  display: 'flex',
});

const PreviousCustomerMapping = ({ uploadedFiles }) => {
  if (uploadedFiles.length === 0) {
    return <div>No previous files uploaded...</div>;
  }
  const groupedFiles = {};
  let latestGroup;
  for (const file of uploadedFiles) {
    const keyMatch = file.key.match(/customer-mapping\/processed\/(\d+)\/(.+)/);
    if (keyMatch) {
      const group = keyMatch[1];
      const fileName = keyMatch[2];
      if (!groupedFiles[group]) {
        groupedFiles[group] = {
          mappingFile: {},
          processLog: {},
          failureReport: {},
        };
      }
      if (!latestGroup || group > latestGroup) {
        latestGroup = group;
      }
      switch (fileName) {
        case 'process.log':
          groupedFiles[group].processLog.fileName = fileName;
          groupedFiles[group].processLog.lastModified = new Date(
            file.lastModified
          );
          groupedFiles[group].processLog.downloadLink = file.downloadLink;
          break;
        case 'FailureReport.xlsx':
          groupedFiles[group].failureReport.fileName = fileName;
          groupedFiles[group].failureReport.lastModified = new Date(
            file.lastModified
          );
          groupedFiles[group].failureReport.downloadLink = file.downloadLink;
          break;
        default:
          groupedFiles[group].mappingFile.fileName = fileName;
          groupedFiles[group].mappingFile.lastModified = new Date(
            file.lastModified
          );
          groupedFiles[group].mappingFile.downloadLink = file.downloadLink;
      }
    }
  }

  const latestFile = groupedFiles[latestGroup];

  return (
    <div data-testid="previousCustomerMapping">
      <Stack>
        <Heading
          type="h3"
          text={`Last update: ${formatDate(
            latestFile.mappingFile.lastModified
          )}`}
        />
        <div css={downloadLinkWrapperStyle}>
          <Tooltip content="Download last uploaded customer / mapping file">
            <a
              href={latestFile.mappingFile.downloadLink}
              target="_blank"
              rel="noreferrer"
              css={downloadLinkStyle}
            >
              <Download css={exportIconStyle} />
              Export customer list
            </a>
          </Tooltip>
        </div>
        {latestFile.failureReport.fileName && (
          <div data-testid="failureReport" css={downloadLinkWrapperStyle}>
            <Tooltip content="There were errors that occurred during this upload. Click to download the report.">
              <a
                target="_blank"
                rel="noreferrer"
                href={latestFile.failureReport.downloadLink}
                css={downloadLinkStyle}
              >
                <StatusWarningBold css={failureReportIconStyle} />
                Failure Report
              </a>
            </Tooltip>
          </div>
        )}
      </Stack>
    </div>
  );
};
PreviousCustomerMapping.propTypes = {
  uploadedFiles: PropTypes.array.isRequired,
};

export { PreviousCustomerMapping };
