import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesLaptopBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.53 20l-1.88-5H2.27L.45 19.85a2.29 2.29 0 002.13 3.26h19A2.19 2.19 0 0023.53 20zm-9.45 0h-3.92a1 1 0 010-2h3.92a1 1 0 010 2z" />
        <path d="M21.65 15.37H2.27V3.46A2.66 2.66 0 015 .89h13.92a2.66 2.66 0 012.74 2.58l-.01 11.9zM4.09 13.66h15.75V3.46a.9.9 0 00-.93-.87H5a.9.9 0 00-.93.87l.02 10.2z" />
      </g>
    );
  }
);
DevicesLaptopBold.displayName = 'DevicesLaptopBold';
