import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesAi = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-ai_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24H14.8a.75.75 0 000-1.5H3.75a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path d="M19.24 14.914a2.21 2.21 0 00-2.2 2.2v6.06a.74.74 0 001.48 0v-3h1.45v3a.74.74 0 101.47 0v-6.03a2.21 2.21 0 00-2.2-2.23zm-.72 3.77v-1.54a.72.72 0 01.72-.72.73.73 0 01.73.72v1.57l-1.45-.03zm4.65 5.23a.78.78 0 01-.83-.72v-7.5a.84.84 0 011.66 0v7.5a.78.78 0 01-.83.72z" />
      </g>
      <defs>
        <clipPath id="files-ai_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesAi.displayName = 'FilesAi';
