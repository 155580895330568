import {
  BreadcrumbSeparator as BreadcrumbSeparatorComponent,
  BreadcrumbSeparatorProps,
} from '@chakra-ui/breadcrumb';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IBreadcrumbSeparatorProps = BreadcrumbSeparatorProps;

export const BreadcrumbSeparator = forwardRef<IBreadcrumbSeparatorProps, 'span'>((props, ref) => {
  return <BreadcrumbSeparatorComponent {...props} ref={ref} />;
});
