import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ViewHide = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M22.072 2.23a.75.75 0 01-.021 1.061l-18.75 18a.75.75 0 11-1.039-1.082l18.75-18a.75.75 0 011.06.022zm-2.929 5.958a.75.75 0 011.047-.164c1.156.843 2.223 1.8 3.187 2.858l.003.003a2.413 2.413 0 010 3.23l-.003.002C20.758 17 16.412 20.07 11.995 20a10.734 10.734 0 01-3.255-.483.75.75 0 01.445-1.432 9.236 9.236 0 002.826.415c3.779.064 7.744-2.627 10.255-5.39a.914.914 0 000-1.22 19.816 19.816 0 00-2.96-2.654.75.75 0 01-.163-1.048zM12 5c.874-.009 1.745.093 2.592.304a.75.75 0 01-.362 1.456 8.799 8.799 0 00-2.244-.26c-3.713-.062-7.69 2.57-10.252 5.388a.913.913 0 000 1.22c.767.84 1.605 1.613 2.503 2.31a.75.75 0 01-.92 1.185 21.553 21.553 0 01-2.693-2.486l-.004-.004a2.413 2.413 0 010-3.23l.002-.002C3.3 7.935 7.655 4.932 12 5z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.998 9.5a3 3 0 00-3 3 .75.75 0 01-1.5 0 4.499 4.499 0 014.5-4.5.75.75 0 010 1.5zm3.75 2.249a.75.75 0 01.75.75 4.502 4.502 0 01-4.5 4.501.75.75 0 110-1.5 3 3 0 003-3 .75.75 0 01.75-.751z"
        fillRule="evenodd"
      />
    </g>
  );
});
ViewHide.displayName = 'ViewHide';
