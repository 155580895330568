import { LinkOverlay as LinkOverlayComponent, LinkOverlayProps } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type ILinkOverlayProps = LinkOverlayProps;

export const LinkOverlay = forwardRef<ILinkOverlayProps, 'a'>((props, ref) => {
  return (
    <LinkOverlayComponent
      _focus={{ outline: 'none', boxShadow: 'focusVisible' }}
      {...props}
      ref={ref}
    />
  );
});
