import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Code = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M21.508 22.74H2.492A2.491 2.491 0 010 20.257V3.774A2.495 2.495 0 012.492 1.25h19.016A2.491 2.491 0 0124 3.774v16.483a2.494 2.494 0 01-2.492 2.493v-.01zM2.492 2.752a.96.96 0 00-.96 1.022v16.483a.96.96 0 00.96.96h19.016a.96.96 0 00.96-.96V3.774a.96.96 0 00-.96-1.022H2.492z" />
      <path d="M23.234 5.981H.766v1.533h22.468V5.981zM10.662 17.886a.766.766 0 01-.684-1.104l2.778-5.63a.768.768 0 111.378.674l-2.777 5.63a.766.766 0 01-.695.43zM7.435 17.6a.766.766 0 01-.51-.194l-2.309-2.044a1.778 1.778 0 010-2.493l2.043-2.044a.794.794 0 111.123 1.124L5.74 13.993a.225.225 0 000 .316l2.246 1.973a.767.767 0 01-.51 1.338l-.041-.02zm9.273.194a.766.766 0 01-.51-1.339l2.307-2.043a.297.297 0 00-.081-.256l-2.043-2.044a.766.766 0 011.072-1.093l2.043 2.044a1.769 1.769 0 01.082 2.422l-2.38 2.105a.766.766 0 01-.49.204z" />
    </g>
  );
});
Code.displayName = 'Code';
