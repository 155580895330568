import { tablePageSizes } from './OverviewPage';

export const getDefaultPageSize = (numberOfAssessments) => {
  // Aiming for a 10 page division of the assessments
  const preferredNumberOfPages = numberOfAssessments / 10;

  const pageSize = tablePageSizes.reduce((acc, next) => {
    if (
      Math.abs(preferredNumberOfPages - acc) >
      Math.abs(preferredNumberOfPages - next)
    ) {
      return next;
    }
    return acc;
  }, tablePageSizes[tablePageSizes.length - 1]);

  return pageSize;
};

export const saveSortByToLocalStorage = (
  sortByFromState,
  localStorageSortByKey
) => {
  if (!sortByFromState.length) {
    return window.localStorage.setItem(localStorageSortByKey, 'NOT_SORTED');
  }

  const sortByObjectFromState = JSON.stringify(sortByFromState[0]);
  const sortByObjectFromLocalStorage = window.localStorage.getItem(
    localStorageSortByKey
  );

  if (
    sortByObjectFromState &&
    sortByObjectFromState !== sortByObjectFromLocalStorage
  ) {
    window.localStorage.setItem(localStorageSortByKey, sortByObjectFromState);
  }
};

export const getSortByFromLocalStorage = (localStorageSortByKey) => {
  const savedSortByObject = window.localStorage.getItem(localStorageSortByKey);

  if (savedSortByObject === 'NOT_SORTED') return [];

  if (savedSortByObject) return [JSON.parse(savedSortByObject)];

  return null;
};
