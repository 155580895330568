import { ThemeComponentProps } from '@chakra-ui/theme';
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['item', 'command', 'list', 'button', 'groupTitle', 'divider'];

const baseStyleList = () => {
  return {
    minW: '3xs',
    zIndex: 1,
  };
};

const baseStyleItem = (props: Dict) => {
  return {
    py: '2',
    px: '3',
    opacity: 1,
    transition: 'background 50ms ease-in 0s',
    _checked: {
      backgroundColor: 'light-blue.50',
    },
    _hover: {
      backgroundColor: transparentize('slate.900', 0.05)(props),
    },
    _focus: {
      backgroundColor: transparentize('slate.900', 0.05)(props),
    },
    _disabled: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  };
};

const baseStyleGroupTitle = {
  py: '2',
  px: '3',
  fontWeight: 'semibold',
  fontSize: 'sm',
  lineHeight: 'sm',
};

const baseStyleCommand = {
  opacity: 0.6,
};

const baseStyleDivider = {
  borderColor: 'slate.100',
  borderTopWidth: '2px',
};

const plainVariant = (props: ThemeComponentProps) => {
  return {
    list: {
      bg: mode(`white`, `slate.900`)(props),
      boxShadow: '2',
      color: 'inherit',
      borderRadius: 'base',
      borderWidth: '2px',
      borderColor: 'information.400',
    },
  };
};

const navVariant = (props: ThemeComponentProps) => {
  return {
    list: {
      bg: 'light-blue.50',
      borderRadius: '0',
      borderWidth: '0',
      boxShadow: '0',
      color: 'black',
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
};

const variants = {
  plain: plainVariant,
  nav: navVariant,
};

const baseStyle = (props: Dict) => ({
  list: baseStyleList(),
  item: baseStyleItem(props),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
});

const defaultProps = {
  variant: 'plain',
};

export const Menu = {
  parts,
  defaultProps,
  variants,
  baseStyle,
};
