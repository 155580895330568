import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Gift = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M23.241 5.997h-4.87a3.648 3.648 0 002.02-1.614c.455-.926.203-1.992-.747-3.138a2.777 2.777 0 00-1.157-.988 2.824 2.824 0 00-1.51-.237c-2.416.358-4.306 4.304-4.831 5.529-.536-1.196-2.375-5.14-4.84-5.53a2.834 2.834 0 00-1.523.254 2.786 2.786 0 00-1.155 1.012c-.92 1.146-1.192 2.212-.748 3.138a3.69 3.69 0 002.031 1.574H.758c-.2 0-.39.078-.532.216A.742.742 0 000 6.735v5.41a.742.742 0 00.226.521.763.763 0 00.532.216h.93v10.37a.752.752 0 00.224.526.77.77 0 00.533.222h19.078a.763.763 0 00.536-.219.742.742 0 00.222-.528V12.97h.96a.757.757 0 00.535-.212.737.737 0 00.223-.525v-5.46a.727.727 0 00-.208-.55.75.75 0 00-.55-.227zm-9.367 5.4H10.62V7.522h3.254v3.875zm3.335-9.963h.14c.233 0 .627.1 1.133.708.505.607.778 1.106.555 1.574-.454.996-3.031 1.454-5.143 1.594.839-1.624 2.162-3.617 3.315-3.786v-.09zM5.244 3.786c-.222-.469.182-1.106.556-1.574.374-.469.91-.708 1.132-.708h.151c1.142.17 2.466 2.162 3.315 3.856-2.163-.12-4.669-.628-5.154-1.574zM1.516 7.522h7.599v3.915h-7.6V7.522zm1.718 5.45h5.88v9.583h-5.88v-9.584zm7.386 9.583v-9.584h3.254v9.584H10.62zm10.105 0h-5.346v-9.584h5.386l-.04 9.584zm1.728-11.068H15.38V7.522h7.073v3.965z" />
    </g>
  );
});
Gift.displayName = 'Gift';
