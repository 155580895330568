import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowUp = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M11.495 4.445a.75.75 0 011.01 0l11 10a.75.75 0 01-1.01 1.11L12 6.014 1.505 15.555a.75.75 0 01-1.01-1.11l11-10z"
        fillRule="evenodd"
      />
    </g>
  );
});
ArrowUp.displayName = 'ArrowUp';
