import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LearningLibraryBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M17.38 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zm3.86 1.11a1 1 0 00.76-.35l.65-.77a1 1 0 00-1.52-1.28l-.65.76a1 1 0 00.76 1.65v-.01zm-8.48-.35a1 1 0 001.53-1.29l-.64-.76A1 1 0 1012.12 3l.64.76zM23 7.38a1 1 0 00-1 1v9.83a18.159 18.159 0 00-9 2.6V6.02a1.31 1.31 0 00-.64-1.1c-.95-.59-5.94-3.44-11.26-2.57A1.32 1.32 0 000 3.65v15a1.32 1.32 0 001.2 1.32c3.55.29 8.21 1.33 9.65 3.32a1.33 1.33 0 001 .55h.09a1.289 1.289 0 00.93-.4c.68-.71 3.39-3 9.83-3.29a1.32 1.32 0 001.3-1.3V8.38a1 1 0 00-1-1zm-12.88 5.48a.79.79 0 01-1 .52 20.926 20.926 0 00-5.17-1 .82.82 0 01-.71-.9.81.81 0 01.86-.74 22.62 22.62 0 015.51 1.07.83.83 0 01.51 1.05zm0 4a.79.79 0 01-1 .52 20.926 20.926 0 00-5.17-1 .82.82 0 01-.71-.9.81.81 0 01.86-.74c1.879.162 3.73.558 5.51 1.18a.83.83 0 01.51 1v-.06zm-.23-7.69a.78.78 0 01-.78.16 20.88 20.88 0 00-5.17-1 .84.84 0 01-.43-1.46.77.77 0 01.59-.18 22.63 22.63 0 015.51 1.08.82.82 0 01.53.62.84.84 0 01-.25.78z" />
        <path d="M15.24 10.8v1.22a.75.75 0 00.75.75h.5v.67a1 1 0 002 0v-.67h.6a.75.75 0 00.65-.74v-1.2a4 4 0 10-4.5 0v-.03zm.3-3.24a2 2 0 114 0 2 2 0 01-4 0z" />
      </g>
    );
  }
);
LearningLibraryBold.displayName = 'LearningLibraryBold';
