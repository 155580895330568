import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Security = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M21.182 1.874A22.45 22.45 0 0012 .001 22.45 22.45 0 002.806 1.88a2.237 2.237 0 00-1.305 2.046v7.611A12.657 12.657 0 009.613 23.35l1.04.4a3.751 3.751 0 002.693 0l1.041-.4A12.657 12.657 0 0022.5 11.537V3.926a2.238 2.238 0 00-1.318-2.052zM3 3.924v-.005a.737.737 0 01.426-.673 20.95 20.95 0 018.581-1.745 20.95 20.95 0 018.568 1.745.738.738 0 01.425.673v7.618a11.156 11.156 0 01-7.15 10.413l-1.042.4c-.52.2-1.096.2-1.616 0l-1.04-.4A11.156 11.156 0 013 11.538V3.923z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.746 12.917a.696.696 0 10-1.392 0V14.5a.696.696 0 101.392 0v-1.583z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.584 8.167v1.284h-.888a.696.696 0 00-.696.695v7.125c0 .384.312.696.696.696h8.708a.696.696 0 00.696-.696v-7.125a.696.696 0 00-.696-.695h-.887V8.167a3.467 3.467 0 10-6.933 0zM10.583 6.7a2.075 2.075 0 013.542 1.467v1.284h-4.15V8.167c0-.55.219-1.078.608-1.467zm-2.191 9.875v-5.733h7.316v5.733H8.392z"
        fillRule="evenodd"
      />
    </g>
  );
});
Security.displayName = 'Security';
