import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesPdf = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.25 14.998a.75.75 0 00-.75.75v7.5a.75.75 0 001.5 0v-2.25h.004a3 3 0 000-6H8.25zm.75 1.5v3h.004a1.5 1.5 0 000-3H9zm5.25-1.5a.75.75 0 00-.75.75v7.5a.75.75 0 00.75.75 3.75 3.75 0 003.75-3.75v-1.5a3.75 3.75 0 00-3.75-3.75zm.75 7.371v-5.742a2.25 2.25 0 011.5 2.121v1.5a2.25 2.25 0 01-1.5 2.121z"
        fillRule="evenodd"
      />
      <path d="M21.22 16.718a.75.75 0 01.53-.22h1.5a.75.75 0 000-1.5h-1.5a2.25 2.25 0 00-2.25 2.25v6a.75.75 0 001.5 0v-2.25h1.5a.75.75 0 000-1.5H21v-2.25a.75.75 0 01.22-.53z" />
    </g>
  );
});
FilesPdf.displayName = 'FilesPdf';
