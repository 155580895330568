import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { ColumnSortIndicator } from './ColumnSortIndicator';
import { spacing } from '../../../../config';
import { Tooltip } from '../../../tooltip/Tooltip';

const headerWrapperStyle = (isCentered, isSortable) =>
  css({
    textAlign: isCentered ? 'center' : 'left',
    marginLeft: isSortable ? -spacing.size4 : 0,
  });

const CustomTableHeader = ({ column }) => {
  const { headerText, isCentered, canSort, tooltipText = '' } = column;
  return (
    <div css={headerWrapperStyle(isCentered, canSort)}>
      <ColumnSortIndicator
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        isSortDisabled={column.disableSortBy}
      />
      {tooltipText ? (
        <Tooltip content={tooltipText}>
          <span>{headerText}</span>
        </Tooltip>
      ) : (
        headerText
      )}
    </div>
  );
};

CustomTableHeader.propTypes = {
  column: PropTypes.object.isRequired,
};

export { CustomTableHeader };
