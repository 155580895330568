import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Overlay, Button, TextArea } from '@lego/klik-react';
import { Cross } from '@lego/klik-icons-react';
import { font, spacing, colors } from '../../config';

const wrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const contentStyle = css({
  width: 480,
  backgroundColor: colors.neutralColors.white,
  border: `1px solid ${colors.neutralColors.slate30}`,
  borderRadius: 2,
  margin: spacing.size4,
  marginTop: '5%',
  padding: spacing.size5,
  color: colors.neutralColors.slate100,
});

const headerWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
});

const headerStyle = css({
  fontSize: font.size.size1,
  fontWeight: font.weight.medium,
  lineHeight: font.lineHeight.m,
});

const bodyWrapperStyle = css({
  marginBottom: '5%',
  marginTop: '5%',
});

const closeButtonWrapperStyle = css({
  marginRight: -spacing.size2,
});

const closeModalButtonIconStyle = css({
  color: colors.neutralColors.slate100,
});

const actionButtonsWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: spacing.size10,
  'button:first-of-type': {
    marginRight: spacing.size4,
  },
});

const ApproveModal = ({ header, onClose, onApprove, onCancel }) => {
  const [comment, setComment] = useState('');
  return (
    <Overlay isOpen={true}>
      <div css={wrapperStyle}>
        <div css={contentStyle}>
          <header css={headerWrapperStyle}>
            <h4 css={headerStyle}>{header}</h4>
            <span css={closeButtonWrapperStyle}>
              <Button
                aria-label="Close modal button"
                icon={<Cross css={closeModalButtonIconStyle} />}
                variant="ghost"
                size="small"
                onClick={onClose}
              />
            </span>
          </header>
          <div css={bodyWrapperStyle}>
            Additional comments:
            <TextArea
              onChange={(event) => setComment(event.target.value)}
              placeholder="Add option comments for justification"
            />
          </div>
          <div css={actionButtonsWrapperStyle}>
            <Button label="Cancel" variant="ghost" onClick={onCancel} />
            <Button
              label="Yes, continue"
              data-transaction-name={header}
              onClick={() => onApprove(comment)}
            />
          </div>
        </div>
      </div>
    </Overlay>
  );
};

ApproveModal.propTypes = {
  header: PropTypes.string.isRequired,
  onApprove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ApproveModal };
