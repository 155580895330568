import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowLongUpBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M10.991.39a1.5 1.5 0 012.018 0l8.001 7.272a1.5 1.5 0 01-2.017 2.22L13.5 4.89V22.5a1.5 1.5 0 01-3 0V4.89L5.009 9.88A1.5 1.5 0 012.99 7.66l8-7.27z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowLongUpBold.displayName = 'ArrowLongUpBold';
