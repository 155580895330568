import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowUpBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M10.994 4.388a1.5 1.5 0 012.012 0l10.5 9.5a1.5 1.5 0 01-2.012 2.224L12 7.522l-9.494 8.59a1.5 1.5 0 11-2.012-2.224l10.5-9.5z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowUpBold.displayName = 'ArrowUpBold';
