import { generateStripe, getColor, mode } from '@chakra-ui/theme-tools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const parts = ['track', 'filledTrack', 'panel'];

const filledStyle = (props: Dict) => {
  const { colorScheme: c, theme: t, isIndeterminate, hasStripe } = props;

  const stripeStyle = mode(generateStripe(), generateStripe('1rem', 'rgba(0,0,0,0.1)'))(props);

  const bgColor = mode(`${c}.500`, `${c}.200`)(props);

  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColor(t, bgColor)} 50%,
    transparent 100%
  )`;

  const addStripe = !isIndeterminate && hasStripe;

  return {
    ...(addStripe && stripeStyle),
    ...(isIndeterminate ? { bgImage: gradient } : { bgColor }),
  };
};

const baseStyleLabel = {
  lineHeight: '1',
  fontSize: '0.25em',
  fontWeight: 'bold',
  color: 'white',
};

const baseStyleTrack = (props: Dict) => {
  return {
    backgroundColor: mode(`slate.100`, `white`)(props),
  };
};

const baseStyleFilledTrack = (props: Dict) => {
  return {
    transition: 'all 0.3s',
    ...filledStyle(props),
  };
};

const baseStyle = (props: Dict) => ({
  label: baseStyleLabel,
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack(props),
});

const sizes = {
  xs: {
    track: { h: '0.25rem' },
  },
};

const defaultProps = {
  size: 'xs',
  colorScheme: 'light-blue',
};

export const Progress = {
  parts,
  sizes,
  baseStyle,
  defaultProps,
};
