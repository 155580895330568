import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesXls = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h1a.75.75 0 000-1.5h-1a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
        fillRule="evenodd"
      />
      <path d="M16.74 22.491h-1.51a.761.761 0 01-.754-.754v-6a.761.761 0 00-.755-.754.761.761 0 00-.754.754v6a2.253 2.253 0 002.246 2.246h1.508a.76.76 0 00.755-.754.732.732 0 00-.737-.738zm-5.726-7.44a.758.758 0 00-.977.412l-.789 2.023-.805-2.006a.752.752 0 00-.978-.411.752.752 0 00-.411.977l1.389 3.463-1.389 3.462a.758.758 0 00.411.978.752.752 0 00.977-.429l.806-2.006.806 2.006a.76.76 0 00.977.429.752.752 0 00.412-.978l-1.389-3.462 1.389-3.463a.765.765 0 00-.429-.995zm10.68 3.84c-.771-.582-1.2-.942-1.2-1.645 0-.412.343-.755.754-.755h1.509a.76.76 0 00.754-.754.761.761 0 00-.754-.754h-1.509a2.254 2.254 0 00-2.245 2.246c0 1.505.99 2.242 1.794 2.841l.005.004c.772.583 1.2.943 1.2 1.646a.761.761 0 01-.754.754H19.74a.761.761 0 00-.755.755c0 .411.343.754.755.754h1.508a2.253 2.253 0 002.246-2.246c0-1.468-.963-2.205-1.762-2.816l-.038-.03z" />
    </g>
  );
});
FilesXls.displayName = 'FilesXls';
