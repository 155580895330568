import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesPptBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM7 14.62h-.62a.25.25 0 00-.25.25V17a.63.63 0 01-1.25 0v-6a.62.62 0 01.63-.62H7a2.125 2.125 0 010 4.25v-.01zm5 0h-.62a.25.25 0 00-.25.25V17a.63.63 0 01-1.25 0v-6a.62.62 0 01.63-.62H12a2.125 2.125 0 010 4.25v-.01zm7.5-3h-1.13a.25.25 0 00-.25.25V17a.63.63 0 01-1.25 0v-5.13a.25.25 0 00-.25-.25H15.5a.63.63 0 010-1.25h4a.63.63 0 010 1.25z" />
        <path d="M7 11.62h-.62a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.88.88 0 000-1.75zm5 0h-.62a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H12a.88.88 0 000-1.75z" />
      </g>
    );
  }
);
FilesPptBold.displayName = 'FilesPptBold';
