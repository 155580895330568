import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { OverviewPage } from './assessment-overview/OverviewPage';
import { DetailsPage } from './assessment-details/DetailsPage';
import { ScoringPage } from './assessment-scoring/ScoringPage';
import { CompletePage } from './assessment-scoring/CompletePage';
import { AssessmentManagementPage } from './admin/AssessmentManagementPage';
import { ErrorPage } from './ErrorPage';
import { PageNotFound } from './PageNotFound';
import { withMainLayout } from '../layouts/withMainLayout';
import { ReviewPage as ScoringReviewPage } from './assessment-scoring/ReviewPage';
import { ApmRoute } from '@elastic/apm-rum-react';
import { ErrorBoundary } from 'react-error-boundary';

const routes = {
  overview: '/overview',
  assessmentMgmt: '/admin/assessment-management',
  details: (customerId, assessmentYear, version) =>
    `/customer/${customerId}/assessment-details/${assessmentYear}/${version}`,
  scoring: (customerId, assessmentYear, version) =>
    `/customer/${customerId}/assessment-scoring/${assessmentYear}/${version}`,
  complete: (customerId, assessmentYear, version) =>
    `/customer/${customerId}/assessment-complete/${assessmentYear}/${version}`,
  scoringReview: (customerId, assessmentYear, version) =>
    `/customer/${customerId}/assessment-scoring/${assessmentYear}/${version}/review`,
};

const RouteHandler = () => {
  const location = useLocation();

  return (
    <ErrorBoundary
      key={location.pathname}
      fallback={<ErrorPage withBackToOverviewButton />}
    >
      <Switch>
        <ApmRoute
          exact
          path="/"
          component={() => <Redirect to={routes.overview} />}
        />
        <Route
          exact
          path={routes.assessmentMgmt}
          render={(props) => (
            <ErrorBoundary fallback={<ErrorPage />}>
              {withMainLayout(AssessmentManagementPage)(props)}
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path={routes.overview}
          render={(props) => (
            <ErrorBoundary fallback={<ErrorPage />}>
              {withMainLayout(OverviewPage)(props)}
            </ErrorBoundary>
          )}
        />
        <ApmRoute
          exact
          path="/customer/:customerId/assessment-details/:assessmentYear/:version"
          component={withMainLayout(DetailsPage)}
        />
        <ApmRoute
          exact
          path="/customer/:customerId/assessment-scoring/:assessmentYear/:version"
          component={withMainLayout(ScoringPage)}
        />
        <ApmRoute
          exact
          path="/customer/:customerId/assessment-scoring/:assessmentYear/:version/review"
          component={withMainLayout(ScoringReviewPage)}
        />
        <ApmRoute
          exact
          path="/customer/:customerId/assessment-complete/:assessmentYear/:version"
          component={withMainLayout(CompletePage)}
        />
        <ApmRoute path="*" component={PageNotFound} />
      </Switch>
    </ErrorBoundary>
  );
};

export { RouteHandler, routes };
