import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesToDoBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M15.537 1.4H3.14v21.41h17.668V6.67L15.537 1.4zm-3.942 13.07l-2.001 2.5a.75.75 0 01-.54.28h-.05a.75.75 0 01-.53-.25l-1.471-1.47a.75.75 0 011-1.06l.78.78a.14.14 0 00.23 0l1.371-1.72a.75.75 0 011.17.94h.04zm0-5.5l-2.001 2.5a.75.75 0 01-.54.28h-.05a.75.75 0 01-.53-.22L7.002 10a.75.75 0 011-1l.78.78a.14.14 0 00.23 0L10.415 8a.773.773 0 011.18 1v-.03zm4.412 7.78h-2.501a.75.75 0 110-1.5h2.501a.75.75 0 110 1.5zm0-5.5h-2.501a.75.75 0 110-1.5h2.501a.75.75 0 110 1.5z" />
        <path d="M16.297.29L21.71 5.7a1 1 0 01.29.71V22a2 2 0 01-2.001 2H4.001A2.001 2.001 0 012 22V2a2 2 0 012.001-2h11.596a1 1 0 01.7.29zM19.51 22a.5.5 0 00.5-.5V7.25a.25.25 0 00-.25-.25h-2.752a2.001 2.001 0 01-2-2V2.25a.25.25 0 00-.25-.25H4.5a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h15.01z" />
      </g>
    );
  }
);
FilesToDoBold.displayName = 'FilesToDoBold';
