import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowRightBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M10.112.494a1.5 1.5 0 10-2.224 2.012L16.478 12l-8.59 9.494a1.5 1.5 0 002.224 2.012l9.5-10.5a1.5 1.5 0 000-2.012l-9.5-10.5z" />
      </g>
    );
  }
);
ArrowRightBold.displayName = 'ArrowRightBold';
