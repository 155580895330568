import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SettingsBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M20.581 6.954l.611-.61a1.5 1.5 0 000-2.122l-1.415-1.413a1.5 1.5 0 00-2.121 0l-.612.612c-.418.417-1.059.498-1.604.272-.545-.227-.94-.736-.94-1.327V1.5A1.5 1.5 0 0013 0h-2a1.5 1.5 0 00-1.5 1.5v.866c0 .59-.395 1.1-.94 1.327-.546.227-1.187.145-1.605-.272l-.612-.612a1.5 1.5 0 00-2.121 0L2.807 4.223a1.5 1.5 0 000 2.122l.612.611c.418.418.499 1.059.272 1.604-.226.545-.735.94-1.325.94H1.5A1.5 1.5 0 000 11v2a1.5 1.5 0 001.5 1.5h.865c.59 0 1.1.395 1.327.94.226.546.145 1.187-.273 1.605l-.613.613a1.5 1.5 0 000 2.121l1.414 1.414a1.545 1.545 0 002.122 0l.611-.61c.419-.42 1.06-.5 1.606-.273.546.226.941.737.941 1.328v.862A1.5 1.5 0 0011 24h2a1.5 1.5 0 001.5-1.5v-.863c0-.59.395-1.1.94-1.327.546-.227 1.187-.145 1.605.272l.612.612a1.5 1.5 0 002.121 0l1.414-1.414a1.5 1.5 0 000-2.121l-.611-.611c-.418-.418-.5-1.059-.273-1.605.227-.546.737-.941 1.328-.941h.864A1.5 1.5 0 0024 13v-2a1.5 1.5 0 00-1.5-1.5h-.865c-.59 0-1.1-.395-1.327-.94-.226-.547-.145-1.188.273-1.606zM12 16a4 4 0 100-8 4 4 0 000 8z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
SettingsBold.displayName = 'SettingsBold';
