import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudUnavailableBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M15.193 6.983a5.479 5.479 0 011.83 2.302.927.927 0 00.906.546 3.642 3.642 0 013.178 1.636c.427.63.65 1.383.64 2.15a3.6 3.6 0 01-.914 2.534c-.365.39-.36 1.01.012 1.393.19.192.447.296.713.29a.97.97 0 00.698-.325 5.508 5.508 0 001.441-3.733c.091-3.043-2.134-5.635-5.085-5.923a.245.245 0 01-.186-.12C17.066 5.42 14.63 4.002 12 3.992c-.742 0-1.479.114-2.187.34-.635.2-.787.845-.368 1.5a.885.885 0 001.055.374 5.608 5.608 0 014.693.777zM4.992 17.396a3.384 3.384 0 01-2.117-.8 2.416 2.416 0 01-.623-1.793c0-1.43 1.129-2.589 2.521-2.59.175 0 .35.019.522.054a.944.944 0 00.79-.184 1.01 1.01 0 00.38-.736 9.8 9.8 0 00.015-.725c-.009-.5.021-.734-.255-1.017a.905.905 0 00-.912-.24.937.937 0 00-.641.709v.019a.151.151 0 01-.14.123 4.514 4.514 0 00-3.62 2.255 4.56 4.56 0 00-.61 2.45 4.352 4.352 0 001.205 3.103 5.256 5.256 0 003.48 1.379h8.418c.394 0 .75-.244.9-.618a1.02 1.02 0 00-.211-1.091.962.962 0 00-.69-.293l-8.412-.005zm17.041 6.323c.383.38.99.374 1.367-.012a1.02 1.02 0 00.012-1.404L1.966.282A.957.957 0 00.6.293a1.02 1.02 0 00-.012 1.403L22.033 23.72z" />
      </g>
    );
  }
);
CloudUnavailableBold.displayName = 'CloudUnavailableBold';
