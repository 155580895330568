import { useState, useEffect, useContext, createContext } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { logger } from '../utils/logger';
import { apmBase } from '@elastic/apm-rum';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function getUser() {
      let user = {};
      try {
        user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch (error) {
        if (error === 'The user is not authenticated') {
          Auth.federatedSignIn({
            customProvider: process.env.FEDERATED_AUTH_PROVIDER,
          });
        } else {
          logger.logError(error);
        }
      }
    }
    if (!user) {
      getUser();
    }
  }, [user]);

  const name = user?.attributes['custom:fullName'] || '';
  const rolesAttributes = user?.attributes['custom:roles'];
  const roles = rolesAttributes ? JSON.parse(rolesAttributes) : [];

  const userAttributes = {
    id: user?.id || '',
    roles: roles,
    name: name,
    username: name,
    email: user?.attributes['email'] || '',
  };

  if (userAttributes.email) {
    apmBase?.setUserContext({
      email: userAttributes.email,
      id: userAttributes.id,
      username: userAttributes.username,
    });
    apmBase?.setCustomContext({
      roles: userAttributes.roles,
    });
  }

  return (
    <UserContext.Provider
      value={{
        cognitoData: user || {},
        isLoggedIn: !!user,
        ...userAttributes,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export { UserProvider, UserContext, useUser };
