import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const PrizeBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M9.59 18.46a9.52 9.52 0 01-5.68-4.05.43.43 0 00-.68 0L.1 18.19a.44.44 0 00.3.71l4.18.38a.44.44 0 01.39.31l1.21 3.94a.43.43 0 00.78.12l2.9-4.54a.43.43 0 00-.27-.65zm14.13-.27l-3.13-3.8a.44.44 0 00-.69 0 9.45 9.45 0 01-5.67 4.05.43.43 0 00-.27.65l2.9 4.54a.43.43 0 00.78-.12l1.21-3.94a.44.44 0 01.39-.31l4.17-.38a.44.44 0 00.31-.69z" />
        <path d="M20.42 9.12a8.61 8.61 0 10-17.221 0 8.61 8.61 0 0017.22 0zm-4.48-.62l-1.6 1.5a.45.45 0 00-.13.38l.36 2.19a.43.43 0 01-.63.45L12 12a.43.43 0 00-.41 0l-2 1a.43.43 0 01-.59-.42l.39-2.19a.44.44 0 00-.13-.39L7.68 8.45a.43.43 0 01.24-.73l2.2-.31a.46.46 0 00.33-.23l1-2a.44.44 0 01.78 0l1 2a.42.42 0 00.32.24l2.19.34a.43.43 0 01.2.74z" />
      </g>
    );
  }
);
PrizeBold.displayName = 'PrizeBold';
