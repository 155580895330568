import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SupportChat = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M12 1.5a7.5 7.5 0 00-7.462 6.75A1.5 1.5 0 016 9.75v7.5a1.5 1.5 0 01-1.5 1.5h-.75A3.75 3.75 0 010 15v-3a3.75 3.75 0 013.026-3.68 9 9 0 0117.948 0A3.75 3.75 0 0124 12v3a3.75 3.75 0 01-3 3.674v.076a3.75 3.75 0 01-3.75 3.75h-1.629A2.25 2.25 0 0113.5 24H12a2.25 2.25 0 010-4.5h1.5a2.25 2.25 0 012.121 1.5h1.629a2.25 2.25 0 002.25-2.25 1.5 1.5 0 01-1.5-1.5v-7.5a1.5 1.5 0 011.462-1.5A7.5 7.5 0 0012 1.5zm2.03 19.72a.75.75 0 01-.53 1.28H12a.75.75 0 110-1.5h1.5a.75.75 0 01.53.22zm6.22-3.97A2.25 2.25 0 0022.5 15v-3a2.25 2.25 0 00-2.25-2.25h-.75v7.5h.75zM2.159 10.409A2.25 2.25 0 013.75 9.75h.75v7.5h-.75A2.25 2.25 0 011.5 15v-3c0-.597.237-1.169.659-1.591z"
          fillRule="evenodd"
        />
        <path d="M9.505 14.888a.75.75 0 10-1.01 1.108 5.204 5.204 0 007.01 0 .75.75 0 00-1.01-1.108 3.704 3.704 0 01-4.99 0zM9 9.235a.75.75 0 01.75.75v1.312a.75.75 0 01-1.5 0V9.985a.75.75 0 01.75-.75zm6.75.75a.75.75 0 00-1.5 0v1.312a.75.75 0 001.5 0V9.985z" />
      </g>
    );
  }
);
SupportChat.displayName = 'SupportChat';
