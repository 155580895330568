/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { transparentize } from '@chakra-ui/theme-tools';
import { mergeWith } from '@chakra-ui/utils';
import { Dict } from '../types';

const parts = ['container', 'brand', 'segment', 'menuItem', 'burger', 'menuList', 'menuOption'];

const baseStyleList = (props: Dict) => {
  return {
    bg: 'light-blue.50',
    borderRadius: '0',
    borderWidth: '0',
    boxShadow: '0',
    color: 'black',
    minW: '2xs',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
  };
};

const baseStyleOption = (props: Dict) => {
  const { isOpen } = props;

  let style: Dict = {
    py: '2',
    px: '3',
    opacity: 1,
    transition: 'background 50ms ease-in 0s',
    textAlign: 'start',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    outline: 'none',
    _checked: {
      backgroundColor: 'light-blue.50',
    },
    _hover: {
      backgroundColor: transparentize('slate.900', 0.05)(props),
    },
    _focusVisible: {
      backgroundColor: transparentize('slate.900', 0.05)(props),
      boxShadow: 'focusVisible',
    },
    _disabled: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  };

  if (isOpen) {
    style = mergeWith(style, {
      backgroundColor: transparentize('slate.900', 0.05)(props),
    });
  }

  return style;
};

const baseStyleContainer = (props: Dict) => {
  const { colorScheme: c, isSecondary, theme, orientation, isFullWidth } = props;

  let style: Dict = {
    //TODO: remove the conditional here after the theme from storybook is fixed
    background: `${c}.500`,
    color: 'white',
    display: 'flex',
    height: '72px',
    pr: 4,
  };

  if (orientation === 'vertical') {
    style = mergeWith(style, {
      flexDirection: 'column',
      height: '100%',
      pr: 0,
      pb: 4,
      width: isFullWidth ? '240px' : '72px',
    });
  }

  if (isSecondary) {
    style = mergeWith(style, {
      background: 'slate.30',
      boxShadow: `inset ${
        orientation === 'horizontal' ? '0px -1px' : '-1px 0px'
      } 0px ${transparentize('slate.900', 0.1)(theme)}`,
      color: 'slate.600',
    });
  }

  return style;
};

const baseStyleBrand = (props: Dict) => {
  const { orientation, isFullWidth } = props;

  let style: Dict = {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'lg',
    fontWeight: 'medium',
    pl: 4,
    pr: 8,
    _focus: {
      boxShadow: 'inline',
      outline: 'none',
    },
    _focusVisible: {
      boxShadow: 'inline',
      outline: 'none',
    },
  };

  if (orientation === 'vertical') {
    style = mergeWith(style, { pl: 4, py: 4 });

    if (!isFullWidth) {
      style = mergeWith(style, {
        alignSelf: 'center',
        px: 4,
      });
    }
  }

  return style;
};

const baseStyleSegment = (props: Dict) => {
  const { orientation } = props;

  let style: Dict = {
    alignItems: 'center',
    display: 'flex',
  };

  if (orientation === 'vertical') {
    style = mergeWith(style, {
      alignItems: 'flex-start',
      flexDirection: 'column',
      px: 0,
      py: '16px',
    });
  }

  return style;
};

const baseStyleMenuItem = (props: Dict) => {
  const { theme, isActive, orientation, isSecondary, isFullWidth } = props;

  let style: Dict = {
    _hover: {
      background: transparentize('slate.900', 0.1)(theme),
      cursor: 'pointer',
    },
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'medium',
    height: '100%',
    justifyContent: 'inherit',
    position: 'relative',
    px: '12px',
    _focus: {
      background: transparentize('slate.900', 0.1)(theme),
      boxShadow: 'inline',
      outline: 'none',
    },
    _focusVisible: {
      background: transparentize('slate.900', 0.1)(theme),
      boxShadow: 'inline',
      outline: 'none',
    },
  };

  if (orientation === 'vertical') {
    style = mergeWith(style, {
      height: '48px',
      px: '16px',
      py: '12px',
      width: '100%',
    });

    if (!isFullWidth) {
      style = mergeWith(style, {
        fontSize: '2xl',
        height: '64px',
      });
    }
  }

  if (isActive) {
    const activeStyle =
      orientation === 'horizontal'
        ? {
            background: transparentize('white', 0.1)(theme),
            '&::before': {
              content: '" "',
              position: 'absolute',
              backgroundColor: isSecondary ? 'slate.400' : 'white',
              width: '100%',
              height: '4px',
              left: '0',
              bottom: '0',
            },
          }
        : {
            background: transparentize('white', 0.1)(theme),
            '&::before': {
              content: '" "',
              position: 'absolute',
              backgroundColor: isSecondary ? 'slate.400' : 'white',
              width: '4px',
              height: '100%',
              left: '0',
              bottom: '0',
            },
          };

    style = mergeWith(style, activeStyle);
  }

  return style;
};

const baseStyleBurger = () => ({
  alignSelf: 'center',
});

const baseStyle = (props: Dict) => ({
  brand: baseStyleBrand(props),
  burger: baseStyleBurger(),
  container: baseStyleContainer(props),
  menuItem: baseStyleMenuItem(props),
  segment: baseStyleSegment(props),
  menuList: baseStyleList(props),
  menuOption: baseStyleOption(props),
});

const defaultProps = {
  colorScheme: 'light-blue',
  isSecondary: false,
  isFullWidth: false,
  orientation: 'horizontal' as any,
};

export const Navbar = {
  baseStyle,
  defaultProps,
  parts,
};
