import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LegoUserFacelessMale1 = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22 20.08a5.77 5.77 0 00-2.24-2.29 3.66 3.66 0 00-1.71-.42H5.68c-3.48.16-4.39 3.85-4.39 5.77a.73.73 0 00.73.73H2a.73.73 0 00.73-.73c0-.17 0-4.18 3-4.32h12.36a2 2 0 011 .24 4.31 4.31 0 012.16 4 .73.73 0 00.68.78H22a.73.73 0 00.73-.68 5.76 5.76 0 00-.73-3.08zM19.79 4.83l-.9-.29.74-1.41a.81.81 0 000-.91.83.83 0 00-.93-.33l-.64.15.38-.42a1 1 0 00.26-.74.83.83 0 00-.7-.64C14.57-.4 7.37 1.85 7 2a2.52 2.52 0 00-1.15 1l-.5.86A1.52 1.52 0 004.08 4a1 1 0 00-.45.76c-.29 3.24.06 5.54 1.07 6.7.252.304.58.535.95.67v.8A3.1 3.1 0 008.75 16h6.13a3.1 3.1 0 003.1-3.1v-.25c1.39-.93 2.09-3.54 2.64-6.22-.01.03.31-1.35-.83-1.6zm-3.28 8.1a1.64 1.64 0 01-1.64 1.64H8.75a1.64 1.64 0 01-1.64-1.64V8.64a17.61 17.61 0 007.09-1.52 5 5 0 002.31 1v4.81zM5.05 5.27l.27.11a.94.94 0 001-.43l.74-1.26a1.07 1.07 0 01.42-.35c.06 0 5.6-1.75 9-1.76l-.89 1a.88.88 0 00-.09.94.85.85 0 00.83.44l1.35-.31-.36.69a1 1 0 00-.13.85.88.88 0 00.61.56l1.34.43A21.125 21.125 0 0118 10.35V7.74a1 1 0 00-.9-1 3.45 3.45 0 01-2-.8.93.93 0 00-1.1-.26c-.76.32-4.45 1.86-7.41 1.46 0 0-.9-.13-.94.48v2.66c-.39-.61-.83-1.98-.6-5.01z" />
      </g>
    );
  }
);
LegoUserFacelessMale1.displayName = 'LegoUserFacelessMale1';
