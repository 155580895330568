import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const NetworkMonitor = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M7.125 0a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75h4.125v1.5H9.375a.75.75 0 000 1.5h5.25a.75.75 0 000-1.5H12.75v-1.5h4.125a.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75h-9.75zm9 6.75V1.5h-8.25v5.25h8.25zM0 13.5a.75.75 0 01.75-.75h9.75a.75.75 0 01.75.75v6.75a.75.75 0 01-.75.75H6.375v1.5H8.25a.75.75 0 010 1.5H3a.75.75 0 010-1.5h1.875V21H.75a.75.75 0 01-.75-.75V13.5zm9.75 6v-5.25H1.5v5.25h8.25zm3-6a.75.75 0 01.75-.75h9.75a.75.75 0 01.75.75v6.75a.75.75 0 01-.75.75h-4.125v1.5H21a.75.75 0 010 1.5h-5.25a.75.75 0 010-1.5h1.875V21H13.5a.75.75 0 01-.75-.75V13.5zm9.75 6h-8.25v-5.25h8.25v5.25z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
NetworkMonitor.displayName = 'NetworkMonitor';
