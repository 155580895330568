import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu } from '@lego/klik-ui';
import { Download, EmailUnread, Save, FilesXls } from '@lego/klik-ui-icons';
import { saveAssessmentsToExcelFile } from '../../../utils/saveAssessmentsToExcelFile';
import { requestDetailedAssessmentExtract } from '../../../utils/requestDetailedAssessmentExtract';
import { GenericConfirmationModal } from '../../modals';
import { excelExportTypes } from '../../../constants/excelExportTypes';

const AssessmentExportMenu = ({ assessments, year }) => {
  const [
    detailConfirmModalVisibility,
    setDetailConfirmModalVisibility,
  ] = useState(false);

  const confirmDetialedAssessmentExtract = () => {
    requestDetailedAssessmentExtract(year);
    setDetailConfirmModalVisibility(false);
  };

  return (
    <div>
      {detailConfirmModalVisibility && (
        <GenericConfirmationModal
          header="Detailed extract"
          body="Are you sure you want to extract a detailed overview? It may take a few minutes and will be emailed to you."
          onClose={() => setDetailConfirmModalVisibility(false)}
          onConfirm={() => confirmDetialedAssessmentExtract()}
        />
      )}
      <Menu>
        <Menu.Button as={Button} variant="ghost" leftIcon={<Download />}>
          Export...
        </Menu.Button>
        <Menu.List>
          <Menu.Item
            icon={<Save />}
            aria-label="Simple overview"
            onClick={() =>
              saveAssessmentsToExcelFile(assessments, excelExportTypes.SIMPLE)
            }
          >
            Simple overview
          </Menu.Item>
          <Menu.Item
            icon={<EmailUnread />}
            onClick={() => setDetailConfirmModalVisibility(true)}
          >
            Detailed overview
          </Menu.Item>
          <Menu.Item
            icon={<FilesXls />}
            aria-label="Upload-ready overview"
            onClick={() =>
              saveAssessmentsToExcelFile(
                assessments,
                excelExportTypes.UPLOAD_PREPARED
              )
            }
          >
            Upload-ready overview
          </Menu.Item>
        </Menu.List>
      </Menu>
    </div>
  );
};

AssessmentExportMenu.propTypes = {
  assessments: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
};

export { AssessmentExportMenu };
