import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const TargetBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.962 3.811a.5.5 0 00-.462-.308h-2.75a.249.249 0 01-.25-.25V.5a.5.5 0 00-.853-.354l-2.414 2.416a2.487 2.487 0 00-.733 1.77v1.685a.25.25 0 01-.117.212.488.488 0 00-.09.069l-5 5.004a1.001 1.001 0 101.414 1.415l5-5.004a.46.46 0 00.07-.09.253.253 0 01.212-.117h1.683c.664.002 1.3-.262 1.768-.734l2.414-2.416a.5.5 0 00.108-.545z" />
        <path d="M11 8.006a1 1 0 000-2.001c-3.313 0-6 2.688-6 6.005a6.002 6.002 0 006 6.005c3.314 0 6-2.689 6-6.005a1 1 0 10-2 0 4.002 4.002 0 01-4 4.003c-2.209 0-4-1.792-4-4.003a4.002 4.002 0 014-4.004z" />
        <path d="M21 12.01a1 1 0 10-2 0c0 4.422-3.582 8.006-8 8.006s-8-3.584-8-8.006c0-4.422 3.582-8.007 8-8.007a1 1 0 000-2.001 9.99 9.99 0 00-9.214 6.11A10.006 10.006 0 003.84 18.984a.25.25 0 01.01.338l-2.608 3.047A1.001 1.001 0 002.76 23.67l2.662-3.107a.249.249 0 01.32-.05 9.932 9.932 0 0010.514 0 .25.25 0 01.322.05l2.662 3.107a1 1 0 001.519-1.3l-2.609-3.05a.25.25 0 01.01-.337A9.974 9.974 0 0021 12.01z" />
      </g>
    );
  }
);
TargetBold.displayName = 'TargetBold';
