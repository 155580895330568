const actionTypes = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  RESCORE: 'RESCORE',
  PERFORM: 'PERFORM',
  DELETE: 'DELETE',
  CHANNELUPDATE: 'CHANNELUPDATE',
  REASSIGN: 'REASSIGN',
  SOFTDELETE: 'SOFTDELETE',
};

export { actionTypes };
