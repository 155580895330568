import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesLaptop = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.211 18.925l-2.005-4.378V5.19a2.197 2.197 0 00-2.19-2.189H4.396a2.2 2.2 0 00-2.206 2.19v9.34L.184 18.91a2.48 2.48 0 00-.184.902c0 .585.234 1.136.635 1.554.401.418.97.635 1.554.635h19.017c.3 0 .601-.067.886-.184a2.124 2.124 0 001.152-1.22 2.15 2.15 0 00-.033-1.67zM3.66 5.19c0-.401.334-.735.735-.735h14.622c.4 0 .735.334.735.735v8.773H3.66V5.19zm18.23 14.89a.653.653 0 01-.383.4c-.1.034-.2.067-.301.067H2.189a.733.733 0 01-.518-.217.733.733 0 01-.217-.518c0-.1.017-.2.067-.284l1.871-4.094h16.61l1.872 4.094a.78.78 0 01.017.551z" />
        <path d="M13.168 17.622h-2.924a.742.742 0 00-.736.735c0 .401.335.735.736.735h2.924a.742.742 0 00.735-.735.742.742 0 00-.735-.735z" />
      </g>
    );
  }
);
DevicesLaptop.displayName = 'DevicesLaptop';
