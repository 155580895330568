import { css } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { spacing, media, colors, font } from '../config';
import { useUser } from '../providers/UserProvider';
import { routes } from './RouteHandler';
import { Tooltip } from '../components/tooltip/Tooltip';
import { AdminHeaderMenu } from './admin/components/AdminHeaderMenu';
import { Container, IconButton, Navbar, Text } from '@lego/klik-ui';
import { LEGOLogo } from '@lego/klik-ui-legologo';
import { Auth } from 'aws-amplify';
import { LogOut } from '@lego/klik-icons-react';

const devMarkStyle = css({
  position: 'absolute',
  left: '42%',
  top: spacing.size1,
  fontSize: font.size.sizeM2,
  color: colors.boldColors.lightBlue.lightBlue90,
  fontWeight: font.weight.medium,
  [media.medium]: {
    fontSize: font.size.size2,
    top: spacing.size5,
    left: '48%',
  },
});

const logoWrapper = css({
  cursor: 'pointer',
  ':hover': {
    color: 'inherit',
  },
});

const userWrapperStyle = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  right: spacing.size6,
  textAlign: 'right',
  color: colors.boldColors.lightBlue.lightBlue50,
  fontWeight: font.weight.medium,
});

const rolesStyle = css({
  position: 'absolute',
  right: spacing.size10,
  top: spacing.size12,
  fontSize: font.size.sizeM2,
  fontStyle: 'italic',
});

const Header = () => {
  const history = useHistory();
  const { name, roles } = useUser();

  const handleLogoClick = () => {
    history.push(routes.overview);
  };

  const handleSignOut = async () => {
    await Auth.signOut();
    global.window.location.reload();
  };

  return (
    <Container maxW="100%" bg="#0A2C47">
      <Navbar isFullWidth={true} bg="#0A2C47">
        <Tooltip content="Back to overview">
          <Navbar.Brand
            data-testid="legoLogo"
            onClick={handleLogoClick}
            css={logoWrapper}
          >
            <LEGOLogo mr="24px" />
            <Text>MCP</Text>
          </Navbar.Brand>
        </Tooltip>
        <Navbar.Segment>
          {roles && roles.includes('admin') && <AdminHeaderMenu />}
        </Navbar.Segment>
        <Navbar.Segment>
          {process.env.MCP_ENV !== 'production' && (
            <div css={devMarkStyle}>{process.env.MCP_ENV}</div>
          )}

          {name && (
            <div css={userWrapperStyle}>
              {name}
              <IconButton
                variant="ghost"
                title="Sign Out"
                icon={<LogOut />}
                onClick={handleSignOut}
                alignSelf="center"
                aria-label="Sign Out"
                color="inherit"
              />
            </div>
          )}

          {process.env.MCP_ENV !== 'production' && roles && (
            <div css={rolesStyle}>{roles.join(', ')}</div>
          )}
        </Navbar.Segment>
      </Navbar>
    </Container>
  );
};

export { Header };
