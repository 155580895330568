import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StatusErrorBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 25', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M14.302 1.424l9.473 19.303c.154.308.24.651.223 1.011a2.262 2.262 0 01-2.265 2.263H2.256c-.343 0-.686-.086-.995-.24a2.271 2.271 0 01-1.03-3.034L9.704 1.424A2.56 2.56 0 0110.87.258a2.58 2.58 0 011.957-.12c.652.206 1.166.669 1.475 1.286zM13.04 15.776h-2.05l-.484-7.775h2.998l-.465 7.775zm.726 2.995c0 .986-.745 1.73-1.713 1.73-1.024 0-1.75-.744-1.75-1.73 0-.967.726-1.73 1.75-1.73.968 0 1.713.763 1.713 1.73z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
StatusErrorBold.displayName = 'StatusErrorBold';
