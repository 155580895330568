import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Tag = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 25', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M17.84 3.85a2.31 2.31 0 110 4.619 2.31 2.31 0 010-4.62zm.295 1.598a.77.77 0 10-.59 1.424.77.77 0 00.59-1.424z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.69 0A2.31 2.31 0 0124 2.31v6.423c0 1.021-.405 2-1.127 2.722l-11.869 11.87a2.31 2.31 0 01-3.266 0L.676 16.262a2.31 2.31 0 010-3.267l11.87-11.868A3.85 3.85 0 0115.265 0h6.424zm.545 1.765a.77.77 0 00-.545-.225h-6.423c-.613 0-1.2.243-1.633.676L1.765 14.084a.77.77 0 000 1.089l7.062 7.063a.77.77 0 001.089 0l11.868-11.87a2.31 2.31 0 00.676-1.633V2.31a.77.77 0 00-.225-.545z"
        fillRule="evenodd"
      />
    </g>
  );
});
Tag.displayName = 'Tag';
