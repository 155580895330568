import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CheckTickBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M22.56 4.44a1.5 1.5 0 010 2.12l-13 13a1.5 1.5 0 01-2.12 0l-6-6a1.5 1.5 0 012.12-2.12l4.94 4.939 11.94-11.94a1.5 1.5 0 012.12 0z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
CheckTickBold.displayName = 'CheckTickBold';
