import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MoneyBagBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#money-bag-bold_svg__a)">
          <path d="M7.736 6.76a.75.75 0 00.7.48h7.14a.75.75 0 00.71-.5l1.45-3.9a2 2 0 00.1-1 1.9 1.9 0 00-.41-.84 1.47 1.47 0 00-.86-.56 1.33 1.33 0 00-1 .22l-1.83 1.19-.5-1a1.63 1.63 0 00-.5-.6 1.4 1.4 0 00-.8-.25 1.35 1.35 0 00-.79.27 1.64 1.64 0 00-.5.61l-.48 1L8.396.72a1.33 1.33 0 00-1-.19 1.46 1.46 0 00-.84.58 1.9 1.9 0 00-.38.89 2.07 2.07 0 00.12 1l1.44 3.76zM21.546 16a7.659 7.659 0 00-1.36-3.73l-2.76-3.51a.75.75 0 00-.59-.29h-9.67a.75.75 0 00-.59.29l-2.79 3.56A7.64 7.64 0 002.456 16a7.84 7.84 0 00.64 3.9 7.1 7.1 0 002.45 3 6.28 6.28 0 003.56 1.1h5.79a6.28 6.28 0 003.56-1.12 7.1 7.1 0 002.45-3 7.84 7.84 0 00.64-3.88zm-6.71 2.85a2.33 2.33 0 01-1.87 1.86 1 1 0 01-1.91 0h-.55a1 1 0 010-2h2a.34.34 0 00.12-.66l-2.06-.82a2.34 2.34 0 01.45-4.47 1 1 0 011.91 0h.55a1 1 0 010 2h-2a.34.34 0 00-.22.08.34.34 0 00.09.58l2.06.82a2.34 2.34 0 011.43 2.62v-.01z" />
        </g>
        <defs>
          <clipPath id="money-bag-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
MoneyBagBold.displayName = 'MoneyBagBold';
