import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const HumanResourcesOfferMan = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M16.827 9.764a4.867 4.867 0 004.503-3.016 4.89 4.89 0 00-1.06-5.322A4.87 4.87 0 0014.954.375a4.875 4.875 0 00-3.002 4.517 4.886 4.886 0 001.431 3.446 4.869 4.869 0 003.443 1.426zm0-8.253a3.37 3.37 0 013.125 2.08 3.387 3.387 0 01-.725 3.689 3.373 3.373 0 01-5.766-2.388 3.374 3.374 0 012.075-3.124 3.36 3.36 0 011.29-.257zm-4.105 16.507H9.647v-.22a3.752 3.752 0 00-3.685-2.781H1.488v-.85a.75.75 0 00-.79-.63.748.748 0 00-.698.73v9.103a.75.75 0 001.28.4.751.751 0 00.208-.5v-.75h14.33a.749.749 0 00.65-.74v-.2a3.754 3.754 0 00-1.162-2.531 3.741 3.741 0 00-2.584-1.031zM1.488 21.019v-4.502h4.644a2.244 2.244 0 011.997 1.49H5.882a.753.753 0 00-.714.796.757.757 0 00.794.715h6.89a2.245 2.245 0 011.918 1.371v.13H1.488z" />
        <path d="M23.937 14.766a7.502 7.502 0 00-2.738-3.623 7.48 7.48 0 00-8.645 0 7.502 7.502 0 00-2.738 3.623.751.751 0 001.02.888.75.75 0 00.389-.387 6 6 0 012.189-2.907 5.984 5.984 0 019.115 2.907.752.752 0 00.67.55.748.748 0 00.738-1.05z" />
      </g>
    );
  }
);
HumanResourcesOfferMan.displayName = 'HumanResourcesOfferMan';
