import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DownwardTrendBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M20 18a1 1 0 00-1 1v1.5a.249.249 0 00.25.25h3.5a.25.25 0 00.25-.25V19a1 1 0 00-1-1h-2zm-4-3h-2a1 1 0 00-1 1v4.5a.249.249 0 00.25.25h3.5a.25.25 0 00.25-.25V16a1 1 0 00-1-1zm-8-3.5a1 1 0 00-1 1v8a.249.249 0 00.25.25h3.5a.25.25 0 00.25-.25v-8a1 1 0 00-1-1H8zm-6.75 9.25h3.5A.25.25 0 005 20.5v-12a1 1 0 00-1-1H2a1 1 0 00-1 1v12a.249.249 0 00.25.25zM21 13.751a.75.75 0 00.651-1.122l-2-3.5a.748.748 0 00-1.275-.044l-.307.46a.244.244 0 01-.159.106.247.247 0 01-.187-.037L3.555.169a1 1 0 00-1.11 1.664l14.168 9.445a.252.252 0 01.07.347l-.307.46a.75.75 0 00.531 1.16l4 .5c.03.004.062.006.093.006zM1 24h22a1 1 0 000-2H1a1 1 0 100 2z" />
      </g>
    );
  }
);
DownwardTrendBold.displayName = 'DownwardTrendBold';
