import {
  AccordionPanel as AccordionPanelComponent,
  AccordionPanelProps,
} from '@chakra-ui/accordion';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IAccordionPanelProps = AccordionPanelProps;

export const AccordionPanel = forwardRef<IAccordionPanelProps, 'div'>((props, ref) => {
  // Accordion Panels should never be focusable by tabbing through them, form elements inside them
  // yes, but nothing else. You can still override it if you want.
  return <AccordionPanelComponent tabIndex={-1} {...props} ref={ref} />;
});
