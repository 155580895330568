import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { generalApiErrorHandler } from '../utils/generalApiErrorHandler';
import { snackbarDefaultMessages } from '../utils/snackbar';

function useFetchImagesFromS3({ uploadId, skip, onCompleted }) {
  const [imagesAreLoading, setImagesAreLoading] = useState(false);
  const [uploadedImagesKeys, setUploadedImagesKeys] = useState(null);
  const [imagesFetchError, setImagesFetchError] = useState(false);

  useEffect(() => {
    if (!uploadId || skip) return;

    async function fetchUploadedImages() {
      let uploadedImagesFromS3 = [];
      try {
        setImagesAreLoading(true);
        uploadedImagesFromS3 = await Storage.list(uploadId);

        const uploadedImageKeysFromS3 = uploadedImagesFromS3.map(
          (imageObject) => imageObject.key
        );
        setUploadedImagesKeys(uploadedImageKeysFromS3);
        setImagesAreLoading(false);
        onCompleted &&
          typeof onCompleted === 'function' &&
          onCompleted(uploadedImageKeysFromS3);
      } catch (error) {
        setImagesAreLoading(false);
        setImagesFetchError(error);
        setUploadedImagesKeys([]);

        generalApiErrorHandler(
          error,
          snackbarDefaultMessages.imageUploadFetchError
        );
      }
    }

    fetchUploadedImages();
  }, [uploadId, skip, onCompleted]);

  return {
    imagesAreLoading,
    uploadedImagesKeys,
    imagesFetchError,
  };
}

export { useFetchImagesFromS3 };
