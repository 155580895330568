import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesTiffBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-1.41.59A2 2 0 002 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM6.84 11.24H6.2v5.72a.64.64 0 01-.63.64.64.64 0 01-.64-.64v-5.72h-.64a.64.64 0 01-.445-1.085.63.63 0 01.445-.185h2.55a.63.63 0 01.63.63.64.64 0 01-.63.64zM10 16.96a.65.65 0 01-.7.6.65.65 0 01-.7-.6V10.6a.65.65 0 01.7-.6.65.65 0 01.7.6v6.36zm4.61-5.69h-2.25v2.49h1.5a.64.64 0 01.64.64.64.64 0 01-.64.63h-1.5v1.9a.64.64 0 11-1.27 0v-6.3a.64.64 0 01.63-.64h2.87a.64.64 0 110 1.28h.02zm5.12 0h-2.25v2.49H19a.64.64 0 01.64.64.64.64 0 01-.64.63h-1.5v1.9a.64.64 0 11-1.27 0v-6.3a.64.64 0 01.63-.64h2.87a.64.64 0 110 1.28z" />
      </g>
    );
  }
);
FilesTiffBold.displayName = 'FilesTiffBold';
