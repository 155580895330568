import { useReducer, useCallback } from 'react';
import { useLocation, useParams, Redirect, useHistory } from 'react-router-dom';
import { css } from '@emotion/core';
import { useQuery, useMutation } from '@apollo/client';
import { Button } from '@lego/klik-react';
import { spacing, padding, media } from '../../config';
import { AssessmentInfoSection } from '../AssessmentInfoSection';
import { Step, Stepper } from '../stepper';
import { OverlayPortal } from '../OverlayPortal';
import { Spinner } from '../spinner/Spinner';
import {
  GET_ASSESSMENT_DATA,
  UPDATE_ANSWER,
  UPDATE_ALL_ANSWERS,
} from '../../queries/assessment';
import {
  adjustAssessmentsBasedOnFeatureFlags,
  areAllAnswersComplete,
  enhanceQuestionsWithAnswersAndProof,
} from './helpers';
import { Question } from '../question/Question';
import {
  surveyReducer,
  SurveyDispatchContext,
  resetAction,
  answerSavedAction,
  setUploadedImages,
  SurveyContext,
} from './reducer';
import { routes } from '../RouteHandler';
import { assessmentStatus } from '../../constants';
import { Heading } from '../Heading';
import { useFetchImagesFromS3 } from '../../hooks/useFetchImagesFromS3';
import { generalApiErrorHandler } from '../../utils/generalApiErrorHandler';

const assessmentSurveyContainer = css({
  maxWidth: '768px',
  margin: 'auto',
  padding: `${spacing.size10}px ${padding.medium}px ${spacing.size12}px`,
  [media.medium]: {
    padding: `${spacing.size12}px ${padding.medium}px ${spacing.size12}px`,
  },
});

const doneEditingButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
  marginTop: spacing.size8,
});

const ScoringPage = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { customerId, assessmentYear, version } = useParams();
  const [surveyReducerState, dispatch] = useReducer(surveyReducer, {});

  const {
    loading: isAssessmentDataLoading,
    error: assessmentDataError,
  } = useQuery(GET_ASSESSMENT_DATA, {
    variables: {
      customerId,
      year: Number(assessmentYear),
      version: Number(version),
    },
    skip: surveyReducerState?.answers,
    onCompleted: (response) => dispatch(resetAction(response, assessmentYear)),
    fetchPolicy: 'network-only',
  });

  const [updateAnswer, { loading: updateAnswerLoading }] = useMutation(
    UPDATE_ANSWER,
    {
      variables: {
        customerId,
        year: Number(assessmentYear),
        version: Number(version),
      },
      onCompleted: handleAnswerUpdate,
    }
  );

  const [updateAllAnswers, { loading: updateAllAnswersLoading }] = useMutation(
    UPDATE_ALL_ANSWERS,
    {
      variables: {
        customerId,
        year: Number(assessmentYear),
        version: Number(version),
      },
      onCompleted: handleAllAnswersUpdateCompleted,
    }
  );

  useFetchImagesFromS3({
    uploadId: surveyReducerState?.surveyInfo?.uploadId,
    skip: isAssessmentDataLoading || !surveyReducerState.surveyInfo?.uploadId,
    onCompleted: useCallback(
      (uploadedImagesKeys) => dispatch(setUploadedImages(uploadedImagesKeys)),
      []
    ),
  });

  function handleAnswerUpdate(response) {
    const {
      updateAnswer: { questionId },
    } = response;
    dispatch(answerSavedAction(questionId));
  }

  const handleAnswerSave = async (questionId) => {
    const answerState = surveyReducerState.answers.find(
      (answer) => answer.questionId === questionId
    );
    try {
      await updateAnswer({
        variables: {
          answer: answerState,
        },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  };

  const handleAllAnswersUpdate = async (answers) => {
    try {
      await updateAllAnswers({
        variables: { customerId, answers },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  };

  function handleAllAnswersUpdateCompleted(response) {
    if (response.updateAllAnswers) {
      history.push(routes.scoringReview(customerId, assessmentYear, version));
    }
  }

  // Error boundary catches this error
  if (assessmentDataError) throw assessmentDataError;

  if (
    isAssessmentDataLoading ||
    !surveyReducerState.answers?.length ||
    !surveyReducerState.questions?.length
  ) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  const isSurveyComplete = areAllAnswersComplete(surveyReducerState.answers);
  const isSurveyInEditMode = queryParams.get('mode') === 'edit';

  if (isSurveyComplete && !isSurveyInEditMode) {
    return (
      <Redirect to={routes.complete(customerId, assessmentYear, version)} />
    );
  }

  const surveyDetailsForFeatureFlag = {
    year: Number(assessmentYear),
    channel: surveyReducerState.surveyInfo.customerDetail.channel,
  };

  let enhancedQuestions = enhanceQuestionsWithAnswersAndProof(
    surveyReducerState.questions,
    surveyReducerState.answers,
    surveyReducerState.uploadedImageKeys
  );
  enhancedQuestions = adjustAssessmentsBasedOnFeatureFlags(
    enhancedQuestions,
    surveyDetailsForFeatureFlag
  );

  return (
    <div>
      <Heading type="h1" text="Complete Assessment Form" />
      <AssessmentInfoSection assessmentInfo={surveyReducerState.surveyInfo} />
      <div css={assessmentSurveyContainer}>
        <SurveyContext.Provider value={surveyReducerState}>
          <SurveyDispatchContext.Provider value={{ dispatch }}>
            <Stepper
              activeStep={surveyReducerState.activeStep}
              stepperState={surveyReducerState}
              shouldHideInactiveStepsContent={!isSurveyInEditMode}
            >
              {enhancedQuestions.map((question, index) => {
                const {
                  questionId,
                  headline,
                  questionImageKeys,
                  isMissingProof,
                } = question;
                const questionState = surveyReducerState.answers.find(
                  (answer) => answer.questionId === questionId
                );

                return (
                  <Step
                    stepId={questionId}
                    key={questionId}
                    header={headline}
                    isValid={questionState.isValid}
                    isComplete={questionState.isComplete}
                    isWarning={isMissingProof}
                    additionalHeight={questionImageKeys.length ? 100 : 0}
                  >
                    <Question
                      question={question}
                      questionState={questionState}
                      hasBackButton={index > 0}
                      saveAnswer={handleAnswerSave}
                      isSaving={updateAnswerLoading}
                      isActive={surveyReducerState.activeStep === index + 1}
                      uploadImageKeys={questionImageKeys}
                      highlightProofRequirement={isMissingProof}
                      hideActionButton={isSurveyInEditMode}
                      uploadId={surveyReducerState.surveyInfo.uploadId}
                    />
                  </Step>
                );
              })}
            </Stepper>
          </SurveyDispatchContext.Provider>
        </SurveyContext.Provider>
      </div>
      {(surveyReducerState.assessmentStatus ===
        assessmentStatus.SELF_ASSESSMENT ||
        surveyReducerState.assessmentStatus ===
          assessmentStatus.RE_ASSESSMENT ||
        surveyReducerState.assessmentStatus === assessmentStatus.ASSESSMENT) &&
        isSurveyInEditMode && (
          <div css={doneEditingButtonWrapperStyle}>
            <Button
              label="Done editing"
              data-transaction-name="Done editing assessment"
              disabled={updateAllAnswersLoading}
              isDisabled={updateAllAnswersLoading}
              onClick={() => handleAllAnswersUpdate(surveyReducerState.answers)}
            />
          </div>
        )}
    </div>
  );
};

export { ScoringPage };
