import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowLongDown = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M12 .75a.75.75 0 01.75.75v19.305l6.747-6.132a.75.75 0 011.009 1.11l-8.002 7.272a.75.75 0 01-1.008 0l-8-7.27a.75.75 0 111.008-1.11l6.746 6.13V1.5A.75.75 0 0112 .75z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ArrowLongDown.displayName = 'ArrowLongDown';
