const assessmentColumnHeaders = {
  YEAR: 'Year',
  C6_ID: 'C6 ID',
  CUSTOMER_ID: 'Customer ID',
  CUSTOMER_NAME: 'Customer Name',
  CUSTOMER_EMAIL: 'Customer Email',
  C6_NAME: 'C6 Name',
  TIER: 'Tier',
  CHANNEL: 'Channel',
  SCORE: 'Score',
  STATUS: 'Status',
  PERFORM_USER: 'Perform User',
  REVIEW_USER: 'Review User',
  APPROVE_USER: 'Approve User',
  PENDING_USER: 'Pending User',
  NEXT_ACTION_BY: 'Next Action By',
  BUSINESS_UNIT: 'Business Unit',
};

const assessmentColumnHeaderAccessors = {
  [assessmentColumnHeaders.YEAR]: 'year',
  [assessmentColumnHeaders.C6_ID]: 'c6Id',
  [assessmentColumnHeaders.CUSTOMER_ID]: 'customerId',
  [assessmentColumnHeaders.CUSTOMER_NAME]: 'customerName',
  [assessmentColumnHeaders.CUSTOMER_EMAIL]: 'customerEmail',
  [assessmentColumnHeaders.C6_NAME]: 'c6Name',
  [assessmentColumnHeaders.BUSINESS_UNIT]: 'businessUnit',
  [assessmentColumnHeaders.TIER]: 'tier',
  [assessmentColumnHeaders.CHANNEL]: 'channel',
  [assessmentColumnHeaders.SCORE]: 'score',
  [assessmentColumnHeaders.STATUS]: 'status',
  [assessmentColumnHeaders.PERFORM_USER]: 'perform',
  [assessmentColumnHeaders.REVIEW_USER]: 'review',
  [assessmentColumnHeaders.APPROVE_USER]: 'approve',
  [assessmentColumnHeaders.PENDING_USER]: 'pendingUser',
  [assessmentColumnHeaders.NEXT_ACTION_BY]: 'pendingUser',
};

export { assessmentColumnHeaders, assessmentColumnHeaderAccessors };
