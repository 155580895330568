import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Target = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M19.824.61a.753.753 0 00-.52-.576.765.765 0 00-.757.181l-3.286 3.192a.747.747 0 00-.218.423l-.537 3.506-4.04 3.993a.744.744 0 000 1.06.765.765 0 001.074 0l4.039-3.992 3.547-.532a.762.762 0 00.427-.215l3.23-3.248a.744.744 0 00.182-.75.756.756 0 00-.581-.513l-2.149-.406-.411-2.124zm-3.701 6.188l.382-2.496L18.6 2.267l.241 1.248a.754.754 0 00.603.596l1.263.238-2.059 2.07-2.525.379z"
        fillRule="evenodd"
      />
      <path d="M12.543 3.046a9.19 9.19 0 00-5.544 1.2A9.033 9.033 0 003.25 8.46a8.904 8.904 0 00-.494 5.59 8.984 8.984 0 003.86 5.468l.01.002.008.002a.755.755 0 01.405.255 9.187 9.187 0 009.424-.156 9.025 9.025 0 003.626-4.316 8.903 8.903 0 00.335-5.6.75.75 0 01.54-.917.76.76 0 01.927.534 10.39 10.39 0 01-.39 6.534 10.516 10.516 0 01-3.593 4.607l.659 2.605a.75.75 0 01-.552.91.76.76 0 01-.92-.546l-.54-2.134a10.726 10.726 0 01-9.62.125l-.508 2.009a.76.76 0 01-.92.545.75.75 0 01-.552-.91l.617-2.436a10.48 10.48 0 01-4.294-6.24 10.388 10.388 0 01.577-6.52 10.539 10.539 0 014.372-4.917 10.723 10.723 0 016.469-1.4c.416.042.72.41.678.822a.757.757 0 01-.832.67z" />
      <path d="M11.617 7.5a.754.754 0 00.758-.75c0-.415-.34-.75-.76-.75a6.119 6.119 0 00-3.523 1.117 6.007 6.007 0 00-2.213 2.931 5.935 5.935 0 00-.08 3.657 6 6 0 002.082 3.024 6.123 6.123 0 007.041.308 6.017 6.017 0 002.344-2.831 5.937 5.937 0 00.246-3.65.76.76 0 00-.92-.547.75.75 0 00-.553.909 4.446 4.446 0 01-.185 2.737 4.512 4.512 0 01-1.757 2.123 4.593 4.593 0 01-5.282-.231 4.5 4.5 0 01-1.561-2.268 4.452 4.452 0 01.06-2.743 4.506 4.506 0 011.66-2.198 4.59 4.59 0 012.643-.838z" />
    </g>
  );
});
Target.displayName = 'Target';
