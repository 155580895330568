import { useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';

import { Modal, Overlay, Button, TextArea } from '@lego/klik-react';
import { PlusBold } from '@lego/klik-icons-react';
import { tableStyle, tableThStyle, tableTdStyle } from '../styles/tableStyle';
import { Heading } from './Heading';
import { formatDate } from '../utils/formatDate';
import { CustomTableHeader } from './assessment-overview/overview-table/components/CustomTableHeader';

const CommentsTable = ({ comments, addCommentHandler }) => {
  const [isAddCommentModalVisible, setIsAddCommentModalVisible] = useState(
    false
  );
  const [commentValue, setCommentValue] = useState('');
  const columns = useMemo(
    () => [
      {
        Header: 'Comment',
        accessor: 'text',
        width: 1000,
      },
      {
        id: 'dateAdded',
        Header: CustomTableHeader,
        headerText: 'Added',
        accessor: (row) => new Date(row.date),
        Cell: ({ cell: { value } }) => formatDate(value, { includeTime: true }),
        sortType: 'datetime',
      },
      {
        Header: 'User',
        accessor: 'userEmail',
      },
    ],
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: comments,
    },
    useSortBy
  );

  const addComment = () => {
    setIsAddCommentModalVisible(false);
    addCommentHandler(commentValue);
  };

  return (
    <div>
      <Heading type="h2" text="Comments" />
      {isAddCommentModalVisible && (
        <Overlay
          isOpen={true}
          onClick={() => setIsAddCommentModalVisible(false)}
        >
          <Modal
            heading={<Modal.Heading>Add Comment</Modal.Heading>}
            body={
              <Modal.Body>
                <TextArea
                  value={commentValue}
                  onChange={(event) => setCommentValue(event.target.value)}
                  placeholder="Write your comment here"
                />
              </Modal.Body>
            }
            action={
              <Modal.Action>
                <Button
                  aria-label="Confirm action"
                  data-transaction-name="Add survey comment"
                  label="Add"
                  onClick={addComment}
                />
                <Button
                  aria-label="Close modal"
                  data-transaction-name="Cancel adding survey comment"
                  label="Cancel"
                  variant="outline"
                  onClick={() => setIsAddCommentModalVisible(false)}
                />
              </Modal.Action>
            }
            exitClickHandler={() => setIsAddCommentModalVisible(false)}
          />
        </Overlay>
      )}
      {/* {comments.length >= 1 && ( */}
      <table css={tableStyle} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              key={`tableheadergroup${index}`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  css={tableThStyle}
                  key={`tableheader${index}`}
                  {...column.getHeaderProps({
                    ...column.getSortByToggleProps({ title: undefined }), // remove default title tooltip
                    style: { width: column.width },
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={`tablerow${i}`} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      css={tableTdStyle}
                      key={`tablecell${index}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            <td colSpan={3}>
              <Button
                size="small"
                variant="ghost"
                label="Add comment"
                iconPosition="right"
                icon={<PlusBold />}
                style={{ width: '100%' }}
                onClick={() => setIsAddCommentModalVisible(true)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {/* )} */}
    </div>
  );
};

CommentsTable.propTypes = {
  comments: PropTypes.array.isRequired,
  addCommentHandler: PropTypes.func.isRequired,
};

export { CommentsTable };
