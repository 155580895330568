import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Ideas = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M12.75.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0V.75z" />
      <path
        clipRule="evenodd"
        d="M5.578 15.171a7.35 7.35 0 002.672 2.576V19.5a2.25 2.25 0 002.25 2.25h.75v1.5a.75.75 0 001.5 0v-1.5h.75a2.25 2.25 0 002.25-2.25v-1.753a7.35 7.35 0 003.75-6.507v-.003a7.624 7.624 0 00-7.513-7.487A7.623 7.623 0 004.5 11.241v.003a7.35 7.35 0 001.078 3.928zM13.5 20.25a.75.75 0 00.75-.75V18h-4.5v1.5a.75.75 0 00.75.75h3zM9.097 16.5h2.153v-2.69l-2.03-2.03a.75.75 0 111.06-1.06L12 12.44l1.72-1.72a.75.75 0 111.06 1.06l-2.03 2.03v2.69h2.153A5.85 5.85 0 0018 11.262a6.123 6.123 0 00-6-6.012 6.123 6.123 0 00-6 6.015A5.85 5.85 0 009.097 16.5z"
        fillRule="evenodd"
      />
      <path d="M.75 11.25a.75.75 0 01.75-.75H3A.75.75 0 013 12H1.5a.75.75 0 01-.75-.75zm3.52-8.04a.75.75 0 00-1.04 1.08l1.1 1.058a.75.75 0 001.04-1.082L4.27 3.21zm15.98 8.04a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5H21a.75.75 0 01-.75-.75zm.52-6.96a.75.75 0 10-1.04-1.08l-1.1 1.056a.75.75 0 001.04 1.082l1.1-1.057z" />
    </g>
  );
});
Ideas.displayName = 'Ideas';
