import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const GraphBarBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M1.25 20.669h3.5c.066 0 .13-.025.177-.07a.236.236 0 00.073-.17v-1.927c0-.532-.448-.963-1-.963H2c-.552 0-1 .43-1 .963v1.926a.246.246 0 00.25.24zM8 12.722c-.552 0-1 .432-1 .964v6.742a.246.246 0 00.25.24h3.5a.248.248 0 00.177-.07.236.236 0 00.073-.17v-6.742c0-.532-.448-.964-1-.964H8zm6 1.927c-.552 0-1 .431-1 .963v4.816a.246.246 0 00.25.24h3.5a.248.248 0 00.177-.07.235.235 0 00.073-.17v-4.816c0-.532-.448-.963-1-.963h-2zm6-6.743c-.552 0-1 .432-1 .964v11.558a.246.246 0 00.25.24h3.5a.248.248 0 00.177-.07.236.236 0 00.073-.17V8.87c0-.532-.448-.964-1-.964h-2zM21 .2a2.03 2.03 0 00-1.568.732 1.872 1.872 0 00-.378 1.638.238.238 0 01-.054.213l-3.258 3.66c-.062.07-.16.1-.252.077-.16-.04-.325-.06-.49-.058a2.04 2.04 0 00-1.417.569.254.254 0 01-.256.058l-2.178-.699a.245.245 0 01-.169-.192c-.148-1-1.072-1.72-2.117-1.65-1.046.069-1.859.904-1.863 1.914 0 .15.018.3.055.446a.236.236 0 01-.084.241l-2.9 2.331a.263.263 0 01-.262.036 2.052 2.052 0 00-2.332.513 1.87 1.87 0 00-.153 2.3c.483.714 1.397 1.038 2.245.795C4.418 12.882 5 12.13 5 11.278c0-.15-.018-.3-.054-.446a.236.236 0 01.083-.241l2.9-2.331a.258.258 0 01.261-.036c.757.321 1.641.16 2.224-.405a.257.257 0 01.257-.058l2.177.697c.089.03.153.104.168.193.133 1.013 1.064 1.75 2.12 1.68 1.058-.07 1.875-.922 1.864-1.943a1.84 1.84 0 00-.16-.752.235.235 0 01.041-.252l3.044-3.421a.256.256 0 01.288-.065c.746.308 1.611.15 2.19-.398.578-.549.755-1.38.447-2.103C22.542.674 21.812.201 21 .201zM1 23.8h22c.552 0 1-.432 1-.964s-.448-.963-1-.963H1c-.552 0-1 .431-1 .963s.448.963 1 .963z" />
      </g>
    );
  }
);
GraphBarBold.displayName = 'GraphBarBold';
