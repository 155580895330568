import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const VideoMultiBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.44 24H4.11c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h17.33c.58 0 1.05-.47 1.05-1.05V4.11c0-.41.34-.75.75-.75s.76.34.76.75v17.33c0 1.41-1.15 2.56-2.56 2.56z" />
        <path d="M19.92 21.73H1.8c-1 0-1.8-.81-1.8-1.8V1.8C0 .81.81 0 1.8 0h18.12c1 0 1.8.81 1.8 1.8v18.12c.01 1-.8 1.81-1.8 1.81zM8.45 16.18l7.18-4.39c.11-.07.21-.17.27-.28.07-.12.1-.25.11-.38 0-.13-.03-.26-.09-.38a.73.73 0 00-.26-.29l-7.18-4.9a.824.824 0 00-.4-.13c-.15 0-.29.03-.42.1a.78.78 0 00-.42.69v9.28c0 .14.04.28.11.4s.18.22.3.29c.12.07.25.1.39.1s.28-.04.41-.11zm5.3-5.1l-4.92 3V7.73l4.92 3.35z" />
      </g>
    );
  }
);
VideoMultiBold.displayName = 'VideoMultiBold';
