import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesGif = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-gif_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h4a.75.75 0 000-1.5h-4a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path d="M19.88 15.037h3.37a.749.749 0 01.75.751.753.753 0 01-.75.752h-2.63v2.957h1.78a.749.749 0 01.75.752.753.753 0 01-.75.752h-1.78v2.245a.753.753 0 01-.74.752.76.76 0 01-.76-.752v-7.458a.763.763 0 01.76-.751zm-3.01 8.961a.779.779 0 01-.83-.722v-7.518a.842.842 0 01.83-.712.839.839 0 01.83.712v7.518a.782.782 0 01-.83.722zm-4.44-.08a2.996 2.996 0 01-1.92-.621 4.18 4.18 0 01-1.27-3.509 4.812 4.812 0 011.55-4.01 3.806 3.806 0 013.05-.631.74.74 0 01.55.912.752.752 0 01-.9.551 2.304 2.304 0 00-1.79.361 3.44 3.44 0 00-1 2.797 3.015 3.015 0 00.7 2.316 2.095 2.095 0 001.72.2l-.05-1.744-.81.05a.749.749 0 01-.75-.752.753.753 0 01.75-.752h.86a1.448 1.448 0 011.45 1.454v1.794a1.457 1.457 0 01-1.1 1.414c-.34.096-.688.153-1.04.17z" />
      </g>
      <defs>
        <clipPath id="files-gif_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesGif.displayName = 'FilesGif';
