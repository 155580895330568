import { Switch as SwitchComponent, SwitchProps } from '@chakra-ui/switch';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type ISwitchProps = SwitchProps;

// children should never be passed,
export const Switch = forwardRef<ISwitchProps, 'input'>(({ children: _children, ...rest }, ref) => {
  return <SwitchComponent {...rest} ref={ref} />;
});
