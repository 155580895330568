import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SecurityBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.15 2.71a2.24 2.24 0 00-1-.83A22.45 22.45 0 0012 0a22.45 22.45 0 00-9.18 1.91 2.24 2.24 0 00-1.32 2v7.61a12.66 12.66 0 008.11 11.83l1 .4a3.75 3.75 0 002.69 0l1-.4a12.66 12.66 0 008.2-11.82V3.91c0-.425-.122-.84-.35-1.2zm-5.2 14.06a.7.7 0 01-.49.2H7.7a.7.7 0 01-.7-.7V9.14a.7.7 0 01.7-.7h.89V7.16a3.47 3.47 0 116.93 0v1.29h.89a.7.7 0 01.7.7v7.12a.7.7 0 01-.16.5z" />
        <path d="M14.13 7.16a2.08 2.08 0 00-4.15 0v1.29h4.15V7.16zm-5.74 8.41h7.32V9.84H8.39v5.73zm3-3.66a.7.7 0 111.39 0v1.58a.7.7 0 11-1.39 0v-1.58z" />
      </g>
    );
  }
);
SecurityBold.displayName = 'SecurityBold';
