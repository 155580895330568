import { CustomTableHeader } from './components/CustomTableHeader';
import { ScoreCell, StatusCell } from './cells';
import {
  assessmentColumnHeaders,
  assessmentColumnHeaderAccessors,
} from '../../../constants';
import { sortByStatus } from './sorting/sortByStatus';

const overviewColumns = [
  {
    Header: CustomTableHeader,
    headerText: assessmentColumnHeaders.C6_NAME,
    accessor: assessmentColumnHeaderAccessors[assessmentColumnHeaders.C6_NAME],
    sortType: 'string',
  },
  {
    Header: CustomTableHeader,
    headerText: assessmentColumnHeaders.CUSTOMER_NAME,
    accessor:
      assessmentColumnHeaderAccessors[assessmentColumnHeaders.CUSTOMER_NAME],
    sortType: 'string',
  },
  {
    Header: CustomTableHeader,
    headerText: assessmentColumnHeaders.TIER,
    accessor: assessmentColumnHeaderAccessors[assessmentColumnHeaders.TIER],
  },
  {
    Header: CustomTableHeader,
    headerText: assessmentColumnHeaders.CHANNEL,
    accessor: assessmentColumnHeaderAccessors[assessmentColumnHeaders.CHANNEL],
    sortType: 'string',
  },
  {
    Header: CustomTableHeader,
    id: 'status',
    headerText: assessmentColumnHeaders.STATUS,
    accessor: assessmentColumnHeaderAccessors[assessmentColumnHeaders.STATUS],
    Cell: StatusCell,
    sortType: sortByStatus,
  },
  {
    Header: CustomTableHeader,
    headerText: assessmentColumnHeaders.NEXT_ACTION_BY,
    accessor:
      assessmentColumnHeaderAccessors[assessmentColumnHeaders.NEXT_ACTION_BY],
    sortType: 'string',
    tooltipText: 'Next person to take action',
  },
  {
    Header: CustomTableHeader,
    headerText: assessmentColumnHeaders.SCORE,
    accessor: assessmentColumnHeaderAccessors[assessmentColumnHeaders.SCORE],
    Cell: ScoreCell,
    isCentered: true,
  },
];

export { overviewColumns };
