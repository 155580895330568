import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const UpwardTrendBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M2 17.864a1 1 0 00-1 1v1.5a.25.25 0 00.25.25h3.5a.249.249 0 00.25-.25v-1.5a1 1 0 00-1-1H2zm6-3a1 1 0 00-1 1v4.5a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-4.5a1 1 0 00-1-1H8zm6-3.5a1 1 0 00-1 1v8a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-8a1 1 0 00-1-1h-2zm5.25 9.25h3.5a.25.25 0 00.25-.25v-12a1 1 0 00-1-1h-2a1 1 0 00-1 1v12a.25.25 0 00.25.25zM18.069 4.206l.307.46c.14.209.373.333.624.333h.025a.75.75 0 00.626-.377l2-3.5a.751.751 0 00-.744-1.116l-4 .5a.75.75 0 00-.531 1.16l.307.46a.251.251 0 01-.07.347L2.445 11.918a1 1 0 101.11 1.664l14.168-9.445a.249.249 0 01.346.07zM1 24h22a1 1 0 100-2H1a1 1 0 100 2z" />
      </g>
    );
  }
);
UpwardTrendBold.displayName = 'UpwardTrendBold';
