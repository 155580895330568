import {
  chakra,
  HTMLChakraProps,
  SystemProps,
  SystemStyleObject,
  useStyles,
} from '@chakra-ui/system';
import { TabProps, useTab } from '@chakra-ui/tabs';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';

export interface ITabProps extends TabProps {
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
  iconSpacing?: SystemProps['marginRight'];
}

const TabIcon: React.FC<HTMLChakraProps<'span'>> = (props) => {
  const { children, className, ...rest } = props;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _children = React.isValidElement(children)
    ? React.cloneElement(children, {
        'aria-hidden': true,
        focusable: false,
      })
    : children;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _className = cx('chakra-tab__icon', className);

  return (
    <chakra.span {...rest} className={_className}>
      {_children}
    </chakra.span>
  );
};

export const Tab = React.forwardRef<HTMLButtonElement, ITabProps>(
  ({ leftIcon, rightIcon, iconSpacing = '0.5rem', ...props }, ref) => {
    const styles = useStyles();
    const tabProps = useTab({ ...props, ref });

    const tabStyles: SystemStyleObject = {
      outline: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...styles.tab,
    };

    return (
      <chakra.button
        {...tabProps}
        __css={tabStyles}
        className={cx('chakra-tabs__tab', props.className)}
      >
        {leftIcon && <TabIcon marginEnd={iconSpacing}>{leftIcon}</TabIcon>}
        {tabProps.children}
        {rightIcon && <TabIcon marginStart={iconSpacing}>{rightIcon}</TabIcon>}
      </chakra.button>
    );
  }
);
