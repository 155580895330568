import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const HeartBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.76 13.284a8.787 8.787 0 001.301-1.699 6.478 6.478 0 00-1.886-8.709c-.906-.62-1.956-1-3.05-1.106h-.13a5.744 5.744 0 00-3.687 1.052 5.727 5.727 0 00-2.2 3.136.11.11 0 01-.22 0 5.587 5.587 0 00-2.184-3.177 5.604 5.604 0 00-3.724-1.01h-.21A6.465 6.465 0 00.654 5.353a6.437 6.437 0 00.32 6.231 8.996 8.996 0 001.292 1.7l8.72 8.705a.942.942 0 001.302 0l9.472-8.706z" />
      </g>
    );
  }
);
HeartBold.displayName = 'HeartBold';
