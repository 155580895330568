import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MoveBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M7.94 6.06a1.5 1.5 0 010-2.12l3-3a1.5 1.5 0 012.12 0l3 3a1.5 1.5 0 01-2.12 2.12l-.44-.439V10.5h4.879l-.44-.44a1.5 1.5 0 012.122-2.12l3 3a1.5 1.5 0 010 2.12l-3 3a1.5 1.5 0 01-2.122-2.12l.44-.44H13.5v4.879l.44-.44a1.5 1.5 0 012.12 2.122l-3 3a1.5 1.5 0 01-2.12 0l-3-3a1.5 1.5 0 012.12-2.122l.44.44V13.5H5.621l.44.44a1.5 1.5 0 01-2.122 2.12l-3-3a1.5 1.5 0 010-2.12l3-3a1.5 1.5 0 112.122 2.12l-.44.44H10.5V5.621l-.44.44a1.5 1.5 0 01-2.12 0z" />
    </g>
  );
});
MoveBold.displayName = 'MoveBold';
