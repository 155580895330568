import { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import { css } from '@emotion/core';
import { Button, TextArea } from '@lego/klik-react';
import { StatusInformationBold } from '@lego/klik-icons-react';
import { questionTypes } from '../../constants';
import { spacing, font, colors, media } from '../../config';
import { infoButtonStyle, infoButtonWrapperStyle } from '../../styles/common';
import { MultiChoice } from './MultiChoice';
import { SingleChoice } from './SingleChoice';
import { Spinner } from '../spinner/Spinner';
import { ImageUpload } from '../ImageUpload';
import { InfoModal, ImageModal, DeleteImageModal } from './modals';
import { UploadedImageThumbnailList } from './UploadedImageThumbnailList';
import {
  SurveyDispatchContext,
  updateCommentAction,
  stepBackAction,
  updateValidationAction,
  addUploadedImage,
  deleteUploadedImage,
} from '../assessment-scoring/reducer';
import { generalApiErrorHandler } from '../../utils/generalApiErrorHandler';
import { QuestionDescription } from './QuestionDescription';

const questionWrapperStyle = css({
  marginBottom: spacing.size10,
});

const buttonWrapperStyle = css({
  display: 'flex',
  margin: `${spacing.size10}px 0 ${spacing.size4}px`,
});

const headerWrapperStyle = css({
  display: 'flex',
});

const headerStyle = css({
  fontSize: font.size.size2,
  marginTop: spacing.size4,
  marginBottom: spacing.size2,
});

const additionalFieldsWrapperStyle = css({
  width: 'auto',
  marginTop: spacing.size10,
  [media.medium]: {
    width: 450,
  },
});

const justificationHelpTextStyle = (isDisabled) =>
  css({
    marginBottom: spacing.size2,
    color: isDisabled
      ? colors.neutralColors.slate40
      : colors.neutralColors.slateBase,
  });

const justificationHelpTextColor = (isDisabled, shouldHighlight) => {
  if (isDisabled) {
    return colors.neutralColors.slate40;
  }

  if (shouldHighlight) {
    return colors.neutralColors.black;
  }

  return colors.neutralColors.slateBase;
};

const uploadSectionStyle = (isDisabled, shouldHighlight) =>
  css({
    color: justificationHelpTextColor(isDisabled, shouldHighlight),
    background: shouldHighlight
      ? colors.utilityColors.warning.warning100
      : 'none',
    padding: shouldHighlight
      ? `${spacing.size1}px ${spacing.size2}px`
      : 'inherit',
    marginBottom: spacing.size2,
  });

const questionContentWrapperStyle = css({
  position: 'relative',
});

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: colors.neutralColors.white,
  opacity: 0.8,
  zIndex: 999999,
});

const additionalFieldsHeaderStyle = (isDisabled) =>
  css({
    fontWeight: font.weight.medium,
    fontSize: font.size.size1,
    marginBottom: spacing.size1,
    color: isDisabled
      ? colors.neutralColors.slate40
      : colors.neutralColors.slate100,
  });

const dropzoneHelpTextStyle = (isDisabled) =>
  css({
    fontSize: font.size.sizeM1,
    marginTop: spacing.size1,
    display: 'block',
    color: isDisabled
      ? colors.neutralColors.slate40
      : colors.neutralColors.slate90,
  });

const additionalFieldWrapperStyle = css({
  marginBottom: spacing.size6,
});

const Question = ({
  question,
  questionState,
  hasBackButton,
  saveAnswer,
  isSaving,
  isActive,
  highlightProofRequirement = false,
  uploadImageKeys = [],
  hideActionButton = false,
  uploadId,
}) => {
  const {
    questionId,
    subHeader,
    explanation,
    conditions,
    questionType,
    uploadHeadline,
    furtherDetails,
    commentsInTextBox,
  } = question;

  const { dispatch } = useContext(SurveyDispatchContext);

  const [infoModalVisibility, setInfoModalVisibility] = useState(false);
  const [deleteImageModal, setDeleteImageModal] = useState({
    isOpen: false,
    imageId: '',
  });
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    imageId: '',
  });

  // Set multi select question to valid
  // as soon as it becomes active as no validation is needed for that question
  // but it looks weird if indicator is blue before reaching that question
  useEffect(() => {
    if (
      questionType === questionTypes.NON_BINARY_MULTI_CHOICE &&
      !questionState.isComplete
    ) {
      if (isActive && !questionState.isValid) {
        dispatch(updateValidationAction(questionId));
      }
    }
  }, [
    isActive,
    questionType,
    questionState.isValid,
    questionState.isComplete,
    questionId,
    dispatch,
  ]);
  const shouldDisableImageUpload =
    !questionState.isValid || uploadImageKeys.length >= 5;

  const closeImageModal = () => {
    setImageModal(() => ({
      ...imageModal,
      isOpen: false,
    }));
  };

  const deleteImageHandler = async (imageKey) => {
    try {
      await Storage.remove(imageKey);
      setDeleteImageModal((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
      dispatch(deleteUploadedImage(imageKey));
    } catch (error) {
      generalApiErrorHandler(error);
    }
  };

  return (
    <div css={questionWrapperStyle}>
      {deleteImageModal.isOpen && (
        <DeleteImageModal
          imageId={deleteImageModal.imageId}
          deleteImageHandler={deleteImageHandler}
          closeDeleteImageModal={() =>
            setDeleteImageModal((prevState) => ({
              ...prevState,
              isOpen: false,
            }))
          }
        />
      )}
      {infoModalVisibility && (
        <InfoModal
          header={subHeader}
          body={furtherDetails}
          closeInfoModal={() => setInfoModalVisibility(!infoModalVisibility)}
        />
      )}
      {imageModal.isOpen && (
        <ImageModal
          imageId={imageModal.imageId}
          closeImageModal={closeImageModal}
        />
      )}
      <div css={questionContentWrapperStyle}>
        {isSaving && (
          <div css={loadingOverlayStyle}>
            <Spinner />
          </div>
        )}

        <div css={headerWrapperStyle}>
          <h2 css={headerStyle}>
            {subHeader}
            <div
              data-testid="questionInfoModalButton"
              onClick={() => setInfoModalVisibility(!infoModalVisibility)}
              css={infoButtonWrapperStyle}
            >
              <StatusInformationBold css={infoButtonStyle} />
            </div>
          </h2>
        </div>

        <QuestionDescription
          explanation={explanation}
          conditions={conditions}
        />

        {questionType === 'NON_BINARY_MULTI_CHOICE' ? (
          <MultiChoice question={question} questionState={questionState} />
        ) : (
          <SingleChoice question={question} questionState={questionState} />
        )}

        <section css={additionalFieldsWrapperStyle}>
          <div css={additionalFieldWrapperStyle}>
            <h3 css={additionalFieldsHeaderStyle(!questionState.isValid)}>
              Justification
            </h3>
            <p css={justificationHelpTextStyle(shouldDisableImageUpload)}>
              {commentsInTextBox}
            </p>
            <TextArea
              onChange={(event) =>
                dispatch(updateCommentAction(questionId, event.target.value))
              }
              value={questionState.comment}
              disabled={!questionState.isValid}
              isDisabled={!questionState.isValid}
              placeholder="Write here"
            />
          </div>
          <div css={additionalFieldWrapperStyle}>
            <p
              css={uploadSectionStyle(
                shouldDisableImageUpload,
                highlightProofRequirement
              )}
            >
              {uploadHeadline}
            </p>
            <ImageUpload
              questionId={questionId}
              uploadId={uploadId}
              isDisabled={shouldDisableImageUpload}
              onSuccess={(imageKey) => dispatch(addUploadedImage(imageKey))}
            />
            <span css={dropzoneHelpTextStyle(shouldDisableImageUpload)}>
              Max. 5 images
            </span>
            {!!uploadImageKeys.length && (
              <UploadedImageThumbnailList
                uploadImageKeys={uploadImageKeys}
                imageClickHandler={(imageId) =>
                  setImageModal((prevState) => ({
                    ...prevState,
                    imageId,
                    isOpen: true,
                  }))
                }
                imageDeleteClickHandler={(imageId) =>
                  setDeleteImageModal((prevState) => ({
                    ...prevState,
                    isOpen: true,
                    imageId,
                  }))
                }
              />
            )}
          </div>
        </section>
      </div>

      {!hideActionButton && (
        <div css={buttonWrapperStyle}>
          {hasBackButton && (
            <Button
              disabled={isSaving}
              isDisabled={isSaving}
              onClick={() => dispatch(stepBackAction())}
              label="Back"
              data-transaction-name="Previous question"
              variant="outline"
              style={{ marginRight: spacing.size4 }}
            />
          )}
          <Button
            label="Next"
            data-transaction-name="Save answer"
            disabled={!questionState.isValid || isSaving}
            isDisabled={!questionState.isValid || isSaving}
            onClick={() => saveAnswer(questionId)}
          />
        </div>
      )}
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
  questionState: PropTypes.object.isRequired,
  hasBackButton: PropTypes.bool.isRequired,
  saveAnswer: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  highlightProofRequirement: PropTypes.bool,
  hideActionButton: PropTypes.bool,
  uploadImageKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  uploadId: PropTypes.string.isRequired,
};

export { Question };
