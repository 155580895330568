import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const HardDrive = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M18.375 16.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm.208 1.437a.378.378 0 01-.281.056.376.376 0 11.281-.056z"
          fillRule="evenodd"
        />
        <path d="M5.25 17.25a.75.75 0 000 1.5H9a.75.75 0 000-1.5H5.25z" />
        <path
          clipRule="evenodd"
          d="M6.878.75h10.245a3.75 3.75 0 013.65 2.894L23.85 16.75a5.249 5.249 0 01-5.099 6.5H5.25a5.25 5.25 0 01-5.1-6.5L3.228 3.645A3.75 3.75 0 016.878.75zm15.523 16.394l-.01-.042a3.75 3.75 0 00-3.641-2.852H5.25a3.75 3.75 0 00-3.64 2.852l-.01.042a3.75 3.75 0 003.65 4.606h13.5a3.75 3.75 0 003.655-4.589l-.004-.017zM19.313 3.986l2.248 9.58a5.25 5.25 0 00-2.811-.816H5.25a5.25 5.25 0 00-2.811.816l2.248-9.58A2.25 2.25 0 016.878 2.25h10.245a2.25 2.25 0 012.19 1.736z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
HardDrive.displayName = 'HardDrive';
