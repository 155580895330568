import {
  INumberDecrementProps,
  NumberDecrement as NumberDecrementInternal,
} from './NumberDecrement';
import {
  INumberIncrementProps,
  NumberIncrement as NumberIncrementInternal,
} from './NumberIncrement';
import {
  INumberInputFieldProps,
  NumberInputField as NumberInputFieldInternal,
} from './NumberInputField';

export * from './NumberInput';
export { INumberDecrementProps, INumberIncrementProps, INumberInputFieldProps };

// TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `NumberInput.Decrement` instead
 */
export const NumberDecrement = NumberDecrementInternal;
/**
 * @deprecated use `NumberInput.Increment` instead
 */
export const NumberIncrement = NumberIncrementInternal;
/**
 * @deprecated use `NumberInput.Field` instead
 */
export const NumberInputField = NumberInputFieldInternal;
