/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Dict } from '../types';

const parts = ['container', 'label', 'closeButton'];

const baseStylePart = {
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'inherit',
  justifyContent: 'center',
  outline: 'none',
};

const disabledStyle = {
  _disabled: {
    opacity: '50%',
    pointerEvents: 'none',
  },
};

const baseStyleInteractive = {
  ...baseStylePart,
  _hover: {
    zIndex: '1',
  },
  _focusVisible: {
    boxShadow: `focusVisible`,
    zIndex: '2',
  },
};

const baseStyleContainer = {
  borderRadius: 'full',
  fontWeight: 'medium',
  '>*:first-child': {
    borderBottomLeftRadius: 'full',
    borderTopLeftRadius: 'full',
  },
  '>*:last-child': {
    borderBottomRightRadius: 'full',
    borderTopRightRadius: 'full',
  },
};

const baseStyleLabel = {
  ...baseStylePart,
  fontSize: 'inherit',
};

const baseStyleCloseButton = {
  ...baseStyleInteractive,
  transition: 'none',
};

const baseStyle = (props: Dict) => ({
  part: baseStylePart,
  container: baseStyleContainer,
  label: baseStyleLabel,
  closeButton: baseStyleCloseButton,
});

const sizes = {
  sm: {
    label: {
      fontSize: 'sm',
      height: '1.5rem',
      px: 2,
      ':not(:first-child)': {
        pl: '0.125rem',
      },
      ':not(:last-child)': {
        pr: '0.125rem',
      },
    },
    closeButton: {
      height: '1.5rem',
      paddingLeft: 0,
      paddingRight: 1,
      svg: {
        height: '1em',
        width: '1em',
      },
    },
  },
  md: {
    label: {
      fontSize: 'md',
      height: '2rem',
      px: '0.625rem',
      ':not(:first-child)': {
        pl: 1,
      },
      ':not(:last-child)': {
        pr: 1,
      },
    },
    closeButton: {
      height: '2rem',
      paddingLeft: '1px',
      paddingRight: '5px',
      svg: {
        height: '1.125em',
        width: '1.125em',
      },
    },
  },
  lg: {
    label: {
      fontSize: 'lg',
      height: '2.375rem',
      px: 3,
      ':not(:first-child)': {
        pl: '0.375rem',
      },
      ':not(:last-child)': {
        pr: '0.375rem',
      },
    },
    closeButton: {
      height: '2.375rem',
      paddingLeft: '1px',
      paddingRight: '5px',
      svg: {
        height: '1.375em',
        width: '1.375em',
      },
    },
  },
};

const variantPlain = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    label: {
      backgroundColor: `${c}.50`,
      ...disabledStyle,
    },
    closeButton: {
      backgroundColor: `${c}.50`,
      _hover: {
        backgroundColor: `${c}.100`,
      },
    },
    container: {
      color: mode(`${c}.700`, `${c}.700`)(props),
      button: {
        ...baseStyleInteractive,
        backgroundColor: `${c}.50`,
        _hover: {
          backgroundColor: `${c}.100`,
        },
        ...disabledStyle,
      },
    },
  };
};

const variantGhost = (props: Dict) => {
  const { colorScheme: c, theme } = props;

  return {
    label: {
      backgroundColor: 'transparent',
      ...disabledStyle,
    },
    closeButton: {
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'slate.5',
      },
    },
    container: {
      color: mode(`${c}.700`, `${c}.200`)(props),
      button: {
        ...baseStyleInteractive,
        backgroundColor: 'transparent',
        _hover: {
          backgroundColor: transparentize('slate.900', 0.05)(theme),
        },
        _focusVisible: {
          boxShadow: `focusVisible`,
          backgroundColor: transparentize('slate.900', 0.05)(theme),
        },
        ...disabledStyle,
      },
    },
  };
};

const variants = {
  plain: variantPlain,
  ghost: variantGhost,
};

const defaultProps = {
  size: 'md',
  variant: 'plain',
  colorScheme: 'light-blue',
};

export const Tag = {
  parts,
  variants,
  baseStyle,
  sizes,
  defaultProps,
};
