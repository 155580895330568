import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Folder = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M.659 2.159A2.25 2.25 0 012.25 1.5H7.5a2.25 2.25 0 011.8.9l1.575 2.1H21.75A2.25 2.25 0 0124 6.75v13.5a2.25 2.25 0 01-2.25 2.25H2.25A2.25 2.25 0 010 20.25V3.75c0-.597.237-1.169.659-1.591zM2.25 3a.75.75 0 00-.75.75v16.5a.75.75 0 00.75.75h19.5a.75.75 0 00.75-.75V6.75a.75.75 0 00-.75-.75H10.5a.75.75 0 01-.6-.3L8.1 3.3a.75.75 0 00-.6-.3H2.25z"
        fillRule="evenodd"
      />
    </g>
  );
});
Folder.displayName = 'Folder';
