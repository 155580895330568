import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ViewShowBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M12.436 4.502h-.315c-3.592-.066-7.594 2.36-10.545 5.854a2.54 2.54 0 00-.002 3.219c2.863 3.392 6.7 5.786 10.236 5.86h.31c3.597.066 7.577-2.368 10.525-5.858a2.54 2.54 0 00.002-3.218C19.79 6.98 15.935 4.586 12.436 4.502zM12.109 16c2.109 0 3.991-1.562 3.991-4.031 0-2.469-1.882-4.039-3.991-4.039S8.12 9.5 8.12 11.969C8.12 14.438 10 16 12.109 16z"
          fillRule="evenodd"
        />
        <path d="M14.6 12a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z" />
      </g>
    );
  }
);
ViewShowBold.displayName = 'ViewShowBold';
