import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const NewspaperBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v19z" />
        <path d="M18.5 4h-13c-.69 0-1.25.56-1.25 1.25v2.5C4.25 8.44 4.81 9 5.5 9h13c.69 0 1.25-.56 1.25-1.25v-2.5c0-.69-.56-1.25-1.25-1.25zm-8 7H5a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-.5a.75.75 0 00-.75-.75zm0 3.5H5a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-.5a.75.75 0 00-.75-.75zm-2 3.5H5a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75h3.5a.75.75 0 00.75-.75v-.5A.75.75 0 008.5 18zm8.5 0h-3.5a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75H17a.75.75 0 00.75-.75v-.5A.75.75 0 0017 18zm2-7h-5.5a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75H19a.75.75 0 00.75-.75v-.5A.75.75 0 0019 11zm0 3.5h-5.5a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75H19a.75.75 0 00.75-.75v-.5a.75.75 0 00-.75-.75z" />
      </g>
    );
  }
);
NewspaperBold.displayName = 'NewspaperBold';
