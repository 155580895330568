import { Grid as GridComponent, GridProps } from '@chakra-ui/layout';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import * as React from 'react';
import { GridItem } from './GridItem';

export type IGridProps = GridProps;

type IGridComponent = ComponentWithAs<'div', IGridProps> & {
  Item: typeof GridItem;
};

export const Grid = forwardRef<IGridProps, 'div'>((props, ref) => {
  return <GridComponent {...props} ref={ref} />;
}) as IGridComponent;

Grid.Item = GridItem;
