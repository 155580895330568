import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const RotateLeft = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M1 1.75a.75.75 0 011.5 0v4.714a10.997 10.997 0 119.497 16.53.75.75 0 010-1.5A9.497 9.497 0 103.47 7.832h5.11a.75.75 0 010 1.5h-6.2a.745.745 0 01-.077 0H1.75a.75.75 0 01-.75-.75V1.75z" />
      </g>
    );
  }
);
RotateLeft.displayName = 'RotateLeft';
