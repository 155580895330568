import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const VideoPlayBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M5 3.12v17.744a1.154 1.154 0 00.563.976 1.145 1.145 0 001.124.025l16.721-8.872a1.14 1.14 0 00.592-1 1.144 1.144 0 00-.591-1.002L6.657 2.12a1.145 1.145 0 00-1.501.452c-.097.167-.15.355-.156.549z" />
      </g>
    );
  }
);
VideoPlayBold.displayName = 'VideoPlayBold';
