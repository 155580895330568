import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const AddToList = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6.06 3.63h13.63a.75.75 0 100-1.5H6.06a.75.75 0 000 1.5zm0 5.62h13.63a.75.75 0 100-1.5H6.06a.75.75 0 000 1.5zm2.75 4.06H6.06a.75.75 0 100 1.5h2.75a.75.75 0 100-1.5zM2.69 3.82a.94.94 0 100-1.88.94.94 0 000 1.88zm0 5.62a.94.94 0 100-1.88.94.94 0 000 1.88zm0 5.56a.94.94 0 100-1.88.94.94 0 000 1.88zm14.19-4.23a6 6 0 100 12 6 6 0 000-12zm0 10.57a4.54 4.54 0 114.54-4.54 4.54 4.54 0 01-4.54 4.55v-.01z" />
        <path d="M18.86 16.06h-1.23v-1.33a.75.75 0 10-1.5 0v1.33h-1.29a.75.75 0 100 1.5h1.29v1.36a.75.75 0 101.5 0v-1.36h1.23a.75.75 0 100-1.5z" />
      </g>
    );
  }
);
AddToList.displayName = 'AddToList';
