import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesTiff = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#files-tiff_svg__a)">
          <path
            clipRule="evenodd"
            d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h.5a.75.75 0 000-1.5h-.5a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
            fillRule="evenodd"
          />
          <path d="M7.362 16.516h.748a.748.748 0 000-1.497H5.117a.748.748 0 100 1.497h.748v6.736a.748.748 0 101.497 0v-6.736zm3.052 7.249a.778.778 0 00.559.185.768.768 0 00.817-.688v-7.495a.828.828 0 00-1.645 0v7.474a.778.778 0 00.269.524zm6.813-8.706h-3.362a.74.74 0 00-.748.748v7.415a.749.749 0 101.496 0v-2.266h1.766a.748.748 0 000-1.497h-1.766v-2.904h2.614a.748.748 0 000-1.496zm2.663 0h3.361a.748.748 0 110 1.496h-2.613v2.904h1.766a.748.748 0 010 1.497h-1.766v2.266a.749.749 0 11-1.496 0v-7.415a.749.749 0 01.748-.748z" />
        </g>
        <defs>
          <clipPath id="files-tiff_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
FilesTiff.displayName = 'FilesTiff';
