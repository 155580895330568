import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const scoreWrapperStyle = css({
  textAlign: 'center',
});

const ScoreCell = ({ cell: { value } }) => (
  <div css={scoreWrapperStyle}>{value && <span>{`${value} %`}</span>}</div>
);

ScoreCell.propTypes = {
  cell: PropTypes.object.isRequired,
};

export { ScoreCell };
