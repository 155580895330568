import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Earth = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M12 1.5C7.243 1.5 3.226 4.663 1.935 9H6.33c1.021 0 1.937.718 2.178 1.71l.748 2.992c.373 1.403-.753 2.802-2.177 2.798h-.463l-.79 3.993A10.453 10.453 0 0012 22.5c3.508 0 6.615-1.72 8.522-4.364-.279 0-.555-.003-.786-.012-.76-.029-1.26-.387-1.55-.853-.202-.323-.303-.73-.343-.89l-.015-.057c-.068-.227-.215-.92-.38-1.69-.068-.322-.14-.658-.21-.979a46.566 46.566 0 00-.369-1.592h-1.05c-.929 0-1.575-.385-1.906-.99a2.052 2.052 0 01-.176-1.465c.066-.24.223-.776.395-1.367.267-.913.572-1.955.637-2.237l.009-.041c.03-.15.11-.536.366-.845.335-.404.84-.571 1.45-.571h2.802A10.467 10.467 0 0012 1.5zm8.647 4.542a.757.757 0 01-.084.005h-3.97a.872.872 0 00-.294.036l-.003.002a1.41 1.41 0 00-.044.163l-.021.092c-.072.313-.427 1.53-.708 2.49v.001c-.157.538-.29.996-.338 1.17a.527.527 0 00-.009.156.492.492 0 00.053.196c.024.045.06.086.126.122.07.038.21.088.465.088h1.57c.358 0 .558.236.59.276l.002.001c.062.073.1.145.118.18.04.08.072.167.095.235.05.144.102.334.154.54.107.42.233.977.355 1.542.085.387.167.772.241 1.121.15.706.27 1.27.32 1.437.017.057.034.12.047.17l.005.02c.015.06.03.111.045.162a.975.975 0 00.096.23.26.26 0 00.09.093c.03.018.1.05.247.056.437.016 1.03.01 1.5.003l.133-.001A10.456 10.456 0 0022.5 12a10.45 10.45 0 00-1.853-5.958zM1.5 12c0-.51.036-1.01.106-1.5h4.725c.332 0 .642.243.72.566l.751 3.006.004.013c.123.455-.266.917-.723.915H6a.75.75 0 00-.736.604l-.745 3.764A10.466 10.466 0 011.5 12zM0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z"
        fillRule="evenodd"
      />
    </g>
  );
});
Earth.displayName = 'Earth';
