import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const GraphBar = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M21.75 0a2.25 2.25 0 00-2.22 2.611l-2.74 2.143a2.25 2.25 0 00-2.871.69l-3.42-.915V4.5a2.25 2.25 0 10-4.462.406l-3.384 3.38a2.25 2.25 0 101.336.786L6.824 6.24a2.25 2.25 0 003.153-.299l3.527.943a2.25 2.25 0 104.311-1.03l2.45-1.915A2.25 2.25 0 1021.75 0zM2.542 9.81l.032.014a.75.75 0 11-.032-.014zM21.22 1.72a.75.75 0 111.06 1.06.75.75 0 01-1.06-1.06zM7.564 4.804l.006.014a.75.75 0 10-.006-.014zM15.75 6a.75.75 0 100 1.5.75.75 0 000-1.5z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9 22.5V10.25A1.25 1.25 0 0110.25 9H13a1.25 1.25 0 011.25 1.25V22.5h2.25v-7.75a1.25 1.25 0 011.248-1.25H20.5a1.25 1.25 0 011.25 1.25v7.75h.75a.75.75 0 110 1.499H.75a.75.75 0 010-1.5h.75v-3.25A1.25 1.25 0 012.75 18H5.5a1.25 1.25 0 011.25 1.25v3.25H9zm1.5 0v-12h2.25v12H10.5zm9.75 0H18V15h2.25v7.5zM3 19.5v3h2.25v-3H3z"
        fillRule="evenodd"
      />
    </g>
  );
});
GraphBar.displayName = 'GraphBar';
