import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesEps = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-eps_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h3.007a.75.75 0 000-1.5H3.75a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
          fillRule="evenodd"
        />
        <path d="M12.75 20.342a.75.75 0 000-1.499h-2.21v-2.069h2.21a.75.75 0 100-1.499h-3a.75.75 0 00-.75.75v7.146a.76.76 0 00.75.749h3a.75.75 0 100-1.499h-2.21v-2.079h2.21z" />
        <path
          clipRule="evenodd"
          d="M15.51 15.195a.74.74 0 00-.74.74v7.325a.74.74 0 001.47 0v-2.198a2.942 2.942 0 003.111-2.933 2.936 2.936 0 00-3.111-2.934h-.73zm.73 1.47v2.928a1.46 1.46 0 001-.43 1.458 1.458 0 000-2.069 1.46 1.46 0 00-1-.43z"
          fillRule="evenodd"
        />
        <path d="M21 17.404c0 .69.42 1 1.18 1.61A3.38 3.38 0 0124 21.8a2.208 2.208 0 01-2.2 2.2h-1.5a.74.74 0 110-1.48h1.48a.74.74 0 00.74-.739c0-.68-.42-.999-1.18-1.609a3.368 3.368 0 01-1.76-2.788 2.208 2.208 0 012.2-2.199h1.48a.74.74 0 110 1.48h-1.48a.742.742 0 00-.78.74z" />
      </g>
      <defs>
        <clipPath id="files-eps_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesEps.displayName = 'FilesEps';
