import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const AlertBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M19.985 10.491c0-4.032-2.906-7.387-6.728-8.103V1.5a1.5 1.5 0 00-3 0v.879c-3.839.703-6.763 4.073-6.763 8.112 0 5.88-1.337 7.783-1.337 7.783a.77.77 0 00-.086.789c.12.257.394.428.669.428h18c.343 0 .668-.188.754-.514.086-.308 0-.617-.274-.806-.137-.171-1.235-1.731-1.235-7.68zM9.006 21.78a.762.762 0 01.54-.729l4.405-.017c.394.12.634.532.514.943 0 0-.208 2.023-2.708 2.023S9.03 21.977 9.03 21.977a.77.77 0 01-.025-.196z" />
      </g>
    );
  }
);
AlertBold.displayName = 'AlertBold';
