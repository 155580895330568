import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LocationBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M18.08 2.413A8.25 8.25 0 004 8.243a11.08 11.08 0 00.87 3.61 43.988 43.988 0 002 4.32c1.48 2.93 3.22 5.82 4.13 7.15a1.5 1.5 0 002.5 0c.91-1.38 2.65-4.22 4.15-7.14a43.99 43.99 0 002-4.32 11.09 11.09 0 00.87-3.61 8.251 8.251 0 00-2.44-5.84zm-3.53 8.13a3.25 3.25 0 11-4.499-4.69 3.25 3.25 0 014.499 4.69z" />
      </g>
    );
  }
);
LocationBold.displayName = 'LocationBold';
