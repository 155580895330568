export const tokens = {
  colors: {
    slate: {
      '10': '#fcfcfc',
      '30': '#f8f8f8',
      '50': '#f1f1f1',
      '100': '#e3e3e1',
      '200': '#cfcfcc',
      '300': '#b2b2b0',
      '400': '#969694',
      '500': '#767674',
      '600': '#61615f',
      '700': '#4d4d4b',
      '800': '#3b3b39',
      '900': '#292928',
    },
    turquoise: {
      '10': '#fafdfd',
      '30': '#f2fbfc',
      '50': '#dff2f5',
      '100': '#c2ecf2',
      '200': '#8bdce8',
      '300': '#51c4d6',
      '400': '#1fa3b8',
      '500': '#068294',
      '600': '#016978',
      '700': '#01525e',
      '800': '#043f47',
      '900': '#062d33',
    },
    teal: {
      '10': '#fafcfc',
      '30': '#f5f9fa',
      '50': '#e9f2f5',
      '100': '#d3e7ed',
      '200': '#b1d3de',
      '300': '#8dbac9',
      '400': '#6198ab',
      '500': '#467b8c',
      '600': '#376878',
      '700': '#294f5c',
      '800': '#203c45',
      '900': '#152429',
    },
    'light-blue': {
      '10': '#f9fbfc',
      '30': '#f5f8fa',
      '50': '#e8f2fa',
      '100': '#cae5fa',
      '200': '#a6d3f7',
      '300': '#79bcf2',
      '400': '#4599de',
      '500': '#247bbf',
      '600': '#1766a6',
      '700': '#0f538a',
      '800': '#0c416b',
      '900': '#0a2c47',
    },
    'dark-blue': {
      '10': '#fafbfc',
      '30': '#f2f6fc',
      '50': '#e8f0fc',
      '100': '#d2e1fc',
      '200': '#b1ccfa',
      '300': '#8ab1f2',
      '400': '#5f91e8',
      '500': '#346ed1',
      '600': '#1951b2',
      '700': '#0c3987',
      '800': '#0b2f6e',
      '900': '#0b2552',
    },
    purple: {
      '10': '#fbfafc',
      '30': '#f8f5fc',
      '50': '#f3edfc',
      '100': '#e8dcfa',
      '200': '#d4bff5',
      '300': '#bfa2eb',
      '400': '#a27ed9',
      '500': '#8a62c4',
      '600': '#734cad',
      '700': '#5b3a8c',
      '800': '#452b6b',
      '900': '#2e2042',
    },
    magenta: {
      '10': '#fcfafb',
      '30': '#fcf5f8',
      '50': '#fcebf3',
      '100': '#fcd4e7',
      '200': '#fab6d6',
      '300': '#f294c0',
      '400': '#e567a2',
      '500': '#cf3e81',
      '600': '#b22567',
      '700': '#8c164d',
      '800': '#611338',
      '900': '#40162a',
    },
    copper: {
      '10': '#fffdfa',
      '30': '#fcf7f0',
      '50': '#faefe1',
      '100': '#fbdcb7',
      '200': '#f3c48b',
      '300': '#e3a65b',
      '400': '#cc8a37',
      '500': '#a3681f',
      '600': '#855315',
      '700': '#654113',
      '800': '#4a3214',
      '900': '#332514',
    },
    information: {
      '50': '#e6f5fc',
      '100': '#c5eafc',
      '400': '#179fe3',
      '500': '#007cba',
      '600': '#056899',
    },
    success: {
      '50': '#e8f7e6',
      '100': '#d3f7d0',
      '400': '#1cb20c',
      '500': '#108a06',
      '600': '#116e09',
    },
    warning: {
      '50': '#fcf2db',
      '100': '#fce9c0',
      '400': '#f2a705',
      '500': '#9e6c00',
      '600': '#805700',
    },
    error: {
      '50': '#fff2f2',
      '100': '#ffdede',
      '400': '#f55b5b',
      '500': '#db1f1f',
      '600': '#b20707',
    },
  },
};
