import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesVideo = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.85 6.59L15.97.71A2.18 2.18 0 0014.38 0H3.75a2.32 2.32 0 00-.87.17 2.36 2.36 0 00-.74.5 2.29 2.29 0 00-.48.75 2.22 2.22 0 00-.16.88v19.4a2.14 2.14 0 00.16.87c.107.28.27.535.48.75.207.219.46.39.74.5.275.12.57.181.87.18h16.5a2.25 2.25 0 002.25-2.25V8.18a2.24 2.24 0 00-.65-1.59zM15 1.82l5.69 5.73h-4.94a.75.75 0 01-.75-.73v-5zm6 19.84a.75.75 0 01-.75.75H3.75a.75.75 0 01-.75-.75V2.3a.75.75 0 01.75-.75h9.75v5.27a2.25 2.25 0 002.25 2.25H21v12.59z" />
        <path d="M9.74 17.82h-.06a1.48 1.48 0 01-1.21-.75 1.4 1.4 0 01-.18-.7v-5.05c0-.248.061-.492.18-.71.125-.213.3-.391.51-.52a1.34 1.34 0 01.7-.22 1.38 1.38 0 01.71.15l5.06 2.53a1.41 1.41 0 01.8 1.3 1.44 1.44 0 01-.8 1.3l-5.06 2.53a1.44 1.44 0 01-.65.14zm0-6.55l-.06 5.1h.06l5.06-2.52-5.06-2.58z" />
      </g>
    );
  }
);
FilesVideo.displayName = 'FilesVideo';
