import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MenuNavigationVerticalBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M1 6.5A1.5 1.5 0 012.5 5h19a1.5 1.5 0 010 3h-19A1.5 1.5 0 011 6.5zm0 6A1.5 1.5 0 012.5 11h19a1.5 1.5 0 010 3h-19A1.5 1.5 0 011 12.5zM2.5 17a1.5 1.5 0 000 3h19a1.5 1.5 0 000-3h-19z" />
      </g>
    );
  }
);
MenuNavigationVerticalBold.displayName = 'MenuNavigationVerticalBold';
