import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const AdvertisingMegaphoneBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.855 14.072l-2.163-3.709a.27.27 0 01-.035-.155l.334-4.327c.04-.51-.282-.975-.76-1.096l-4.048-1.028a.253.253 0 01-.13-.084l-2.7-3.306A.972.972 0 0013.07.152L9.51 2.405a.24.24 0 01-.15.037l-4.154-.348a1.03 1.03 0 00-1.052.791L3.071 7.517c-.13.56.198 1.123.735 1.26.537.136 1.078-.207 1.21-.766l.83-3.549a.251.251 0 01.263-.197l3.5.293c.21.016.42-.036.6-.149l3-1.895a.242.242 0 01.32.054l2.262 2.777c.137.166.32.284.524.336l3.407.866c.12.03.2.146.19.274l-.28 3.645a1.07 1.07 0 00.141.625l1.821 3.125a.27.27 0 01-.052.333l-2.67 2.358c-.16.142-.274.333-.324.546l-.83 3.548a.251.251 0 01-.264.198l-3.494-.293c-.551-.046-1.033.382-1.077.956-.044.573.366 1.075.917 1.121l4.555.381h.082c.46 0 .86-.328.97-.793l.988-4.218a.26.26 0 01.081-.136l3.173-2.812c.373-.332.46-.896.206-1.333z" />
        <path
          clipRule="evenodd"
          d="M.085 19.835a.538.538 0 01.031-.624l8.28-10.555a.268.268 0 00.015-.313l-.071-.11a.802.802 0 01.21-1.083.732.732 0 011.04.218l6.379 10.007a.802.802 0 01-.21 1.084.722.722 0 01-.414.13.744.744 0 01-.626-.35l-.078-.121a.244.244 0 00-.263-.104l-4.294 1.004a.25.25 0 00-.164.126.269.269 0 00-.018.211c.477 1.427-.142 3-1.444 3.667-1.301.667-2.876.217-3.669-1.048l-.6-.948a.244.244 0 00-.28-.104l-2.257.703a.49.49 0 01-.559-.208L.085 19.835zm5.961 1.273a1.34 1.34 0 001.906.401 1.466 1.466 0 00.496-1.764.248.248 0 00-.3-.145l-2.233.698a.256.256 0 00-.163.161.27.27 0 00.026.233l.268.416z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
AdvertisingMegaphoneBold.displayName = 'AdvertisingMegaphoneBold';
