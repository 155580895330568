import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { ArrowDown } from '@lego/klik-icons-react';
import { useClickOutsideElement } from '../hooks/useClickOutsideElement';
import { DropDown } from './dropdown/DropDown';
import { colors, cssVariables, spacing, font } from '../config';

const borderWidthHover = 2;

const selectWrapperStyle = (width) =>
  css({
    width: width || '100%',
    position: 'relative',
  });

const selectFieldStyle = (isOpen) => {
  const borderWidth = isOpen ? 2 : 1;
  return css({
    borderWidth: borderWidth,
    borderStyle: 'solid',
    borderColor: isOpen
      ? colors.utilityColors.information.base
      : colors.neutralColors.slate40,
    boxShadow: `0px 1px ${colors.shadows.boxShadow}`,
    borderRadius: cssVariables.borderRadius,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: `${spacing.size1 - borderWidth}px ${
      spacing.size3 - borderWidth
    }px`,
    minHeight: 30,
    '&:hover': {
      borderWidth: 2,
      borderColor: isOpen
        ? colors.utilityColors.information.base
        : colors.neutralColors.slate60,
      padding: `${spacing.size1 - borderWidthHover}px ${
        spacing.size3 - borderWidthHover
      }px`,
    },
  });
};

const selectedValueStyle = css({
  fontSize: font.size.sizeM1,
  flexGrow: 1,
  flexShrink: 1,
});

const iconStyle = css({
  width: 12,
  height: 12,
});

const dropDownWrapperStyle = (isOpen) =>
  css({
    display: isOpen ? 'block' : 'none',
    position: 'absolute',
    width: '100%',
    top: 32,
  });

const Select = ({ value = '', onSelect, children }) => {
  const selectFieldRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [value]);

  useClickOutsideElement([selectFieldRef, dropdownRef], () => {
    setIsOpen(false);
  });

  const handleDropDownButtonClick = (event) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  return (
    <div css={selectWrapperStyle}>
      <div
        aria-label="Select"
        ref={selectFieldRef}
        css={selectFieldStyle(isOpen)}
        onClick={(event) => handleDropDownButtonClick(event)}
      >
        <span css={selectedValueStyle}>{value}</span>
        <ArrowDown css={iconStyle} />
      </div>
      <div css={dropDownWrapperStyle(isOpen)}>
        <DropDown
          onSelectHandler={onSelect}
          selectedValue={value}
          ref={dropdownRef}
        >
          {children}
        </DropDown>
      </div>
    </div>
  );
};

Select.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func.isRequired,
};

export { Select };
