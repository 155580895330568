import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Language = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M6.893 10.354l-2.497 3.202v-2.745a.746.746 0 00-.746-.746H1.492V1.522h11.935v4.445a.745.745 0 001.422 0V.776A.756.756 0 0014.143 0H.746A.756.756 0 000 .776V10.81a.756.756 0 00.746.746h2.168v4.197a.746.746 0 00.508.706.67.67 0 00.238 0 .716.716 0 00.587-.288l3.8-4.973a.745.745 0 10-1.154-.845z" />
      <path d="M22.985 7.519H9.588a.756.756 0 00-.706.746V18.3a.756.756 0 00.746.746h6.335l3.56 4.655a.757.757 0 00.598.288c.079.015.16.015.238 0a.746.746 0 00.507-.706v-4.237h2.159a.756.756 0 00.746-.746V8.265a.756.756 0 00-.786-.746zm-.746 10.035h-2.158a.746.746 0 00-.746.746v2.745l-2.497-3.202a.748.748 0 00-.586-.289h-5.968V9.011H22.22l.02 8.543zM6.107 2.477a2.089 2.089 0 00-2.079 2.078V7.54a.746.746 0 101.492 0v-.507h1.184v.537a.746.746 0 001.492 0V4.585a2.089 2.089 0 00-2.089-2.108zM5.52 5.54v-.995a.577.577 0 01.587-.567.587.587 0 01.597.587v.995L5.52 5.54z" />
      <path d="M13.765 12.69h2.755a7.747 7.747 0 01-3.063 2.567.746.746 0 00.627 1.353 9.429 9.429 0 002.158-1.393 8.952 8.952 0 002.983 1.661.81.81 0 00.22 0 .756.756 0 00.715-.527.747.747 0 00-.497-.925 7.42 7.42 0 01-2.407-1.313 8.85 8.85 0 00.726-.995.796.796 0 00.1-.477h1.74a.746.746 0 000-1.492h-2.307v-.746a.746.746 0 00-1.492 0v.746h-2.258a.746.746 0 000 1.492v.05z" />
    </g>
  );
});
Language.displayName = 'Language';
