import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Brick = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M21.345 24H2.718A2.729 2.729 0 010 21.282V2.718A2.729 2.729 0 012.718 0h18.564A2.729 2.729 0 0124 2.718v18.627A2.655 2.655 0 0121.345 24zM2.718 1.58A1.148 1.148 0 001.58 2.718v18.564a1.149 1.149 0 001.138 1.138h18.627a1.053 1.053 0 001.054-1.054V2.718A1.149 1.149 0 0021.26 1.58H2.718z" />
      <path d="M6.943 10.483a3.382 3.382 0 110-6.765 3.382 3.382 0 010 6.765zm0-5.184a1.802 1.802 0 101.802 1.802 1.812 1.812 0 00-1.802-1.802zm9.935 5.184a3.382 3.382 0 110-6.764 3.382 3.382 0 010 6.764zm0-5.184a1.801 1.801 0 101.802 1.802 1.812 1.812 0 00-1.802-1.802zM7.122 20.28a3.382 3.382 0 110-6.763 3.382 3.382 0 010 6.764zm0-5.183a1.801 1.801 0 100 3.603 1.801 1.801 0 000-3.602zm9.935 5.184a3.382 3.382 0 11-.02-6.765 3.382 3.382 0 01.02 6.765zm0-5.183a1.802 1.802 0 101.791 1.801 1.812 1.812 0 00-1.79-1.802z" />
    </g>
  );
});
Brick.displayName = 'Brick';
