import { useHistory } from 'react-router-dom';

import { Navbar } from '@lego/klik-ui';
import { routes } from '../../RouteHandler';

const AdminHeaderMenu = () => {
  const history = useHistory();

  const navigateToAssessmentManagement = () => {
    history.push(routes.assessmentMgmt);
  };
  const isActive = window.location.pathname.includes(routes.assessmentMgmt);

  return (
    <Navbar.Item isActive={isActive} hasMenu={true} title="Admin">
      <Navbar.Option onClick={() => navigateToAssessmentManagement()}>
        Assessment Management
      </Navbar.Option>
    </Navbar.Item>
  );
};

export { AdminHeaderMenu };
