import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { spacing, colors, font } from '../config';

const scoreWrapperStyle = css({
  display: 'flex',
  alignItems: 'flex-end',
});

const scoreTextStyle = css({
  fontSize: font.size.sizeM1,
  marginRight: spacing.size4,
});

const scoreStyle = css({
  fontSize: font.size.size3,
  fontWeight: font.weight.bold,
  color: colors.boldColors.lightBlue.lightBlue80,
  backgroundColor: colors.boldColors.lightBlue.ligthBlue10,
  display: 'inline',
  borderRadius: 100,
  padding: `0 ${spacing.size4}px`,
});

const AssessmentScore = ({ scoreText, score }) => (
  <div css={scoreWrapperStyle}>
    {scoreText && <p css={scoreTextStyle}>{scoreText}</p>}
    <div css={scoreStyle}>{`${score}%`}</div>
  </div>
);

AssessmentScore.propTypes = {
  scoreText: PropTypes.string,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { AssessmentScore };
