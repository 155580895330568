/* eslint-disable react/jsx-no-bind */
import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import * as React from 'react';

interface IFileUploaderOptions {
  isInDropArea: boolean;
}

export interface IFileUploaderProps
  extends HTMLChakraProps<'div'>,
    IFileUploaderOptions,
    ThemingProps<'FileUploader'> {}

export const FileUploader = forwardRef<IFileUploaderProps, 'div'>((props, ref) => {
  const { ...rest } = omitThemingProps(props);

  const styles = useMultiStyleConfig('FileUploader', rest);

  return <chakra.div ref={ref} sx={styles} {...rest} />;
});
