import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BusinessDealBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M7.448 16.749c0 1.518-1.282 2.75-2.864 2.75-1.582 0-2.864-1.232-2.864-2.75 0-1.52 1.282-2.75 2.864-2.75 1.582 0 2.864 1.23 2.864 2.75zM.111 23.675a.242.242 0 00.04.222c.05.064.128.101.21.101h8.446c.082 0 .16-.038.209-.101a.242.242 0 00.04-.222c-.602-1.887-2.417-3.176-4.473-3.176-2.055 0-3.87 1.289-4.472 3.176zm22.169-6.926c0 1.518-1.282 2.75-2.864 2.75-1.582 0-2.864-1.232-2.864-2.75 0-1.52 1.282-2.75 2.864-2.75 1.582 0 2.864 1.23 2.864 2.75zm1.57 7.149a.241.241 0 00.039-.221c-.602-1.887-2.417-3.176-4.472-3.176-2.056 0-3.87 1.289-4.472 3.176a.242.242 0 00.04.222.263.263 0 00.209.101h8.446c.082 0 .16-.038.21-.102z" />
        <path
          clipRule="evenodd"
          d="M3.147 10.5v-9c0-.828.7-1.5 1.562-1.5h14.582c.863 0 1.563.672 1.563 1.5v9c0 .828-.7 1.499-1.563 1.499H15.45a.265.265 0 00-.185.07l-2.895 2.784a.532.532 0 01-.568.108.5.5 0 01-.322-.462v-2.25a.255.255 0 00-.26-.25H4.71c-.863 0-1.562-.671-1.562-1.5zm5.815-5.667c0 .973.81 1.768 1.822 1.791h2.432c.311 0 .564.243.564.542 0 .3-.253.542-.564.542H9.57c-.36 0-.65.28-.65.625s.29.625.65.625h1.519c.069 0 .135.026.184.073.049.047.076.11.076.177V9.5c0 .345.291.625.651.625.36 0 .651-.28.651-.625v-.29c0-.067.027-.13.076-.178a.265.265 0 01.184-.073h.305c1.03 0 1.866-.802 1.866-1.792s-.836-1.791-1.866-1.791h-2.431a.552.552 0 01-.54-.542c0-.29.237-.529.54-.541h3.645c.36 0 .651-.28.651-.625a.638.638 0 00-.651-.625h-1.519a.255.255 0 01-.26-.25V2.5A.638.638 0 0012 1.875c-.36 0-.65.28-.65.625v.292c0 .138-.117.25-.261.25h-.305c-1.013.023-1.822.818-1.822 1.791z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
BusinessDealBold.displayName = 'BusinessDealBold';
