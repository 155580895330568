import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';
import { memoizedGet as get } from '@chakra-ui/utils';
import { Dict } from '../types';

const parts = ['container', 'title', 'description', 'icon'];

const baseStyle = (props: Dict) => {
  const { isStandalone, theme } = props;

  return {
    container: {
      bg: 'white',
      borderRadius: isStandalone ? 'sm' : 'none',
      display: 'flex',
      minHeight: '3.5rem',
      justifyContent: 'flex-start',
      borderWidth: isStandalone ? '1px' : '0px',
      borderColor: 'slate.300',
    },
    content: {
      alignItems: 'flex-start',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      px: '3',
      py: '4',
    },
    title: {
      textStyle: 'h5',
      fontWeight: 'medium',
    },
    wrapper: {
      minHeight: isStandalone ? '3.5rem' : 'calc(3.5rem + 2px)',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    description: {
      textStyle: 'body2',
    },
    status: {
      marginTop: isStandalone ? '5' : `calc(${get(theme, 'space.5')} + 1px)`,
      alignSelf: 'flex-start',
      marginStart: '3',
    },
  };
};

const getBg = (props: Dict) => {
  const { theme, colorScheme: c } = props;
  const lightBg = getColor(theme, `${c}.50`, c);
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);

  return mode(lightBg, darkBg)(props);
};

const getStatus = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    status: {
      color: `${c}.400`,
      height: '100%',
    },
    wrapper: {
      borderStartWidth: '0.75rem',
      borderColor: `${c}.400`,
    },
    container: {
      borderColor: `${c}.400`,
      backgroundColor: getBg(props),
    },
  };
};

const variantPlain = {};

const variantSuccess = (props: Dict) => {
  return getStatus({ ...props, ...{ colorScheme: 'success' } });
};

const variantInfo = (props: Dict) => {
  return getStatus({ ...props, ...{ colorScheme: 'information' } });
};

const variantWarning = (props: Dict) => {
  return getStatus({ ...props, ...{ colorScheme: 'warning' } });
};

const variantError = (props: Dict) => {
  return getStatus({ ...props, ...{ colorScheme: 'error' } });
};

const variants = {
  plain: variantPlain,
  success: variantSuccess,
  info: variantInfo,
  warning: variantWarning,
  error: variantError,
};

const defaultProps = {
  variant: 'info',
};

export const InlineNotification = {
  parts,
  baseStyle,
  variants,
  defaultProps,
};
