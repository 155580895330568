import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesMovBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M12.16 11.54c-.56 0-1.2 1.08-1.2 2.53 0 1.45.64 2.53 1.2 2.53.56 0 1.2-1.08 1.2-2.53 0-1.45-.63-2.53-1.2-2.53z" />
        <path d="M21.69 5.71L16.27.29a1 1 0 00-.7-.29H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.31-.7zM9.26 17.25a.65.65 0 01-.65.65.66.66 0 01-.61-.65V12.9l-.8 1.19a.64.64 0 01-.54.28.659.659 0 01-.54-.28l-.84-1.22v4.38a.65.65 0 01-.65.65.66.66 0 01-.66-.65v-6.46a.65.65 0 01.46-.62.64.64 0 01.73.25l1.44 2.15 1.47-2.15a.64.64 0 01.73-.25.65.65 0 01.46.62v6.46zm2.9.65c-1.36 0-2.43-1.68-2.43-3.83 0-2.15 1.07-3.83 2.43-3.83s2.44 1.68 2.44 3.83c0 2.15-1.07 3.83-2.44 3.83zm5.9-.33a.7.7 0 01-.81.166.65.65 0 01-.23-.186c-1.92-2.44-1.94-6.54-1.94-6.71a.63.63 0 01.65-.6.64.64 0 01.66.6c.023 1.78.41 3.536 1.14 5.16a11 11 0 001.19-5.17.64.64 0 01.66-.6.63.63 0 01.65.6 11 11 0 01-1.97 6.74z" />
      </g>
    );
  }
);
FilesMovBold.displayName = 'FilesMovBold';
