import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CodeBold = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M23.19 2.039a2.692 2.692 0 00-1.9-.789H2.66a2.693 2.693 0 00-2.472 1.7c-.13.33-.194.682-.188 1.036v16.078a2.684 2.684 0 002.68 2.686h18.61a2.692 2.692 0 002.503-1.654A2.67 2.67 0 0024 20.064V3.986a2.683 2.683 0 00-.81-1.947zm-21 1.418a.68.68 0 01.5-.21h18.62a.691.691 0 01.69.74v2.885H2V3.876a.689.689 0 01.18-.42h.01zm5.98 12.512a1 1 0 11-1.3 1.478L4.52 15.4a1.995 1.995 0 01.09-2.716l2-1.997a1.001 1.001 0 011.613.742.998.998 0 01-.223.676l-2 1.997 2.17 1.867zm1.62.6l2.72-5.503a1.003 1.003 0 011.79.909l-2.72 5.502a1 1 0 01-1.79-.878v-.03zm6.3-4.344a.998.998 0 01.701-1.708 1.001 1.001 0 01.709.29l2 1.997a1.997 1.997 0 01.12 2.756l-.09.09-2.26 1.997a1.003 1.003 0 01-1.665-.809 1 1 0 01.335-.689l2.17-1.917-2.02-2.007z" />
    </g>
  );
});
CodeBold.displayName = 'CodeBold';
