import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const RotateRightBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M24 2a1.5 1.5 0 00-3 0v2.414A11.75 11.75 0 007.576 1.47a11.747 11.747 0 004.678 22.525 1.5 1.5 0 00-.002-3 8.747 8.747 0 117.23-13.664h-3.813a1.5 1.5 0 000 3h6.179c.04.002.08.002.12 0h.532a1.5 1.5 0 001.5-1.5V2z" />
      </g>
    );
  }
);
RotateRightBold.displayName = 'RotateRightBold';
