import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudDownloadBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M24 10.606a6.184 6.184 0 00-1.772-4.336 5.613 5.613 0 00-3.446-1.651.248.248 0 01-.191-.125C16.974 1.678 13.87.175 10.763.702c-3.109.527-5.594 2.977-6.27 6.181a.255.255 0 01-.227.209C1.868 7.27.008 9.347.004 11.852a4.608 4.608 0 001.236 3.342 5.404 5.404 0 003.604 1.435.981.981 0 00.703-.317c.184-.198.286-.464.282-.74-.02-.57-.468-1.022-1.015-1.026a3.223 3.223 0 01-2.172-.834 2.535 2.535 0 01-.639-1.86 2.727 2.727 0 01.954-2.091 2.522 2.522 0 012.167-.549.97.97 0 00.81-.193c.23-.184.372-.463.39-.764.153-2.703 2.046-4.953 4.598-5.466 2.552-.513 5.113.842 6.22 3.291a.955.955 0 00.94.594 3.605 3.605 0 012.758 1.092 3.988 3.988 0 011.16 2.84c-.012 2.036-1.493 3.737-3.437 3.948-.525.07-.907.552-.872 1.102.035.55.475.976 1.004.973a.912.912 0 00.13-.01c2.946-.336 5.176-2.927 5.175-6.013z" />
        <path d="M15.501 17.67h-1.75a.255.255 0 01-.25-.26V9.859c0-.863-.67-1.562-1.5-1.562-.827 0-1.499.699-1.499 1.562v7.555c0 .144-.112.26-.25.26h-1.75a1 1 0 00-.923.643c-.155.39-.07.837.217 1.135l3.499 3.645a.972.972 0 001.414 0l3.5-3.645c.285-.298.37-.746.216-1.135a1 1 0 00-.924-.643v-.004z" />
      </g>
    );
  }
);
CloudDownloadBold.displayName = 'CloudDownloadBold';
