import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesPngBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#files-png-bold_svg__a)">
          <path d="M6 11.31v2.47a1.229 1.229 0 00.87-.36 1.22 1.22 0 000-1.75 1.229 1.229 0 00-.87-.36z" />
          <path d="M21.68 5.71L16.26.29a1 1 0 00-.7-.29H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.32-.7zM7.77 14.29A2.46 2.46 0 016 15v1.85a.61.61 0 01-.62.61.612.612 0 01-.62-.61v-6.16a.61.61 0 01.62-.61H6a2.49 2.49 0 012.29 1.52 2.45 2.45 0 01-.54 2.69h.02zm6.15 2.57a.63.63 0 01-.48.61.41.41 0 01-.16 0 .65.65 0 01-.55-.32l-2.25-4v3.73a.63.63 0 01-1.26 0V10.7a.62.62 0 01.47-.61.64.64 0 01.71.3l2.25 4V10.7a.64.64 0 011.27 0v6.16zm5.24-.67a1.21 1.21 0 01-.93 1.18 3.673 3.673 0 01-.91.12A2.6 2.6 0 0115.7 17a3.52 3.52 0 01-1.06-3 4 4 0 011.3-3.34 3.2 3.2 0 012.58-.53.63.63 0 01.46.76.63.63 0 01-.76.46 2 2 0 00-1.51.31A2.91 2.91 0 0015.9 14a2.55 2.55 0 00.59 2 1.77 1.77 0 001.45.16V14.7h-.69a.63.63 0 010-1.26h.73a1.22 1.22 0 011.22 1.22l-.04 1.53z" />
        </g>
        <defs>
          <clipPath id="files-png-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
FilesPngBold.displayName = 'FilesPngBold';
