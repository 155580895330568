import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CheckTick = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M22.03 4.97a.75.75 0 010 1.06l-13 13a.75.75 0 01-1.06 0l-6-6a.75.75 0 111.06-1.06l5.47 5.47L20.97 4.97a.75.75 0 011.06 0z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
CheckTick.displayName = 'CheckTick';
