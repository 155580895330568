import { chakra, HTMLChakraProps, useStyles } from '@chakra-ui/system';
import { Collapse } from '@chakra-ui/transition';
import * as React from 'react';
import { useStep, useStepperContext } from './StepperProvider';

export type IStepPanelProps = HTMLChakraProps<'div'> & {
  index?: number;
};

export const StepPanel: React.FunctionComponent<IStepPanelProps> = ({
  index,
  children,
  ...props
}) => {
  const styles = useStyles();
  const { activeStep } = useStepperContext();
  const { getPanelProps } = useStep({ isOpen: index === activeStep });

  const isOpen = index === activeStep;

  return (
    <Collapse in={isOpen}>
      <chakra.div __css={styles.panel} {...{ ...getPanelProps(), ...props }}>
        {children}
      </chakra.div>
    </Collapse>
  );
};
