import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const SavingMoneyFlower = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M23.358 13.668a8 8 0 00-7.2-.11l-.1.06-.15.11c-1.68 1.24-2.18 3-.85 4.49a18.232 18.232 0 00-2.33 2.32l-.11.13v-7.21a6.75 6.75 0 10-1.5 0v7.21a18.004 18.004 0 00-2.42-2.45c1.37-1.49.8-3.36-1-4.6l-.1-.06a8 8 0 00-7.2.11.75.75 0 00-.17 1.2c.261.252.496.53.7.83.295.447.543.922.74 1.42l.09.21a6.84 6.84 0 001.29 2.17l.13.14c1.43 1.43 3.3 1.44 4.44-.38.905.74 1.73 1.575 2.46 2.49.332.415.63.857.89 1.32l.06.1.09.17.07.17 1.017-.04h.06l.09-.2.1-.2c.26-.463.573-.905.905-1.32.73-.915 1.554-1.75 2.46-2.49 1.16 2 3.348 1.798 4.828.178.75-.938.75-.938 1.25-1.858l.29-.66a6.89 6.89 0 01.66-1.24 5.19 5.19 0 01.69-.82.75.75 0 00-.18-1.19zm-15.85 3.61a26.92 26.92 0 00-1.54-1.05.753.753 0 00-.81 1.27c.44.28.856.563 1.25.85-.61 1.1-1.43 1.06-2.27.15a5.56 5.56 0 01-1-1.75l-.1-.23a8.85 8.85 0 00-.86-1.65l-.18-.25h.14a6.47 6.47 0 014.57.22l.2.09h-.01c1.08.72 1.38 1.57.61 2.35zm4.39-5.28a5.25 5.25 0 110-10.5 5.25 5.25 0 010 10.5zm9.72 2.85c-.31.455-.571.941-.78 1.45l-.19.44a5.562 5.562 0 01-1 1.76c-.84.92-1.66.95-2.27-.15.393-.287.81-.57 1.25-.85a.753.753 0 00-.81-1.27 26.91 26.91 0 00-1.54 1.05c-.75-.76-.49-1.58.53-2.34l.08-.06a6.47 6.47 0 014.78-.32h.13l-.18.29z" />
        <path d="M13.188 6.468l-2.06-.83h-.08a.59.59 0 01.3-1.1h2.14a.771.771 0 10-.11-1.54h-.75v-.1a.75.75 0 00-1.49.1 2.09 2.09 0 00-.56 4l2.06.83h.08a.59.59 0 01-.3 1.1h-2.14a.75.75 0 00.1 1.49h.75v.1a.75.75 0 001.49-.1 2.09 2.09 0 00.56-4l.01.05z" />
      </g>
    );
  }
);
SavingMoneyFlower.displayName = 'SavingMoneyFlower';
