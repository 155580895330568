import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DownloadBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.77 16.79c-.675 0-1.223.527-1.223 1.177v1.8c-.001.842-.711 1.524-1.587 1.524H4.035c-.876 0-1.586-.682-1.587-1.524v-1.8c0-.65-.548-1.176-1.224-1.176-.676 0-1.224.526-1.224 1.176v1.8c0 2.143 1.806 3.88 4.035 3.883H19.96c2.231 0 4.04-1.738 4.04-3.883v-1.8c0-.313-.13-.613-.36-.834a1.25 1.25 0 00-.87-.342z" />
        <path d="M11.998.35c-1.082 0-1.959.843-1.959 1.882v8.8a.248.248 0 01-.252.242H7.1a.983.983 0 00-.89.552c-.16.335-.1.729.15 1.007l4.897 5.412a.996.996 0 00.74.325.996.996 0 00.739-.325l4.896-5.412a.913.913 0 00.152-1.008.983.983 0 00-.891-.55h-2.686a.248.248 0 01-.252-.243v-8.8c0-1.04-.877-1.882-1.958-1.882z" />
      </g>
    );
  }
);
DownloadBold.displayName = 'DownloadBold';
