import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BusinessDeal = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M13.66 7.55H10.4a.752.752 0 00.1 1.5H12v.85a.75.75 0 001.49-.1v-.75h.32a2.09 2.09 0 00.61-3.999l-2.71-.84h-.07a.6.6 0 01-.24-.61.59.59 0 01.6-.55h2.73a.75.75 0 00-.1-1.49H13.5v-.86a.751.751 0 10-1.5.1v.75h-.17a2.09 2.09 0 00-.61 4l2.71.84H14a.6.6 0 01.24.61.59.59 0 01-.58.55zM6.33 20.589a3.37 3.37 0 10-3.409 0 5.209 5.209 0 00-2.82 2.34.75.75 0 001.3.74 3.71 3.71 0 016.45 0 .75.75 0 001.3-.74 5.209 5.209 0 00-2.82-2.34zm-1.709-1a1.88 1.88 0 111.88-1.92 1.88 1.88 0 01-1.87 1.88l-.01.04zm19.277 3.339a5.21 5.21 0 00-2.82-2.34 3.38 3.38 0 10-3.409 0 5.21 5.21 0 00-2.82 2.34.75.75 0 001.3.74 3.71 3.71 0 016.45 0 .75.75 0 001.3-.74zM19.37 19.55a1.88 1.88 0 110-3.76 1.88 1.88 0 010 3.76z" />
        <path d="M20.399 12.8a2.25 2.25 0 002.1-2.24V2.151a2.25 2.25 0 00-2.25-2.1h-2.35a.752.752 0 00.1 1.5h2.35a.75.75 0 01.65.74v8.36a.75.75 0 01-.74.65h-4.63a.75.75 0 00-.41.21L12 14.74v-2.79a.75.75 0 00-.74-.65H5.151a.75.75 0 01-.65-.74V2.201a.75.75 0 01.74-.65h3.1a.751.751 0 10-.09-1.5H5.1A2.25 2.25 0 003 2.301v8.4A2.25 2.25 0 005.24 12.8h5.26v3.85a.75.75 0 001.27.43l4.279-4.28h4.349z" />
      </g>
    );
  }
);
BusinessDeal.displayName = 'BusinessDeal';
