import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const EarthBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#earth-bold_svg__a)">
          <path d="M12 0a12 12 0 100 24 12 12 0 000-24zm-1.971 20.349a.446.446 0 01-.343-.429v-3.171a4.029 4.029 0 00-4.012-4.029H3.857a.428.428 0 01-.428-.411V12a8.571 8.571 0 0113.234-7.183.412.412 0 01.188.48.428.428 0 01-.411.309h-2.074a4.054 4.054 0 000 8.108.719.719 0 01.737.72l.5 2.666s.115.923.506 1.267c.392.344 1.485.222 1.485.36 0 .137-.04.203-.04.203-.171.148-.843.533-1.773.906-1.129.453-2.226.744-3.781.735a9.253 9.253 0 01-1.971-.222z" />
        </g>
        <defs>
          <clipPath id="earth-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
EarthBold.displayName = 'EarthBold';
