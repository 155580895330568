import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Image = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M9.06 11.18a2.52 2.52 0 01-2.33-1.57c-.13-.3-.2-.63-.19-.97 0-.67.27-1.31.74-1.78.48-.45 1.12-.71 1.78-.71.66 0 1.3.25 1.78.71a2.52 2.52 0 01.55 2.74c-.13.3-.31.58-.55.82-.47.48-1.11.75-1.78.76zm0-3.54a.99.99 0 00-1 1 1.046 1.046 0 00.3.72c.19.18.45.28.72.28.27 0 .53-.1.72-.28.1-.09.17-.21.23-.33.05-.12.08-.26.08-.39 0-.14-.03-.27-.08-.39-.06-.12-.13-.23-.23-.33s-.21-.17-.34-.22c-.13-.04-.26-.06-.4-.06zm8.28 10.18a.73.73 0 01-.35-.09.78.78 0 01-.27-.24l-3.09-4.63-2.19 3c-.06.08-.13.16-.22.21-.09.05-.19.09-.29.1-.1.02-.2.01-.3-.01a.698.698 0 01-.27-.14l-1.31-1-1.73 2.5c-.12.17-.29.28-.49.31-.2.03-.41-.01-.57-.13a.726.726 0 01-.31-.49c-.03-.2.01-.4.13-.57l1.68-2.48c.12-.18.28-.33.46-.44.18-.12.39-.2.6-.23.21-.04.42-.04.63 0 .21.06.4.15.57.29l.68.55 1.66-2.37c.14-.21.34-.37.56-.49.22-.12.47-.17.72-.17s.5.07.72.19c.22.12.41.29.55.5l3.06 4.65c.1.16.13.35.1.54a.75.75 0 01-.3.46c-.13.1-.28.16-.43.18z" />
      <path d="M22.2 1.5c.17 0 .3.14.3.3v20.4a.3.3 0 01-.3.3H1.8a.3.3 0 01-.3-.3V1.8c0-.17.14-.3.3-.3h20.4zm0-1.5H1.8C.81 0 0 .81 0 1.8v20.4c0 1 .81 1.8 1.8 1.8h20.4c1 0 1.8-.81 1.8-1.8V1.8c0-.99-.81-1.8-1.8-1.8z" />
    </g>
  );
});
Image.displayName = 'Image';
