import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Time = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M12 7.5a.75.75 0 01.75.75v3.44l4.467 4.468a.75.75 0 01-1.06 1.06L11.47 12.53a.748.748 0 01-.22-.53V8.25A.75.75 0 0112 7.5z" />
      <path
        clipRule="evenodd"
        d="M4.045 4.045a11.25 11.25 0 1115.91 15.91 11.25 11.25 0 01-15.91-15.91zM12 2.25a9.75 9.75 0 100 19.5 9.75 9.75 0 000-19.5z"
        fillRule="evenodd"
      />
    </g>
  );
});
Time.displayName = 'Time';
