import React, { useEffect, useState, useContext } from 'react';
import { loadSpace } from '@usersnap/browser';
import PropTypes from 'prop-types';

const USERSNAP_GLOBAL_API_KEY = process.env.USERSNAP_GLOBAL_API_KEY ?? '';
const USERSNAP_PROJECT_API_KEY = process.env.USERSNAP_PROJECT_API_KEY ?? '';

export const UserSnapContext = React.createContext(null);

export const UsersnapProvider = ({ initParams, children }) => {
  const [usersnapApi, setUsersnapApi] = useState(null);

  useEffect(() => {
    loadSpace(USERSNAP_GLOBAL_API_KEY).then((api) => {
      api.init(initParams);
      api.show(USERSNAP_PROJECT_API_KEY);
      setUsersnapApi(api);
    });
  }, [initParams]);

  return (
    <UserSnapContext.Provider value={usersnapApi}>
      {children}
    </UserSnapContext.Provider>
  );
};

export function useUsersnapApi() {
  return useContext(UserSnapContext);
}

UsersnapProvider.propTypes = {
  initParams: PropTypes.object,
  children: PropTypes.node,
};
