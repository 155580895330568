import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesZip = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <g clipPath="url(#files-zip_svg__a)">
        <path
          clipRule="evenodd"
          d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h3a.75.75 0 000-1.5h-3a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81zm5.228 13.209a.766.766 0 00-.755.75v7.48a.75.75 0 00.755.75.756.756 0 00.754-.75v-2.223c.8 0 1.568-.316 2.134-.88a2.997 2.997 0 000-4.248 3.025 3.025 0 00-2.134-.88h-.754zm.754 1.512v2.993a1.532 1.532 0 001.395-.924 1.477 1.477 0 00-.329-1.629 1.532 1.532 0 00-1.066-.44z"
          fillRule="evenodd"
        />
        <path d="M17.26 23.96a.787.787 0 01-.835-.711v-7.51a.84.84 0 01.835-.711.847.847 0 01.835.712v7.509a.78.78 0 01-.835.71zm-7.956-.101c.11.065.235.1.362.1h4.547a.726.726 0 00.724-.72.72.72 0 00-.724-.721h-3.25l3.873-6.368a.71.71 0 000-.72.723.723 0 00-.633-.371H9.666a.726.726 0 00-.724.72.72.72 0 00.724.721h3.25l-3.863 6.368a.719.719 0 00.251.99z" />
      </g>
      <defs>
        <clipPath id="files-zip_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </g>
  );
});
FilesZip.displayName = 'FilesZip';
