import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const PieChartBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M10.5 24c5.796-.007 10.493-4.704 10.5-10.5a.5.5 0 00-.5-.5h-9a.5.5 0 01-.5-.5v-9a.5.5 0 00-.5-.5C4.701 3 0 7.701 0 13.5S4.701 24 10.5 24z" />
        <path d="M13.5 0a.5.5 0 00-.5.5V10a1 1 0 001 1h9.5a.5.5 0 00.5-.5C23.993 4.704 19.296.007 13.5 0z" />
      </g>
    );
  }
);
PieChartBold.displayName = 'PieChartBold';
