import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Analytics = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M.75 0a.75.75 0 01.75.75v9.175L6.045 7.2a2.25 2.25 0 114.448-.273l2.874 1.277.042-.044a2.25 2.25 0 012.33-.534l3.837-4.796a2.25 2.25 0 11.9 1.276l-3.565 4.457a2.25 2.25 0 11-4.154 1.011L9.884 8.297a2.25 2.25 0 01-3.067.188L1.5 11.675v7.361l3.017-1.508A2.249 2.249 0 018.871 16.5h3.258a2.25 2.25 0 013.61-.936l3.761-2.09a2.25 2.25 0 11.5 1.44l-3.531 1.96a2.253 2.253 0 01-.628 1.967A2.25 2.25 0 0112.129 18H8.87a2.25 2.25 0 01-3.683.87L1.5 20.712V22.5h21.75a.75.75 0 010 1.5H.75a.75.75 0 01-.75-.75V.75A.75.75 0 01.75 0zM7.5 17.25a.75.75 0 10-1.5 0 .75.75 0 001.5 0zM21.082 2.59l.016.031a.748.748 0 001.182.16.75.75 0 10-1.198-.19zM7.607 7.137l.004.006a.75.75 0 10-.007-.012l.003.006zM13.72 17.78a.75.75 0 111.06-1.06.75.75 0 01-1.06 1.06zm7.308-4.076a.75.75 0 10-.005-.02l.004.013.001.007zM14.47 9.22a.75.75 0 111.06 1.06.75.75 0 01-1.06-1.06z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
Analytics.displayName = 'Analytics';
