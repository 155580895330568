import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Save = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M7.5 15.75a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75zM8.25 18a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zm7.5-15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75z" />
      <path
        clipRule="evenodd"
        d="M2.25 24A2.25 2.25 0 010 21.75V7.243a3.75 3.75 0 011.099-2.651L4.59 1.099A3.75 3.75 0 017.243 0H21.75A2.25 2.25 0 0124 2.25v19.5A2.25 2.25 0 0121.75 24H2.25zM6.382 1.671a2.25 2.25 0 01.86-.171H7.5v5.25A2.25 2.25 0 009.75 9h7.5a2.25 2.25 0 002.25-2.25V1.5h2.25a.75.75 0 01.75.75v19.5a.75.75 0 01-.75.75H19.5v-8.25A2.25 2.25 0 0017.25 12H6.75a2.25 2.25 0 00-2.25 2.25v8.25H2.25a.75.75 0 01-.75-.75V7.243c0-.597.237-1.169.66-1.59l3.49-3.495a2.25 2.25 0 01.73-.488zM6 22.5h12v-8.25a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v8.25zm12-21v5.25a.75.75 0 01-.75.75h-7.5A.75.75 0 019 6.75V1.5h9z"
        fillRule="evenodd"
      />
    </g>
  );
});
Save.displayName = 'Save';
