import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FactoryBuilding = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M0 14.5a.75.75 0 01.75-.75h10.5a.75.75 0 01.75.75v8.75a.75.75 0 01-.75.75H.75a.75.75 0 01-.75-.75V14.5zm1.5.75v7.25h9v-7.25h-9z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6 10a4.5 4.5 0 00-4.5 4.5.75.75 0 01-1.5 0 6 6 0 1112 0 .75.75 0 01-1.5 0A4.5 4.5 0 006 10zm4.5 7.25a.75.75 0 01.75-.75h12a.75.75 0 01.75.75v6a.75.75 0 01-.75.75h-12a.75.75 0 01-.75-.75v-6zM12 18v4.5h10.5V18H12zM14.5.727A.75.75 0 0115.25 0h5a.75.75 0 01.75.727l.5 15.773h-7.484L14.5.727zm1.478.773l-.455 15h4.454l-.455-15h-3.544z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M13.5 20.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.5 0a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm-2.075-17h3.65l.137 4.5h-3.924l.137-4.5zm-.046 1.5l-.045 1.5h3.832l-.045-1.5H15.88z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
FactoryBuilding.displayName = 'FactoryBuilding';
