import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesDocumentBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M21.71 5.7L16.297.29a1 1 0 00-.7-.29H4a2.002 2.002 0 00-2 2v20a2 2 0 002.001 2h15.998A2.001 2.001 0 0022 22V6.41a1 1 0 00-.29-.71zm-.7 1.86h-5.783a.75.75 0 01-.75-.75V1h-2.002v5.81c0 .597.338 1.568.76 1.99.423.422 1.395.76 1.992.76h5.782v-2z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
FilesDocumentBold.displayName = 'FilesDocumentBold';
