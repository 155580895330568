import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Download = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M12 3a.75.75 0 01.75.75v10.19l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.748.748 0 01-1.058.003l-.002-.003-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3.75A.75.75 0 0112 3z" />
      <path d="M1.5 15.75a.75.75 0 00-1.5 0v1.5A3.75 3.75 0 003.75 21h16.5A3.75 3.75 0 0024 17.25v-1.5a.75.75 0 00-1.5 0v1.5a2.25 2.25 0 01-2.25 2.25H3.75a2.25 2.25 0 01-2.25-2.25v-1.5z" />
    </g>
  );
});
Download.displayName = 'Download';
