import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Book = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M14.262 11.668a.758.758 0 00.678.428.727.727 0 00.33-.08c1.7-.733 3.53-1.116 5.381-1.126a.758.758 0 000-1.505 15.15 15.15 0 00-6.04 1.276.758.758 0 00-.349 1.007zm6.389 1.953c-2.08 0-4.137.435-6.04 1.276a.758.758 0 00-.349.996.746.746 0 00.678.419.717.717 0 00.33-.07 13.953 13.953 0 015.381-1.126.758.758 0 000-1.505v.01zm-17.471-3.1c1.848.011 3.675.394 5.372 1.127a.758.758 0 00.658-1.365 15.129 15.129 0 00-6.03-1.277.757.757 0 000 1.515zm6.03 3.997a15.129 15.129 0 00-6.03-1.276.758.758 0 000 1.515c1.847.01 3.673.39 5.372 1.117.102.05.214.076.328.08a.757.757 0 00.33-1.436z" />
      <path d="M23.113 1.581c-1.186-.14-6.957-.397-11.153 2.085C7.774 1.184 1.993 1.442.827 1.58A.997.997 0 000 2.578v16.306a.997.997 0 00.997.996c3.475.07 6.894.885 10.026 2.392.31.152.652.23.997.23.315-.004.625-.079.907-.22a24.199 24.199 0 0110.076-2.422.997.997 0 00.997-.997V2.548a.997.997 0 00-.887-.967zM1.505 18.385V3.006c1.694-.209 6.359-.028 9.718 1.985v15.706a25.585 25.585 0 00-9.718-2.312zm20.93 0c-3.35.13-6.64.915-9.687 2.312V4.932c3.358-1.994 7.973-2.165 9.687-1.965v15.418z" />
    </g>
  );
});
Book.displayName = 'Book';
