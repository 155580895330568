import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const BluetoothBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M18.737 8.21a2.172 2.172 0 00.063-3.157L14.265.57a1.688 1.688 0 00-1.858-.462 1.68 1.68 0 00-.975 1.64v6.768a.25.25 0 01-.42.184L6.69 4.733a1.256 1.256 0 00-1.772.072c-.47.508-.437 1.3.072 1.768l5.712 5.233a.249.249 0 010 .369l-5.735 5.244a1.249 1.249 0 00.465 2.126c.431.133.901.025 1.23-.283l4.353-3.984a.251.251 0 01.42.184v6.782c-.047.697.341 1.35.976 1.642.181.076.375.114.572.114.488-.016.95-.224 1.283-.58l4.534-4.48a2.175 2.175 0 00-.06-3.156l-3.933-3.607a.25.25 0 010-.37l3.93-3.597zm-2.133 9.011a.253.253 0 01.006.362L14.37 19.8a.251.251 0 01-.427-.178v-4.27a.25.25 0 01.42-.184l2.241 2.054zm-2.511-8.36a.25.25 0 00.27-.043l2.24-2.048a.25.25 0 00.006-.362l-2.24-2.214a.251.251 0 00-.426.177v4.263c0 .1.06.188.15.228z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
BluetoothBold.displayName = 'BluetoothBold';
