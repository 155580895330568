import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Game = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M18.542 5.469H12.51a5.612 5.612 0 00-1.785-3.928A7.536 7.536 0 005.333.046a.748.748 0 00-.648.837.738.738 0 00.828.648A6.11 6.11 0 019.73 2.667a4.197 4.197 0 011.275 2.802h-6.33A4.685 4.685 0 000 10.144v10.248a3.618 3.618 0 003.609 3.579 2.99 2.99 0 002.562-1.446l2.492-4.067h5.822l2.103 3.37a3.928 3.928 0 002.831 2.143 3.32 3.32 0 002.841-.997 4.984 4.984 0 00.997-2.99v-9.84a4.675 4.675 0 00-4.715-4.675zm3.15 14.514a3.84 3.84 0 01-.528 1.944 1.765 1.765 0 01-1.565.558 2.392 2.392 0 01-1.735-1.296l-2.333-3.748a.746.746 0 00-.638-.349l-6.649-.1a.738.738 0 00-.638.36l-2.711 4.425a1.515 1.515 0 01-1.286.728 2.114 2.114 0 01-2.114-2.113V10.144a3.18 3.18 0 013.18-3.18h13.867a3.18 3.18 0 013.15 3.18v9.84z" />
      <path d="M7.128 12.307H6v-1.126a.748.748 0 00-1.495 0v1.126H3.369a.748.748 0 000 1.495h1.137v1.137a.747.747 0 101.495 0v-1.137h1.127a.747.747 0 100-1.495zm9.699 2.433a1.446 1.446 0 100-2.892 1.446 1.446 0 000 2.891zm2.343-4.835a1.186 1.186 0 100 2.372 1.186 1.186 0 000-2.372z" />
    </g>
  );
});
Game.displayName = 'Game';
