import Auth from '@aws-amplify/auth';
import { Lambda } from '@aws-sdk/client-lambda';
import { addSnackbar, snackbarTypes } from '../../../utils/snackbar';

const invokeGenerateDataLambda = async (filePath) => {
  const credentials = await Auth.currentCredentials();

  const lambda = new Lambda({
    credentials: Auth.essentialCredentials(credentials),
    region: 'eu-west-1',
  });

  const userBucketLocation = `private/${credentials.identityId}/`;

  const payload = JSON.stringify({
    userBucketLocation,
    filePath: `customer-mapping/upload/${filePath}`,
    uploadType: 'customer-channel',
  });

  return lambda
    .invoke({
      LogType: 'None',
      InvocationType: 'RequestResponse',
      Payload: payload,
      FunctionName: process.env.LAMBDA_GENERATE_DATA_FUNCTION,
    })
    .then((response) => {
      const stringResponse = new TextDecoder('utf-8').decode(response.Payload);
      const generateResponse = JSON.parse(stringResponse);
      if (generateResponse.errorMessage) {
        if (generateResponse.errorMessage.includes('Task timed out')) {
          addSnackbar({
            header: 'Data generation timed out',
            text: `It is taking too long to process the uploaded customer list. Please try again or upload a smaller list.`,
            type: snackbarTypes.ERROR,
          });
        }
      }
      if (generateResponse.complete) {
        if (generateResponse.failures && generateResponse.failures.length > 0) {
          addSnackbar({
            header: 'Data generation successful',
            text: `One or more assessment mappings failed.\nPlease download the failure report for details.`,
            type: snackbarTypes.WARNING,
          });
        } else {
          addSnackbar({
            header: 'Data generation successful',
            text: `Assessment mappings have been updated.`,
            type: snackbarTypes.SUCCESS,
          });
        }
      }
    })
    .catch((error) => {
      addSnackbar({
        header: 'Data generation failed',
        text: `An error occurred while generating data`,
        type: snackbarTypes.ERROR,
      });
      throw error;
    });
};

export { invokeGenerateDataLambda };
