import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesIndd = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#files-indd_svg__a)">
          <path
            clipRule="evenodd"
            d="M3.22 1.72a.75.75 0 01.53-.22h9.75v5.25A2.25 2.25 0 0015.75 9H21v3.75a.75.75 0 001.5 0V8.122a2.25 2.25 0 00-.659-1.591L15.971.66A2.25 2.25 0 0014.379 0H3.75A2.25 2.25 0 001.5 2.25v19.5A2.25 2.25 0 003.75 24h-.005a.75.75 0 000-1.5h.005a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zM15 1.81v4.94a.75.75 0 00.75.75h4.94L15 1.81z"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M15.16 15.012a.75.75 0 00-.75.749v7.451a.725.725 0 00.21.519.798.798 0 00.54.22 3.75 3.75 0 002.648-1.087 3.73 3.73 0 001.102-2.635v-1.486a3.746 3.746 0 00-1.105-2.635 3.765 3.765 0 00-2.645-1.096zm.75 7.322v-5.706a2.25 2.25 0 011.5 2.115v1.486a2.24 2.24 0 01-1.5 2.105z"
            fillRule="evenodd"
          />
          <path d="M6.3 23.94a.782.782 0 01-.83-.718V15.74a.778.778 0 01.83-.709.782.782 0 01.83.709v7.48a.778.778 0 01-.83.72zm6.51 0a.761.761 0 01-.66-.379l-2.67-4.808v4.42a.747.747 0 01-.75.747.75.75 0 01-.75-.748V15.88a.747.747 0 01.563-.71.752.752 0 01.837.351l2.68 4.778V15.88a.747.747 0 01.75-.748.75.75 0 01.75.748v7.292a.747.747 0 01-.56.768.572.572 0 01-.19 0z" />
          <path
            clipRule="evenodd"
            d="M20.25 15.052a.761.761 0 00-.75.748v7.452a.747.747 0 00.75.748 3.765 3.765 0 002.645-1.095A3.746 3.746 0 0024 20.269v-1.486a3.746 3.746 0 00-1.105-2.636 3.765 3.765 0 00-2.645-1.095zm.75 7.322v-5.696c.313.112.6.29.84.519.422.42.66.991.66 1.586v1.486c0 .595-.238 1.166-.66 1.586-.24.23-.527.407-.84.52z"
            fillRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="files-indd_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
FilesIndd.displayName = 'FilesIndd';
