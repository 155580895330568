import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ArrowDownBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M2.506 7.888a1.5 1.5 0 00-2.012 2.224l10.5 9.5a1.5 1.5 0 002.012 0l10.5-9.5a1.5 1.5 0 10-2.012-2.224L12 16.478l-9.494-8.59z" />
      </g>
    );
  }
);
ArrowDownBold.displayName = 'ArrowDownBold';
