import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const TimeExceededBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M14.25 1.5h-.75v3.116a9.751 9.751 0 013.997 1.581l1.425-1.424a.75.75 0 011.109-.053l.75.75a.75.75 0 01-.06 1.114l-1.32 1.32A9.75 9.75 0 1110.5 4.615V1.5h-.75a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zM12 8.15c.746 0 1.35.604 1.35 1.35v5.45a1.35 1.35 0 11-2.7 0V9.5c0-.746.604-1.35 1.35-1.35zm0 12.3a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
TimeExceededBold.displayName = 'TimeExceededBold';
