import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ShoppingCartRemoveItemBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M18 11.72a6 6 0 100 12 6 6 0 000-12zm2 6.76h-4A.75.75 0 0116 17h4a.75.75 0 110 1.5v-.02zM8.06 22.67a1.76 1.76 0 100-3.52 1.76 1.76 0 000 3.52z" />
        <path d="M19.7 11h.09a1 1 0 001-.72l1-3.32A1.84 1.84 0 0020 4.62H4.88l-.57-3A1.78 1.78 0 002.53.21H1a1 1 0 000 2h1.34l2.87 15.06A1.84 1.84 0 007 18.76h3a1 1 0 100-2H7.15l-.36-1.88h4a1 1 0 00.83-.48A7.69 7.69 0 0119.7 11z" />
      </g>
    );
  }
);
ShoppingCartRemoveItemBold.displayName = 'ShoppingCartRemoveItemBold';
