import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const MusicMulti = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6.48 17.14c.36 0 .73-.07 1.06-.21.34-.14.65-.34.9-.6.26-.26.46-.57.6-.9.14-.34.21-.7.21-1.07v-7l6.11-1v3.51c-.37-.18-.78-.27-1.19-.27-.55 0-1.09.16-1.54.47-.46.31-.81.74-1.02 1.25a2.775 2.775 0 003.63 3.63c.51-.21.94-.57 1.25-1.02.31-.46.47-.99.47-1.54v-7c0-.12-.02-.23-.07-.34a.705.705 0 00-.21-.27.832.832 0 00-.66-.18l-7.7 1.3c-.18.03-.35.12-.47.27-.12.14-.18.33-.18.51v5.18c-.38-.18-.79-.28-1.21-.28-.42 0-.83.09-1.21.27-.38.18-.71.45-.97.77s-.44.71-.54 1.12c-.09.41-.09.83.01 1.24.1.41.28.79.54 1.12.26.33.6.59.98.77.37.19.79.28 1.21.27zm7.7-3.57a1.191 1.191 0 01-1.17-1.42 1.22 1.22 0 01.94-.94c.23-.05.47-.02.69.07.22.09.4.24.53.44s.2.43.2.66c0 .32-.12.62-.35.84-.23.23-.53.35-.84.35zm-7.7-.43a1.191 1.191 0 011.17 1.42 1.22 1.22 0 01-.94.94c-.23.05-.47.02-.69-.07-.22-.09-.4-.24-.53-.44s-.2-.43-.2-.66c0-.32.12-.62.35-.84.22-.22.52-.35.84-.35z" />
        <path d="M19.92 1.5c.17 0 .3.14.3.3v18.12a.3.3 0 01-.3.3H1.8a.3.3 0 01-.3-.3V1.8c0-.17.14-.3.3-.3h18.12zm0-1.5H1.8C.81 0 0 .81 0 1.8v18.12c0 1 .81 1.8 1.8 1.8h18.12c1 0 1.8-.81 1.8-1.8V1.8c.01-.99-.8-1.8-1.8-1.8z" />
        <path d="M21.44 24H4.11c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h17.33c.58 0 1.05-.47 1.05-1.05V4.11c0-.41.34-.75.75-.75s.76.34.76.75v17.33c0 1.41-1.15 2.56-2.56 2.56z" />
      </g>
    );
  }
);
MusicMulti.displayName = 'MusicMulti';
