import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ControlsBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M.754 0h22.492c.411 0 .754.343.754.754v22.492a.761.761 0 01-.754.754H.754A.761.761 0 010 23.246V.754C0 .343.343 0 .754 0zm12.652 7.241a3.25 3.25 0 013.085-2.245 3.264 3.264 0 013.25 3.25 3.253 3.253 0 01-3.25 3.25c-1.439 0-2.654-.95-3.083-2.246H5.246c-.55 0-1.005-.455-1.005-1.004 0-.55.455-1.005 1.005-1.005h8.16zm-6 7.492a3.25 3.25 0 013.085-2.246c.87 0 1.688.334 2.3.946.383.366.645.818.801 1.3h5.145c.55 0 1.004.455 1.004 1.004a1.01 1.01 0 01-1.004 1.004h-5.16a3.25 3.25 0 01-3.086 2.246c-1.439 0-2.654-.95-3.083-2.246H5.246c-.55 0-1.005-.454-1.005-1.004s.455-1.004 1.005-1.004h2.16z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
ControlsBold.displayName = 'ControlsBold';
