import {
  ListProps as UnorderedListProps,
  UnorderedList as UnorderedListComponent,
} from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IUnorderedListProps = UnorderedListProps;

export const UnorderedList = forwardRef<IUnorderedListProps, 'ul'>((props, ref) => {
  return <UnorderedListComponent spacing="3" {...props} ref={ref} />;
});
