import {
  ISliderFilledTrackProps,
  SliderFilledTrack as SliderFilledTrackInternal,
} from './SliderFilledTrack';
import { ISliderThumbProps, SliderThumb as SliderThumbInternal } from './SliderThumb';
import { ISliderTrackProps, SliderTrack as SliderTrackInternal } from './SliderTrack';

export * from './Slider';
export { ISliderThumbProps, ISliderTrackProps, ISliderFilledTrackProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Slider.FilledTrack` instead
 */
export const SliderFilledTrack = SliderFilledTrackInternal;
/**
 * @deprecated use `Slider.Thumb` instead
 */
export const SliderThumb = SliderThumbInternal;
/**
 * @deprecated use `Slider.Track` instead
 */
export const SliderTrack = SliderTrackInternal;
