import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const RobotArmBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#robot-arm-bold_svg__a)">
          <path
            clipRule="evenodd"
            d="M22.366 6.581A4.32 4.32 0 0123.92 8.81l.02-.03a1.25 1.25 0 11-2.39.7 1.81 1.81 0 10-2.2 2.26 1.25 1.25 0 01-.31 2.46 1.174 1.174 0 01-.32 0 4.31 4.31 0 01-3.21-4.17 4.188 4.188 0 01.45-1.9l-2.24-2.26a3 3 0 01-2.33-.27L6.52 9.45a3.25 3.25 0 010 1.55L10 14.52A4.54 4.54 0 008.39 18v.43l-4.76-4.89a3.29 3.29 0 01-1.84-6.19A3.2 3.2 0 013.72 7L10 2.17A3 3 0 0111.46.34a3 3 0 014.28 3.44L18 6.1a4.19 4.19 0 011.79-.38 4.32 4.32 0 012.576.861zM2.73 11.128c.171.117.373.18.58.182a1 1 0 001.06-1.05 1.05 1.05 0 10-1.64.868zm9.523-7.248c.174.127.382.2.598.21A1.09 1.09 0 0014 3a1.1 1.1 0 10-1.748.88zM16 20.44h.65a1.5 1.5 0 011.5 1.5v1.56a.5.5 0 01-.5.5H8.22a.5.5 0 01-.5-.5v-1.56a1.5 1.5 0 011.5-1.5h.64v-2.37a3.07 3.07 0 016.14 0v2.37zm-3.654-1.694c.168.193.4.319.654.354v.01A1.06 1.06 0 1013 17a1.06 1.06 0 00-.654 1.746z"
            fillRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="robot-arm-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
RobotArmBold.displayName = 'RobotArmBold';
