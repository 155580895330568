import { StackDivider as StackDividerComponent, StackDividerProps } from '@chakra-ui/layout';
import { useToken } from '@chakra-ui/system';
import { Divider, IDividerProps } from '@lego/klik-ui-divider';
import * as React from 'react';

export interface IStackDividerProps extends StackDividerProps, Pick<IDividerProps, 'orientation'> {}

export const StackDivider: React.FunctionComponent<IStackDividerProps> = (props) => {
  const borderColor = useToken('components', 'Divider.baseStyle.borderColor');

  // we pass borderColor here like this, because the chakra component has "borderColor: inherit"
  // baked in and doesn't use the theming engine
  return <StackDividerComponent as={Divider} borderColor={borderColor} {...props} />;
};
