import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesExternalScreen = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M.746 2A.746.746 0 000 2.746v14.92c0 .413.334.747.746.747h5.853a10.296 10.296 0 00-2.703 2.375.746.746 0 001.166.932 8.804 8.804 0 016.715-3.307h.325a8.802 8.802 0 016.715 3.307.746.746 0 101.166-.932 10.296 10.296 0 00-2.703-2.375h5.847a.746.746 0 00.746-.746V2.746A.746.746 0 0023.127 2H.746zm11.37 14.92H22.38V3.493H1.492v13.429h10.272a10.291 10.291 0 01.351 0z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
DevicesExternalScreen.displayName = 'DevicesExternalScreen';
