const baseStyle = {
  marginEnd: 3,
  transition: 'all 0.2s',
};

const sizes = {
  sm: {
    fontSize: 'sm',
    mb: 1,
  },
  md: {
    fontSize: 'md',
    mb: 1,
  },
  lg: {
    fontSize: 'lg',
    mb: 2,
  },
};

const defaultProps = {
  size: 'md',
};

export const FormLabel = {
  baseStyle,
  defaultProps,
  sizes,
};
