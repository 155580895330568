import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DashboardBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M11 12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h7a2 2 0 012 2v10zm2 0a2 2 0 012-2h7a2 2 0 012 2v10a2 2 0 01-2 2h-7a2 2 0 01-2-2V12zm2-12a2 2 0 00-2 2v4a2 2 0 002 2h7a2 2 0 002-2V2a2 2 0 00-2-2h-7zm-4 22a2 2 0 01-2 2H2a2 2 0 01-2-2v-4a2 2 0 012-2h7a2 2 0 012 2v4z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
DashboardBold.displayName = 'DashboardBold';
