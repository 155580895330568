import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const WifiOffBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M1.016 6.39a.976.976 0 00-.282.973c.094.349.377.622.74.713a1.06 1.06 0 001.008-.272c3.079-2.963 7.453-4.31 11.744-3.616.356.061.72-.06.959-.322l.006-.007a.972.972 0 00.196-.987 1.026 1.026 0 00-.792-.652c-4.96-.81-10.02.744-13.579 4.17zm20.527 1.414a1.065 1.065 0 001.453-.012.974.974 0 00.013-1.401 16.256 16.256 0 00-.236-.221 1.065 1.065 0 00-1.466.034.975.975 0 00.036 1.414c.068.06.134.123.2.186zm-10.83 1.052a.971.971 0 00.114-1.154 1.051 1.051 0 00-1.095-.48 11.504 11.504 0 00-4.942 2.26c-.29.23-.57.476-.84.734a.976.976 0 00-.283.972c.095.35.378.622.74.714.363.091.749-.013 1.009-.273a9.367 9.367 0 014.709-2.441 1.04 1.04 0 00.577-.319l.011-.013zm7.349.971a.983.983 0 00.123 1.42c.146.122.288.25.426.382a1.065 1.065 0 001.453-.012.974.974 0 00.013-1.4 8.722 8.722 0 00-.566-.5 1.064 1.064 0 00-1.451.114l.002-.004zm-3.242 3.619a.975.975 0 00.195 1.47c.239.158.461.338.664.538.405.39 1.062.39 1.467 0a.974.974 0 000-1.414 7.291 7.291 0 00-.926-.753 1.076 1.076 0 00-1.4.159zm-.734 6.541c0 1.104-.928 1.999-2.074 1.999-1.145 0-2.073-.895-2.073-1.999s.928-1.998 2.073-1.998c1.146 0 2.074.894 2.074 1.998zM22.54.244a1.064 1.064 0 00-1.463.1L1.38 22.328a.973.973 0 00-.215.993c.12.344.426.598.796.663.37.065.749-.069.988-.35L22.646 1.65a.974.974 0 00-.107-1.406z" />
      </g>
    );
  }
);
WifiOffBold.displayName = 'WifiOffBold';
