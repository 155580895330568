import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesDocument = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.869 6.59L15.99.71A2.254 2.254 0 0014.399 0H3.723a2.256 2.256 0 00-2.07 1.43 2.25 2.25 0 00-.153.87v19.4a2.247 2.247 0 001.358 2.115c.273.118.567.181.865.185h16.523a2.255 2.255 0 002.253-2.25V8.18a2.248 2.248 0 00-.631-1.59zm-6.85-4.73l5.728 5.7H15.74a.752.752 0 01-.75-.75l.03-4.95zm6.009 19.84a.75.75 0 01-.751.75H3.724a.751.751 0 01-.751-.75V2.3a.75.75 0 01.75-.75h9.764v5.26a2.248 2.248 0 002.253 2.25h5.258l.03 12.64z" />
      </g>
    );
  }
);
FilesDocument.displayName = 'FilesDocument';
