import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { spacing, colors } from '../config';
import { assessmentStatus, assessmentStatusTranslations } from '../constants';

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const getColor = (status) => {
  switch (status) {
    case assessmentStatus.ASSESSMENT:
    case assessmentStatus.SELF_ASSESSMENT:
    case assessmentStatus.RE_ASSESSMENT:
      return colors.neutralColors.slate300;
    case assessmentStatus.REVIEW:
      return colors.boldColors.copper.copper200;
    case assessmentStatus.APPROVAL:
      return colors.boldColors.copper.copper400;
    case assessmentStatus.COMPLETED:
      return colors.utilityColors.success.success400;
    default:
      return colors.neutralColors.slate40;
  }
};

const colorStyle = (color) =>
  css({
    display: 'block',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: color,
    marginRight: spacing.size2,
    flexShrink: 0,
  });

const AssessmentStatusIndicator = ({ status }) => (
  <div css={wrapperStyle}>
    <span css={colorStyle(getColor(status))}></span>
    <span>{assessmentStatusTranslations[status]}</span>
  </div>
);

AssessmentStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
};

export { AssessmentStatusIndicator };
