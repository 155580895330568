import {
  As,
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import { Icon } from '@lego/klik-ui-icon';
import * as React from 'react';
import { Menu, MenuList } from './Menu';
import { useNavbarContext } from './NavbarProvider';

interface INavbarMenuItemOptions {
  isActive?: boolean;
}

export interface INavbarMenuItemProps
  extends HTMLChakraProps<'a'>,
    INavbarMenuItemOptions,
    ThemingProps<'Navbar'> {
  hasMenu?: boolean;
  title?: string;
  icon?: As<any>;
  closeOnBlur?: boolean;
  onClick?(): void;
}

/**
 * NavbarMenuItem
 * TODO: The chakra typing is running haywire in this file and breaks both ESlint and the html  properties. That needs to be addressed. We can postpone it until we can upgrade chakra again
 *
 */
export const NavbarMenuItem = forwardRef<INavbarMenuItemProps, 'a'>(
  ({ hasMenu, icon, children, title, isActive, closeOnBlur = true, ...props }, ref) => {
    const { ...rest } = omitThemingProps(props);
    const { orientation, isSecondary, isFullWidth } = useNavbarContext();

    const { menuItem: styles } = useMultiStyleConfig('Navbar', {
      ...props,
      orientation,
      isSecondary,
      isFullWidth,
      isActive,
    });

    const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (!props.onClick) {
        return;
      }

      switch (e.key) {
        case ' ':
        case 'Enter':
          e.preventDefault();
          props.onClick();
      }
    };

    // This can be improved.
    // TODO: Use ContextProvider instead to pass down the properties from popper and disclosure
    // TODO: extract controls for Expand Menu buttons into their own hooks
    // TODO: fix the typing issues coming from chakra. (Could be resolved when we update to 1.6.4)
    if (hasMenu) {
      return (
        <Menu
          closeOnBlur={closeOnBlur}
          offset={[0, 1]}
          placement={orientation === 'vertical' ? 'right-start' : 'bottom-start'}
        >
          <MenuList
            // eslint-disable-next-line react/jsx-no-bind
            render={({ getReferenceProps, onToggle }) => {
              return (
                <NavbarMenuItem
                  as="button"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onClick={onToggle}
                  sx={styles}
                  {...getReferenceProps()}
                  {...rest}
                >
                  {title}

                  {icon && (
                    <Icon
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      as={icon}
                      fontSize={title ? '0.75em' : '1em'}
                      ml={title ? 2 : 0}
                    />
                  )}
                </NavbarMenuItem>
              );
            }}
          >
            {children}
          </MenuList>
        </Menu>
      );
    }

    return (
      // eslint-disable-next-line react/jsx-no-bind
      <chakra.a onKeyDown={onKeyDown} ref={ref} sx={styles} tabIndex={0} {...rest}>
        {children}
      </chakra.a>
    );
  }
);

interface IMenuOption extends HTMLChakraProps<'button'> {
  hasMenu?: boolean;
  title?: string;
  icon?: As<any>;
  closeOnBlur?: boolean;
  isOpen?: boolean;
}

/**
 * MenuOption
 * @summary use the menu option nested inside a menuList
 *
 */
export const MenuOption = forwardRef<IMenuOption, 'button'>(
  ({ hasMenu, title, icon, closeOnBlur = true, isOpen, ...rest }, ref) => {
    const { children } = rest;

    const { menuOption: styles } = useMultiStyleConfig('Navbar', {
      isOpen,
    });

    // This can be improved.
    // TODO: Use ContextProvider instead to pass down the properties from popper and disclosure
    // TODO: extract controls for Expand Menu buttons into their own hooks
    // TODO: fix the typing issues coming from chakra. (Could be resolved when we update to 1.6.4)
    if (hasMenu) {
      return (
        <Menu closeOnBlur={closeOnBlur} offset={[0, 1]} placement="right-start">
          <MenuList
            // eslint-disable-next-line react/jsx-no-bind
            render={({ onToggle, getReferenceProps, isOpen: isMenuOpen }) => (
              <MenuOption
                // eslint-disable-next-line react/jsx-no-bind
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onToggle();
                }}
                {...getReferenceProps()}
                isOpen={isMenuOpen}
                w="100%"
              >
                {title}
                {icon && <Icon as={icon} fontSize="0.75em" ml="2" />}
              </MenuOption>
            )}
          >
            {children}
          </MenuList>
        </Menu>
      );
    }

    return (
      <chakra.button ref={ref} sx={styles} tabIndex={0} {...rest}>
        {children}
      </chakra.button>
    );
  }
);
