import {
  AccordionButton as AccordionButtonInternal,
  IAccordionButtonProps,
} from './AccordionButton';
import { AccordionIcon as AccordionIconInternal } from './AccordionIcon';
import { AccordionItem as AccordionItemInternal, IAccordionItemProps } from './AccordionItem';
import { AccordionPanel as AccordionPanelInternal, IAccordionPanelProps } from './AccordionPanel';

export {
  AccordionProvider,
  useAccordion,
  useAccordionContext,
  useAccordionItem,
  UseAccordionItemProps,
  UseAccordionItemReturn,
  useAccordionItemState,
  UseAccordionProps,
  UseAccordionReturn,
} from '@chakra-ui/accordion';
export * from './Accordion';
export { IAccordionItemProps, IAccordionPanelProps, IAccordionButtonProps };
// TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Accordion.Button` instead
 */
export const AccordionButton = AccordionButtonInternal;
/**
 * @deprecated use `Accordion.Icon` instead
 */
export const AccordionIcon = AccordionIconInternal;
/**
 * @deprecated use `Accordion.Item` instead
 */
export const AccordionItem = AccordionItemInternal;
/**
 * @deprecated use `Accordion.Panel` instead
 */
export const AccordionPanel = AccordionPanelInternal;
