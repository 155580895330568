import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const RemoveFromList = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M6.11 3.61h13.62a.75.75 0 000-1.5H6.11a.75.75 0 000 1.5zm0 5.62h13.62a.75.75 0 000-1.5H6.11a.75.75 0 000 1.5zm2.75 4.06H6.11a.75.75 0 100 1.5h2.75a.75.75 0 100-1.5zM2.73 3.8a.94.94 0 100-1.88.94.94 0 000 1.88zm0 5.62a.94.94 0 100-1.88.94.94 0 000 1.88zm0 5.56a.94.94 0 100-1.88.94.94 0 000 1.88zm14.19-4.22a6 6 0 100 12 6 6 0 000-12zm0 10.57a4.54 4.54 0 110-9.08 4.54 4.54 0 010 9.08z" />
        <path d="M18.9 16h-4a.75.75 0 000 1.5h4a.75.75 0 100-1.5z" />
      </g>
    );
  }
);
RemoveFromList.displayName = 'RemoveFromList';
