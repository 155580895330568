import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Stopwatch = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M8.807 10.346a.75.75 0 00-1.114 1.006l3.75 4.15a.75.75 0 001.114-1.005l-3.75-4.15z" />
        <path
          clipRule="evenodd"
          d="M14.25 1.5h-1.5v3.029a9.751 9.751 0 015.18 1.981l.907-.908a.75.75 0 011.193-.882l.375.375.375.375a.75.75 0 01-.882 1.193l-.85.85a9.75 9.75 0 11-7.798-2.984V1.5h-1.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zM12 6a8.25 8.25 0 100 16.5A8.25 8.25 0 0012 6z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
Stopwatch.displayName = 'Stopwatch';
