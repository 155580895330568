import { css } from '@emotion/react';

import { Heading } from '../Heading';
import { useUser } from '../../providers/UserProvider';
import { ActivateYearAndBusinessUnit } from './components/ActivateYearAndBusinessUnit';
import { CustomerChannelMapping } from './components/CustomerChannelMapping';

import { withTransaction } from '@elastic/apm-rum-react';

const assessmentManagementContainer = css({
  display: 'flex',
});

const assessmentManagementContent = css({
  width: '50%',
});

const AssessmentManagement = () => {
  const { roles } = useUser();

  if (!roles || !roles.includes('admin')) {
    return (
      <div>
        You do not have access to the Assessment Management page. Please contact
        MCP Support.
      </div>
    );
  }

  return (
    <div>
      <Heading type="h1" text="Assessment Management" />
      <div css={assessmentManagementContainer}>
        <div css={assessmentManagementContent}>
          <CustomerChannelMapping />
        </div>
        <div css={assessmentManagementContent}>
          <ActivateYearAndBusinessUnit />
        </div>
      </div>
    </div>
  );
};

const AssessmentManagementPage = withTransaction(
  'AssessmentManagementPage',
  'component'
)(AssessmentManagement);
export { AssessmentManagementPage };
