import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Overlay, Button } from '@lego/klik-react';
import { Cross } from '@lego/klik-icons-react';
import { font, spacing, colors } from '../../config';
import { ModalPageIndicator } from './ModalPageIndicator';

const wrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const contentStyle = css({
  width: 480,
  backgroundColor: colors.neutralColors.white,
  borderRadius: 2,
  margin: spacing.size4,
  marginTop: '5%',
  padding: spacing.size5,
  color: colors.neutralColors.slate100,
  border: `1px solid ${colors.utilityColors.error.error500}`,
  borderLeftWidth: 12,
});

const headerWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
});

const headerStyle = css({
  fontSize: font.size.size1,
  fontWeight: font.weight.medium,
  lineHeight: font.lineHeight.m,
});

const bodyTextStyle = css({
  marginTop: spacing.size3,
});

const closeButtonWrapperStyle = css({
  marginRight: -spacing.size2,
});

const closeModalButtonIconStyle = css({
  color: colors.neutralColors.slate100,
});

const pageIndicatorWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
  marginTop: spacing.size2,
});

const RejectModal = ({
  header,
  bodyText,
  onClose,
  pageIndicator,
  children,
}) => {
  return (
    <Overlay isOpen={true}>
      <div css={wrapperStyle}>
        <div css={contentStyle}>
          <header css={headerWrapperStyle}>
            <h4 css={headerStyle}>{header}</h4>
            {onClose && typeof onClose === 'function' && (
              <span css={closeButtonWrapperStyle}>
                <Button
                  aria-label="Close modal button"
                  icon={<Cross css={closeModalButtonIconStyle} />}
                  variant="ghost"
                  size="small"
                  onClick={onClose}
                />
              </span>
            )}
          </header>
          {bodyText && <p css={bodyTextStyle}>{bodyText}</p>}
          {children}
          {pageIndicator && (
            <div css={pageIndicatorWrapperStyle}>
              <ModalPageIndicator
                numberOfPages={pageIndicator[0]}
                currentPageIndex={pageIndicator[1]}
              />
            </div>
          )}
        </div>
      </div>
    </Overlay>
  );
};

RejectModal.propTypes = {
  header: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
  onClose: PropTypes.func,
  pageIndicator: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]),
  children: PropTypes.node.isRequired,
};

export { RejectModal };
