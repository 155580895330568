import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@lego/klik-react';
import {
  SurveyDispatchContext,
  updateAnswerAction,
} from '../assessment-scoring/reducer';
import { AnswerLabel } from './AnswerLabel';

const SingleChoice = ({ question, questionState }) => {
  const { dispatch } = useContext(SurveyDispatchContext);
  const { questionId, questionType, answerOptions } = question;
  return (
    <Radio
      name={questionId}
      onChange={(event) =>
        dispatch(
          updateAnswerAction(questionId, questionType, event.target.value)
        )
      }
    >
      {answerOptions.map((answer, index) => {
        return (
          <Radio.Button
            checked={
              questionState.selectedAnswer.includes(answer.answerId)
                ? 'checked'
                : false
            }
            key={index}
            label={<AnswerLabel labelText={answer.text} score={answer.score} />}
            value={answer.answerId}
          />
        );
      })}
    </Radio>
  );
};

SingleChoice.propTypes = {
  question: PropTypes.object.isRequired,
  questionState: PropTypes.object.isRequired,
};

export { SingleChoice };
