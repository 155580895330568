import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const VideoBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.2 24H1.8c-1 0-1.8-.81-1.8-1.8V1.8C0 .81.81 0 1.8 0h20.4c1 0 1.8.81 1.8 1.8v20.4c0 .99-.81 1.8-1.8 1.8zM9.82 17.28L17 12.89c.11-.07.21-.17.27-.28.07-.12.1-.25.11-.38 0-.13-.03-.26-.09-.38a.73.73 0 00-.26-.29l-7.18-4.9a.802.802 0 00-.4-.14c-.14 0-.28.03-.41.1a.72.72 0 00-.31.29c-.07.12-.11.26-.12.4v9.28c0 .14.04.28.11.4s.18.22.3.29c.12.07.25.1.39.1.15 0 .29-.04.41-.1zm5.3-5.1l-4.92 3V8.82l4.92 3.36z" />
      </g>
    );
  }
);
VideoBold.displayName = 'VideoBold';
