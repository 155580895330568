import { useFormControlProps } from '@chakra-ui/form-control';
import { NumberInputProps, useNumberInput } from '@chakra-ui/number-input';
import { getValidChildren } from '@chakra-ui/react-utils';
import {
  chakra,
  ComponentWithAs,
  forwardRef,
  omitThemingProps,
  StylesProvider,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';
import { NumberDecrement } from './NumberDecrement';
import { NumberIncrement } from './NumberIncrement';
import { NumberInputField } from './NumberInputField';
import { NumberInputProvider } from './NumberInputProvider';

export interface INumberInputProps extends NumberInputProps {
  isFullWidth?: boolean;
}

type INumberInputComponent = ComponentWithAs<'div', INumberInputProps> & {
  Decrement: typeof NumberDecrement;
  Increment: typeof NumberIncrement;
  Field: typeof NumberInputField;
};

export const NumberInput = forwardRef<INumberInputProps, 'div'>(
  ({ children, size = 'md', isFullWidth, isInvalid, ...rest }, ref) => {
    const variant = isFullWidth ? 'full' : 'plain';
    const styles = useMultiStyleConfig('NumberInput', { size, variant, ...rest });

    const ownProps = omitThemingProps(rest);
    const controlProps = useFormControlProps(ownProps);
    const context = useNumberInput(controlProps);
    const ctx = React.useMemo(() => context, [context]);
    const validChildren = getValidChildren(children);

    return (
      <NumberInputProvider value={ctx}>
        <StylesProvider value={styles}>
          <chakra.div
            __css={{
              zIndex: 0,
              ...styles.container,
            }}
            className={cx('chakra-numberinput')}
            ref={ref}
          >
            {validChildren.map((child: React.ReactElement) => {
              return React.cloneElement(child, {
                size,
                isInvalid,
                ...child.props,
              });
            })}
          </chakra.div>
        </StylesProvider>
      </NumberInputProvider>
    );
  }
) as INumberInputComponent;

NumberInput.Decrement = NumberDecrement;
NumberInput.Increment = NumberIncrement;
NumberInput.Field = NumberInputField;
