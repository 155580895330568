import { UseNumberInputReturn } from '@chakra-ui/number-input';
import { createContext } from '@chakra-ui/react-utils';

export type NumberInputContext = Omit<UseNumberInputReturn, 'htmlProps'> & {
  isFullWidth?: boolean;
};

export const [NumberInputProvider, useNumberInputContext] = createContext<NumberInputContext>({
  name: 'NumberInputContext',
  errorMessage:
    "useNumberInputContext: `context` is undefined. Seems you forgot to wrap number-input's components within <NumberInput />",
});
