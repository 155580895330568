import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, font } from '../../../config';

const rowCounterStyle = css({
  color: colors.neutralColors.slateBase,
  fontSize: font.size.size05,
});

const TableRowCounter = ({ numberOfFilteredRows, searchText }) => {
  const filterString = searchText
    ? `${numberOfFilteredRows} result(s) for "${searchText}"`
    : `${numberOfFilteredRows} result(s)`;
  return (
    <div css={rowCounterStyle}>
      <span aria-label="Table row count">{filterString}</span>
    </div>
  );
};

TableRowCounter.propTypes = {
  numberOfFilteredRows: PropTypes.number.isRequired,
  searchText: PropTypes.string,
};

export { TableRowCounter };
