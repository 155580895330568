import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Modal, Overlay, Button } from '@lego/klik-react';
import { spacing } from '../../config';

const bodyText = css({
  marginTop: spacing.size6,
  '& > p': {
    marginBottom: spacing.size4,
  },
});

const GenericConfirmationModal = ({
  header,
  body,
  onCloseText = 'Cancel',
  onConfirmText = 'Yes, continue',
  onClose,
  onConfirm,
  onExitHandler,
  status = 'information',
  icon,
}) => (
  <Overlay isOpen={true} onClick={onExitHandler ? onExitHandler : onClose}>
    <Modal
      status={status}
      icon={icon}
      heading={<Modal.Heading>{header}</Modal.Heading>}
      body={
        <Modal.Body>
          <div css={bodyText} dangerouslySetInnerHTML={{ __html: body }} />
        </Modal.Body>
      }
      action={
        <Modal.Action>
          <Button
            aria-label="Confirm action"
            data-transaction-name={header}
            label={onConfirmText}
            onClick={() => onConfirm()}
          />
          <Button
            aria-label="Close modal"
            label={onCloseText}
            variant="outline"
            onClick={onClose}
          />
        </Modal.Action>
      }
      exitClickHandler={onExitHandler ? onExitHandler : onClose}
    />
  </Overlay>
);

GenericConfirmationModal.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onCloseText: PropTypes.string,
  onConfirmText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onExitHandler: PropTypes.func,
  status: PropTypes.string,
  icon: PropTypes.element,
};

export { GenericConfirmationModal };
