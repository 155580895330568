import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const UploadBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M.1 19.834c.003 2.3 1.793 4.164 4.002 4.166h15.796c2.21-.002 4-1.866 4.002-4.166V17.9a1.277 1.277 0 00-.597-1.125 1.174 1.174 0 00-1.235 0c-.38.234-.61.665-.597 1.125v1.935c-.001.904-.704 1.636-1.573 1.638H4.102c-.868-.002-1.572-.734-1.573-1.638V17.9a1.277 1.277 0 00-.597-1.125 1.174 1.174 0 00-1.235 0c-.38.234-.609.665-.596 1.125v1.935z" />
        <path d="M12 19.557c1.073 0 1.943-.905 1.943-2.022v-9.46c0-.143.11-.258.248-.258h2.668a.97.97 0 00.884-.592c.158-.36.099-.784-.151-1.083l-4.86-5.81A.984.984 0 0012 0a.984.984 0 00-.733.331L6.408 6.147c-.247.3-.305.72-.147 1.08a.97.97 0 00.88.59H9.81c.137 0 .247.116.247.259v9.459c0 1.117.87 2.022 1.943 2.022z" />
      </g>
    );
  }
);
UploadBold.displayName = 'UploadBold';
