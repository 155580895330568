import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useSortBy } from 'react-table';
import { css } from '@emotion/core';
import { ArrowLeftBold, ArrowRightBold } from '@lego/klik-icons-react';
import { Button } from '@lego/klik-react';
import { colors, spacing, font, media } from '../../../config';
import { Select } from '../../Select';
import { DropDownItem } from '../../dropdown/DropDownItem';
import {
  tableStyle,
  tableThStyle,
  tableTdStyle,
} from '../../../styles/tableStyle';
import {
  getDefaultPageSize,
  saveSortByToLocalStorage,
  getSortByFromLocalStorage,
} from '../helpers';

const localStorageSortByKey = 'overviewSortBy';

const tablePaginationWrapperStyle = css({
  display: 'flex',
  marginTop: spacing.size12,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  [media.large]: {
    flexDirection: 'row',
  },
});

const pageIndexButtonsWrapperStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  margin: `0 ${spacing.size4}px`,
});

const tableRowStyle = css({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: colors.boldColors.lightBlue.ligthBlue10,
  },
});

const pageSizeSelectorContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 200,
  marginRight: spacing.size12,
  marginBottom: spacing.size6,
});

const pagerContainerStyle = css({
  display: 'flex',
  marginBottom: spacing.size6,
});

const pageSizeSelectorTextStyle = css({
  marginRight: spacing.size2,
  fontSize: font.size.sizeM1,
});

const selectWrapperStyle = css({
  flexGrow: 1,
});

const emptyTableText = css({
  textAlign: 'center',
});

const OverviewTable = ({ overviewTableData, columns, rowClick, pageSizes }) => {
  const defaultSortByArray = [{ id: 'status', desc: false }];
  const sortByFromLocalStorage = useMemo(
    () => getSortByFromLocalStorage(localStorageSortByKey),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: overviewTableData,
      initialState: {
        pageIndex: 0,
        pageSize: getDefaultPageSize(overviewTableData.length),
        sortBy: sortByFromLocalStorage || defaultSortByArray,
      },
      useControlledState: (state) => {
        const { sortBy: sortByFromState } = state;
        saveSortByToLocalStorage(sortByFromState, localStorageSortByKey);
        return state;
      },
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  const getRowProps = (row) => ({
    onClick: rowClick ? () => rowClick(row.original) : () => {},
    css: tableRowStyle,
  });

  if (!overviewTableData.length) {
    return (
      <div css={emptyTableText}>
        There are no results with your applied filters.
      </div>
    );
  }

  return (
    <div>
      <table css={tableStyle} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              key={`tableheadergroup${index}`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  css={tableThStyle}
                  key={`tableheader${index}`}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined }) // remove default title tooltip
                  )}
                >
                  {column.render('Header', { column })}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={`tablerow${i}`} {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      css={tableTdStyle}
                      key={`tablecell${index}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div css={tablePaginationWrapperStyle}>
        <div css={pageSizeSelectorContainerStyle}>
          <span css={pageSizeSelectorTextStyle}>Rows per page: </span>
          <div css={selectWrapperStyle}>
            <Select value={pageSize} onSelect={setPageSize}>
              {pageSizes.map((pageSize) => (
                <DropDownItem
                  key={pageSize}
                  label={String(pageSize)}
                  value={pageSize}
                />
              ))}
            </Select>
          </div>
        </div>
        <div css={pagerContainerStyle}>
          <Button
            icon={<ArrowLeftBold />}
            size="small"
            variant="ghost"
            disabled={!canPreviousPage}
            onClick={() => previousPage()}
          />
          <div css={pageIndexButtonsWrapperStyle}>
            {pageOptions.map((pageOptionIndex) => {
              return (
                <Button
                  key={`pagerButtonPage${pageOptionIndex}`}
                  label={pageOptionIndex + 1}
                  variant="ghost"
                  size="small"
                  state={pageOptionIndex === pageIndex && 'hover'}
                  onClick={() => gotoPage(pageOptionIndex)}
                />
              );
            })}
          </div>
          <Button
            icon={<ArrowRightBold />}
            size="small"
            variant="ghost"
            disabled={!canNextPage}
            onClick={() => nextPage()}
          />
        </div>
      </div>
    </div>
  );
};

OverviewTable.propTypes = {
  overviewTableData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowClick: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export { OverviewTable, localStorageSortByKey };
