import { ITableBodyProps, TableBody as TableBodyInternal } from './TableBody';
import { ITableCellProps, TableCell as TableCellInternal } from './TableCell';
import {
  ITableColumnHeaderProps,
  TableColumnHeader as TableColumnHeaderInternal,
} from './TableColumnHeader';
import { ITableFooterProps, TableFooter as TableFooterInternal } from './TableFooter';
import { ITableHeadProps, TableHead as TableHeadInternal } from './TableHead';
import { ITableRowProps, TableRow as TableRowInternal } from './TableRow';

export * from './Table';
export {
  ITableBodyProps,
  ITableCellProps,
  ITableColumnHeaderProps,
  ITableFooterProps,
  ITableHeadProps,
  ITableRowProps,
};

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Table.Body` instead
 */
export const TableBody = TableBodyInternal;
/**
 * @deprecated use `Table.Cell` instead
 */
export const TableCell = TableCellInternal;
/**
 * @deprecated use `Table.ColumnHeader` instead
 */
export const TableColumnHeader = TableColumnHeaderInternal;
/**
 * @deprecated use `Table.Footer` instead
 */
export const TableFooter = TableFooterInternal;
/**
 * @deprecated use `Table.Head` instead
 */
export const TableHead = TableHeadInternal;
/**
 * @deprecated use `Table.Row` instead
 */
export const TableRow = TableRowInternal;
