import { css } from '@emotion/core';
import { spacing, colors, media } from '../config';

const tableStyle = css({
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: spacing.size5,
  display: 'block',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  [media.xLarge]: {
    display: 'table',
  },
});

const generalCellStyle = {
  borderBottom: `1px solid ${colors.neutralColors.slate20}`,
  padding: spacing.size1,
  paddingLeft: spacing.size5,
  height: spacing.size10,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  minWidth: 190,
  [media.xLarge]: {
    minWidth: 0,
  },
};

const tableTdStyle = css(generalCellStyle);

const tableThStyle = css({
  ...generalCellStyle,
  textAlign: 'left',
  borderBottom: `2px solid ${colors.neutralColors.slate20}`,
});

export { tableStyle, tableThStyle, tableTdStyle };
