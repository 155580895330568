import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const EmailUnread = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.6 3.2H2.4A2.4 2.4 0 000 5.6v12.8a2.4 2.4 0 002.4 2.4h19.2a2.4 2.4 0 002.4-2.4V5.6a2.4 2.4 0 00-2.4-2.4zM2.4 4.8h19.2a.8.8 0 01.8.8v12.8a.8.8 0 01-.8.8H2.4a.8.8 0 01-.8-.8V5.6a.8.8 0 01.8-.8z" />
        <path d="M22.35 3.953a.8.8 0 011.065 1.189l-.089.079-8.687 6.681a4.329 4.329 0 01-5.089.138l-.19-.138L.675 5.221a.8.8 0 01.876-1.334l.1.066 8.686 6.681a2.729 2.729 0 003.16.119l.168-.119 8.687-6.681z" />
      </g>
    );
  }
);
EmailUnread.displayName = 'EmailUnread';
