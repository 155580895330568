import PropTypes from 'prop-types';
import { Button, ButtonGroup, Modal } from '@lego/klik-ui';

const AssessmentEditModal = ({
  header,
  onClose,
  onSave,
  status = 'warning',
  children,
  saveDisableCondition = () => false,
}) => (
  <Modal variant={status} isOpen={true} onClose={onClose} size="3xl">
    <Modal.Overlay />
    <Modal.Content>
      <Modal.Header>{header}</Modal.Header>
      <Modal.CloseButton />
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            isDisabled={saveDisableCondition()}
            colorScheme={status}
            onClick={onSave}
            data-transaction-name={header}
          >
            Yes, make change(s)
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

AssessmentEditModal.propTypes = {
  header: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  status: PropTypes.string,
  children: PropTypes.node.isRequired,
  saveDisableCondition: PropTypes.func,
};

export { AssessmentEditModal };
