import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const EditFile = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M13.755 14.775l7.904-7.904a.812.812 0 00.032-.031l.974-.975a2.852 2.852 0 00-.924-4.648 2.85 2.85 0 00-3.107.618l-8.91 8.91a.75.75 0 00-.211.424l-.495 3.465a.75.75 0 00.849.849l3.464-.496a.75.75 0 00.424-.212zM20.65 2.5a1.35 1.35 0 00-.955.395l-.46.46 1.91 1.91.46-.46A1.35 1.35 0 0020.65 2.5zm-9.688 9.128l7.213-7.212 1.909 1.909-7.213 7.213-2.227.318.318-2.228z"
        fillRule="evenodd"
      />
      <path d="M2.69 6.892a.65.65 0 01.46-.19h7a.75.75 0 000-1.5h-7A2.15 2.15 0 001 7.351v14a2.15 2.15 0 002.15 2.15h14a2.15 2.15 0 002.15-2.15v-7a.75.75 0 00-1.5 0v7a.65.65 0 01-.65.65h-14a.65.65 0 01-.65-.65v-14a.65.65 0 01.19-.46z" />
    </g>
  );
});
EditFile.displayName = 'EditFile';
