import { Icon } from '@lego/klik-ui-icon';
import { ArrowLeftBold, ArrowRightBold } from '@lego/klik-ui-icons';
import * as React from 'react';
import { IPaginationProps } from '.';

export type PaginationActions = {
  getNavigation: () => NavigationButtons;
  getPaginationItems: () => IPaginationItemProps[];
};

export enum PaginationAction {
  Next = 'Next',
  Prev = 'Prev',
  First = 'First',
  Last = 'Last',
}

export interface IPaginationItemProps {
  key: number;
  page: number;
  isSelected?: boolean;
  onClick?: (page: number) => void;
  isEllipsis?: boolean;
  isDisabled?: boolean;
}

type PaginationButtonProps = {
  'aria-label': string;
  label?: string;
  navAction: PaginationAction;
  onClick: (action: PaginationAction) => void;
  icon?: React.ReactElement;
  size: string;
  variant: string;
  disabled?: boolean;
};

type NavigationButtons = {
  next: () => PaginationButtonProps;
  prev: () => PaginationButtonProps;
  first: () => PaginationButtonProps;
  last: () => PaginationButtonProps;
};

type HooksProps = IPaginationProps & {
  page: number;
  totalPages: number;
};

type Hook = (props: HooksProps) => PaginationActions;

const itemsAmout = 7;
const startEndAmount = 4;

export const usePagination: Hook = ({
  page,
  totalPages,
  nextLabel,
  prevLabel,
  lastLabel = 'Last',
  firstLabel = 'First',
  showLabel = true,
  onPageClick,
  isDisabled = false,
}): PaginationActions => {
  const [currentPage, setCurrentPage] = React.useState<number>(page);

  const onClick = (clickedPage: number): void => {
    setCurrentPage(clickedPage);
  };

  const onNavigationClick = (action: PaginationAction): void => {
    switch (action) {
      case PaginationAction.First:
        setCurrentPage(1);
        break;
      case PaginationAction.Last:
        setCurrentPage(totalPages);
        break;
      case PaginationAction.Next:
        setCurrentPage((prev) => (prev !== totalPages ? ++prev : prev));
        break;
      case PaginationAction.Prev:
        setCurrentPage((prev) => (prev !== 1 ? --prev : prev));
        break;
    }
  };

  React.useLayoutEffect(() => {
    onPageClick?.(currentPage);
  }, [currentPage]);

  const fullLayout = (): IPaginationItemProps[] => {
    let items: IPaginationItemProps[] = [];

    for (let index = 1; index <= totalPages; index++) {
      items = [
        ...items,
        { key: index, onClick, page: index, isSelected: index === page, isDisabled },
      ];
    }

    return items;
  };

  const edgeLayout = (): IPaginationItemProps[] => {
    let items: IPaginationItemProps[] = [];

    if (page > totalPages - startEndAmount) {
      items = [...items, { key: 1, onClick, page: 1, isDisabled }];
      items = [...items, { isEllipsis: true, key: Math.random(), page: 0, isDisabled }];
    }

    for (let index = 1; index <= 5; index++) {
      const itemPage = page <= 4 ? index : totalPages - 5 + index;
      const isSelected = itemPage === page;

      items = [...items, { key: itemPage, onClick, page: itemPage, isSelected, isDisabled }];
    }

    if (page <= startEndAmount) {
      items = [...items, { isEllipsis: true, key: Math.random(), page: 0, isDisabled }];
      items = [...items, { key: totalPages, onClick, page: totalPages, isDisabled }];
    }

    return items;
  };

  const middleLayout = (): IPaginationItemProps[] => {
    let items: IPaginationItemProps[] = [];
    items = [...items, { key: 1, onClick, page: 1, isDisabled }];
    items = [...items, { isEllipsis: true, key: Math.random(), page: 0, isDisabled }];

    for (let index = page - 1; index < page + 2; index++) {
      items = [
        ...items,
        { key: index, onClick, page: index, isSelected: index === page, isDisabled },
      ];
    }

    items = [...items, { isEllipsis: true, key: Math.random(), page: 0, isDisabled }];
    items = [...items, { key: totalPages, onClick, page: totalPages, isDisabled }];

    return items;
  };

  const getPaginationItems = React.useCallback((): IPaginationItemProps[] => {
    if (totalPages <= itemsAmout) {
      return fullLayout();
    }

    if (page > startEndAmount && page <= totalPages - startEndAmount) {
      return middleLayout();
    }

    return edgeLayout();
  }, [page, totalPages]);

  const getNavigation = (): NavigationButtons => {
    return {
      prev: () => ({
        'aria-label': prevLabel,
        disabled: page === 1 || isDisabled,
        icon: React.createElement(Icon, {
          as: ArrowLeftBold,
          fontSize: '.75em',
        }),
        label: showLabel ? prevLabel : '',
        navAction: PaginationAction.Prev,
        onClick: onNavigationClick,
        size: 'sm',
        variant: 'ghost',
      }),
      next: () => ({
        'aria-label': nextLabel,
        disabled: page === totalPages || isDisabled,
        icon: React.createElement(Icon, {
          as: ArrowRightBold,
          fontSize: '.75em',
        }),
        label: showLabel ? nextLabel : '',
        navAction: PaginationAction.Next,
        onClick: onNavigationClick,
        size: 'sm',
        variant: 'ghost',
      }),
      first: () => ({
        'aria-label': firstLabel,
        disabled: page === 1 || isDisabled,
        icon: React.createElement(Icon, {
          as: ArrowLeftBold,
          fontSize: '.75em',
        }),
        label: showLabel ? firstLabel : '',
        navAction: PaginationAction.First,
        onClick: onNavigationClick,
        size: 'sm',
        variant: 'ghost',
      }),
      last: () => ({
        'aria-label': lastLabel,
        disabled: page === totalPages || isDisabled,
        icon: React.createElement(Icon, {
          as: ArrowRightBold,
          fontSize: '.75em',
        }),
        label: showLabel ? lastLabel : '',
        navAction: PaginationAction.Last,
        onClick: onNavigationClick,
        size: 'sm',
        variant: 'ghost',
      }),
    } as NavigationButtons;
  };

  return {
    getPaginationItems,
    getNavigation,
  };
};
