import { forwardRef } from '@chakra-ui/system';
import { TableRowProps, Tr as TableRowComponent } from '@chakra-ui/table';
import { dataAttr } from '@chakra-ui/utils';
import * as React from 'react';

export interface ITableRowProps extends TableRowProps {
  /* determines whether this row is selected when using the checkbox selection mechanism */
  isSelected?: boolean;
  /* determines what depth this whole row is at, when expanded */
  rowDepth?: number;
}

export const TableRow = forwardRef<ITableRowProps, 'tr'>(
  ({ isSelected = false, rowDepth = 0, ...rest }, ref) => {
    return (
      <TableRowComponent
        data-row-depth={rowDepth}
        data-selected={dataAttr(isSelected)}
        {...rest}
        ref={ref}
      />
    );
  }
);
