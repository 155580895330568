import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { assessmentStatusTranslations } from '../constants';
import {
  assessmentColumnHeaders,
  assessmentColumnHeaderAccessors,
  uploadPreparedAssessmentColumnHeaders,
  uploadPreparedAssessmentColumnHeaderAccessors,
} from '../constants';
import { formatDate } from './formatDate';
import { excelExportTypes } from '../constants/excelExportTypes';
import { isChannelLongTail } from './isChannelLongTail';
import { extractSoldToFromJoinedCustomerId } from './extractSoldToFromJoinedCustomerId';

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtension = '.xlsx';
export const simpleExportFileName = 'Mcp_Overview';
export const readyForUploadFileName = 'Mcp_Overview_Ready_For_Upload';

const uploadPreparedAssessmentColumnHeadersArray = Object.values(
  uploadPreparedAssessmentColumnHeaders
);
const simpleExportWorkSheetHeadersArray = [
  assessmentColumnHeaders.YEAR,
  assessmentColumnHeaders.C6_ID,
  assessmentColumnHeaders.CUSTOMER_ID,
  assessmentColumnHeaders.CUSTOMER_NAME,
  assessmentColumnHeaders.C6_NAME,
  assessmentColumnHeaders.BUSINESS_UNIT,
  assessmentColumnHeaders.TIER,
  assessmentColumnHeaders.CHANNEL,
  assessmentColumnHeaders.CUSTOMER_EMAIL,
  assessmentColumnHeaders.STATUS,
  assessmentColumnHeaders.PERFORM_USER,
  assessmentColumnHeaders.REVIEW_USER,
  assessmentColumnHeaders.APPROVE_USER,
  assessmentColumnHeaders.PENDING_USER,
  assessmentColumnHeaders.SCORE,
];

const mapDownloadDataToHeaders = (row, headersArray, headersAccessors) =>
  headersArray.reduce((rowObject, nextHeader) => {
    const accessor = headersAccessors[nextHeader];

    if (nextHeader === assessmentColumnHeaders.STATUS) {
      rowObject[nextHeader] = assessmentStatusTranslations[row[accessor]];
      return rowObject;
    }

    rowObject[nextHeader] = row[accessor];
    return rowObject;
  }, {});

const getDateAndTimeString = () => {
  let dateString = formatDate(new Date(), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

  dateString = dateString.replace(',', '');
  return dateString.replace(' ', '_');
};

const exportConfigurations = {
  [excelExportTypes.SIMPLE]: {
    mapper: (assessment) =>
      mapDownloadDataToHeaders(
        assessment,
        simpleExportWorkSheetHeadersArray,
        assessmentColumnHeaderAccessors
      ),
    headers: simpleExportWorkSheetHeadersArray,
  },
  [excelExportTypes.UPLOAD_PREPARED]: {
    mapper: (assessment) =>
      mapDownloadDataToHeaders(
        assessment,
        uploadPreparedAssessmentColumnHeadersArray,
        uploadPreparedAssessmentColumnHeaderAccessors
      ),
    headers: uploadPreparedAssessmentColumnHeadersArray,
  },
};

export const mapAssessmentsByType = (assessments, excelExportType) => {
  const configuration = exportConfigurations[excelExportType];

  if (!configuration) {
    throw new Error(`Unsupported excel export type: ${excelExportType}`);
  }

  const assessmentsToBeExported = assessments.map(configuration.mapper);

  return {
    assessmentsToBeExported,
    workSheetHeadersArray: configuration.headers,
  };
};

const prepareAssessmentsForDownload = (assessments) => {
  return assessments.map((assessment) => ({
    ...assessment,
    customerId: isChannelLongTail(assessment.channel)
      ? assessment.customerId
      : extractSoldToFromJoinedCustomerId(
          assessment.customerId,
          assessment.c6Id
        ),
  }));
};

const saveAssessmentsToExcelFile = (
  assessmentsForDownload,
  excelExportType
) => {
  assessmentsForDownload = prepareAssessmentsForDownload(
    assessmentsForDownload
  );

  const {
    assessmentsToBeExported,
    workSheetHeadersArray,
  } = mapAssessmentsByType(assessmentsForDownload, excelExportType);

  const workSheet = XLSX.utils.json_to_sheet(assessmentsToBeExported, {
    header: workSheetHeadersArray,
  });

  const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  const fileName =
    excelExportType === excelExportTypes.UPLOAD_PREPARED
      ? readyForUploadFileName
      : simpleExportFileName;

  FileSaver.saveAs(
    data,
    `${fileName}_${getDateAndTimeString()}${fileExtension}`
  );
};

export { saveAssessmentsToExcelFile };
