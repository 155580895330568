import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesPdfBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM7.5 14.62h-.62a.25.25 0 00-.25.25V17a.63.63 0 01-1.25 0v-6a.63.63 0 01.62-.63h1.5a2.125 2.125 0 010 4.25zm3.5 3a.62.62 0 01-.62-.62v-6a.63.63 0 01.63-.63 3.625 3.625 0 010 7.25H11zm8-6h-2a.38.38 0 00-.37.38v1.12a.25.25 0 00.25.25H18a.624.624 0 110 1.25h-1.12a.25.25 0 00-.25.25V17a.63.63 0 01-1.25 0v-5A1.63 1.63 0 0117 10.37h2a.624.624 0 110 1.25z" />
        <path d="M7.5 11.62h-.62a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25h.62a.88.88 0 000-1.75zm4.5.2c-.1 0-.33 0-.33.16v4a.241.241 0 00.218.227.241.241 0 00.112-.017 2.37 2.37 0 000-4.35v-.02z" />
      </g>
    );
  }
);
FilesPdfBold.displayName = 'FilesPdfBold';
