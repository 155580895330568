import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ShoppingCartRemoveItem = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M10.66 16.6H6.77a.08.08 0 01-.08-.07l-.44-2.36h5.33a.75.75 0 100-1.5H5.95L4.68 5.94h15.07v.08l-1.17 3.93a.75.75 0 00.5.93c.07.01.14.01.21 0a.75.75 0 00.72-.54l1.24-3.89a1.58 1.58 0 00-1.52-2H4.4l-.61-3.2A1.53 1.53 0 002.29 0H.75a.75.75 0 100 1.5h1.57l2.9 15.24a1.59 1.59 0 001.56 1.29h3.88a.75.75 0 00.53-1.28c-.14-.141-.331-.15-.53-.15zm-3.08 4.52a1.22 1.22 0 100-2.44 1.22 1.22 0 000 2.44z" />
        <path d="M17.87 11.12a6 6 0 100 12 6 6 0 000-12zm0 10.57a4.54 4.54 0 114.54-4.54 4.54 4.54 0 01-4.54 4.55v-.01z" />
        <path d="M19.85 16.41h-4a.75.75 0 100 1.5h4a.75.75 0 100-1.5z" />
      </g>
    );
  }
);
ShoppingCartRemoveItem.displayName = 'ShoppingCartRemoveItem';
