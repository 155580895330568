import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LogInBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.168.5a2.905 2.905 0 012.079.945c.506.556.775 1.286.752 2.035v17.04a2.886 2.886 0 01-.753 2.035 2.906 2.906 0 01-2.078.945H9.47c-.026 0-.051 0-.076-.002a2.906 2.906 0 01-2.003-.943 2.886 2.886 0 01-.753-2.035v-2.21a1.53 1.53 0 011.532-1.526 1.53 1.53 0 011.532 1.527v2.136h11.233V3.553H9.702v2.545A1.53 1.53 0 018.17 7.624a1.53 1.53 0 01-1.532-1.526V3.479a2.888 2.888 0 01.752-2.034A2.906 2.906 0 019.47.5h11.697z" />
        <path d="M11.886 7.362a1.523 1.523 0 000 2.16l.96.955H1.531A1.53 1.53 0 000 12.004a1.53 1.53 0 001.532 1.526h11.313l-.959.956a1.523 1.523 0 000 2.16 1.536 1.536 0 002.167 0l3.569-3.558.048-.05a1.519 1.519 0 00-.043-2.114l-3.574-3.562a1.536 1.536 0 00-2.167 0z" />
      </g>
    );
  }
);
LogInBold.displayName = 'LogInBold';
