import { css } from '@emotion/core';
import { font, spacing, colors, baseUnit } from '../../config';

export const wrapperStyle = css({
  display: 'flex',
  alignItems: 'stretch',
  width: 'auto',
});

export const indicatorStyle = (activeColor, isLast, isValid) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 0,
    '&::after': !isLast && {
      margin: `${baseUnit}px 0`,
      borderRadius: 2,
      width: baseUnit,
      backgroundColor: isValid ? activeColor : colors.neutralColors.slate20,
      content: `''`,
      display: 'block',
      flexGrow: 1,
      height: baseUnit,
    },
  });

export const numberStyle = (
  activeColor,
  isActive,
  isComplete,
  isValid,
  isWarning
) => {
  const numberSize = baseUnit * 8;

  return css({
    alignItems: 'center',
    backgroundColor: getBackgroundColor(
      activeColor,
      isActive,
      isComplete,
      isWarning
    ),
    borderColor: getBorderColor(activeColor, isActive, isValid, isWarning),
    borderRadius: numberSize / 2,
    borderWidth: 3,
    borderStyle: 'solid',
    color: getNumberColor(
      activeColor,
      isActive,
      isComplete,
      isValid,
      isWarning
    ),
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    fontSize: font.size.size1,
    fontWeight: font.weight.medium,
    height: numberSize,
    justifyContent: 'center',
    outline: 'none',
    textDecoration: 'none',
    width: numberSize,
  });
};

export const contentWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: spacing.size4,
  minHeight: spacing.size16,
});

export const headerStyle = (activeColor, isActive, isValid, isWarning) =>
  css({
    padding: `${baseUnit / 2}px ${spacing.size4}px ${baseUnit / 2}px 0`,
    fontSize: font.size.size1,
    color: getHeaderColor(activeColor, isActive, isValid, isWarning),
    fontWeight: !isActive ? font.weight.regular : font.weight.medium,
  });

export const contentStyle = (isActive, questionHeight) =>
  css({
    display: 'flex',
    overflow: 'hidden',
    visibility: isActive ? 'visible' : 'hidden',
    height: getContentHeight(isActive, questionHeight),
    opacity: isActive ? 1 : 0,
    transition: 'height .5s ease, opacity .5s ease',
    paddingLeft: baseUnit / 2,
  });

// Helpers

function getBackgroundColor(activeColor, isActive, isComplete, isWarning) {
  if (isWarning && !isActive) return colors.utilityColors.warning.warning400;
  return isComplete && !isActive ? activeColor : colors.neutralColors.white;
}

function getBorderColor(activeColor, isActive, isValid, isWarning) {
  if (isWarning) return colors.utilityColors.warning.warning400;

  return !isActive && !isValid ? colors.neutralColors.slate20 : activeColor;
}

function getHeaderColor(activeColor, isActive, isValid, isWarning) {
  if (isWarning) return colors.utilityColors.warning.warning400;

  !isActive && !isValid ? colors.neutralColors.slateBase : activeColor;
}

function getNumberColor(activeColor, isActive, isComplete, isValid, isWarning) {
  if (isWarning) return colors.utilityColors.warning.warning400;
  if (isActive || isValid) return activeColor;
  if (isComplete) return colors.neutralColors.white;
  return colors.neutralColors.slateBase;
}

function getContentHeight(isActive, questionHeight) {
  if (!questionHeight) return 'auto';
  if (questionHeight && isActive) return questionHeight;
  return 0;
}
