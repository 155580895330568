import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import * as React from 'react';

export interface IWellOptions {
  hasBorder?: boolean;
}

export interface IWellProps extends HTMLChakraProps<'div'>, IWellOptions, ThemingProps<'Well'> {}

export const Well = forwardRef<IWellProps, 'div'>((props, ref) => {
  const { hasBorder = true, ...rest } = omitThemingProps(props);

  const styles = useMultiStyleConfig('Well', { hasBorder });

  return <chakra.div __css={styles} ref={ref} {...rest} />;
});
