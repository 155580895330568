import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const AccountingDocumentBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M22.34 6.53L16.47.66A2.25 2.25 0 0014.88 0H4.22A2.25 2.25 0 002 2.25v19.5A2.25 2.25 0 004.22 24h16.5a2.25 2.25 0 002.25-2.25V8.12a2.25 2.25 0 00-.63-1.59zm-15.69-.4a3 3 0 011.16-.52v-.36a.75.75 0 011.5 0v.35a3 3 0 011.7 1.1.75.75 0 11-1.19.91A1.47 1.47 0 008.6 7a1.7 1.7 0 00-1 .3.64.64 0 00-.3.49.64.64 0 00.3.49 1.7 1.7 0 001 .3 3.18 3.18 0 011.91.6 2 2 0 010 3.39 3 3 0 01-1.15.52v.36a.75.75 0 11-1.5 0v-.35A3 3 0 016.1 12a.75.75 0 011.19-.91 1.47 1.47 0 001.23.58 1.7 1.7 0 001-.3.55.55 0 000-1 1.69 1.69 0 00-1-.3 3.19 3.19 0 01-1.91-.6 2.13 2.13 0 01-.9-1.69 2.13 2.13 0 01.94-1.65zM18.5 18.75a.75.75 0 110 1.5H7.22a.75.75 0 110-1.5H18.5zm0-3h-6a.75.75 0 110-1.5h6a.75.75 0 110 1.5z" />
      </g>
    );
  }
);
AccountingDocumentBold.displayName = 'AccountingDocumentBold';
