import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const DevicesSmartWatchBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M16.43 2.75a.248.248 0 00.2-.1.25.25 0 00.035-.225l-.69-2.083A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.035.225.25.25 0 00.2.1l8.863.004zm-8.86 18.5a.251.251 0 00-.238.329l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.25.25 0 00-.238-.329H7.57z" />
        <path
          clipRule="evenodd"
          d="M6.5 4h11A2.5 2.5 0 0120 6.5v11a2.5 2.5 0 01-2.5 2.5h-11A2.508 2.508 0 014 17.5v-11A2.5 2.5 0 016.5 4zm4.25 4a1 1 0 10-2 0 1 1 0 002 0zm-3.5 5a1 1 0 110-2 1 1 0 010 2zm1.5 3a1 1 0 102 0 1 1 0 00-2 0zM10 12.25a2 2 0 114 0 2 2 0 01-4 0zM13.25 16a1 1 0 102 0 1 1 0 00-2 0zm1-7a1 1 0 110-2 1 1 0 010 2zm1.5 3a1 1 0 102 0 1 1 0 00-2 0z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
DevicesSmartWatchBold.displayName = 'DevicesSmartWatchBold';
