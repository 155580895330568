import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Strategy = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M10.88.647a1.298 1.298 0 012.25 0l2.337 4.142a1.295 1.295 0 01-.448 1.824 1.3 1.3 0 01-.67.172h-1.6v9.72a9.732 9.732 0 014.867-3.502l-.73-1.747a1.306 1.306 0 011.39-1.786l4.596.648a1.301 1.301 0 011.03.79 1.296 1.296 0 01-.17 1.286l-2.788 3.703a1.3 1.3 0 01-2.249-.29l-.5-1.207c-3.587 1.297-5.356 3.553-5.446 7.275v1.597a.748.748 0 01-1.489.1v-1.388c0-3.892-1.758-6.237-5.446-7.564l-.5 1.217a1.307 1.307 0 01-1.711.703 1.31 1.31 0 01-.537-.413L.268 12.164a1.296 1.296 0 01.86-2.076l4.597-.648a1.31 1.31 0 011.467 1.125c.03.228 0 .46-.088.671l-.72 1.766a9.732 9.732 0 014.867 3.503v-9.74h-1.6a1.3 1.3 0 01-1.333-1.332c.007-.236.077-.465.205-.664L10.88.647zM14 5.268l-2-3.563-1.999 3.563H14zm-9.184 7.565l.78-1.826-3.928.548 2.339 3.134.81-1.856zm17.518-1.308l-3.928-.518.78 1.856.81 1.826 2.338-3.164z" />
    </g>
  );
});
Strategy.displayName = 'Strategy';
