import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesWordBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.71 5.71L16.29.29a1 1 0 00-.7-.29H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.29-.7zM5.5 17.12a.62.62 0 01-.62-.62v-6a.63.63 0 01.63-.62 3.625 3.625 0 110 7.25l-.01-.01zM14.13 15a2.13 2.13 0 01-4.25 0v-3a2.125 2.125 0 014.25 0v3zm4.38.88a.624.624 0 110 1.25 3.625 3.625 0 010-7.25.625.625 0 110 1.25 2.38 2.38 0 000 4.75z" />
        <path d="M6.45 11.33c-.1 0-.33 0-.33.16v4a.24.24 0 00.33.21 2.37 2.37 0 000-4.35v-.02zm5.55-.21a.88.88 0 00-.87.88v3a.88.88 0 101.75 0v-3a.88.88 0 00-.88-.88z" />
      </g>
    );
  }
);
FilesWordBold.displayName = 'FilesWordBold';
