import { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { css } from '@emotion/react';

import { Button, LinearProgress } from '@lego/klik-react';

import { Dropzone } from '../../Dropzone';
import { Heading } from '../../Heading';
import { invokeGenerateDataLambda } from '../utils/invokeGenerateDataLambda';
import { logger } from '../../../utils/logger';
import { media } from '../../../config';
import { imageUploadErrorTypes } from '../../../constants';
import { OverlayPortal } from '../../OverlayPortal';
import { PreviousCustomerMapping } from '../components/PreviousCustomerMapping';
import { Spinner } from '../../spinner/Spinner';
import { UploadedFile } from '../components/UploadedFile';

import {
  addSnackbar,
  snackbarTypes,
  snackbarDefaultMessages,
} from '../../../utils/snackbar';
import { spinnerOverlayText } from '../../../styles/common';

const bucketName = process.env.CUSTOMER_MAPPING_BUCKET;
const s3StorageOptions = {
  bucket: bucketName,
  level: 'private',
};
const processedLocation = 'customer-mapping/processed/';
const acceptedFiles = ['.xlsx'];

const progressBarContainer = css({
  [media.medium]: {
    width: 400,
  },
});

const uploadDropzoneWrapperStyle = css({
  width: '50%',
});

const uploadRejected = (errorsArray) => {
  const error = errorsArray[0].errors[0];
  let errorMessage;

  switch (error.code) {
    case imageUploadErrorTypes.FILE_INVALID_TYPE:
      errorMessage = 'Invalid file type. Only Excel worksheets are allowed.';
      break;

    case imageUploadErrorTypes.TOO_MANY_FILES:
      errorMessage = 'Too many files. Only one file at a time is allowed.';
      break;

    case imageUploadErrorTypes.FILE_TOO_LARGE:
      errorMessage = 'File is too large. Max size is 10 MB.';
      break;

    default:
      errorMessage = snackbarDefaultMessages.errorText;
  }

  return addSnackbar({
    header: snackbarDefaultMessages.errorHeader,
    text: errorMessage,
    type: snackbarTypes.ERROR,
  });
};

const CustomerChannelMapping = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [historicFiles, setHistoricFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [generateLoading, setGenerateLoading] = useState(false);

  const requestDataGenerate = async () => {
    setGenerateLoading(true);
    invokeGenerateDataLambda(currentFile.path)
      .then(() => {
        setCurrentFile(null);
      })
      .catch((error) => {
        logger.logError(error);
      })
      .finally(() => {
        setGenerateLoading(false);
        retrieveHistoricFiles();
        document.dispatchEvent(new CustomEvent('customerDataGenerated'), {});
      });
  };

  const retrieveHistoricFiles = () => {
    setFilesLoading(true);
    const getFromS3 = async () => {
      const s3Files = await Storage.list(processedLocation, s3StorageOptions);

      setHistoricFiles(
        await Promise.all(
          s3Files.map(async (file) => {
            return {
              key: file.key,
              lastModified: file.lastModified,
              downloadLink: await Storage.get(file.key, {
                bucket: bucketName,
                level: 'private',
              }),
            };
          })
        )
      );
      setFilesLoading(false);
    };
    getFromS3();
  };

  useEffect(() => {
    retrieveHistoricFiles();
  }, []);

  const uploadCustomerMappingFile = async (file) => {
    if (!file) return false;
    const filePath = 'customer-mapping/upload/' + file.path;
    try {
      await Storage.put(filePath, file, {
        bucket: bucketName,
        level: 'private',
        contentType: file.type,
        progressCallback(progress) {
          const uploadProgressInPercent = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setUploadProgress(uploadProgressInPercent);
        },
      });
      setCurrentFile(file);
      setUploadProgress(0);
    } catch (error) {
      logger.logError(error);
    }
  };

  const deleteFile = async () => {
    const filePath = 'customer-mapping/upload/' + currentFile.path;
    try {
      await Storage.remove(filePath, s3StorageOptions);
      setCurrentFile(null);
    } catch (error) {
      logger.logError(error);
    }
  };

  const generateData = async () => {
    setGenerateLoading(true);
    await requestDataGenerate();
  };

  if (generateLoading) {
    return (
      <OverlayPortal>
        <Spinner />
        <p css={spinnerOverlayText}>
          Generating data. This may take a few minutes.
        </p>
      </OverlayPortal>
    );
  }

  return (
    <div>
      <Heading type="h2" text="Customer / Channel Mapping" />
      {filesLoading ? (
        <div css={progressBarContainer}>
          <LinearProgress />{' '}
        </div>
      ) : (
        <PreviousCustomerMapping uploadedFiles={historicFiles} />
      )}
      <div css={uploadDropzoneWrapperStyle}>
        <Heading type="h3" text="Upload new customer/channel mapping" />
        <Dropzone
          acceptedFiles={acceptedFiles}
          uploadProgress={uploadProgress}
          onDrop={(fileDropped) => uploadCustomerMappingFile(fileDropped[0])}
          onDropRejected={uploadRejected}
          isDisabled={!!uploadProgress}
        />
      </div>
      {currentFile && (
        <UploadedFile uploadedFile={currentFile} onDelete={deleteFile} />
      )}

      <div style={{ marginTop: !currentFile ? '48px' : '0px' }}>
        <Button
          label="Generate Data"
          data-transaction-name="Generate Data - Customer Channel Mapping"
          disabled={!currentFile}
          onClick={() => generateData()}
        />
      </div>
    </div>
  );
};

export { CustomerChannelMapping };
