import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors } from '../config';

const overlayPortalStyle = css({
  position: 'absolute',
  height: '80%',
  width: '100%',
  marginTop: 80, // Top bar height
  background: colors.neutralColors.white,
  zIndex: 99,
});

const OverlayPortal = ({ children }) => {
  const overlayPortalRootDiv = document.getElementById('overlay');
  const overlayElement = document.createElement('div');

  useEffect(() => {
    overlayPortalRootDiv.appendChild(overlayElement);
    return () => overlayPortalRootDiv.removeChild(overlayElement);
  }, [overlayElement, overlayPortalRootDiv]);

  return createPortal(
    <div css={overlayPortalStyle}>{children}</div>,
    overlayElement
  );
};

OverlayPortal.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OverlayPortal };
