import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesAnalyticsBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#files-analytics-bold_svg__a)">
          <path d="M15.59 0a1 1 0 01.71.29l5.41 5.41a1 1 0 01.29.71V22a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2h11.59zm3.91 22a.5.5 0 00.5-.5V7a.5.5 0 00-.15-.35l-4.53-4.5A.5.5 0 0015 2H4.5a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h15z" />
          <path d="M20.06 5.68l-3.54-3.8-13.17-.46v21.23h17l-.29-16.97zM6.53 13.25L9.8 10a1 1 0 011.2-.17l1.66 1a.25.25 0 00.3 0l2.5-2.5a1 1 0 011.44 1.38l-3.2 3.19a1 1 0 01-1.22.15l-1.66-1a.25.25 0 00-.3 0l-2.58 2.61a1 1 0 01-1.41-1.41zm10.22 5.19H7a1 1 0 010-2h9.75a1 1 0 010 2z" />
        </g>
        <defs>
          <clipPath id="files-analytics-bold_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
FilesAnalyticsBold.displayName = 'FilesAnalyticsBold';
