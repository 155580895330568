import { Fragment, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import { Button } from '@lego/klik-react';
import { spacing, colors, media, padding } from '../../config';
import { Stepper, Step } from '../stepper';
import { QuestionReview } from '../question/QuestionReview';
import { AssessmentScore } from '../AssessmentScore';
import { AssessmentInfoSection } from '../AssessmentInfoSection';
import { OverlayPortal } from '../OverlayPortal';
import { Spinner } from '../spinner/Spinner';
import { Divider } from '../Divider';
import { GET_ASSESSMENT_DATA, FINISH_SURVEY } from '../../queries/assessment';
import { routes } from '../RouteHandler';
import { Heading } from '../Heading';
import { assessmentStatus as assessmentStatusTypes } from '../../constants';
import {
  areAllAnswersComplete,
  isAnyQuestionMissingProof,
  enhanceQuestionsWithAnswersAndProof,
  adjustAssessmentsBasedOnFeatureFlags,
} from '../assessment-scoring/helpers';
import { useFetchImagesFromS3 } from '../../hooks/useFetchImagesFromS3';
import { generalApiErrorHandler } from '../../utils/generalApiErrorHandler';
import {
  addSnackbar,
  snackbarTypes,
  snackbarDefaultMessages,
} from '../../utils/snackbar';
import { MissingProofExplainer } from '../MissingProofExplainer';
import { MissingProofWarningModal } from '../modals/MissingProofWarningModal';

const assessmentReviewStyle = css({
  maxWidth: '768px',
  margin: 'auto',
  padding: `${spacing.size10}px ${padding.medium}px ${padding.medium}px ${padding.small}px`,
  [media.medium]: {
    padding: `${spacing.size10 * 2}px ${padding.medium}px ${padding.large}px`,
  },
});

const sectionContainerStyle = css({
  marginTop: spacing.size10,
  paddingBottom: spacing.size10,
});

const actionButtonsWrapper = css({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: spacing.size8,
  marginBottom: spacing.size16,
  'button:first-of-type': {
    marginRight: spacing.size5,
  },
});

const ReviewPage = () => {
  const history = useHistory();
  const { customerId, assessmentYear, version } = useParams();
  const [isProofWarningModalVisible, setIsProofWarningModalVisible] = useState(
    false
  );

  const {
    data: reviewData,
    loading: isReviewPageLoading,
    error: reviewDataError,
  } = useQuery(GET_ASSESSMENT_DATA, {
    variables: {
      customerId,
      year: Number(assessmentYear),
      version: Number(version),
    },
    fetchPolicy: 'network-only',
  });

  const [finishSurvey, { loading: finishSurveyLoading }] = useMutation(
    FINISH_SURVEY,
    {
      onCompleted: handleFinishSurveyResponse,
    }
  );

  const { uploadedImagesKeys } = useFetchImagesFromS3({
    uploadId: reviewData?.getSurveyInfo?.uploadId,
    skip: !reviewData,
  });

  async function submitSurvey() {
    try {
      await finishSurvey({
        variables: {
          customerId,
          year: Number(assessmentYear),
          version: Number(version),
        },
      });
    } catch (error) {
      generalApiErrorHandler(error);
    }
  }

  async function handleFinishSurvey() {
    if (isAnyQuestionMissingProof(enhancedQuestions)) {
      setIsProofWarningModalVisible(true);
      return;
    }

    await submitSurvey();
  }

  function handleFinishSurveyResponse(response) {
    if (response.finishSurvey) {
      const {
        getSurveyInfo: {
          customerDetail: { name },
        },
      } = reviewData;

      addSnackbar({
        header: snackbarDefaultMessages.successHeader,
        text: `${name}'s assessment was submitted.`,
        type: snackbarTypes.SUCCESS,
      });

      history.push(routes.overview);
    }
  }

  // Error boundary catches this error
  if (reviewDataError) throw reviewDataError;

  if (isReviewPageLoading || finishSurveyLoading) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  const {
    getAnswers: savedAnswers,
    getSurvey: questions,
    getSurveyInfo: {
      surveyState: assessmentState,
      assessmentStatus,
      customerDetail,
    },
  } = reviewData;

  const surveyDetailsForFeatureFlag = {
    year: Number(assessmentYear),
    channel: customerDetail.channel,
  };

  let enhancedQuestions = enhanceQuestionsWithAnswersAndProof(
    questions,
    savedAnswers,
    uploadedImagesKeys
  );
  enhancedQuestions = adjustAssessmentsBasedOnFeatureFlags(
    enhancedQuestions,
    surveyDetailsForFeatureFlag
  );

  return (
    <div>
      {isProofWarningModalVisible && (
        <MissingProofWarningModal
          onClose={() => {
            setIsProofWarningModalVisible(false);
          }}
          onConfirm={() => {
            setIsProofWarningModalVisible(false);
            history.push(
              `${routes.scoring(customerId, assessmentYear, version)}?mode=edit`
            );
          }}
        />
      )}
      <Heading type="h1" text="Review assessment" />

      <AssessmentInfoSection assessmentInfo={reviewData.getSurveyInfo} />

      <Fragment>
        <div css={assessmentReviewStyle}>
          <Stepper activeColor={colors.neutralColors.slate60}>
            {enhancedQuestions.map((question) => {
              const {
                questionId,
                headline,
                savedAnswer,
                questionImageKeys,
                isMissingProof,
              } = question;

              return (
                <Step
                  stepId={questionId}
                  key={questionId}
                  header={headline}
                  isValid={savedAnswer.isValid}
                  isWarning={isMissingProof}
                  isComplete={savedAnswer.isComplete}
                >
                  <QuestionReview
                    question={question}
                    answer={savedAnswer}
                    uploadImageKeys={questionImageKeys}
                  />
                </Step>
              );
            })}
          </Stepper>
        </div>
        <Divider />
        <div css={sectionContainerStyle}>
          <Heading type="h2" text="Score" />
          <AssessmentScore
            scoreText="The calculated functional discount score is:"
            score={assessmentState.score}
          />
        </div>
        {isAnyQuestionMissingProof(enhancedQuestions) && (
          <MissingProofExplainer />
        )}
      </Fragment>

      <div css={sectionContainerStyle}>
        {(assessmentStatus === assessmentStatusTypes.ASSESSMENT ||
          assessmentStatus === assessmentStatusTypes.SELF_ASSESSMENT ||
          assessmentStatus === assessmentStatusTypes.RE_ASSESSMENT) &&
          areAllAnswersComplete(savedAnswers) && (
            <div css={actionButtonsWrapper}>
              <Link
                to={{
                  pathname: routes.scoring(customerId, assessmentYear, version),
                  search: '?mode=edit',
                }}
              >
                <Button
                  label="Edit"
                  data-transaction-name="Edit assessment"
                  variant="outline"
                />
              </Link>
              <Button
                label="Submit assessment"
                data-transaction-name="Submit assessment - from review"
                onClick={handleFinishSurvey}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export { ReviewPage };
