import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { css } from '@emotion/core';
import { colors, spacing, font } from '../config';
import { CloudUpload } from '@lego/klik-icons-react';

const {
  neutralColors: { white, slate02, slate20, slate40, slate50, slate60 },
  boldColors: {
    lightBlue: { ligthBlue20 },
  },
  utilityColors: { information, success, error },
} = colors;

const getColor = (isDisabled, isDragAccept, isDragActive, isDragReject) => {
  if (isDragAccept) {
    return success.base;
  }
  if (isDragReject) {
    return error.error500;
  }
  if (isDragActive) {
    return information.base;
  }
  if (isDisabled) {
    return slate20;
  }
  return slate60;
};

const DropzoneContainerStyle = (
  isDisabled,
  isDragAccept,
  isDragActive,
  isDragReject
) =>
  css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: getColor(isDisabled, isDragAccept, isDragActive, isDragReject),
    borderStyle: 'dashed',
    backgroundColor: isDisabled ? slate02 : white,
    color: isDisabled ? slate40 : slate60,
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
    height: 120,
    position: 'relative',
  });

const UploadIconStyle = css({
  zIndex: 1,
  color: slate50,
});

const UploadTextStyle = css({
  lineHeight: font.lineHeight.s,
  marginTop: spacing.size1,
  color: slate50,
  zIndex: 1,
});

const UploadProgressbarStyle = (uploadProgress) =>
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: ligthBlue20,
    width: `${uploadProgress}%`,
  });

const Dropzone = ({
  acceptedFiles,
  uploadProgress,
  onDrop,
  isDisabled,
  isMultipleFilesAllowed = false,
  onDropRejected,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptedFiles,
    disabled: isDisabled,
    onDrop,
    multiple: isMultipleFilesAllowed,
    maxSize: 10000000, // 10 MB
    onDropRejected,
  });

  return (
    <section className="container">
      <div
        aria-label="upload-drag-and-drop-area"
        css={DropzoneContainerStyle(
          isDisabled,
          isDragAccept,
          isDragActive,
          isDragReject
        )}
        {...getRootProps({})}
      >
        <input data-transaction-name="Upload Image" {...getInputProps()} />
        <CloudUpload css={UploadIconStyle} />
        <p css={UploadTextStyle}>
          {uploadProgress ? (
            <span>Uploading image, please wait ...</span>
          ) : (
            <span>
              Drag and drop image files here,
              <br />
              or click to select from disk.
            </span>
          )}
        </p>
        {!!uploadProgress && (
          <div css={UploadProgressbarStyle(uploadProgress)}></div>
        )}
      </div>
    </section>
  );
};

Dropzone.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  uploadProgress: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isMultipleFilesAllowed: PropTypes.bool,
};

export { Dropzone };
