import { forwardRef } from '@chakra-ui/system';
import { Tooltip as TooltipComponent, TooltipProps, UseTooltipProps } from '@chakra-ui/tooltip';
import * as React from 'react';

export type ITooltipProps = TooltipProps;

export const Tooltip = forwardRef<ITooltipProps, 'div'>(({ modifiers = [], ...rest }, ref) => {
  const internalModifiers: UseTooltipProps['modifiers'] = [
    ...modifiers,
    {
      name: 'applyArrowHide',
      enabled: true,
      phase: 'write',
      fn({ state }) {
        const { arrow } = state.elements;

        // we cannot style the arrow directly, we're changing the zIndex here like this because // of the box-shadow of the tooltip
        if (arrow) {
          arrow.style.zIndex = '1';
        }
      },
    },
  ];

  return <TooltipComponent hasArrow={true} modifiers={internalModifiers} {...rest} ref={ref} />;
});
