import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const ShoppingCartEmptyBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M7.88 23.75a1.76 1.76 0 100-3.52 1.76 1.76 0 000 3.52zm9.12 0a1.76 1.76 0 100-3.52 1.76 1.76 0 000 3.52zM22.88 5.5a1.88 1.88 0 00-1.52-.76H5.08L4.46 1.5A1.83 1.83 0 002.63 0H1a1 1 0 100 2h1.52l3.11 16.22a1.9 1.9 0 001.86 1.54h10.54a1 1 0 100-2H7.55l-.41-2.17h10.83a3.52 3.52 0 003.38-2.5l1.82-5.92a1.88 1.88 0 00-.29-1.67z" />
      </g>
    );
  }
);
ShoppingCartEmptyBold.displayName = 'ShoppingCartEmptyBold';
