import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesDatabaseBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M17.086.587l4.328 4.328A2 2 0 0122 6.329V22a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2h11.672a2 2 0 011.414.587zm-5.6 19.664c3.264 0 5.264-1.144 5.264-1.767v-1.46a.244.244 0 00-.358-.218 11.7 11.7 0 01-4.906.978 11.743 11.743 0 01-4.865-.938.244.244 0 00-.356.219v1.46c0 .586 1.842 1.726 5.221 1.726zm5.264-5.734c0 .623-2 1.767-5.264 1.767-3.379 0-5.221-1.141-5.221-1.727v-1.573a.243.243 0 01.356-.219c1.537.655 3.195.975 4.865.938a11.7 11.7 0 004.906-.978.244.244 0 01.358.219v1.573zM6.265 10.473c0 .586 1.842 1.727 5.221 1.727 3.266 0 5.264-1.14 5.264-1.766 0-.572-1.857-1.686-5.264-1.686-3.379 0-5.221 1.139-5.221 1.725z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
FilesDatabaseBold.displayName = 'FilesDatabaseBold';
