import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudAvailableBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M22.224 9.735A5.825 5.825 0 0124 13.908a5.819 5.819 0 01-5.185 5.783.938.938 0 01-.132.009H4.81a5.38 5.38 0 01-3.567-1.38 4.34 4.34 0 01-1.24-3.219 4.601 4.601 0 014.259-4.582.255.255 0 00.23-.204A7.69 7.69 0 0118.59 8.026c.042.07.114.115.194.123a5.72 5.72 0 013.44 1.586zM4.827 17.658h13.78a3.784 3.784 0 003.347-3.75 3.741 3.741 0 00-3.886-3.743.98.98 0 01-.96-.582 5.65 5.65 0 00-10.75 2.076 1.02 1.02 0 01-1.226.94 2.56 2.56 0 00-3.086 2.503c-.044.65.185 1.288.631 1.763a3.37 3.37 0 002.15.793z"
          fillRule="evenodd"
        />
      </g>
    );
  }
);
CloudAvailableBold.displayName = 'CloudAvailableBold';
