import {
  ListProps as OrderedListProps,
  OrderedList as OrderedListComponent,
} from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import * as React from 'react';

export type IOrderedListProps = OrderedListProps;

export const OrderedList = forwardRef<IOrderedListProps, 'ol'>((props, ref) => {
  return <OrderedListComponent spacing="3" {...props} ref={ref} />;
});
