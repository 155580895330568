import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Balloon = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path d="M14.102 7.48a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48zm-4.76.01a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48z" />
      <path d="M20.002 23c-1-2.52-3.27-3.92-6.58-3.84-.77 0-1-.3-1.1-.4a2.6 2.6 0 01-.32-1.76c4.29-.07 7.76-3.84 7.76-8.46 0-4.62-3.54-8.54-7.89-8.54s-7.87 3.84-7.87 8.51a8.33 8.33 0 006.53 8.33 3.91 3.91 0 00.62 2.81 2.83 2.83 0 002.3 1c1.83 0 4.23.44 5.15 2.86a.76.76 0 00.7.49.86.86 0 00.27-.05.75.75 0 00.43-.95zM5.482 8.51a6.7 6.7 0 016.39-7 6.7 6.7 0 016.39 7 6.7 6.7 0 01-6.39 7 6.701 6.701 0 01-6.39-7z" />
      <path d="M11.832 12.75a5 5 0 01-3.41-1.67.754.754 0 111.13-1 3.58 3.58 0 002.28 1.15 2.8 2.8 0 002-1.15.74.74 0 011-.08.76.76 0 01.17 1.08 4.2 4.2 0 01-3.18 1.67h.01z" />
    </g>
  );
});
Balloon.displayName = 'Balloon';
