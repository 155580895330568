import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesAnalytics = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.85 6.59L15.97.71A2.25 2.25 0 0014.38 0H3.75A2.25 2.25 0 001.5 2.3v19.4A2.25 2.25 0 003.75 24h16.5a2.25 2.25 0 002.25-2.25V8.18a2.25 2.25 0 00-.65-1.59zM15 1.86l5.69 5.69h-4.94A.75.75 0 0115 6.8V1.86zm6 19.84a.75.75 0 01-.75.75H3.75A.75.75 0 013 21.7V2.3a.75.75 0 01.75-.75h9.75v5.26a2.25 2.25 0 002.25 2.25H21V21.7z" />
        <path d="M6.87 8.6a.64.64 0 01.64.64v5.58l1.8-1.68a2.33 2.33 0 013.13 0l.62.58a1 1 0 00.82.26 1 1 0 00.71-.43l1.34-2.09a.64.64 0 011.08.69l-1.34 2.1a2.261 2.261 0 01-1.64 1 2.32 2.32 0 01-1.83-.59l-.62-.58a1 1 0 00-1.4 0l-2.67 2.49v1.65h9.62a.64.64 0 110 1.28H6.87a.64.64 0 01-.64-.64V9.24a.64.64 0 01.64-.64z" />
      </g>
    );
  }
);
FilesAnalytics.displayName = 'FilesAnalytics';
