import { FormControl as FormControlComponent, FormControlProps } from '@chakra-ui/form-control';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import * as React from 'react';
import { FormErrorIcon } from './FormErrorIcon';
import { FormErrorMessage } from './FormErrorMessage';
import { FormHelperText } from './FormHelperText';
import { FormLabel } from './FormLabel';

export type IFormControlProps = FormControlProps;

type IFormControlComponent = ComponentWithAs<'div', IFormControlProps> & {
  ErrorIcon: typeof FormErrorIcon;
  ErrorMessage: typeof FormErrorMessage;
  HelperText: typeof FormHelperText;
  Label: typeof FormLabel;
};

export const FormControl = forwardRef<IFormControlProps, 'div'>((props, ref) => {
  return <FormControlComponent {...props} ref={ref} />;
}) as IFormControlComponent;

FormControl.ErrorIcon = FormErrorIcon;
FormControl.ErrorMessage = FormErrorMessage;
FormControl.HelperText = FormHelperText;
FormControl.Label = FormLabel;
