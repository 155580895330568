import { createContext } from 'react';
import {
  questionValidator,
  getMultiSelectAnswers,
  removeTypenameFromAllAnswers,
  getActiveStep,
} from './helpers';
import { questionTypes } from '../../constants';

export const SurveyDispatchContext = createContext();
export const SurveyContext = createContext();

export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const STEP_BACK = 'STEP_BACK';
export const UPDATE_VALIDATION = 'UPDATE_VALIDATION';
export const RESET = 'RESET';
export const ANSWER_SAVED = 'ANSWER_SAVED';
export const SET_UPLOADED_IMAGES = 'SET_UPLOADED_IMAGES';
export const ADD_UPLOADED_IMAGE = 'ADD_UPLOADED_IMAGE';
export const DELETE_UPLOADED_IMAGE = 'DELETE_UPLOADED_IMAGE';

export function surveyReducer(state, action) {
  switch (action.type) {
    case UPDATE_ANSWER: {
      const { questionId, questionType, value } = action.payload;
      const isMultiSelect =
        questionType === questionTypes.NON_BINARY_MULTI_CHOICE;
      return {
        ...state,
        answers: state.answers.map((answer) => {
          if (answer.questionId !== questionId) return answer;

          return {
            ...answer,
            isValid: questionValidator(isMultiSelect, value),
            selectedAnswer: isMultiSelect
              ? getMultiSelectAnswers(value, answer.selectedAnswer)
              : [value],
          };
        }),
      };
    }
    case UPDATE_COMMENT: {
      const { questionId, value } = action.payload;
      return {
        ...state,
        answers: state.answers.map((answer) => {
          if (answer.questionId !== questionId) return answer;

          return {
            ...answer,
            comment: value,
          };
        }),
      };
    }
    case SET_UPLOADED_IMAGES: {
      const { uploadedImages } = action.payload;

      return {
        ...state,
        isFetchingUploadedImages: false,
        uploadedImageKeys: uploadedImages,
      };
    }
    case ADD_UPLOADED_IMAGE: {
      const { imageKey } = action.payload;
      return {
        ...state,
        uploadedImageKeys: [...state.uploadedImageKeys, imageKey],
      };
    }
    case DELETE_UPLOADED_IMAGE: {
      const { imageKey } = action.payload;
      return {
        ...state,
        uploadedImageKeys: state.uploadedImageKeys.filter(
          (imageKeyFromState) => imageKeyFromState !== imageKey
        ),
      };
    }
    case STEP_BACK: {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    }
    case UPDATE_VALIDATION: {
      const { questionId } = action.payload;
      return {
        ...state,
        answers: state.answers.map((answer) => {
          if (answer.questionId !== questionId) return answer;

          return {
            ...answer,
            isValid: !answer.isValid,
          };
        }),
      };
    }
    case ANSWER_SAVED: {
      const { questionId } = action.payload;
      const updatedAnswersArray = state.answers.map((answer) => {
        if (answer.questionId !== questionId) return answer;

        return {
          ...answer,
          isComplete: true,
        };
      });
      return {
        ...state,
        activeStep: state.activeStep + 1,
        answers: updatedAnswersArray,
      };
    }
    case RESET: {
      const {
        surveyPageData: { getAnswers, getSurvey, getSurveyInfo },
      } = action.payload;

      return {
        activeStep: getActiveStep(getAnswers),
        isFetchingUploadedImages: false,
        questions: getSurvey,
        isSurveyComplete: getAnswers.every((answer) => answer.isComplete),
        isSurveyEditable: getSurveyInfo.surveyState.isEditable,
        // Get rid of __typename property as it causes
        // trouble when using the answer object for a mutation
        answers: removeTypenameFromAllAnswers(getAnswers),
        uploadedImageKeys: [],
        assessmentStatus: getSurveyInfo.assessmentStatus,
        surveyInfo: getSurveyInfo,
      };
    }
    default:
      return state;
  }
}

export function updateAnswerAction(questionId, questionType, value) {
  return {
    type: UPDATE_ANSWER,
    payload: {
      questionId,
      questionType,
      value,
    },
  };
}

export function updateCommentAction(questionId, value) {
  return {
    type: UPDATE_COMMENT,
    payload: {
      questionId,
      value,
    },
  };
}

export function stepBackAction() {
  return { type: STEP_BACK };
}

export function updateValidationAction(questionId) {
  return {
    type: UPDATE_VALIDATION,
    payload: { questionId },
  };
}

export function resetAction(surveyPageData, assessmentYear) {
  return {
    type: RESET,
    payload: { surveyPageData, assessmentYear },
  };
}

export function answerSavedAction(questionId) {
  return {
    type: ANSWER_SAVED,
    payload: { questionId },
  };
}

export function setUploadedImages(uploadedImages) {
  return {
    type: SET_UPLOADED_IMAGES,
    payload: { uploadedImages },
  };
}

export function addUploadedImage(imageKey) {
  return {
    type: ADD_UPLOADED_IMAGE,
    payload: { imageKey },
  };
}

export function deleteUploadedImage(imageKey) {
  return {
    type: DELETE_UPLOADED_IMAGE,
    payload: { imageKey },
  };
}
