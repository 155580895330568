import { forwardRef } from '@chakra-ui/system';
import { TableCellProps, Td as TableCellComponent } from '@chakra-ui/table';
import { dataAttr } from '@chakra-ui/utils';
import * as React from 'react';

export interface ITableCellProps extends TableCellProps {
  /* determines whether this column will be sortable */
  isSortable?: boolean;
  /* determines whether this column is sorted */
  isSorted?: boolean;
  /* determines whether this column is expandable */
  isExpandable?: boolean;
  /* determines whether this column is expanded */
  isExpanded?: boolean;
  /* determines what depth this cell is at, when expanded */
  rowDepth?: number;
}

export const TableCell = forwardRef<ITableCellProps, 'td'>(
  (
    {
      isSortable = false,
      isSorted = false,
      isExpandable = false,
      isExpanded = false,
      rowDepth = 0,
      ...rest
    },
    ref
  ) => {
    return (
      <TableCellComponent
        data-expandable={dataAttr(isExpandable)}
        data-expanded={dataAttr(isExpanded)}
        data-row-depth={rowDepth}
        data-sortable={dataAttr(isSortable)}
        data-sorted={dataAttr(isSorted)}
        {...rest}
        ref={ref}
      />
    );
  }
);
