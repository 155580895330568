import { IStackDividerProps, StackDivider as StackDividerInternal } from './StackDivider';

export * from './HorizontalStack';
export * from './Stack';
export * from './VerticalStack';
export { IStackDividerProps };

//TODO: remove subcomponent exports in the next major release
/**
 * @deprecated use `Stack.Divider` instead
 */
export const StackDivider = StackDividerInternal;
