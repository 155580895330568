import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const Faq = forwardRef<IIconProps, 'svg'>(({ viewBox = '0 0 24 24', ...rest }, ref) => {
  return React.createElement(
    Icon,
    {
      viewBox,
      ...rest,
    },
    ref,
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M2.25 1.5a.75.75 0 00-.75.75v15a.75.75 0 00.75.75h3a.75.75 0 01.75.75v3l4.8-3.6a.75.75 0 01.45-.15h10.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75H2.25zM.659.659A2.25 2.25 0 012.25 0h19.5A2.25 2.25 0 0124 2.25v15a2.25 2.25 0 01-2.25 2.25H11.5l-5.8 4.35a.75.75 0 01-1.2-.6V19.5H2.25A2.25 2.25 0 010 17.25v-15C0 1.653.237 1.081.659.659z"
        fillRule="evenodd"
      />
      <path d="M11.325 11.46h1.227c-.037-.166-.091-.367-.091-.587 0-.659.494-1.117 1.3-1.85 1.025-.915 1.739-1.738 1.739-2.965C15.5 4.263 14.053 3 12.277 3c-1.61 0-2.8.934-3.277 2.563l1.19.495c.366-1.172 1.117-1.758 2.05-1.758.99 0 1.795.75 1.795 1.85 0 .896-.512 1.39-1.428 2.251-.787.696-1.465 1.337-1.465 2.252 0 .293.074.586.183.806zm.696 4.027c.641 0 1.172-.476 1.172-1.153 0-.678-.531-1.154-1.172-1.154-.604 0-1.153.476-1.153 1.154 0 .677.549 1.153 1.153 1.153z" />
    </g>
  );
});
Faq.displayName = 'Faq';
