import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesVideoBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M21.91 6a.93.93 0 00-.21-.33L16.29.29a1 1 0 00-.66-.29H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.41a1 1 0 00-.09-.41zm-5.72 8.3L9.8 17.8a1.33 1.33 0 01-.66.17 1.36 1.36 0 01-1.37-1.37v-7a1.36 1.36 0 01.67-1.18 1.37 1.37 0 011.36 0l6.39 3.51a1.37 1.37 0 010 2.4v-.03z" />
        <path d="M9.77 15.57l4.45-2.44-4.45-2.44v4.88z" />
      </g>
    );
  }
);
FilesVideoBold.displayName = 'FilesVideoBold';
