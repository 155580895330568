import { Avatar as AvatarComponent, AvatarProps } from '@chakra-ui/avatar';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import { LegoUserFacelessBold } from '@lego/klik-ui-icons';
import * as React from 'react';
import { AvatarBadge } from './AvatarBadge';

export type IAvatarProps = AvatarProps;

type IAvatarComponent = ComponentWithAs<'span', IAvatarProps> & {
  Badge: typeof AvatarBadge;
};

export const Avatar = forwardRef<IAvatarProps, 'span'>((props, ref) => {
  return <AvatarComponent icon={<LegoUserFacelessBold />} showBorder={true} {...props} ref={ref} />;
}) as IAvatarComponent;

Avatar.Badge = AvatarBadge;
