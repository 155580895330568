import PropTypes from 'prop-types';
import { GenericConfirmationModal } from './GenericConfirmationModal';
import { Attach } from '@lego/klik-icons-react';

const MissingProofWarningModal = ({ onClose, onConfirm, onExitHandler }) => (
  <GenericConfirmationModal
    status="warning"
    header="Missing Attachments"
    onConfirmText="Back To Edit"
    onCloseText="Cancel"
    body="Some of the answers that you have selected require proof of performance to be considered valid. </br> 
          Plese upload the corresponding attachments."
    onClose={onClose}
    onConfirm={onConfirm}
    onExitHandler={onExitHandler}
    icon={<Attach />}
  />
);

MissingProofWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onExitHandler: PropTypes.func,
};

export { MissingProofWarningModal };
