import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const CloudUploadDownloadBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M3.802 17.112a.953.953 0 00.83-.226c.232-.206.368-.512.37-.835.005-.515-.332-.96-.8-1.056a2.83 2.83 0 01-1.563-.794 2.687 2.687 0 01-.636-1.928c0-1.543 1.157-2.793 2.585-2.794.18 0 .359.02.534.06a.945.945 0 00.827-.216c.232-.202.369-.505.373-.826.084-6.214 8.297-7.953 10.818-2.21.165.397.539.642.94.618 1.148-.06 2.256.458 3 1.404a4.41 4.41 0 01.906 3.017c-.052 1.375-.796 2.608-1.94 3.214a1.099 1.099 0 00-.55.96 1.1 1.1 0 00.463.915c.291.198.656.22.964.057 1.863-.983 3.052-3.017 3.072-5.256.122-3.306-2.169-6.137-5.217-6.449a.249.249 0 01-.19-.13C16.968 1.718 13.866.161 10.757.71 7.65 1.257 5.166 3.8 4.491 7.123a.258.258 0 01-.227.216C2.758 7.457 1.4 8.36.623 9.76a5.146 5.146 0 00-.62 2.643c-.021 1.247.424 2.45 1.235 3.338a4.734 4.734 0 002.564 1.371z" />
        <path d="M10 11.522c0-.596-.448-1.08-1-1.08s-1 .484-1 1.08v6.207c0 .15-.111.27-.25.27H6.502a.495.495 0 00-.437.278.578.578 0 00.013.549l2.5 4.32c.089.154.244.25.412.254H9a.49.49 0 00.412-.234l2.748-4.321a.577.577 0 00.03-.557.496.496 0 00-.442-.289h-1.5c-.137 0-.248-.121-.248-.27v-6.207zm5.998 3.241c0-.15.112-.27.25-.27h1.5a.484.484 0 00.432-.27.566.566 0 00-.012-.54c-.009-.015-.008-.035-.018-.05l-2.752-4.05a.488.488 0 00-.414-.22.492.492 0 00-.405.24l-2.499 4.05c0 .01 0 .02-.009.03a.57.57 0 00-.008.54.488.488 0 00.433.27h1.25c.138 0 .25.12.25.27v6.481c0 .596.447 1.08 1 1.08.551 0 .999-.484.999-1.08l.003-6.481z" />
      </g>
    );
  }
);
CloudUploadDownloadBold.displayName = 'CloudUploadDownloadBold';
