import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const LearningLibrary = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <g clipPath="url(#learning-library_svg__a)">
          <path d="M2.196 2.296h.18c3.694.19 6.689 1.049 8.535 2.336a.749.749 0 01-.858 1.228C8.466 4.762 5.73 3.994 2.286 3.794a.749.749 0 00-.799.649V17.88a.749.749 0 00.69.748c5.61.25 9.773 2.077 10.501 4.383a.748.748 0 01-1.387.55v-.1c-.49-1.528-4.103-3.115-9.155-3.335A2.246 2.246 0 010 18.029V4.553a2.246 2.246 0 012.196-2.257z" />
          <path d="M23.19 8.266a.749.749 0 01.74.65V17.9a2.246 2.246 0 01-2.117 2.256c-4.932.22-8.496 1.737-9.105 3.234v.11a.749.749 0 01-1.427-.45c.728-2.305 4.901-4.132 10.492-4.382a.749.749 0 00.699-.658V8.985a.749.749 0 01.718-.719zM3.823 6.749c1.96.22 3.886.668 5.74 1.338a.749.749 0 11-.508 1.407 23.65 23.65 0 00-5.391-1.258.75.75 0 01.07-1.497l.09.01zm.01 3.803c1.955.226 3.878.674 5.73 1.338a.75.75 0 11-.508 1.408 24.228 24.228 0 00-5.401-1.258.749.749 0 01.07-1.488h.11zm-.159 4.033h.1c1.976.24 3.92.705 5.79 1.388a.75.75 0 01-.5 1.408 24.459 24.459 0 00-5.45-1.258.75.75 0 11.07-1.498l-.01-.04z" />
          <path d="M11.98 9.764a.749.749 0 01.738.649V23.24a.749.749 0 01-1.487.1V10.511a.749.749 0 01.748-.748zM17.2 0a.749.749 0 01.77.68v1.596a.749.749 0 01-1.488.1V.78A.748.748 0 0117.2 0zm-5.33 2.716a.749.749 0 01.998-.07l.08.07 1.028 1.058a.753.753 0 11-.998 1.128v-.07L11.87 3.774a.749.749 0 010-1.058zm9.603 0a.753.753 0 011.128.998l-.07.08-1.058 1.038a.753.753 0 01-1.128-.998l.07-.08 1.058-1.038zM17.2 11.98a.749.749 0 01.74.649v1.597a.749.749 0 01-1.488.1v-1.597a.749.749 0 01.749-.75z" />
          <path d="M13.627 7.158a3.744 3.744 0 001.218 3.993l.12.09v1.518a.749.749 0 00.748.748h3.095a.749.749 0 00.649-.739v-1.517l.16-.13a3.744 3.744 0 10-5.93-4.133l-.06.17zM17.62 6.04a2.246 2.246 0 01.729 4.153l-.09.06a.749.749 0 00-.28.589v1.138h-1.497v-1.138a.749.749 0 00-.37-.65A2.246 2.246 0 0117.63 6.04h-.01z" />
        </g>
        <defs>
          <clipPath id="learning-library_svg__a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </g>
    );
  }
);
LearningLibrary.displayName = 'LearningLibrary';
