import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const FilesCodeBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M16.297.29L21.71 5.7a1 1 0 01.29.71V22a2 2 0 01-2.001 2H4.001A2.001 2.001 0 012 22V2a2 2 0 012.001-2h11.596a1 1 0 01.7.29zM19.51 22a.5.5 0 00.5-.5V7a.5.5 0 00-.15-.35l-4.532-4.5a.5.5 0 00-.32-.15H4.5a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h15.01z" />
        <path d="M15.577 1.43H2.9v21.41h18.28V7.26l-5.603-5.83zM8.573 13.31L11.065 16a1 1 0 010 1.39 1 1 0 01-1.411 0l-3.382-3.56a1 1 0 010-1.36l3.332-3.58a1 1 0 111.46 1.36L8.535 13a.25.25 0 000 .32l.04-.01zm9.215.51l-3.382 3.58a1.013 1.013 0 01-1.46-1.4l2.54-2.72a.25.25 0 000-.34l-2.54-2.72a.999.999 0 01.749-1.774 1.001 1.001 0 01.711.444l3.332 3.57a1 1 0 010 1.37l.05-.01z" />
      </g>
    );
  }
);
FilesCodeBold.displayName = 'FilesCodeBold';
