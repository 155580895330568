import { forwardRef } from '@chakra-ui/system';
import { Icon, IIconProps } from '@lego/klik-ui-icon';
import * as React from 'react';

export const StackBold = forwardRef<IIconProps, 'svg'>(
  ({ viewBox = '0 0 24 24', ...rest }, ref) => {
    return React.createElement(
      Icon,
      {
        viewBox,
        ...rest,
      },
      ref,
      <g fill="currentColor">
        <path d="M1.337 7.074l10.63 6.01a.54.54 0 00.55 0l10.64-6.01a.551.551 0 000-1.002L12.217.071a.57.57 0 00-.551 0L1.356 6.082a.561.561 0 00-.02.992z" />
        <path d="M23.097 11.462l-2.144-1.182a.58.58 0 00-.571 0l-7.835 4.338a.601.601 0 01-.58 0l-7.765-4.459a.551.551 0 00-.581 0l-2.254 1.293a.59.59 0 000 1.002l10.57 6.01a.58.58 0 00.57 0l10.59-6.01a.582.582 0 000-.992z" />
        <path d="M23.007 16.912l-2.144-1.182a.611.611 0 00-.561 0l-7.845 4.348a.582.582 0 01-.57 0L4.081 15.61a.601.601 0 00-.581 0l-2.214 1.302a.58.58 0 000 1.002l10.57 6.011a.58.58 0 00.57 0l10.59-6.011a.58.58 0 00-.01-1.002z" />
      </g>
    );
  }
);
StackBold.displayName = 'StackBold';
