import { List as ListComponent, ListProps } from '@chakra-ui/layout';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import * as React from 'react';
import { ListItem } from './ListItem';

export type IListProps = ListProps;

type IListComponent = ComponentWithAs<'ul', IListProps> & {
  Item: typeof ListItem;
};

export const List = forwardRef<IListProps, 'ul'>((props, ref) => {
  return <ListComponent {...props} ref={ref} />;
}) as IListComponent;

List.Item = ListItem;
